import { ReactNode, useContext } from 'react';
import { Button } from '@careeros/coco';
import './success-modal.scss';
import { ModalContext } from '@/components/modal/modal-provider';

export interface SuccessModalProps {
  title?: string;
  children?: ReactNode;
  image?: string;
  imagePosition?: 'top' | 'bottom';
  isTitleBlack?: boolean;
  buttonLabel?: string;
  containerPadding?: string;
  handleClose?: () => void;
  withCloseButton?: boolean;
}
/**
 * Primary UI component for the modal that warns the user about moving to the next step
 */
export const SuccessModal = ({
  title,
  children: text,
  image,
  imagePosition = 'top',
  isTitleBlack = false,
  buttonLabel,
  handleClose,
  withCloseButton = false,
  containerPadding = '26px 112px 130px',
}: SuccessModalProps) => {
  const { closeModal } = useContext(ModalContext) as any;
  const defaultTitle = 'Congratulations!';
  const defaultText = 'The sky’s the limit. We wish you a wonderful start to your career!';

  return (
    <div className="success-modal" style={{ padding: containerPadding }}>
      {withCloseButton && (
        <i
          onClick={handleClose || closeModal}
          className="success-modal__close bi bi-x-lg"
        />
      )}
      <div className={`success-modal__content ${imagePosition === 'bottom' ? 'success-modal__content--reverse' : ''}`}>
        {image && <img className="success-modal__image" src={image} alt="" />}
        <div className="success-modal__text">
          <div className={`success-modal__title ${isTitleBlack ? 'success-modal__title--black' : ''}`}>
            {title || defaultTitle}
          </div>
          <div className="success-modal__text-description">{text || defaultText}</div>
        </div>
      </div>
      {buttonLabel && (
        <Button
          label={buttonLabel}
          handleClick={handleClose}
          mode="primary"
          size="large"
        />
      )}
    </div>
  );
};
