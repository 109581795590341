export const postedDateStaticOptions = [
  {
    name: 'Last Week',
    value: '1',
  },
  {
    name: 'Last Month',
    value: '2',
  },
];

export const workTypeStaticOptions = [
  {
    name: 'Remote',
    value: 'Remote',
  },
  {
    name: 'Hybrid',
    value: 'Hybrid',
  },
  {
    name: 'On-site',
    value: 'On-Site',
  },
];

export const contractStaticOptions = [
  {
    name: 'Full-time',
    value: 'Full-time',
  },
  {
    name: 'Part-time',
    value: 'Part-time',
  },
  {
    name: 'Internship',
    value: 'Internship',
  },
];

export const languageMap: { [key: string]: string } = {
  EN: 'English',
  CA: 'Catalan',
  PT: 'Portuguese',
  ES: 'Spanish',
  FR: 'French',
  IT: 'Italian',
  DE: 'German',
  ZH: 'Chinese',
  JA: 'Japanese',
  KO: 'Korean',
  RU: 'Russian',
  AR: 'Arabic',
  HI: 'Hindi',
  BN: 'Bengali',
  NL: 'Dutch',
  SV: 'Swedish',
  NO: 'Norwegian',
  FI: 'Finnish',
  DA: 'Danish',
  PL: 'Polish',
  CS: 'Czech',
  SK: 'Slovak',
  HU: 'Hungarian',
  RO: 'Romanian',
  BG: 'Bulgarian',
  EL: 'Greek',
  TR: 'Turkish',
  HE: 'Hebrew',
  TH: 'Thai',
  VI: 'Vietnamese',
  ID: 'Indonesian',
  MS: 'Malay',
  FIL: 'Filipino',
  UK: 'Ukrainian',
  SR: 'Serbian',
  HR: 'Croatian',
  SL: 'Slovenian',
  ET: 'Estonian',
  LV: 'Latvian',
  LT: 'Lithuanian',
  IS: 'Icelandic',
  GA: 'Irish',
  MT: 'Maltese',
  CY: 'Welsh',
  EU: 'Basque',
  GL: 'Galician',
  FA: 'Persian',
  UR: 'Urdu',
  SW: 'Swahili',
  AM: 'Amharic',
  KM: 'Khmer',
  LO: 'Lao',
  MY: 'Burmese',
  NE: 'Nepali',
  SI: 'Sinhala',
  KA: 'Georgian',
  HY: 'Armenian',
  AZ: 'Azerbaijani',
  BE: 'Belarusian',
  KK: 'Kazakh',
  KY: 'Kyrgyz',
  TG: 'Tajik',
  TK: 'Turkmen',
  UZ: 'Uzbek',
  MN: 'Mongolian',
  AF: 'AF',
  SQ: 'SQ',
  YO: 'YO',
  ZU: 'ZU',
};
