import { fetchStrapiAPI } from './fetch-api';

const fetchTrialUniversities = async (page: number) => fetchStrapiAPI('/universities', {
  sort: { createdAt: 'asc' },
  populate: {
    logo: { fields: ['url'] },
  },
  pagination: {
    page,
  },
});

export async function getTrialUniversities(output: any = {}, start = 1) {
  const universities = await fetchTrialUniversities(start);

  if (universities.meta.pagination.page < universities.meta.pagination.pageCount) {
    const nextPage = await getTrialUniversities(output, start + 1);
    universities.data = universities.data.concat(nextPage.data);
  }

  return universities;
}

export const getCode = async (code: string) => {
  const urlParamsObject = {
    filters: { code },
    populate: '*',
  };
  return fetchStrapiAPI('/codes', urlParamsObject);
};

export const getTrialCodes = async () => fetchStrapiAPI('/codes');

export const getUniversity = async (id: string) => fetchStrapiAPI(`/universities/${id}`);

const fetchTrialUniversitiesByName = async (nameQuery: string, isStrictComparison: boolean, page: number) => {
  const filterOperator = isStrictComparison ? '$eqi' : '$containsi';

  return fetchStrapiAPI('/universities', {
    sort: { createdAt: 'asc' },
    populate: {
      logo: { fields: ['url'] },
    },
    pagination: {
      page,
    },
    filters: {
      name: {
        [filterOperator]: nameQuery,
      },
    },
  });
};

export async function searchTrialUniversitiesByName(query: string, isStrictComparison = false, output: any = {}, start = 1) {
  const universities = await fetchTrialUniversitiesByName(query, isStrictComparison, start);

  if (universities.meta.pagination.page < universities.meta.pagination.pageCount) {
    const nextPage = await searchTrialUniversitiesByName(query, isStrictComparison, output, start + 1);
    universities.data = universities.data.concat(nextPage.data);
  }

  return universities;
}
