import { Button } from '@careeros/coco';
import { downloadPDF } from '@/domains/core/resume/helpers/save-resume';
import { getStudentData } from '@/services/api/advisor';

type Props = {
  universityID?: string;
  cohortID?: string;
};

export const ExportStudentData = ({ universityID, cohortID }: Props) => {
  const downloadStudentsData = async () => {
    let response;
    if (universityID && !cohortID) {
      response = await getStudentData(universityID);
    } else if (universityID && cohortID) {
      response = await getStudentData(universityID, [cohortID]);
    }
    const blob = await response.response;
    const fileName = 'students-data.xlsx';
    const blobUrl = URL.createObjectURL(blob);

    await downloadPDF(blobUrl, fileName);
  };

  return (
    <Button
      mode="primary"
      outlined
      size="medium"
      label="Export Student Data"
      onClick={downloadStudentsData}
      icon="bi bi-cloud-download"
    />
  );
};
