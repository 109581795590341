import { Tooltip } from '@/components/tooltip/tooltip';
import './applied-badge.scss';
import { Badge } from '@/components/badge/badge';

type Props = {
  handleClick: React.MouseEventHandler<HTMLDivElement>;
};

export const AppliedBadge = ({ handleClick }: Props) => (
  <div className="applied-badge" data-testid="applied-badge" onClick={handleClick}>
    <Tooltip label="Applied. Click to change" withArrow position="top">
      <Badge
        color="dark-gradient"
        label="Applied"
        icon="bi bi-check-circle"
        iconPosition="left"
      />
    </Tooltip>
  </div>
);
