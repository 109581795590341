import { UniversityResponse } from '@/services/api/student';
import {
  AdminLinks, BetaUsersLinks, CareerAdvisorLinks, StudentLinks,
} from '../sidebar-links';
import { addUniversityIdToUrl } from './add-university-id-to-url';
import { LinkType } from '../types/types';

const getFormattedCareerAdvisorLinks = (university: UniversityResponse) => {
  const formatFor = ['dashboard-advisor', 'students'];

  return CareerAdvisorLinks.map((link) => {
    if (formatFor.includes(link.url)) {
      return addUniversityIdToUrl(link, university.id);
    }
    return link;
  });
};

export const getLinksForRole = (roles: string[], university: UniversityResponse) => {
  let visibleLinks: LinkType[] = [];
  if (roles.includes('CareerAdvisor')) {
    const filteringFunction = (link: LinkType) => (!link.includedForUniversities
      || link.includedForUniversities.includes(university.name))
      && (!link.excludedForUniversities || !link.excludedForUniversities?.includes(university.name));
    visibleLinks = [...getFormattedCareerAdvisorLinks(university), ...StudentLinks].filter(
      filteringFunction,
    );
  } else if (roles.includes('Admin')) {
    visibleLinks = [...AdminLinks, ...getFormattedCareerAdvisorLinks(university), ...StudentLinks];
  } else {
    visibleLinks = StudentLinks.filter(
      (link) => !link.excludedForUniversities
      || !link.excludedForUniversities.includes(university.name),
    );
  }
  if (roles.includes('BetaUser')) {
    visibleLinks = [
      ...visibleLinks.filter(
        (link) => link.label !== 'Saved Companies' && link.label !== 'Archive',
      ),
      ...BetaUsersLinks,
    ];
  }

  return visibleLinks.sort((a, b) => a.order - b.order);
};
