import { useEffect, useState } from 'react';
import { Button } from '@careeros/coco';
import { useParams } from 'react-router-dom';
import { Loader } from '@/components/loader/loader';
import './styles/overview.scss';
import { CompanyKanbanBoard } from '@/domains/core/advisor';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';
import { Student } from '@/domains/core/advisor/types';
import { useGetSingleCompany } from '@/services/queries/company';
import { useStudentsByUniversity } from '@/services/hooks/use-students-by-university';
import { useGetStudentsByCohort } from '@/services/queries/student';

const companies: string[] = [
  'Company Saved',
  'Networking',
  'Applying',
  'Applied',
  'Interviewing',
  'Offer',
  'Archived',
  'Rejected',
];

function CompanyOverviewPage() {
  const { companyID, universityID, cohortID } = useParams();
  const { data: company } = useGetSingleCompany(companyID, {
    enabled: !!companyID,
  });
  const { data: students } = cohortID
    ? useGetStudentsByCohort(cohortID)
    : useStudentsByUniversity('students', universityID);

  const [kanbanLayout, setKanbanLayout] = useState({});

  const computeColumnData = (data: any, column: string) => {
    const filteredData = data.filter((student: any) => student?.applications?.length > 0 && student.applications.some((tile: any) => tile.status === column && tile.company_id === (companyID || company.id)));
    const mappedData = filteredData.map((student: any) => ({
      ...student,
      company_name: `${student.first_name} ${student.last_name}`,
      industry: student.cohort,
      studentID: student.id,
      jobs: student.applications[0].jobs || [],
    }));

    return mappedData;
  };
  const computeKanbanLayout = (allStudents: Student[]) => {
    const kanban = {
      'Company Saved': computeColumnData(allStudents, 'saved'),
      Networking: computeColumnData(allStudents, 'networking'),
      Applying: computeColumnData(allStudents, 'applying'),
      Applied: computeColumnData(allStudents, 'applied'),
      Interviewing: computeColumnData(allStudents, 'interviewing'),
      Offer: computeColumnData(allStudents, 'offer'),
      Rejected: computeColumnData(allStudents, 'reject'),
      Archived: computeColumnData(allStudents, 'archive'),
    };
    setKanbanLayout(kanban);
  };

  const backButtonClicked = () => {
    // eslint-disable-next-line no-restricted-globals
    history.back();
  };

  useEffect(() => {
    if (students) {
      const studentsWhoHaveThisCompany = students.filter(
        (student: Student) => student.applications.some(
          (tile: any) => tile.company_id === (companyID || company.id),
        ),
      );
      computeKanbanLayout(studentsWhoHaveThisCompany);
    }
  }, [students, companyID, company]);

  return (
    <div className="advisor-overview">
      <div className="advisor-overview__top">
        <div
          className="advisor-overview__top__back-button"
        >
          <Button
            mode="invisible"
            size="medium"
            label="Back"
            icon="bi bi-arrow-left"
            handleClick={backButtonClicked}
          />
        </div>
        <div className="advisor-overview__top__company-name">
          <img className="advisor-overview__top__company-name__logo" src={company?.logo_url} alt="" />
          <span className="advisor-overview__top__company-name__name">
            {company?.name}
          </span>
        </div>
      </div>
      <CompanyKanbanBoard
        columns={companies}
        layout={kanbanLayout}
      />
    </div>
  );
}

export default CustomWithAuthenticationRequired(CompanyOverviewPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
