/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
import AMSTemplate from '../components/resume-templates/ams-template';
import ASBTemplate from '../components/resume-templates/asb-template';
import BayesTemplate from '../components/resume-templates/bayes-template';
import EsadeTemplate from '../components/resume-templates/esade-template';
import ESMTATSTemplate from '../components/resume-templates/esmt-ast-template';
import ESMTTemplate from '../components/resume-templates/esmt-template';
import GeorgiaTemplate from '../components/resume-templates/georgia-template';
import HultTemplate from '../components/resume-templates/hult-template';
import RiceTemplate from '../components/resume-templates/rice-template';
import { ResumeSchema, TemplateNames } from '../types';

export const RESUME_TEMPLATES: any = {
  'Modern Simple': EsadeTemplate,
  AMS: AMSTemplate,
  ESMT: ESMTTemplate,
  Hult: HultTemplate,
  Rice: RiceTemplate,
  'Modern Minimalist': AMSTemplate,
  Georgia: GeorgiaTemplate,
  'ESMT ATS': ESMTATSTemplate,
  ASB: ASBTemplate,
  Bayes: BayesTemplate,
};

export const RESUME_SIZE_LIMITS = {
  ESMT: 1,
  'Modern Simple': 1,
  Hult: 1,
  Rice: 1,
  'Modern Minimalist': 1,
  Georgia: 1,
  'ESMT ATS': 1,
  ASB: 1,
  AMS: 1,
  Bayes: 1,
};

export type DefaultFields = {
  [K in keyof ResumeSchema]: ResumeSchema[K] extends (infer U)[]
    ? { [P in keyof U]: boolean }[]
    : { [P in keyof ResumeSchema[K]]: boolean }
};

export type ESMTFields = Omit<DefaultFields, 'additional_information'>;
export type RiceFields = Omit<DefaultFields, 'additional_information'>;
export type HultFields = Pick<DefaultFields, 'basics' | 'education' | 'languages' | 'skills' | 'work' | 'additional_information'>;

const HULT_FIELDS: HultFields = {
  basics: {
    emails: true,
    image: true,
    label: true,
    location: false,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: true,
    summaryTitle: false,
    url: true,
    nationality: false,
    work_permit: false,
    birth_date: false,
    availability: false,
    profile_picture_url: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: true,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  additional_information: {
    bullets: true,
    gmatscore: false,
    subheadings: false,
    order: false,
  },
};

const BAYES_FIELDS: DefaultFields = {
  basics: {
    emails: true,
    image: false,
    label: false,
    location: true,
    name: true,
    phone_numbers: true,
    profiles: false,
    summary: false,
    summaryTitle: false,
    url: true,
    nationality: false,
    work_permit: false,
    birth_date: false,
    availability: false,
    profile_picture_url: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: true,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  additional_information: {
    bullets: true,
    gmatscore: false,
    subheadings: true,
    order: true,
  },
};

const ESMT_FIELDS: ESMTFields = {
  basics: {
    emails: true,
    image: true,
    label: true,
    location: true,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: true,
    summaryTitle: true,
    url: true,
    nationality: true,
    work_permit: false,
    birth_date: true,
    profile_picture_url: true,
    avatar_url: true,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: true,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  work_section_subheading: {
    value: true,
  },
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  additional_information: {
    bullets: true,
    gmatscore: false,
    subheadings: true,
    order: true,
  },
};

const MODERN_SIMPLE_FIELDS: DefaultFields = {
  basics: {
    emails: true,
    image: true,
    label: true,
    location: false,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: false,
    summaryTitle: false,
    url: true,
    nationality: true,
    work_permit: true,
    birth_date: false,
    profile_picture_url: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: true,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  additional_information: {
    bullets: true,
    gmatscore: false,
    subheadings: false,
    order: false,
  },
};

const MODERN_MINIMALIST_FIELDS: DefaultFields = {
  basics: {
    emails: true,
    image: true,
    label: true,
    location: false,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: true,
    summaryTitle: false,
    url: true,
    nationality: false,
    work_permit: false,
    birth_date: false,
    profile_picture_url: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: true,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  additional_information: {
    bullets: true,
    gmatscore: false,
    subheadings: false,
    order: false,
  },
};

const RICE_FIELDS: RiceFields = {
  basics: {
    emails: true,
    image: true,
    label: true,
    location: true,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: false,
    summaryTitle: false,
    url: true,
    nationality: false,
    work_permit: false,
    birth_date: false,
    profile_picture_url: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: true,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
};

const GEORGIA_FIELDS: DefaultFields = {
  basics: {
    emails: true,
    image: true,
    label: true,
    location: true,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: true,
    summaryTitle: true,
    url: true,
    nationality: false,
    work_permit: false,
    birth_date: false,
    profile_picture_url: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: true,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  additional_information: {
    bullets: true,
    gmatscore: false,
    subheadings: false,
    order: false,
  },
};

const ESMT_ATS_FIELDS: DefaultFields = {
  basics: {
    emails: true,
    image: true,
    label: true,
    location: true,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: true,
    url: true,
    nationality: true,
    work_permit: false,
    birth_date: true,
    profile_picture_url: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: true,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  additional_information: {
    bullets: true,
    gmatscore: false,
    subheadings: false,
    order: false,
  },
};

const ASB_FIELDS: DefaultFields = {
  basics: {
    emails: true,
    image: true,
    label: true,
    location: true,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: true,
    url: true,
    nationality: false,
    work_permit: false,
    birth_date: false,
    profile_picture_url: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: true,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  additional_information: {
    bullets: true,
    gmatscore: false,
    subheadings: false,
    order: false,
  },
};

const AMS_FIELDS: DefaultFields = {
  basics: {
    emails: true,
    image: true,
    label: true,
    location: false,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: true,
    url: true,
    nationality: false,
    work_permit: false,
    birth_date: false,
    profile_picture_url: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: true,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  additional_information: {
    bullets: true,
    gmatscore: false,
    subheadings: false,
    order: false,
  },
};

export const RESUME_FIELDS = {
  ESMT: ESMT_FIELDS,
  'Modern Simple': MODERN_SIMPLE_FIELDS,
  Hult: HULT_FIELDS,
  Rice: RICE_FIELDS,
  'Modern Minimalist': MODERN_MINIMALIST_FIELDS,
  Georgia: GEORGIA_FIELDS,
  'ESMT ATS': ESMT_ATS_FIELDS,
  ASB: ASB_FIELDS,
  AMS: AMS_FIELDS,
  Bayes: BAYES_FIELDS,
};

const defaultParams = {
  m: 1,
  b: 1,
  a: 1,
};

export type ParamsObject = {
  m: number;
  b: number;
  a: number;
};

export type ScoringParamsType = { [key: string]: ParamsObject };

export const ScoringParams: ScoringParamsType = {
  ESMT: defaultParams,
  'Modern Simple': defaultParams,
  Hult: defaultParams,
  Rice: defaultParams,
  'Modern Minimalist': defaultParams,
  Georgia: defaultParams,
  'ESMT ATS': defaultParams,
  ASB: defaultParams,
  Bayes: defaultParams,
};

export const defaultTemplates = ['ESMT', 'Modern Simple', 'Hult', 'Rice'];
export type TemplatesValues = 'ESMT' | 'Modern Simple' | 'Hult' | 'Rice' | 'Modern Minimalist' | 'Georgia' | 'ESMT ATS' | 'AMS' | 'ASB' | 'Bayes';

export const ALLOWED_TEMPLATES: { [key: string]: string[] } = {
  ESMT: ['ESMT', 'ESMT ATS'],
  Trial: ['Hult', 'Bayes', 'ESMT'],
  Hult: ['Hult'],
  'Rice University': ['Rice'],
  'University of Georgia': ['Georgia'],
  ASB: ['ASB'],
  // 'Bayes Business School': ['Bayes'],
};

export const DEFAULT_TEMPLATES: any = ['Modern Simple', 'Modern Minimalist'];

export const RESUME_RULES = {
  basics: {
    name: true,
    work_permit: true,
    emails: {
      min: 1,
      max: 2,
      require: true,
      contains: '@',
      example: 'username@example.com',
    },
    nationality: {
      min: 1,
      require: true,
    },
    phone_numbers: {
      require: false,
      min: 0,
      contains: '+',
    },
    url: {
      require: true,
      contains: 'linkedin.com/in/',
      pattern: /linkedin\.com\/in\/.+/,
      example: 'linkedin.com/in/username',
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: 'City, Country',
      },
    },
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: 'City, Country',
      },
      highlights: {
        min: 1,
        max: 50,
      },
    },
  },
  languages: {
    min: 1,
    box: {
      language: true,
      fluency: true,
    },
  },
  skills: {
    min: 1,
    box: {
      name: true,
      level: true,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};

export const AMS_RULES = {
  basics: {
    name: true,
    work_permit: false,
    emails: {
      min: 1,
      max: 2,
      require: true,
      contains: '@',
      example: 'username@example.com',
    },
    nationality: false,
    phone_numbers: {
      require: false,
      min: 0,
      contains: '+',
    },
    url: {
      require: true,
      contains: 'linkedin.com/in/',
      pattern: /linkedin\.com\/in\/.+/,
      example: 'linkedin.com/in/username',
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: 'City, Country',
      },
    },
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: 'City, Country',
      },
      highlights: {
        min: 1,
        max: 50,
      },
    },
  },
  languages: {
    min: 1,
    box: {
      language: true,
      fluency: true,
    },
  },
  skills: {
    min: 1,
    box: {
      name: true,
      level: true,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};

export const ESMT_ATS_RULES = {
  basics: {
    name: true,
    work_permit: false,
    emails: {
      min: 1,
      max: 1,
      require: true,
      contains: '@',
      example: 'username@example.com',
    },
    nationality: {
      min: 1,
      require: true,
    },
    phone_numbers: {
      require: false,
      min: 0,
      contains: '+',
    },
    url: {
      require: true,
      contains: 'linkedin.com/in/',
      pattern: /linkedin\.com\/in\/.+/,
      example: 'linkedin.com/in/username',
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: 'City, Country',
      },
    },
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: 'City, Country',
      },
      highlights: {
        min: 1,
        max: 50,
      },
    },
  },
  languages: {
    min: 1,
    box: {
      language: true,
      fluency: true,
    },
  },
  skills: {
    min: 1,
    box: {
      name: true,
      level: true,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};

export const GEORGIA_RULES = {
  basics: {
    name: true,
    work_permit: false,
    emails: {
      min: 1,
      max: 2,
      require: true,
      contains: '@',
      example: 'username@example.com',
    },
    nationality: false,
    phone_numbers: {
      require: false,
      min: 0,
      contains: '+',
    },
    url: {
      require: true,
      contains: 'linkedin.com/in/',
      pattern: /linkedin\.com\/in\/.+/,
      example: 'linkedin.com/in/username',
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: 'City, Country',
      },
    },
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: 'City, Country',
      },
      highlights: {
        min: 1,
        max: 50,
      },
    },
  },
  languages: {
    min: 1,
    box: {
      language: true,
      fluency: true,
    },
  },
  skills: {
    min: 1,
    box: {
      name: true,
      level: true,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};

export const RICE_RULES = {
  basics: {
    name: true,
    work_permit: false,
    emails: {
      min: 1,
      max: 2,
      require: true,
      contains: '@',
      example: 'username@example.com',
    },
    nationality: false,
    phone_numbers: {
      require: false,
      min: 0,
      contains: '+',
    },
    url: {
      require: true,
      contains: 'linkedin.com/in/',
      pattern: /linkedin\.com\/in\/.+/,
      example: 'linkedin.com/in/username',
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: 'City, Country',
      },
    },
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: 'City, Country',
      },
      highlights: {
        min: 1,
        max: 50,
      },
    },
  },
  languages: {
    min: 1,
    box: {
      language: true,
      fluency: true,
    },
  },
  skills: {
    min: 1,
    box: {
      name: true,
      level: true,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};

export const HULT_RULES = {
  basics: {
    name: true,
    work_permit: false,
    emails: {
      min: 1,
      max: 2,
      require: true,
      contains: '@',
      example: 'username@example.com',
    },
    nationality: false,
    phone_numbers: {
      require: false,
      min: 0,
      contains: '+',
    },
    url: {
      require: true,
      contains: 'linkedin.com/in/',
      pattern: /linkedin\.com\/in\/.+/,
      example: 'linkedin.com/in/username',
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: 'City, Country',
      },
    },
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: 'City, Country',
      },
      highlights: {
        min: 1,
        max: 50,
      },
    },
  },
  languages: {
    min: 1,
    box: {
      language: true,
      fluency: true,
    },
  },
  skills: {
    min: 1,
    box: {
      name: true,
      level: true,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};

export const MODERN_MINIMALIST_RULES = {
  basics: {
    name: true,
    work_permit: false,
    emails: {
      min: 1,
      max: 2,
      require: true,
      contains: '@',
      example: 'username@example.com',
    },
    nationality: false,
    phone_numbers: {
      require: false,
      min: 0,
      contains: '+',
    },
    url: {
      require: true,
      contains: 'linkedin.com/in/',
      pattern: /linkedin\.com\/in\/.+/,
      example: 'linkedin.com/in/username',
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: 'City, Country',
      },
    },
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: 'City, Country',
      },
      highlights: {
        min: 1,
        max: 50,
      },
    },
  },
  languages: {
    min: 1,
    box: {
      language: true,
      fluency: true,
    },
  },
  skills: {
    min: 1,
    box: {
      name: true,
      level: true,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};

export const ASB_RULES = {
  basics: {
    name: true,
    work_permit: false,
    emails: {
      min: 1,
      max: 1,
      require: true,
      contains: '@',
      example: 'xxx@MBA202X.asb.edu.my',
    },
    phone_numbers: {
      require: true,
      min: 1,
      max: 1,
      contains: '+',
    },
    url: {
      require: true,
      contains: 'linkedin.com/in/',
      pattern: /linkedin\.com\/in\/.+/,
      example: 'linkedin.com/in/username',
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: 'City, Country',
      },
    },
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: 'City, Country',
      },
      highlights: {
        min: 1,
        max: 50,
      },
    },
  },
  languages: {
    min: 1,
    box: {
      language: true,
      fluency: true,
    },
  },
  skills: {
    min: 1,
    box: {
      name: true,
      level: true,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};

export const ESMT_RULES = {
  basics: {
    name: true,
    work_permit: false,
    birth_date: false,
    summary: true,
    emails: {
      min: 1,
      max: 1,
      require: true,
      contains: '@',
      example: 'username@example.com',
    },
    nationality: false,
    phone_numbers: {
      require: false,
      min: 0,
      max: 1,
      contains: '+',
    },
    url: {
      require: true,
      contains: 'linkedin.com/in/',
      pattern: /linkedin\.com\/in\/.+/,
      example: 'linkedin.com/in/username',
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: 'City, Country',
      },
    },
  },
  work_section_subheading: {
    require: true,
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: 'City, Country',
      },
      highlights: {
        min: 1,
        max: 50,
      },
    },
  },
  languages: {
    min: 1,
    box: {
      language: true,
      fluency: true,
    },
  },
  skills: {
    min: 0,
    box: {
      name: false,
      level: false,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};

export const BAYES_RULES = {
  basics: {
    name: true,
    work_permit: false,
    birth_date: false,
    summary: false,
    emails: {
      min: 1,
      max: 1,
      require: true,
      contains: '@',
      example: 'username@example.com',
    },
    nationality: false,
    phone_numbers: {
      require: true,
      min: 0,
      contains: '+',
    },
    url: {
      require: true,
      contains: 'linkedin.com/in/',
      pattern: /linkedin\.com\/in\/.+/,
      example: 'linkedin.com/in/username',
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: 'City, Country',
      },
    },
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: 'City, Country',
      },
      highlights: {
        min: 1,
        max: 50,
      },
    },
  },
  languages: {
    min: 1,
    box: {
      language: true,
      fluency: true,
    },
  },
  skills: {
    min: 0,
    box: {
      name: false,
      level: false,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};

export const UNIVERSITY_RULES = {
  ESMT: ESMT_RULES,
  'Modern Simple': RESUME_RULES,
  Hult: HULT_RULES,
  Rice: RICE_RULES,
  'Modern Minimalist': RESUME_RULES,
  Georgia: GEORGIA_RULES,
  'ESMT ATS': ESMT_ATS_RULES,
  ASB: ASB_RULES,
  AMS: AMS_RULES,
  Bayes: BAYES_RULES,
};

export const validateResume = (resumeObject: ResumeSchema, templateName: TemplateNames) => {
  const newErrors: any = {};

  const rules = UNIVERSITY_RULES[templateName];
  // Validate basics
  newErrors.basics = {};
  Object.keys(rules.basics).forEach((field) => {
    const key = field as keyof typeof rules.basics;
    const rule: any = rules.basics[key];
    const value = resumeObject.basics[key];

    if (typeof rule === 'boolean') {
      if (rule && (!value || (Array.isArray(value) && (value.length === 0 || value.every((v) => v.trim() === ''))))) {
        newErrors.basics[key] = 'This field is required';
      }
    } else if (typeof rule === 'object') {
      const values = Array.isArray(value) ? value : value ? [value] : [];

      if (rule.require && (values.length === 0 || values.every((v) => v.trim() === ''))) {
        newErrors.basics[key] = 'This field is required';
      } else if (values.length > 0) {
        values.forEach((val: string, index: number) => {
          if (!newErrors.basics[key]) {
            newErrors.basics[key] = [];
          }

          if (rule.min && index < rule.min && (!val || val.trim() === '')) {
            newErrors.basics[key][index] = 'This field is required';
          } else if (rule.max && index > rule.max - 1) {
            newErrors.basics[key][index] = `Only ${rule.max} ${key} are allowed`;
          } else if (val && rule.contains && !val.includes(rule.contains)) {
            newErrors.basics[key][index] = `The field ${key} must contain '${rule.contains}' Required format: ${rule.example}`;
          } else if (val && rule.pattern && !rule.pattern.test(val)) {
            newErrors.basics[key][index] = `The field ${key} must contain '${rule.contains}' Required format: ${rule.example}`;
          }
        });
      }

      // Remove the key if there are no errors
      if (Array.isArray(newErrors.basics[key]) && newErrors.basics[key].length === 0) {
        delete newErrors.basics[key];
      }
    }
  });

  // Remove any fields with no errors
  Object.keys(newErrors.basics).forEach((key) => {
    if (!newErrors.basics[key] || (Array.isArray(newErrors.basics[key]) && newErrors.basics[key].length === 0)) {
      delete newErrors.basics[key];
    }
  });

  // Validate education
  newErrors.education = resumeObject.education.map((educationBox) => {
    const educationErrors: any = {};
    Object.keys(rules.education.box).forEach((field) => {
      const key = field as keyof typeof rules.education.box;
      const rule: any = rules.education.box[key];
      const value = educationBox[key];

      if (typeof rule === 'boolean') {
        if (rule && (!value || (Array.isArray(value) && (value.length === 0 || value.every((v) => v.trim() === ''))))) {
          educationErrors[key] = 'This field is required';
        }
      } else if (typeof rule === 'object') {
        const values = Array.isArray(value) ? value : value ? [value] : [];

        if (rule.require && (values.length === 0 || values.every((v) => v.trim() === ''))) {
          educationErrors[key] = 'This field is required';
        } else if (values.length > 0) {
          values.forEach((val: string, index: number) => {
            if (!educationErrors[key]) {
              educationErrors[key] = [];
            }

            if (rule.min && index < rule.min && (!val || val.trim() === '')) {
              educationErrors[key][index] = 'This field is required';
            } else if (val && rule.contains && !val.includes(rule.contains)) {
              educationErrors[key][index] = `The field ${key} must contain '${rule.contains}' Example: ${rule.example}`;
            }
          });
        }

        // Remove the key if there are no errors
        if (Array.isArray(educationErrors[key]) && educationErrors[key].length === 0) {
          delete educationErrors[key];
        }
      }
    });
    return educationErrors;
  });
  if (resumeObject.education.length < rules.education.min) {
    newErrors.educationMin = `At least ${rules.education.min} education entry is required`;
  }

  // Validate work
  let nrOfHighlights = 0;
  newErrors.work = resumeObject.work.map((workBox) => {
    const workErrors: any = {};
    Object.keys(rules.work.box).forEach((field) => {
      const key = field as keyof typeof rules.work.box;
      const rule: any = rules.work.box[key];
      const value = workBox[key];

      if (typeof rule === 'boolean') {
        if (rule && (!value || (Array.isArray(value) && (value.length === 0 || value.every((v) => v.trim() === ''))))) {
          workErrors[key] = 'This field is required';
        }
      } else if (typeof rule === 'object') {
        const values = Array.isArray(value) ? value : value ? [value] : [];

        if (rule.require && (values.length === 0 || values.every((v) => v.trim() === ''))) {
          workErrors[key] = 'This field is required';
        } else if (values.length > 0) {
          values.forEach((val: string, index: number) => {
            if (!workErrors[key]) {
              workErrors[key] = [];
            }

            if (rule.min && index < rule.min && (!val || val.trim() === '')) {
              workErrors[key][index] = 'This field is required';
            } else if (val && rule.contains && !val.includes(rule.contains)) {
              workErrors[key][index] = `Required format: ' ${rule.example}`;
            }
          });
        }

        // Remove the key if there are no errors
        if (Array.isArray(workErrors[key]) && workErrors[key].length === 0) {
          delete workErrors[key];
        }
      }
    });

    nrOfHighlights += workBox.highlights ? workBox.highlights.length : 0;
    if (workBox.highlights && workBox.highlights.length < rules.work.box.highlights.min) {
      workErrors.highlights = `At least ${rules.work.box.highlights.min} highlights are required`;
    }

    return workErrors;
  });

  if (nrOfHighlights > rules.work.box.highlights.max) {
    newErrors.workHighlights = `Maximum ${rules.work.box.highlights.max} highlights are allowed`;
  }
  if (resumeObject.work.length < rules.work.min) {
    newErrors.workMin = `At least ${rules.work.min} work experience entry is required`;
  }

  // Remove any fields with no errors
  Object.keys(newErrors.work).forEach((key) => {
    if (!newErrors.work[key] || (Array.isArray(newErrors.work[key]) && newErrors.work[key].length === 0)) {
      delete newErrors.work[key];
    }
  });

  // Validate skills
  newErrors.skills = resumeObject.skills?.map((skill) => {
    const skillErrors: any = {};
    Object.keys(rules.skills.box).forEach((field) => {
      const key = field as keyof typeof rules.skills.box;
      if (rules.skills.box[key] && !skill[key]) {
        skillErrors[key] = 'This field is required';
      }
    });
    return skillErrors;
  });
  if (resumeObject.skills?.length < rules.skills.min) {
    newErrors.skillsMin = `At least ${rules.skills.min} skill entry is required`;
  }

  // Validate languages
  newErrors.languages = resumeObject.languages?.map((language) => {
    const languageErrors: any = {};
    Object.keys(rules.languages.box).forEach((field) => {
      const key = field as keyof typeof rules.languages.box;
      if (rules.languages.box[key] && !language[key]) {
        languageErrors[key] = 'This field is required';
      }
    });
    return languageErrors;
  });
  if (resumeObject.languages?.length < rules.languages.min) {
    newErrors.languagesMin = `At least ${rules.languages.min} language entry is required`;
  }

  // Validate additional_information
  newErrors.additional_information = {};
  const additionalInfoRule = rules.additional_information.bullets;
  const additionalInfoValues = resumeObject.additional_information?.bullets || [];

  if (additionalInfoValues.length < additionalInfoRule.min) {
    newErrors.additional_information.bullets = `At least ${additionalInfoRule.min} bullet points are required`;
  } else if (additionalInfoValues.length > additionalInfoRule.max) {
    newErrors.additional_information.bullets = `Maximum ${additionalInfoRule.max} bullet points are allowed`;
  }
  return newErrors;
};

export const computePercentageFilled = (validationErrors: any, templateName: TemplateNames) => {
  const rules = UNIVERSITY_RULES[templateName];
  let totalRequiredFields = 0;
  let totalErrors = 0;

  // Function to count required fields and errors in a section
  const countFieldsAndErrors = (sectionErrors: any, sectionRules: any) => {
    let sectionRequiredFields = 0;
    let sectionErrorsCount = 0;

    Object.keys(sectionRules).forEach((field) => {
      const rule = sectionRules[field];
      const error = sectionErrors[field];

      if (typeof rule === 'boolean') {
        if (rule) sectionRequiredFields++;
        if (error) sectionErrorsCount++;
      } else if (typeof rule === 'object') {
        if (rule.require) sectionRequiredFields++;
        if (error) {
          if (Array.isArray(error)) {
            sectionErrorsCount += error.filter((e) => e !== undefined).length;
          } else {
            sectionErrorsCount++;
          }
        }
      }
    });

    return { sectionRequiredFields, sectionErrorsCount };
  };

  // Basics
  const basicsResult = countFieldsAndErrors(validationErrors.basics, rules.basics);
  totalRequiredFields += basicsResult.sectionRequiredFields;
  totalErrors += basicsResult.sectionErrorsCount;

  // Education
  if (validationErrors.education && validationErrors.education.length > 0) {
    validationErrors.education.forEach((educationError: any) => {
      const educationResult = countFieldsAndErrors(educationError, rules.education.box);
      totalRequiredFields += educationResult.sectionRequiredFields;
      totalErrors += educationResult.sectionErrorsCount;
    });
  } else {
    totalRequiredFields += rules.education.min * Object.keys(rules.education.box).length;
    totalErrors += validationErrors.educationMin ? 1 : 0;
  }

  // Work
  if (validationErrors.work && validationErrors.work.length > 0) {
    validationErrors.work.forEach((workError: any) => {
      const workResult = countFieldsAndErrors(workError, rules.work.box);
      totalRequiredFields += workResult.sectionRequiredFields;
      totalErrors += workResult.sectionErrorsCount;
    });
  } else {
    totalRequiredFields += rules.work.min * Object.keys(rules.work.box).length;
    totalErrors += validationErrors.workMin ? 1 : 0;
  }

  // Skills
  if (validationErrors.skills && validationErrors.skills.length > 0) {
    validationErrors.skills.forEach((skillError: any) => {
      const skillResult = countFieldsAndErrors(skillError, rules.skills.box);
      totalRequiredFields += skillResult.sectionRequiredFields;
      totalErrors += skillResult.sectionErrorsCount;
    });
  } else {
    totalRequiredFields += rules.skills.min * Object.keys(rules.skills.box).length;
    totalErrors += validationErrors.skillsMin ? 1 : 0;
  }

  // Languages
  if (validationErrors.languages && validationErrors.languages.length > 0) {
    validationErrors.languages.forEach((languageError: any) => {
      const languageResult = countFieldsAndErrors(languageError, rules.languages.box);
      totalRequiredFields += languageResult.sectionRequiredFields;
      totalErrors += languageResult.sectionErrorsCount;
    });
  } else {
    totalRequiredFields += rules.languages.min * Object.keys(rules.languages.box).length;
    totalErrors += validationErrors.languagesMin ? 1 : 0;
  }

  // Additional Information
  const additionalInfoRule = rules.additional_information.bullets;
  totalRequiredFields += additionalInfoRule.min;
  if (validationErrors.additional_information?.bullets) {
    totalErrors += validationErrors.additional_information.bullets ? 1 : 0;
  }

  const completionPercentage = ((totalRequiredFields - totalErrors) / totalRequiredFields) * 100;

  return Math.round(completionPercentage);
};
