import { useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { LoginLoaderResponse, LoginProviders } from '@/domains/generic/auth/types';
import { router } from '@/main';
import LogoImage from '@/assets/images/new-logo.svg';
import { ProviderLoginButton } from '@/domains/generic/auth';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { getUniversityProviders } from '@/services/public-api/universities';
import { IconButton } from '@/components/icon-button/icon-button';
import '../styles/login-box-form.scss';
import { useProviderAuth } from '@/services/hooks/use-provider-auth';

export default function SelectLoginProvider() {
  const { selectedUniversity } = useLoaderData() as LoginLoaderResponse;
  const [providers, setProviders] = useState<LoginProviders[]>([]);
  const { trackEvent } = useAnalytics();
  const { loginWithProvider } = useProviderAuth();

  const handleBackButtonClick = () => {
    trackEvent('Login: Back button clicked', {}, { flowStep: 'Select Login Provider' });
    router.navigate('/with-university');
  };

  if (!selectedUniversity) {
    window.location.href = '/with-university';
    return null;
  }

  const fetchProviders = async (): Promise<LoginProviders[] | null> => {
    let providersResponse = await getUniversityProviders(selectedUniversity.id);

    // fix for Maryland - remove gmail provider from the response to not render the button.
    // It is used only for legacy logins
    if (selectedUniversity.id === '81cd0f3d-5ce0-497c-9da0-ad25cb6823fa') {
      providersResponse = ['samlp'];
    }

    setProviders(providersResponse);

    if (providersResponse.length === 1) {
      loginWithProvider(providersResponse[0], 'organizations', selectedUniversity.name, selectedUniversity.id);
      return null;
    }

    return providersResponse;
  };

  useEffect(() => {
    fetchProviders();
  }, []);

  return (
    <>
      <div
        className="university-login__back-button"
      >
        <IconButton
          mode="unset"
          size="medium"
          icon="bi bi-arrow-left-circle"
          hoverIcon="bi bi-arrow-left-circle-fill"
          handleClick={handleBackButtonClick}
        />
      </div>
      <div className="login-form">
        <div className="login-form__header">
          <img
            src={LogoImage}
            alt=""
            className="login-form__image"
          />
          <h2 className="login-form__title">
            {selectedUniversity?.name}
            : Sign in
          </h2>
        </div>

        <div className="login-form__buttons-container">
          {providers.map((provider) => (
            <ProviderLoginButton
              key={provider}
              provider={provider}
              universityName={selectedUniversity.name}
              universityId={selectedUniversity.id}
              accountType="organizations"
            />
          ))}
        </div>
      </div>
    </>
  );
}
