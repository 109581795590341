import { useMemo } from 'react';
import { CompanyLocation } from '../../types/company';
import { stringifyCompanyLocation } from './data-parser';
import { CompanyLocationWithName } from '../../types/companies-filtering';
import { DropdownSearchDynamic } from '@/components/dropdown-search-dynamic/dropdown-search-dynamic';
import { getCompaniesLocationsByQuery } from '@/services/api/company';

type Props = {
  allLocations: CompanyLocation[];
  selectedLocations: CompanyLocationWithName[];
  handleLocationSelect: (filterValue: CompanyLocationWithName) => void;
};

export const CompaniesLocationFilter = ({
  selectedLocations,
  handleLocationSelect,
  allLocations,
}: Props) => {
  const nonSelectedLocations = useMemo<CompanyLocationWithName[]>(() => {
    const mappedLocations = allLocations.map(stringifyCompanyLocation);
    const mappedSelectedLocationsNames = selectedLocations.map((location) => location.name);

    return mappedLocations.filter((location) => !mappedSelectedLocationsNames.includes(location.name));
  }, [selectedLocations.length]);

  const handleCompaniesLocationSearch = async (query: string) => {
    let companyLoc: CompanyLocation[] = [];
    try {
      companyLoc = await getCompaniesLocationsByQuery(query || '');
    } catch {
      companyLoc = [];
    }

    return companyLoc.map(stringifyCompanyLocation).map((location) => ({ name: location.name, value: location.value }));
  };

  return (
    <DropdownSearchDynamic
      selectedOption=""
      options={nonSelectedLocations.map((location) => ({ name: location.name, value: location.value }))}
      handleItemSelect={(item, value) => handleLocationSelect({ name: item, value })}
      loaderFunction={handleCompaniesLocationSearch}
      placeholder="Search office locations"
      name="loaction-search"
      id="location-search"
      inputIcon="bi bi-search"
      size="medium"
      canAddItems={false}
      showIcon={false}
      withMultipleSelection
      openOnClick
      listWidth="full"
    />
  );
};
