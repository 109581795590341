import { useMemo } from 'react';
import './job-header.scss';
import { JobTagType } from '../../types/job';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { VerifiedBadge } from '../verified-badge/verified-badge';
import { Tag, TagColors } from '@/components/tag/tag';
import { getTagIcons } from '../../helpers/getTagIcons';
import { sortTags } from '@/services/helpers/job-feed';
import { JobHeaderCta } from '../job-header-cta/job-header-cta';

type Props = {
  handleApplyButtonClick: () => void;
  tags: JobTagType[];
  handleSaveButtonClick: () => void;
  isEmployerVerified: boolean;
  logoUrl: string;
  companyName: string;
  postedDate: string;
  title: string;
  isApplied: boolean;
  isSaved: boolean;
  isIconCTA?: boolean;
  isHiddenApplyButton: boolean;
};

export const JobHeader = ({
  handleApplyButtonClick: handleApply,
  tags,
  handleSaveButtonClick,
  isEmployerVerified,
  logoUrl,
  companyName,
  postedDate,
  title,
  isSaved,
  isIconCTA = false,
  isApplied,
  isHiddenApplyButton,
}: Props) => {
  const sortedTags = useMemo(() => (tags ? sortTags(tags) : []), [tags]);

  return (
    <div className="job-header">
      <div className="job-header__main-info">
        <div className="job-header__company-logo">
          <DefaultLogo
            source={logoUrl}
            type="company"
            name={companyName}
            className="job-header__company-logo"
            data-testid="job-logo"
            size={48}
          />
        </div>
        <div className="job-header__job-details">
          <div className="job-header__job-title" data-testid="job-title">{title}</div>
          <div className="job-header__job-subtitle">
            <div className="job-header__job-company-name">{companyName}</div>
            •
            <div>{postedDate}</div>
          </div>
        </div>
        <div className={`job-header__actions ${isIconCTA ? 'job-header__actions--gap-small' : ''}`}>
          <JobHeaderCta
            handleApplyButtonClick={handleApply}
            handleSaveButtonClick={handleSaveButtonClick}
            isSaved={isSaved}
            isIconCTA={isIconCTA}
            isApplied={isApplied}
            hiddenApplyButton={isHiddenApplyButton}
          />
        </div>
      </div>
      {isEmployerVerified && (
      <div className="job-header__verified-badge">
        <VerifiedBadge
          label="Verified CareerOS Partner"
        />
      </div>
      )}
      <div className={`job-header__actions job-header__actions--mobile ${isIconCTA ? 'job-header__actions--gap-small' : ''}`}>
        <JobHeaderCta
          handleApplyButtonClick={handleApply}
          handleSaveButtonClick={handleSaveButtonClick}
          isSaved={isSaved}
          isIconCTA={isIconCTA}
          isApplied={isApplied}
          hiddenApplyButton={isHiddenApplyButton}
        />
      </div>
      <div className="job-header__tags">
        {sortedTags.map((tag) => (
          <Tag
            key={tag.tag_type}
            color={TagColors.Grey}
            label={tag.tag_content}
            image={getTagIcons(tag.tag_type)}
          />
        ))}
      </div>
    </div>
  );
};
