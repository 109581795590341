import React, { useState, useEffect } from 'react';
import './resume-fake-loader.scss';

type ProgressStage = {
  text: string;
  start: number;
  end: number;
  duration: number;
};

type Props = {
  progressStages: ProgressStage[];
};

export const ResumeFakeLoader = ({ progressStages }: Props) => {
  const [currentStage, setCurrentStage] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const stage = progressStages[currentStage];
    if (stage) {
      const increment = (stage.end - stage.start) / (stage.duration * 10);
      const interval = setInterval(() => {
        setProgress(prev => {
          const nextProgress = prev + increment;
          if (nextProgress >= stage.end) {
            clearInterval(interval);
            setCurrentStage(prevStage => prevStage + 1);
            return stage.end;
          }
          return nextProgress;
        });
      }, 100);
    }
  }, [currentStage]);

  const currentText = progressStages[currentStage]?.text || '';

  return (
    <div className="fake-loader">
      <span className="fake-loader__progress-meter">
        {Math.round(progress)}
        %
      </span>
      <div className="fake-loader__progress-container">
        <div
          className="fake-loader__progress-bar"
          style={{ width: `${progress}%` }}
        />
      </div>
      <span className="fake-loader__progress-text">
        {currentText}
        <span className="typing-dots" />
      </span>
    </div>
  );
};
