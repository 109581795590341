import { useLocation, useParams } from 'react-router-dom';
import { Loader } from '@/components/loader/loader';
import CustomWithAuthenticationRequired from '../auth/custom-protected-route';
import { useGetAllStudentResumesQueries, useGetStudentProfileData } from '@/services/queries/student';
import { ResumeAdvisorListingBox } from '@/domains/core/resume';
import '@/pages/styles/resume-feed.scss';
import { DefaultLogo } from '@/components/default-logo/default-logo';

const EmptyResumes = ({ firstName }: { firstName: string }) => (
  <div className="resume-empty">
    <h2>
      Looks like
      {' '}
      {firstName || 'the student'}
      {' '}
      hasn’t created any resumes yet.
    </h2>
    <p>Let’s get them started on creating their very first resume. You might send them an email to nudge them.</p>

  </div>
);

type ProfileCardProps = {
  profileURL: string;
  name: string;
  university: string;
  cohort: string;
  email: string;
};

const ProfileCard = ({
  profileURL, name, university, cohort, email,
}: ProfileCardProps) => (
  <div className="profile-card">
    <DefaultLogo type="contact" source={profileURL} className="profile-card__image" name={name} />
    <div className="profile-card__info">
      <h2 className="profile-card__name">
        {name}
        ,
        {' '}
        {university}
        {' '}
        {cohort}
      </h2>
      <p className="profile-card__email">{email}</p>
    </div>
  </div>
);

const StudentResumesPage = () => {
  const { studentID } = useParams();
  const location = useLocation();
  const { data: studentResumeData, isLoading: isStudentBaseResumesLoading } = useGetAllStudentResumesQueries(studentID ?? '', {
    enabled: !!studentID,
  });
  const { data: studentProfileData, isLoading: isStudentProfileLoading } = useGetStudentProfileData(studentID ?? '', {
    enabled: !!studentID,
  });

  if (isStudentBaseResumesLoading || isStudentProfileLoading) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }

  return (
    <div className="resume-listing">
      <h2>
        {`${studentProfileData.first_name} ${studentProfileData.last_name}`}
        {'’s '}
        resumes

      </h2>
      <ProfileCard profileURL={studentProfileData.profile_picture_url} name={`${studentProfileData.first_name} ${studentProfileData.last_name}`} university={studentProfileData.university} cohort={studentProfileData.cohort} email={studentProfileData.email} />
      <div className="resume-listing__content">
        {Array.isArray(studentResumeData) && studentResumeData.length > 0 ? (
          <div className="resume-listing__list">
            {studentResumeData?.map((resume) => (<ResumeAdvisorListingBox studentID={studentID} key={resume.id} resume={resume} type={resume.type} isValid={resume.is_valid} />))}
          </div>
        ) : <EmptyResumes firstName={location?.state?.name} />}
      </div>
    </div>
  );
};

export default CustomWithAuthenticationRequired(StudentResumesPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
