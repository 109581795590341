/* eslint-disable no-await-in-loop */
import { useEffect, useState } from 'react';
import {
  collection, getDocs, limit, orderBy, query,
  where,
} from 'firebase/firestore';
import { signInWithCustomToken } from 'firebase/auth';

import { ContactBox } from '@/domains/core/contact';
import { Contact } from '@/domains/core/contact/types';
import { ContactBoxSkeletonContainer } from '@/domains/core/contact/components/contact-box-skeleton/contact-box-skeleton-container';
// import { IconButton } from '@/components/icon-button/icon-button';

import { Input } from '@/components/input/input';
import { useSelf } from '@/services/queries/user';
import { getFirebaseUserToken } from '@/services/api/contact';
import { auth, db } from '@/services/firebase/client';
import { MessageType } from '../../types';
import './inbox-contacts-list.scss';

type InboxContactListProps = {
  contacts: Contact[];
  selectedContactId?: string;
  changeFirstChatContactId: (contactId: string) => void;
};

type ContactWithChat = Contact & {
  chat: {
    id: string;
    meta: any;
    type: MessageType;
  };
};

const sortChatMessages = (chatMessages: ContactWithChat[]) => chatMessages.sort((a, b) => {
  const aLatestMessageTimestamp = a.chat?.meta.lastMessageTimestamp;
  const bLatestMessageTimestamp = b.chat?.meta.lastMessageTimestamp;
  const aLatestMessage = aLatestMessageTimestamp ? aLatestMessageTimestamp.toDate() : new Date(0);
  const bLatestMessage = bLatestMessageTimestamp ? bLatestMessageTimestamp.toDate() : new Date(0);
  const aSavedAt = new Date(a.saved_at || 0);
  const bSavedAt = new Date(b.saved_at || 0);
  const aLatestInteraction = aLatestMessage > aSavedAt ? aLatestMessage : aSavedAt;
  const bLatestInteraction = bLatestMessage > bSavedAt ? bLatestMessage : bSavedAt;

  return bLatestInteraction.getTime() - aLatestInteraction.getTime();
});

export const InboxContactsList = ({
  contacts, selectedContactId, changeFirstChatContactId,
}: InboxContactListProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const { data: self, isLoading: isSelfLoading } = useSelf();
  const [allConversations, setAllConversations] = useState<any[] | null>(null);
  const [contactWithMessages, setContactWithMessages] = useState<ContactWithChat[]>([]);
  const [isLoadingAll, setIsLoadingAll] = useState(false);

  const fetchAllEmailsAndLinkedin = async (contactIDs: string[]) => {
    setIsLoadingAll(true);
    const token: string = await getFirebaseUserToken();
    await signInWithCustomToken(auth, token);
    const conversations: any[] = [];

    if (contactIDs.length && self) {
      const conversationPromises = contactIDs.map(async (contactId) => {
        const emailsRef = collection(db, 'outlookConversations');
        const emailsQuery = query(
          emailsRef,
          where('toContactID', '==', contactId),
          where('userID', '==', self.id),
          orderBy('lastMessageTimestamp', 'desc'),
          limit(1),
        );
        const emailsSnapshot = await getDocs(emailsQuery);

        if (!emailsSnapshot.empty) {
          const docSnap = emailsSnapshot.docs[0];
          if (docSnap.exists()) {
            conversations.push({
              id: docSnap.id,
              meta: docSnap.data(),
              type: 'email',
            });
          }
        }

        const conversationsRef = collection(db, 'linkedInConversations');
        const q = query(
          conversationsRef,
          where('toContactID', '==', contactId),
          where('userID', '==', self.id),
          orderBy('lastMessageTimestamp', 'desc'),
          limit(1),
        );
        const linkedInSnapshot = await getDocs(q);

        if (!linkedInSnapshot.empty) {
          const docSnap = linkedInSnapshot.docs[0];
          if (docSnap.exists()) {
            conversations.push({
              id: docSnap.id,
              meta: docSnap.data(),
              type: 'linkedin',
            });
          }
        }
      });

      await Promise.all(conversationPromises);
      const sortedConversations = conversations.sort((a: any, b: any) => b.meta.lastMessageTimestamp.seconds - a.meta.lastMessageTimestamp.seconds);
      setAllConversations(sortedConversations);
      setIsLoadingAll(false);
    }
  };

  const getAllMessages = (conversationsObject: any) => {
    const chatMessages = contacts.map((contact: Contact) => {
      const allChats = Array.isArray(conversationsObject) ? conversationsObject.filter((conversation: any) => contact?.id === conversation.meta.toContactID) : [];
      const mostRecentChat = allChats.reduce((latestChat: any, currentChat: any) => {
        if (!latestChat) {
          return currentChat;
        }
        return currentChat.meta.lastMessageTimestamp.seconds > latestChat.meta.lastMessageTimestamp.seconds ? currentChat : latestChat;
      }, null);

      return {
        ...contact,
        chat: mostRecentChat,
      };
    });

    return sortChatMessages(chatMessages);
  };

  const handleSearch = (val: string) => {
    setSearchQuery(val);

    const allMessages = getAllMessages(allConversations);
    if (val === '') {
      setContactWithMessages(allMessages);
    } else {
      const filteredContacts = allMessages.filter((contact: Contact) => contact.career_summary.basics.name.toLowerCase().includes(val.toLowerCase()));
      setContactWithMessages(filteredContacts);
    }
  };

  useEffect(() => {
    if (allConversations) {
      const allMessages = getAllMessages(allConversations);
      setContactWithMessages(allMessages);
    }
  }, [allConversations]);

  useEffect(() => {
    if (self && !isSelfLoading && contacts.length > 0) {
      fetchAllEmailsAndLinkedin(contacts.map((contact) => contact.id));
    }
  }, [contacts.length, isSelfLoading]);

  useEffect(() => {
    if (contactWithMessages.length > 0) {
      changeFirstChatContactId(contactWithMessages[0].id);
    }
  }, [contactWithMessages?.length]);

  return (
    <div className="inbox-contact-list" data-testid="inbox-contact-list">
      <div className="inbox-contact-list__header">
        <h3 className="inbox-contact-list__title" data-testid="inbox-contact-list-title">Conversations</h3>
        {/* <IconButton mode="rounded" outlined size="small" icon="bi bi-pencil-square" /> */}
      </div>
      <div className="inbox-contact-list__search" data-testid="inbox-contact-list-search">
        <Input
          icon="bi bi-search"
          inputSize="medium"
          placeholder="Search for contact"
          value={searchQuery}
          id="contacts-search"
          label=""
          handleValueChange={(val) => handleSearch(val)}
        />
      </div>
      <div className="inbox-contact-list__contacts" data-testid="inbox-contact-list-contacts">
        {isLoadingAll ? (
          <ContactBoxSkeletonContainer />
        ) : (
          contactWithMessages.map((contact: any) => (
            <ContactBox
              key={contact.id}
              contactId={contact.id}
              company={contact.current_position.company}
              contactImage={contact.career_summary.basics.image}
              contactName={contact.career_summary.basics.name}
              messageTimestamp={contact.chat?.meta?.lastMessageTimestamp || null}
              type={contact.chat?.type || (contact.email ? 'email' : 'linkedin')}
              selected={selectedContactId === contact.id}
              text={contact.chat?.meta?.lastMessage || contact.chat?.meta?.lastMessageTimestamp ? (contact.chat?.meta?.lastMessage || 'You have a new message or email') : '<span class="contact-box__no-text">Reach out to this contact</span>'}
            />
          ))
        )}
      </div>
    </div>

  );
};
