import { Tooltip } from '@/components/tooltip/tooltip';
import './did-you-apply.scss';

type Props = {
  handleYesClick: () => void;
  handleNoClick: () => void;
};

export const DidYouApply = ({ handleYesClick, handleNoClick }: Props) => (
  <div className="did-you-apply">
    <span className="did-you-apply__text">Did you apply?</span>
    <div className="did-you-apply__options">
      <Tooltip label="Yes, I applied!" position="top" withArrow>
        <button
          type="button"
          className="did-you-apply__button"
          onClick={handleYesClick}
        >
          👍
        </button>
      </Tooltip>
      <span className="did-you-apply__text">or</span>
      <Tooltip label="No, I haven’t applied yet." position="top" withArrow>
        <button
          type="button"
          className="did-you-apply__button"
          onClick={handleNoClick}
        >
          👎
        </button>
      </Tooltip>
    </div>
  </div>
);
