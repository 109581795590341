import {
  useEffect, useRef,
} from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { TCanvasConfettiInstance, TOnInitComponentFn } from 'react-canvas-confetti/dist/types';
import confetti from 'canvas-confetti';

const COLORS = ['#FF80DB', '#9747FF', '#808CD7', '#98A6FD', '#4CDA68', '#51B5EB', '#B9EED9', '#20A19D', '#C6E7FF', '#2DCDC7', '#04D1A1', '#DBE1FF'];

function randomInRange(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

const commonOptions: confetti.Options = {
  ticks: 500,
  angle: 240,
  shapes: ['square'],
  origin: {
    x: Math.random(),
    y: 0,
  },
};

type Props = {
  fireCount?: number;
  emojis?: string[];
  duration?: number;
};

const Confetti = ({
  fireCount = 0,
  emojis,
  duration = 3000,
}: Props) => {
  const instance = useRef<TCanvasConfettiInstance>();

  const onInit: TOnInitComponentFn = ({ confetti: confettiInstance }) => {
    instance.current = confettiInstance;
  };

  const getConfettiOptions = (): confetti.Options => {
    const colors = [COLORS[Math.floor(Math.random() * COLORS.length)]];

    return {
      ...commonOptions,
      particleCount: 1,
      startVelocity: 10,
      ticks: duration,
      colors,
      gravity: randomInRange(1.5, 2),
      shapes: ['square'],
    };
  };

  const getEmojiRainOptions = (): confetti.Options => {
    const scalar = 3;
    const emojiShapes = emojis ? emojis.map((emoji) => confetti.shapeFromText({ text: emoji, scalar })) : undefined;

    return {
      ...commonOptions,
      startVelocity: 0,
      ticks: duration,
      colors: undefined,
      gravity: randomInRange(0.5, 1),
      shapes: emojiShapes,
      scalar,
      flat: !!emojis,
    } as confetti.Options;
  };

  const fire = () => {
    if (!instance.current) {
      return;
    }

    const animationEnd = Date.now() + duration;

    const fireSingleParticle = () => {
      const customOptions = emojis ? getEmojiRainOptions() : getConfettiOptions();
      const timeLeft = animationEnd - Date.now();
      const skew = Math.max(0.8, 1 - 0.001);

      if (instance.current) {
        instance.current({
          ...customOptions,
          particleCount: 1,
          origin: {
            x: Math.random(),
            y: (Math.random() * skew) - 0.2,
          },
          angle: 240,
        });
      }

      if (timeLeft > 0) {
        requestAnimationFrame(fireSingleParticle);
      }
    };

    fireSingleParticle();
  };

  useEffect(() => {
    if (fireCount) {
      fire();
    }
  }, [fireCount]);

  return (
    <ReactCanvasConfetti
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }}
      onInit={onInit}
    />
  );
};

export default Confetti;
