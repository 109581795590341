import { useLoaderData } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { withTabs } from '@/components/tabs';
import { Loader } from '@/components/loader/loader';
import './styles/jobs.scss';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';
import { addCompanyToFavoritesFromQRCode } from '@/services/api/favorite';
import { QRLeaderBoard } from '@/domains/core/student';
import { QRCompanyResponse } from '@/domains/core/company/types/company';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { getQRLeaderboard } from '@/services/api/student';
import { QRLeaderboardType } from '@/domains/core/student/types';

function CompanyQRPage() {
  const { company } = useLoaderData() as QRCompanyResponse;
  const { user } = useAuth0();
  const [leaderboard, setLeaderBoard] = useState<QRLeaderboardType | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { trackEvent } = useAnalytics();

  const saveCompany = async () => {
    setIsLoading(true);
    await addCompanyToFavoritesFromQRCode(company.id);
    const leaderboardResponse = await getQRLeaderboard();
    setLeaderBoard(leaderboardResponse);
    trackEvent('QR Company Scanned', user, {
      company_id: company.id,
      company_name: company.name,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    if (company?.id) {
      saveCompany();
    }
  }, [company]);

  if (isLoading) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }

  return (
    <div>
      {company?.id && leaderboard && Array.isArray(leaderboard.students) && leaderboard.students.length > 0 ? (
        <div className="company-qr">
          <h2>
            Congrats on saving
            {' '}
            <span className="company-qr__company">{company.name}</span>
            {' '}
            to CareerOS!
          </h2>
          <p>Scan more companies at the career fair to climb the leaderboard! To Improve your chances of winning do not forget to save contacts and network!</p>
          <br />
          <QRLeaderBoard students={leaderboard.students} universityName={leaderboard.university_name} universityLogo={leaderboard.university_logo} />
        </div>
      ) : (
        <p>
          <i>You are not part of the university cohort</i>
        </p>
      )}
    </div>
  );
}

export default CustomWithAuthenticationRequired(withTabs(CompanyQRPage), {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
