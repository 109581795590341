import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { Button } from '@careeros/coco';
import { ModalContext } from '@/components/modal/modal-provider';
import { InfoModal } from '@/domains/generic/modals';
import { validateEmail } from '@/services/helpers/contact';
import { Input } from '@/components/input/input';

interface EmailFieldProps {
  emailType?: 'primary' | 'secondary';
  email: string;
  handleClose: () => void;
  manageEmail: (emailAdress: string) => void;
  swapEmails?: () => void;
}

export const ManageEmailField = ({
  email,
  emailType = 'primary',
  handleClose,
  manageEmail,
  swapEmails,
}: EmailFieldProps) => {
  const [isEditing, setIsEditing] = useState(!email);
  const [newEmail, setNewEmail] = useState(email || '');
  const [isInvalid, setIsInvalid] = useState(false);
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const showSwapButton = useMemo(() => email && emailType === 'secondary' && !isEditing, [email, isEditing]);

  const onPrimaryChange = (value: string) => {
    if (value !== email) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
    setNewEmail(value);
    setIsInvalid(false);
  };

  const clearEmailField = () => {
    setNewEmail(email || '');
    if (newEmail === email) {
      setIsEditing(false);
    }
  };

  const handleCancel = () => {
    if (newEmail.length) {
      clearEmailField();
    } else {
      handleClose();
    }
  };

  const deleteEmail = () => {
    manageEmail('');
    setIsEditing(true);
    closeModal();
  };

  const saveEmailChanges = () => {
    if (validateEmail(newEmail)) {
      setIsInvalid(false);
    } else {
      setIsInvalid(true);
      return;
    }
    setIsEditing(false);
    manageEmail(newEmail);
  };

  const openAreYouSureModal = () => {
    openModal(
      <InfoModal
        handleSecondaryButtonClick={closeModal}
        handleButtonClick={deleteEmail}
        title="Delete Email"
        description={`The email ${email} will be deleted forever. Are you sure?`}
        secondaryButtonLabel="Cancel"
        buttonLabel="Delete"
        isButtonDanger
      />,
    );
  };

  useEffect(() => {
    setNewEmail(email || '');
    setIsEditing(!email);
  }, [email]);

  return (
    <div className={`manage-email-box__input ${email && 'manage-email-box__input--with-delete'} ${showSwapButton && 'manage-email-box__input--with-buttons'}`}>
      <div className="manage-email-box__input__field">
        <Input
          label={emailType === 'primary' ? 'Primary email' : 'Secondary email'}
          id={`${emailType}-email`}
          value={newEmail}
          inputSize="medium"
          placeholder=""
          invalid={isInvalid}
          warningText="Not a valid email address"
          handleValueChange={onPrimaryChange}
        />
      </div>
      {showSwapButton && (
        <div className="manage-email-box__input__toggle-button">
          <Button
            label="Make Primary"
            mode="primary"
            outlined
            size="medium"
            handleClick={swapEmails}
          />
        </div>
      )}
      {email && (
        <div className="manage-email-box__input__delete-button">
          <Button
            label="Delete"
            mode="dangerous"
            size="medium"
            handleClick={openAreYouSureModal}
          />
        </div>
      )}
      {isEditing && (
        <div className="manage-email-box__input__actions">
          <Button
            label="Save"
            mode="primary"
            size="small"
            disabled={!newEmail.length || isInvalid}
            handleClick={saveEmailChanges}
          />
          <Button
            label="Cancel"
            mode="primary"
            outlined
            size="small"
            handleClick={handleCancel}
          />
        </div>
      )}
    </div>
  );
};
