import { CompletedQuestPerContact } from '../../contact/types';
import { QuestType } from '../../student/types';
import { SequenceStepName, SequenceStepType } from '../types';

const firstOutreachStep: SequenceStepType = {
  name: QuestType.SendFirstOutreach,
  icon: 'bi bi-envelope-arrow-up',
  currentStateIcon: 'bi bi-envelope-arrow-up-fill',
  label: 'First Outreach',
};

const secondOutreachStep: SequenceStepType = {
  name: QuestType.SendSecondOutreach,
  icon: 'bi bi-envelope-arrow-up',
  currentStateIcon: 'bi bi-envelope-arrow-up-fill',
  label: 'Second Outreach',
};

const responseReceivedStep: SequenceStepType = {
  name: QuestType.ScheduleCoffeeChat,
  icon: 'bi bi-check-circle-fill',
  currentStateIcon: 'bi bi-check-circle-fill',
  label: 'Response Received',
  isAlwaysDone: true,
};

export const defaultSteps: SequenceStepType[] = [
  firstOutreachStep,
  secondOutreachStep,
  {
    name: QuestType.AddNewContact,
    icon: 'bi bi-person-add',
    currentStateIcon: 'bi bi-person-fill-add',
    label: 'Add New Contact',
  },
];

export const responseReceivedAfterFirstOutreachSteps: SequenceStepType[] = [
  {
    ...firstOutreachStep,
    isAlwaysDone: true,
  },
  responseReceivedStep,
];

export const responseReceivedAfterSecondOutreachSteps: SequenceStepType[] = [
  {
    ...firstOutreachStep,
    isAlwaysDone: true,
  },
  {
    ...secondOutreachStep,
    isAlwaysDone: true,
  },
  responseReceivedStep,
];

export function isSequenceStepNameType(keyInput: QuestType): keyInput is SequenceStepName {
  return [QuestType.SendFirstOutreach, QuestType.SendSecondOutreach, QuestType.AddNewContact, QuestType.ScheduleCoffeeChat].includes(keyInput);
}

export function getSortedSequenceStepNamesFromQuests(contactCompletedQuests: CompletedQuestPerContact[] | null): CompletedQuestPerContact[] {
  if (!contactCompletedQuests) {
    return [];
  }
  const filteredAndSortedSequenceSteps = contactCompletedQuests
    .filter((quest) => isSequenceStepNameType(quest.quest_type))
    .sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());

  return filteredAndSortedSequenceSteps;
}
