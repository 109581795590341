import { useEffect, useState } from 'react';
import { Column } from './table-types';

type TableHeadProps = {
  columns: Column[];
  handleSorting: (accessor: string, sortOrder: 'asc' | 'desc') => void;
  withRowNumber?: boolean;
  align?: 'left' | 'center' | 'right';
};

const TableHead = ({
  columns,
  handleSorting,
  withRowNumber = false,
  align = 'left',
}: TableHeadProps) => {
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('asc');

  const computeDefaultSorting = (col: Column[]) => {
    col.forEach((column: Column) => {
      if (column.sortbyOrder) {
        setSortField(column.accessor);
        setOrder(column.sortbyOrder);
      }
    });
  };

  const handleSortingChange = (accessor: string, sortable: boolean) => {
    if (!sortable) return;
    const sortOrder = accessor === sortField && order === 'asc' ? 'desc' : 'asc';
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  };
  const computeSortClass = (accessor: string, sortable: boolean) => {
    if (!sortable) {
      return 'not-sortable';
    }

    if (sortField === accessor) {
      if (order === 'asc') {
        return 'up';
      } if (order === 'desc') {
        return 'down';
      }
    }

    return 'default';
  };

  useEffect(() => {
    computeDefaultSorting(columns);
  }, [columns]);

  return (
    <thead>
      <tr>
        {withRowNumber && <th># Nr</th>}
        {columns.map(({
          label, accessor, sortable, isCentered = true,
        }: Column) => {
          const cl = computeSortClass(accessor, sortable);
          return (
            <th key={accessor} className={`${cl} th--${align}`} onClick={() => handleSortingChange(accessor, sortable)}>
              <div className={`table-header-cell ${isCentered ? 'table-header-cell--centered' : ''}`}>
                <span className="table-header-cell__text">{label}</span>
                {sortable && (
                  <div className="sort-button">
                    <i className={`sort-button__icon ${(sortField === accessor && order === 'asc') ? 'sort-button__icon--active' : ''} bi bi-caret-up-fill`} />
                    <i className={`sort-button__icon ${(sortField === accessor && order === 'desc') ? 'sort-button__icon--active' : ''} bi bi-caret-down-fill`} />
                  </div>
                )}
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHead;
