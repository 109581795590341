import {
  InputHTMLAttributes, useEffect, useRef, useState,
} from 'react';
import './input.scss';

export interface InputProps extends InputHTMLAttributes<any> {
  value: string | number | undefined;
  secondaryLabel?: string;
  inputSize?: 'medium' | 'large';
  id: string;
  label: string;
  placeholder: string;
  height?: 'auto' | 'fixed';
  invalid?: boolean;
  warningText?: string | JSX.Element;
  helperText?: string;
  icon?: string;
  image?: string;
  readOnly?: boolean;
  isClearable?: boolean;
  handleValueChange: (value: string, name?: string, checked?: boolean) => void;
  hint?: string;
}
/**
 * Primary UI component for the Input
 */
export const Input = ({
  id,
  label,
  placeholder = '',
  value = '',
  invalid,
  helperText,
  warningText,
  icon,
  image,
  height = 'fixed',
  type = 'text',
  readOnly = false,
  inputSize = 'medium',
  isClearable = false,
  secondaryLabel,
  handleValueChange,
  hint,
  ...props
}: InputProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const inputRef = useRef<any>(null);
  const formattedInputValue = type === 'number' ? Number(value).toString() : value;
  const isInvalidUrlValue = type === 'url'
    && isFocused
    && !(
      inputRef.current?.value.startsWith('http://') || inputRef.current?.value.startsWith('https://')
    );

  const handleChangeCallback = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setIsDirty(true);
    handleValueChange(ev.target.value, ev.target.name, ev.target.checked);
  };

  const adjustTextareaHeight = () => {
    if (height === 'auto' && inputRef.current && inputRef.current.scrollHeight > 0) {
      inputRef.current.style.height = 'auto';
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  };

  const handleChangeTextAreaCallback: any = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    setIsDirty(true);
    handleValueChange(ev.target.value, ev.target.name);
    adjustTextareaHeight();
  };

  useEffect(() => {
    if (height === 'auto') {
      adjustTextareaHeight();
    }
  }, [value, height]);

  const focusInput = () => inputRef.current?.focus();

  const handleClear = () => {
    handleValueChange('', id);
  };

  return (
    <div className={`input-wrapper input-wrapper--${inputSize}`}>
      {label && (
        <label data-testid="input-label" className="input-wrapper__label" htmlFor={id}>
          <span className="input-wrapper__label-text" data-testid="input-label-text">{label}</span>
          {secondaryLabel && <span className="input-wrapper__label-text input-wrapper__label-text--secondary">{secondaryLabel}</span>}
        </label>
      )}
      <div onClick={focusInput} className={`input-wrapper__container input-wrapper__container--${inputSize} ${invalid ? 'input-wrapper__container--invalid' : ''} ${isDirty && invalid && !value ? 'input-wrapper__container--animated' : ''}`}>
        {icon && <i data-testid="input-icon" className={`input-wrapper__icon ${icon}`} />}
        {image && <img data-testid="input-image" className={`input-wrapper__image input-wrapper__image--${inputSize}`} src={image} alt="" />}
        {height === 'auto' ? (
          <textarea
            ref={inputRef}
            id={id}
            name={id}
            placeholder={placeholder}
            value={value}
            readOnly={readOnly}
            onChange={handleChangeTextAreaCallback}
            className={`input-wrapper__input input-wrapper__input--textarea ${invalid ? 'input-wrapper__input--invalid' : ''}`}
          />
        )
          : (
            <input
              ref={inputRef}
              id={id}
              data-testid="input-field"
              name={id}
              type={type}
              placeholder={placeholder}
              className={`input-wrapper__input input-wrapper__input--${inputSize} ${invalid ? 'input-wrapper__input--invalid' : ''} ${isClearable ? 'input-wrapper__input--clearable' : ''}`}
              value={formattedInputValue}
              readOnly={readOnly}
              aria-describedby={`helper-${id}`}
              onChange={handleChangeCallback}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              autoComplete="off"
              {...props}
            />
          )}
        {isClearable && formattedInputValue && (
          <div className="input-wrapper__clear">
            <i onClick={handleClear} className="bi bi-x-circle-fill" />
          </div>
        )}
      </div>
      {type === 'url' && (
        <div className={`input-wrapper__tooltip tooltip ${isInvalidUrlValue ? 'tooltip--active' : ''} tooltip--bottom`}>
          <div className="tooltip__arrow tooltip__arrow--bottom" />
          <div className="tooltip__tip">
            Please enter a URL.
          </div>
        </div>
      )}
      {(invalid && warningText) && (
        <span data-testid="input-warning" className="input-wrapper__warning">
          {warningText}
        </span>
      )}
      <p data-testid="input-helper" className="input-wrapper__sr-helper" id={`helper-${id}`}>
        {helperText || placeholder}
      </p>
      {hint && (
        <div className="input-wrapper__hint">
          <span className="input-wrapper__hint-text">{hint}</span>
        </div>
      )}
    </div>
  );
};
