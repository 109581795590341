import { memo, useRef } from 'react';
import { Bar, getElementAtEvent, Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  Title,
  Legend,

} from 'chart.js';

import './graph-widget.scss';

interface GraphWidgetProps {
  title: string;
  subtitle: string;
  options?: any;
  data: any;
  chartType: 'bar' | 'doughnut';
  chartClicked: (index: number) => void;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  ChartDataLabels,
  ArcElement,
  BarElement,
  Title,
  Legend,
);

export const GraphWidget = memo(({
  title, subtitle, options = {}, data, chartType = 'bar', chartClicked,
}: GraphWidgetProps) => {
  const chartRef = useRef<any>(null);

  const handleClick = (event: any) => {
    const ev = getElementAtEvent(chartRef.current, event);
    if (ev.length > 0) {
      const element = ev[0];
      const { index } = element;
      chartClicked(index);
    }
  };
  return (
    <div className="graph-widget">
      <h3 className="graph-widget__title">{title}</h3>
      <p className="graph-widget__subtitle">
        {subtitle}
      </p>
      <div className="graph-widget__chart">
        {chartType === 'bar' && <Bar ref={chartRef} onClick={handleClick} options={options} data={data} />}
        {chartType === 'doughnut' && <Doughnut style={{ height: '450px', width: '450px' }} ref={chartRef} onClick={handleClick} options={options} data={data} />}
      </div>
    </div>
  );
});
