import { Column } from '@/components/table/table-types';

export const StudentResumeTableColumns: Column[] = [
  {
    label: 'Full Name', accessor: 'full_name', sortable: true, type: 'text',
  },
  {
    label: 'Base Resumes', accessor: 'base_resumes', sortable: true, type: 'text',
  },
  {
    label: 'Tailored Resumes', accessor: 'tailored_resumes', sortable: true, type: 'text',
  },
  {
    label: 'Resumes', accessor: 'resumes', sortable: true, type: 'text',
  },
  {
    label: 'Actions', accessor: 'actions', type: 'link-icon', sortable: false,
  },
];

export const AlumniTableColumns: Column[] = [
  {
    label: 'Full Name', accessor: 'full_name', sortable: true, type: 'text', isCentered: false,
  },
  {
    label: 'Company', accessor: 'current_company', sortable: true, type: 'text', isCentered: false,
  },
  {
    label: 'Job Title', accessor: 'current_position', sortable: true, type: 'text', isCentered: false,
  },
  {
    label: 'Hiring success', accessor: 'job_after_graduation', sortable: true, type: 'text', isCentered: false,
  },
  {
    label: 'Professional Email', accessor: 'professional_email', sortable: true, type: 'text', isCentered: false,
  },
  {
    label: 'Phone Number', accessor: 'phone_number', sortable: false, type: 'text', isCentered: false,
  },
  {
    label: 'Industry', accessor: 'current_industry', sortable: true, type: 'text', isCentered: false,
  },
  {
    label: 'Location', accessor: 'current_location', sortable: true, type: 'text', isCentered: false,
  },
  {
    label: 'Languages', accessor: 'languages', sortable: false, type: 'text', isCentered: false,
  },
  {
    label: 'Previous Work', accessor: 'previous_work', sortable: true, type: 'text', isCentered: false,
  },
  {
    label: 'Last Education', accessor: 'last_education', sortable: true, type: 'text', isCentered: false,
  },
  {
    label: 'Previous Education', accessor: 'previous_education', sortable: true, type: 'text', isCentered: false,
  },
  {
    label: 'Personal Email', accessor: 'personal_email', sortable: true, type: 'text', isCentered: false,
  },
  {
    label: 'Actions', accessor: 'actions', type: 'text', sortable: false, isCentered: false,
  },
];

export const StudentTableColumns: Column[] = [
  {
    label: 'Full Name', accessor: 'full_name', sortable: true, type: 'text',
  },
  {
    label: 'Email', accessor: 'email', sortable: true, type: 'text',
  },
  {
    label: 'Tags', accessor: 'tags', type: 'tags', sortable: false,
  },
  {
    label: 'Companies Saved', accessor: 'companies', sortable: true, type: 'text', sortbyOrder: 'desc',
  },
  {
    label: 'Contacts Saved', accessor: 'contacts', sortable: true, type: 'text',
  },
  {
    label: 'Networking', accessor: 'networking', sortable: true, type: 'icon',
  },
  {
    label: 'Overview', accessor: 'actions', type: 'link-icon', sortable: false,
  },
  {
    label: 'Documents', accessor: 'documents', type: 'link-icon', sortable: false,
  },
  {
    label: 'Action Plan', accessor: 'action_plan', type: 'link-icon', sortable: false,
  },
];

export const CompaniesTableColumns: Column[] = [
  {
    label: 'Company Logo', accessor: 'logo', sortable: false, type: 'image',
  },
  {
    label: 'Company Name', accessor: 'company_name', sortable: true, type: 'text',
  },
  {
    label: 'Industry', accessor: 'industry', sortable: true, type: 'text',
  },
  {
    label: 'Students Interacting', accessor: 'count', sortable: true, type: 'text', sortbyOrder: 'desc',
  },
  {
    label: 'Actions', accessor: 'actions', type: 'link-icon', sortable: false,
  },
];

export const industriesObject = [
  {
    name: 'Consulting',
    icon: 'consulting',
  },
  {
    name: 'Banking',
    icon: 'banking',
  },
  {
    name: 'Financial Services',
    icon: 'financials',
  },
  {
    name: 'Investment Firm',
    icon: 'investment',
  },
  {
    name: 'Technology',
    icon: 'tech',
  },
  {
    name: 'Social Impact',
    icon: 'social',
  },
  {
    name: 'Travel \u0026 Hospitality',
    icon: 'travel',
  },
  {
    name: 'Professional Services',
    icon: 'professional',
  },
  {
    name: 'FMCG, Food \u0026 Retail',
    icon: 'food',
  },
  {
    name: 'Industrials \u0026 Manufacturing',
    icon: 'industrial',
  },
  {
    name: 'Sports and Fashion',
    icon: 'sports',
  },
  {
    name: 'Media \u0026 Entertainment',
    icon: 'media',
  },
  {
    name: 'Healthcare',
    icon: 'healthcare',
  },
  {
    name: 'Energy \u0026 Utilities',
    icon: 'energy',
  },
  {
    name: 'Transport \u0026 Logistics',
    icon: 'transport',
  },
  {
    name: 'Other',
    icon: 'other',
  },
];

export const companyOptions = {
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
  plugins: {
    legend: {
      position: 'bottom',
    },
    datalabels: {
      color: '#fff',
      font: {
        size: 14,
      },
      formatter() {
        return null;
      },
    },
  },
  animation: false,
};

export const industryOptions = {
  responsive: true,
  scales: {
    x: {
      beginAtZero: true,
      precision: 0,
      grid: {
        display: false, // Hide x-axis grid lines
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        precision: 0,
        callback: (value: any) => (Number.isInteger(value) ? value : ''),
      },
      grid: {
        display: false, // Hide y-axis grid lines
      },
    },
  },
  plugins: {
    legend: {
      position: 'top',
    },
    datalabels: {
      color: '#fff',
      font: {
        size: 14,
      },
      formatter(value: any) {
        return `${value}`;
      },
    },
  },
  animation: false,
};
export const networkingOptions = {
  plugins: {
    legend: {
      position: 'bottom',
    },
    datalabels: {
      color: '#fff',
      font: {
        size: 20,
      },
      formatter(value: any) {
        return `${value}%`;
      },
    },
  },
  animation: false,
};
