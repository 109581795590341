import {
  useCallback, useState, useEffect, useRef,
} from 'react';
import { Toast } from '@/domains/generic/toasts/types';

interface UseToastReturn {
  toasts: Toast[];
  addToast: (toast: Omit<Toast, 'id'>) => void;
  removeToast: (id: number) => void;
}

const AUTOCLOSE_TIME_DURATION = 10000;
const MAX_TOASTS = 5;

export function useToast(): UseToastReturn {
  const [toasts, setToasts] = useState<Toast[]>([]);
  const toastsRef = useRef<Toast[]>([]);

  const removeToast = useCallback((id: number): void => {
    setToasts((currentToasts) => currentToasts.filter((toast) => toast.id !== id));
  }, []);

  const addToast = useCallback(({ autoClose = true, ...toast }: Omit<Toast, 'id'>) => {
    const newToast: Toast = {
      id: Date.now(),
      autoClose,
      ...toast,
    };
    setToasts((prevToasts) => {
      if (prevToasts.some(t => t.message === newToast.message && t.type === newToast.type)) {
        return prevToasts;
      }
      return [...prevToasts, newToast].slice(-MAX_TOASTS);
    });
  }, []);

  useEffect(() => {
    const persistedToasts = JSON.parse(localStorage.getItem('persistedToasts') || '[]');
    setToasts(persistedToasts);
  }, []);

  useEffect(() => {
    toastsRef.current = toasts;
    localStorage.setItem('persistedToasts', JSON.stringify(toasts));
  }, [toasts]);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      toastsRef.current.forEach((toast) => {
        if (toast.autoClose && now - toast.id >= AUTOCLOSE_TIME_DURATION) {
          removeToast(toast.id);
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [removeToast]);

  return { toasts, addToast, removeToast };
}
