import { useRef, useState } from 'react';
import { Button } from '@careeros/coco';
import LinkedInLogo from '@/assets/images/linkedin-logo-round.png';
import JobTeaserLogo from '@/assets/images/jobteaser-logo-round.png';
import HandshakeLogo from '@/assets/images/handshake-logo-round.png';
import IndeedLogo from '@/assets/images/indeed-logo-round.png';
import './add-job-button.scss';
import { DropdownList } from '@/components/dropdown-list/dropdown-list';
import { useCheckExtension } from '@/services/hooks/use-check-extension';
import { Tooltip } from '@/components/tooltip/tooltip';

type Props = {
  companyLinkedIn?: string;
  isOutlined?: boolean;
  onAddManuallyClick?: () => void;
  listPosition?: 'left' | 'right';
  mode?: 'primary' | 'invisible';
  size?: 'medium' | 'large' | 'small';
  icon?: string;
  iconPosition?: 'left' | 'right';
  buttonLabel?: string;
  withSaveManually?: boolean;
  isFullWidth?: boolean;
  isRelative?: boolean;
  tooltipLabel?: string
};

export const AddJobButton = ({
  isOutlined = false,
  mode = 'primary',
  size = 'medium',
  listPosition = 'left',
  buttonLabel = 'Save Job',
  icon = 'bi bi-briefcase',
  iconPosition = 'left',
  withSaveManually = false,
  companyLinkedIn,
  onAddManuallyClick,
  isFullWidth = false,
  isRelative = false,
  tooltipLabel,
}: Props) => {
  const [saveJobOptionsOpened, setSaveJobOptionsOpened] = useState(false);
  const boxRef = useRef<HTMLDivElement>(null);
  const { checkUserExtension } = useCheckExtension();

  const handleAddManually = () => {
    if (onAddManuallyClick) {
      onAddManuallyClick();
      setSaveJobOptionsOpened(false);
    }
  };

  const handleLinkClick = async (url: string) => {
    const isExtensionInstalled = await checkUserExtension();

    if (!isExtensionInstalled) {
      return;
    }

    if (companyLinkedIn && url.includes('www.linkedin.com')) {
      window.open(`${companyLinkedIn}/jobs`, '_blank');
    } else {
      window.open(url, '_blank');
    }

    setSaveJobOptionsOpened(false);
  };

  const defaultOptions = [
    {
      label: 'Save Job from LinkedIn',
      image: LinkedInLogo,
      onClick: () => handleLinkClick('https://www.linkedin.com/jobs/'),
    },
    {
      label: 'Save Job from JobTeaser',
      image: JobTeaserLogo,
      onClick: () => handleLinkClick('https://www.jobteaser.com/en/job-offers?locale=en'),
    },
    {
      label: 'Save Job from Handshake',
      image: HandshakeLogo,
      onClick: () => handleLinkClick('https://joinhandshake.com/'),
    },
    {
      label: 'Save Job from Indeed',
      image: IndeedLogo,
      onClick: () => handleLinkClick('https://www.indeed.com/'),
    },
  ];

  const manuallyAddOptions = [
    ...defaultOptions,
    {
      label: 'Save Job Manually',
      icon: 'bi bi-plus-circle',
      onClick: handleAddManually,
    },
  ];

  return (
    <div tabIndex={-1} ref={boxRef} className="add-job-button">
      <Tooltip label={tooltipLabel || ''} disableHoverListener={!tooltipLabel} position="top" withArrow>
        <Button
          handleClick={() => setSaveJobOptionsOpened(!saveJobOptionsOpened)}
          icon={icon}
          label={buttonLabel}
          mode={mode}
          size={size}
          outlined={isOutlined}
          iconPosition={iconPosition}
          isFullWidth={isFullWidth}
        />
      </Tooltip>
      <div className={`add-job-button__list add-job-button__list--${listPosition} ${isRelative ? 'add-job-button__list--relative' : ''}`}>
        {saveJobOptionsOpened
          && (
            <DropdownList
              options={withSaveManually ? manuallyAddOptions : defaultOptions}
              handleClose={() => setSaveJobOptionsOpened(false)}
              parentRef={boxRef}
            />
          )}
      </div>
    </div>
  );
};
