import { useRevalidator } from 'react-router-dom';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { Button } from '@careeros/coco';

import { useAuth0 } from '@auth0/auth0-react';
import Lottie from 'react-lottie';
import { ContactCard } from '@/domains/core/contact';
import { useExtensionMessaging } from '@/services/hooks/use-extension-messaging';
import LoaderGif from '@/assets/images/contacts-loading.json';

import './suggested-contacts.scss';
import { extractNameSubstringFromContactLink } from '@/services/helpers/contact';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { SuggestedContactsError } from './suggested-contacts-error';
import { useTabs } from '@/components/tabs/TabsContext';
import { ContactTabStates } from '@/domains/core/tracker-data';
import { SuggestedPeopleItem } from '@/domains/core/contact/types';
import { TooltipIcon } from '@/components/tooltip-icon/tooltip-icon';

type SuggestedContactsProps = {
  companyLinkedIn: string;
  companyId: string;
  savedContactsUrls: string[];
  handleSeeMoreContactsClicked: () => void;
  companyName: string;
  updateContacts: () => void;
  tabState?: ContactTabStates;
  changeTab: (tab: string, state?: string) => void;
};

export const SuggestedContacts = ({
  companyLinkedIn,
  companyId,
  savedContactsUrls,
  handleSeeMoreContactsClicked,
  updateContacts,
  companyName,
  changeTab,
  tabState,
}: SuggestedContactsProps) => {
  const [exensionIsInstalled, setExtensionIsInstalled] = useState(false);
  const [isLoggedIntoLinkedIn, setIsLoggedIntoLinkedIn] = useState(false);
  const [suggestedContacts, setSuggestedContacts] = useState<SuggestedPeopleItem[]>([]);
  const [companyLinkedInID, setCompanyLinkedInID] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const revalidator = useRevalidator();
  const { user } = useAuth0();
  const { trackEvent } = useAnalytics();
  const { getSuggestedContacts, saveContact, checkLinkedInLogin } = useExtensionMessaging();
  const { addToast } = useContext(ToastContext) as AddToastType;
  const { currentTab } = useTabs();
  const [isContactsHighlighted, setIsContactsHighlighted] = useState(false);
  const suggestedContactsFromLocalStorageID = `suggested-contacts-info-${companyId}-version-1`;
  const savedContactsLinkedInIds = useMemo<string[]>(() => savedContactsUrls
    .map((link) => extractNameSubstringFromContactLink(link) || '')
    .filter(Boolean), [savedContactsUrls]);
  const filteredSuggestions = useMemo<SuggestedPeopleItem[]>(() => suggestedContacts
    .filter((contact) => {
      const nameSubstring = extractNameSubstringFromContactLink(contact.link);
      return nameSubstring !== null && !savedContactsLinkedInIds.includes(nameSubstring);
    }), [suggestedContacts, savedContactsLinkedInIds.length]);
  const highlightSuggestedContactsBox = () => {
    setIsContactsHighlighted(true);

    setTimeout(() => {
      changeTab('Contacts', undefined);
      setIsContactsHighlighted(false);
    }, 3000);
  };
  const getFilteredSuggestions = (suggestions: SuggestedPeopleItem[]): SuggestedPeopleItem[] => suggestions
    .filter((contact) => {
      const nameSubstring = extractNameSubstringFromContactLink(contact.link);
      return nameSubstring !== null && !savedContactsLinkedInIds.includes(nameSubstring);
    });

  const checkSuggestedContactsInLocalStorage = () => {
    let suggestedContactsFromLocalStorage: any = window.localStorage.getItem(suggestedContactsFromLocalStorageID);
    if (!suggestedContactsFromLocalStorage) {
      return [];
    }

    suggestedContactsFromLocalStorage = JSON.parse(suggestedContactsFromLocalStorage);
    setCompanyLinkedInID(suggestedContactsFromLocalStorage.companyLinkedInId);

    return suggestedContactsFromLocalStorage.contacts;
  };

  const handleSuggestedContactSave = async (contactUrl: string) => {
    if (!companyLinkedInID || !companyName) {
      return;
    }

    try {
      await saveContact(contactUrl, companyName, companyLinkedInID, companyId, companyLinkedIn);

      trackEvent('Saved Contact', user, {
        companyId,
        source: 'Suggested',
      });
      revalidator.revalidate();
      updateContacts();
      addToast(
        {
          type: 'success',
          message: 'Contact saved successfully.',
        },
      );
    } catch {
      trackEvent('Suggested Contact Failed', user, {
        companyId,
      });
      addToast(
        {
          type: 'warning',
          message: 'An error occurred. Click here to save this contact from their Linkedin profile.',
          handleClick: () => window.open(contactUrl, '_blank'),
        },
      );
    }
  };

  const handleSeeMoreContactsClick = () => {
    handleSeeMoreContactsClicked();
    const searchContactsLink = companyLinkedInID
      ? `https://www.linkedin.com/search/results/people/?currentCompany=%5B"${companyLinkedInID}"%5D&origin=COMPANY_PAGE_CANNED_SEARCH`
      : `${companyLinkedIn}/people`;
    window.open(searchContactsLink, '_blank');
  };

  const checkUserExtension = async () => {
    const linkedInLoginStatus = await checkLinkedInLogin();

    if (!linkedInLoginStatus) {
      setExtensionIsInstalled(false);
      setIsLoading(false);
      return false;
    }

    if (linkedInLoginStatus !== 'User is logged into LinkedIn.') {
      setExtensionIsInstalled(true);
      setIsLoggedIntoLinkedIn(false);
      setIsLoading(false);
      return false;
    }

    setExtensionIsInstalled(true);
    setIsLoggedIntoLinkedIn(true);

    return true;
  };

  const getSuggestedContactsFromExtension = async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      const peopleResult = await getSuggestedContacts(companyLinkedIn, savedContactsLinkedInIds || []);

      if (!peopleResult?.contacts || !peopleResult?.contacts.length) {
        setIsLoading(false);
        return;
      }

      setSuggestedContacts(peopleResult.contacts);
      setCompanyLinkedInID(peopleResult.companyLinkedInId);
      window.localStorage.setItem(suggestedContactsFromLocalStorageID, JSON.stringify(peopleResult));
    } catch (e) {
      setIsError(true);
    }
    setIsLoading(false);
  };

  const getAndSetSuggestions = async () => {
    if (filteredSuggestions.length > 0) {
      return;
    }

    setIsLoading(true);
    const checkExtensionResult = await checkUserExtension();

    if (!checkExtensionResult) {
      setIsError(true);
      setIsLoading(false);
      return;
    }

    const suggestedContactsFromLocalStorage = checkSuggestedContactsInLocalStorage();
    const filteredSuggestionsFromLocalStorage = getFilteredSuggestions(suggestedContactsFromLocalStorage);

    if (!suggestedContactsFromLocalStorage.length) {
      getSuggestedContactsFromExtension();
    } else if (filteredSuggestionsFromLocalStorage.length === 0) {
      getSuggestedContactsFromExtension();
    } else {
      setSuggestedContacts(suggestedContactsFromLocalStorage);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAndSetSuggestions();
  }, [savedContactsUrls.length]);

  useEffect(() => {
    if (tabState === 'addContact') {
      highlightSuggestedContactsBox();
    }
  }, [currentTab, tabState]);

  return (
    <div className={`suggested-contacts ${isContactsHighlighted ? 'suggested-contacts--highlighted' : ''}`}>
      <div className="suggested-contacts__title">
        <div className="suggested-contacts__title-text">
          Suggested contacts
          <TooltipIcon textSize="text-large" tooltipLabel="You will typically see contacts that have some shared past experience with you, e.g. they are alumni from your university, have previously worked at the same company as you, live in the same area, or all of the above. When reaching out to a contact, make sure to personalize your message." tooltipPosition="right" />
        </div>
        {(!isLoading && !isError) && (
          <Button
            mode="text"
            label="See more"
            size="medium"
            onClick={handleSeeMoreContactsClick}
            iconPosition="right"
            icon="bi bi-box-arrow-up-right"
          />
        )}
      </div>
      {(!isLoading && !!suggestedContacts.length && !isError) && (
        <div className="suggested-contacts__container">
          {!!filteredSuggestions && filteredSuggestions.map((contact: any) => (
            <ContactCard
              isPreview
              linkedInUrl={contact.link}
              key={contact.link}
              id={contact.link}
              imageURL={contact.image}
              contactPosition={contact.role}
              contactName={contact.name}
              handleSave={() => handleSuggestedContactSave(contact.link)}
            />
          ))}
        </div>
      )}
      {!filteredSuggestions.length && !isLoading && !isError && (
        <p className="suggested-contacts__error">
          That’s it. You saved all LinkedIn contacts from this company.
        </p>
      )}
      {isLoading && (
        <div className="suggested-contacts__loader">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: LoaderGif,
            }}
            height={150}
            width={150}
            style={{
              background: 'transparent',
            }}
          />
          <div className="suggested-contacts__typing-animation" />
          <p className="suggested-contacts__loader-text">
            We are fetching suggested contacts for you. This might take a minute, hang tight!
            <br />
            <br />
            Remember: an ideal networking contact works in the department you want to enter.
            Look for common ground such as the same university, or same previous employers.
          </p>
        </div>
      )}
      {isError && (
        <SuggestedContactsError exensionIsInstalled={exensionIsInstalled} isLoggedIntoLinkedIn={isLoggedIntoLinkedIn} />
      )}
    </div>
  );
};
