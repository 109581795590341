import { useMemo } from 'react';
import { Button } from '@careeros/coco';

import DOMPurify from 'dompurify';
import { Accordion } from '@/components/accordion/accordion';
import { TabsContent, TabsHeader, withTabs } from '@/components/tabs';
import './message-template.scss';
import { Template } from '@/domains/core/contact/types';
import { prettifyMessageTemplate } from '@/services/helpers/messages';

interface TemplatesProps {
  templates: Template[];
  type?: 'LinkedIn' | 'Email' | 'Outreach' | 'Outreach Non-Premium';
  usable?: boolean;
  useTemplate: (messageTemplate: string, subjectTemplate: string | null) => void;
}

const MessageTemplates = ({
  templates,
  usable = false,
  type,
  useTemplate,
}: TemplatesProps) => {
  // TODO: order templates tabs accoring to the API response
  const getTabs = () => {
    switch (type) {
      case 'LinkedIn':
        return ['Linkedin Invitation', 'Follow-Ups', 'Thank You Notes'];
      case 'Outreach':
        return ['Linkedin Invitation'];
      case 'Outreach Non-Premium':
        return ['Linkedin Invitations'];
      case 'Email':
      default:
        return ['Initial Email Outreach', 'Follow-Ups', 'Thank You Notes', 'Offer Stage'];
    }
  };
  const filteredTemplates = useMemo(() => {
    const templateType = type === 'Outreach' || type === 'Outreach Non-Premium' ? 'LinkedIn' : type;
    if (!type) {
      return templates;
    }
    return templates.filter((template: any) => template.type === templateType?.toLowerCase());
  }, [type]);

  return (
    <div className="message-templates">
      <div className="message-templates__title">
        {type && (type !== 'Outreach' && type !== 'Outreach Non-Premium') ? `${type} Templates` : 'Templates: Outreach'}
      </div>
      <div className="message-templates__content">
        {usable ? (
          <>
            <TabsHeader
              tabs={getTabs()}
              defaultTab={getTabs()[0]}
            />
            <TabsContent>
              {getTabs().map((tabHeader: string) => (
                <div key={tabHeader} data-tab={tabHeader}>
                  <div className="tip-modal-accordions">
                    {filteredTemplates
                      .filter(template => template.outreach_type === tabHeader)
                      .sort((template1, template2) => template1.ordering - template2.ordering)
                      .map(template => (
                        <Accordion key={template.id} title={template.name}>
                          <div>
                            {template.email_subject_template && (
                              <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`Subject line: ${prettifyMessageTemplate(template.email_subject_template)}`) }} />
                            )}
                            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prettifyMessageTemplate(template.message_template)) }} />
                            {usable && (
                            <Button
                              label="Use template"
                              mode="rounded"
                              outlined
                              size="small"
                              handleClick={() => useTemplate(prettifyMessageTemplate(template.message_template), template.email_subject_template)}
                            />
                            )}
                          </div>
                        </Accordion>
                      ))}
                  </div>
                </div>
              ))}
            </TabsContent>
          </>
        ) : (
          <>
            <TabsHeader
              tabs={['Initial Linkedin', 'Initial Email', 'Follow-Ups', 'After Coffee Chat']}
              defaultTab="Initial Linkedin"
            />
            <TabsContent>
              <div data-tab="Initial Linkedin">
                <div className="tip-modal-accordions">
                  <Accordion title="Alumni Connection">
                    <div>
                      <p>
                        <strong>Example:</strong>
                      </p>
                      <p>
                        Hi Jeff,
                        <br />
                        {' '}
                        <br />
                        Max here, from the ESADE MBA class of ‘22.
                        It’s always great to connect with alumni, so I would love to get in touch.
                        <br />
                        {' '}
                        <br />
                        Thanks and best,
                        {' '}
                        <br />
                        Max
                      </p>

                      <p>
                        <strong>Template:</strong>
                      </p>
                      <p>
                        Hi [alum first name],
                        <br />
                        {' '}
                        <br />
                        [your first name] here, from the [university program] class of [graduation year].
                        It’s always great to connect with alumni, so I would love to get in touch!
                        <br />
                        {' '}
                        <br />
                        Thanks and best,
                        {' '}
                        <br />
                        [your first name]
                      </p>
                    </div>
                  </Accordion>
                  <Accordion title="Same Former Company">
                    <div>
                      <p>
                        <strong>Example:</strong>
                      </p>
                      <p>
                        Hi Jeff,
                        <br />
                        {' '}
                        <br />
                        Max here, a former Deloitte colleague.
                        I find your career path super exciting and would love to get in touch.
                        <br />
                        {' '}
                        <br />
                        Thanks and best,
                        {' '}
                        <br />
                        Max
                      </p>

                      <p>
                        <strong>Template:</strong>
                      </p>
                      <p>
                        Hi [first name],
                        <br />
                        {' '}
                        <br />
                        [your first name] here, a former [company] colleague.
                        I find your career path super exciting and would love to get in touch.
                        <br />
                        {' '}
                        <br />
                        Thanks and best,
                        {' '}
                        <br />
                        [your first name]
                      </p>
                    </div>
                  </Accordion>
                  <Accordion title="Cold Outreach 1">
                    <div>
                      <p>
                        <strong>Example:</strong>
                      </p>
                      <p>
                        Hi Kakali,
                        <br />
                        {' '}
                        <br />
                        {'I\'m'}
                        {' '}
                        a recent Esade MBA graduate looking to transition into Consulting.I was hoping to ask you a little about your experience at Deloitte in London if you have a spare minute next week - especially if you have insight about Deloitte Ventures.
                        <br />
                        {' '}
                        <br />
                        Thanks!
                        {' '}
                        <br />
                        Nicole
                      </p>

                      <p>
                        <strong>Template:</strong>
                      </p>
                      <p>
                        Hi [first name],
                        <br />
                        {' '}
                        <br />
                        {'I\'m'}
                        a recent [university and student program] looking to transition into [industry]. I was hoping to ask you a little about your experience at [company] in [location] if you have a spare minute next week - especially if you have insight about [specific department].
                        <br />
                        {' '}
                        <br />
                        Thanks!
                        {' '}
                        <br />
                        [your first name]
                      </p>
                    </div>
                  </Accordion>
                  <Accordion title="Cold Outreach 2">
                    <div>
                      <p>
                        <strong>Example:</strong>
                      </p>
                      <p>
                        Hi Narayanan - apologies for the cold outreach! I just graduated from the Esade MBA and am interested in transitioning into fintech; I was hoping to ask you a little about your experience at Klarna, if you have a minute next week.
                        <br />
                        {' '}
                        <br />
                        Thanks!
                        {' '}
                        <br />
                        Nicole
                      </p>

                      <p>
                        <strong>Template:</strong>
                      </p>
                      <p>
                        Hi [first name] - apologies for the cold outreach! I just graduated from [university and student program] and am interested in transitioning into [industry]; I was hoping to ask you a little about your experience at [company], if you have a minute next week.
                        <br />
                        {' '}
                        <br />
                        Thanks!
                        {' '}
                        <br />
                        [your first name]
                      </p>
                    </div>
                  </Accordion>
                  <Accordion title="Cold Outreach 3">
                    <div>
                      <p>
                        <strong>Example:</strong>
                      </p>
                      <p>
                        Hi Nina!
                        <br />
                        {' '}
                        <br />
                        {'I\'m'}
                        {' '}
                        a second year MBA at Esade in Barcelona with experience in digital marketing - and interested in switching into Consumer Products, and Red Bull in particular. I wanted to ask you a few questions about your experience at Red Bull if you have a minute!
                        <br />
                        {' '}
                        <br />
                        Your insights would be much appreciated!
                        <br />
                        {' '}
                        <br />
                        Thanks,
                        {' '}
                        <br />
                        Nicole
                      </p>

                      <p>
                        <strong>Template:</strong>
                      </p>
                      <p>
                        Hi [first name]!
                        {' '}
                        {'I\'m'}
                        {' '}
                        a [year] [study program] at [university and city] with experience in [your previous industry] - and interested in switching into [industry], and [company] in particular. I wanted to ask you a few questions about your experience at [company] if you have a minute!
                        <br />
                        {' '}
                        <br />
                        Your insights would be much appreciated!
                        <br />
                        {' '}
                        <br />
                        Thanks,
                        {' '}
                        <br />
                        [your first name]
                      </p>
                    </div>
                  </Accordion>
                </div>
              </div>
              <div data-tab="Initial Email">
                <div className="tip-modal-accordions">
                  <Accordion title="Alumni Email 1">
                    <div>
                      <p>
                        <strong>Example:</strong>
                      </p>
                      <p>
                        Hi Jeff,
                        {' '}
                        <br />
                        {' '}
                        <br />
                        {'I\'m'}
                        {' '}
                        Max, a fellow ESADE MBA Class of 2022. May I chat with you for a few minutes about your product management experience at N26?
                        <br />
                        {' '}
                        <br />
                        I am trying to learn more about product management in the German scale-up space, so your insights would be greatly appreciated.
                        <br />
                        {' '}
                        <br />
                        Best regards,
                        Max
                      </p>

                      <p>
                        <strong>Template:</strong>
                      </p>
                      <p>
                        Hi [first name],
                        {' '}
                        <br />
                        {' '}
                        <br />
                        name], a fellow [school and study program] student. May I chat with you for a few minutes about your [role] experience at [company]?
                        <br />
                        {' '}
                        <br />
                        I am trying to learn more about [role] in the [country] [industry] space, so your insights would be greatly appreciated.
                        {' '}
                        <br />
                        {' '}
                        <br />
                        <br />
                        [your first name]
                      </p>
                    </div>
                  </Accordion>
                  <Accordion title="Alumni Email 2">
                    <div>
                      <p>
                        <strong>Example:</strong>
                      </p>
                      <p>
                        Hey Jeff,
                        <br />
                        {' '}
                        <br />
                        This is Max, a fellow alum from the ESADE MBA class of ‘22. I hope this message finds you well! I was hoping to reach out and connect with you. I always love connecting with alumni from our program and hearing about their experiences.
                        <br />
                        {' '}
                        <br />
                        In particular, I was impressed with your career path into Consulting and was wondering if you have a minute to chat about how you broke into this industry?
                        <br />
                        {' '}
                        <br />
                        Thanks and hope to talk soon,
                        {' '}
                        <br />
                        Max
                      </p>

                      <p>
                        <strong>Template:</strong>
                      </p>
                      <p>
                        Hi [first name],
                        <br />
                        {' '}
                        <br />
                        This is [your first name], a fellow alum from the [university, study program and year of graduation]. I hope this message finds you well! I was hoping to reach out and connect with you. I always love connecting with alumni from our program and hearing about their experiences.
                        <br />
                        {' '}
                        <br />
                        In particular, I was impressed with your career path into [industry] and was wondering if you have a minute to chat about how you broke into this industry?
                        <br />
                        {' '}
                        <br />
                        Thanks and hope to talk soon,
                        {' '}
                        <br />
                        [your first name]
                      </p>
                    </div>
                  </Accordion>
                  <Accordion title="Cold Email">
                    <div>
                      <p>
                        <strong>Example:</strong>
                      </p>
                      <p>
                        Hey Jeff,
                        <br />
                        {' '}
                        <br />
                        This is Max, a fellow alum from the ESADE MBA class of ‘22. I hope this message finds you well! I was hoping to reach out and connect with you. I always love connecting with alumni from our program and hearing about their experiences.
                        <br />
                        {' '}
                        <br />
                        In particular, I was impressed with your career path into Consulting and was wondering if you have a minute to chat about how you broke into this industry?
                        <br />
                        {' '}
                        <br />
                        Thanks and hope to talk soon,
                        {' '}
                        <br />
                        Max
                      </p>

                      <p>
                        <strong>Template:</strong>
                      </p>
                      <p>
                        Hi [first name],
                        <br />
                        {' '}
                        <br />
                        This is [your first name], a fellow alum from the [university, study program and year of graduation]. I hope this message finds you well! I was hoping to reach out and connect with you. I always love connecting with alumni from our program and hearing about their experiences.
                        <br />
                        {' '}
                        <br />
                        In particular, I was impressed with your career path into [industry] and was wondering if you have a minute to chat about how you broke into this industry?
                        <br />
                        {' '}
                        <br />
                        Thanks and hope to talk soon,
                        {' '}
                        <br />
                        [your first name]
                      </p>
                    </div>
                  </Accordion>
                </div>
              </div>
              <div data-tab="Follow-Ups">
                <div className="tip-modal-accordions">
                  <Accordion title="Set Up Coffee Chat">
                    <div>
                      <p>
                        <strong>Example:</strong>
                      </p>
                      <p>
                        Hi Jeff,
                        <br />
                        {' '}
                        <br />
                        Thanks for connecting! May I chat with you for a few minutes about your product management experience at N26?
                        <br />
                        {' '}
                        <br />
                        I am trying to learn more about product management in the German scale-up space, so your insights would be greatly appreciated.
                        <br />
                        {' '}
                        <br />
                        Best regards,
                        {' '}
                        <br />
                        Max
                      </p>

                      <p>
                        <strong>Template:</strong>
                      </p>
                      <p>
                        Hi [first name],
                        <br />
                        {' '}
                        <br />
                        Thanks for connecting! May I chat with you for a few minutes about your [role] experience at [company]?
                        <br />
                        {' '}
                        <br />
                        I am trying to learn more about [role] in the [country] [industry] space, so your insights would be greatly appreciated.
                        <br />
                        {' '}
                        <br />
                        <br />
                        [your first name]
                      </p>
                    </div>
                  </Accordion>
                  <Accordion title="Interview Tips">
                    <div>
                      <p>
                        <strong>Example:</strong>
                      </p>
                      <p>
                        Hi Dan,
                        <br />
                        {' '}
                        <br />
                        Great news! With your help and the information you provided in our last chat, I was able to get an interview for the Senior Consultant role at the Milan office.
                        <br />
                        {' '}
                        <br />
                        I was wondering if we could chat again for 20 minutes about your own interview experience and any tips you may have?
                        <br />
                        {' '}
                        <br />
                        Thanks a lot and best regards,
                        {' '}
                        <br />
                        David
                      </p>

                      <p>
                        <strong>Template:</strong>
                      </p>
                      <p>
                        Hi [first name],
                        <br />
                        {' '}
                        <br />
                        Great news! With your help and the information you provided in our last chat, I was able to get an interview for the [job title] role at the [location] office.
                        <br />
                        {' '}
                        <br />
                        I was wondering if we could chat again for 20 minutes about your own interview experience and any tips you may have?
                        <br />
                        {' '}
                        <br />
                        Thanks a lot and best regards,
                        {' '}
                        <br />
                        [your first name]
                      </p>
                    </div>
                  </Accordion>
                  <Accordion title="Haven’t Heard Back">
                    <div>
                      <p>
                        <strong>Example:</strong>
                      </p>
                      <p>
                        Hi Dan,
                        <br />
                        {' '}
                        <br />
                        I just wanted to follow up on my message from last week. Might this week be a more convenient time for you to chat about your experience at Snap? Please let me know if that works.
                        <br />
                        {' '}
                        <br />
                        Best regards,
                        {' '}
                        <br />
                      </p>

                      <p>
                        <strong>Template:</strong>
                      </p>
                      <p>
                        Hi [first name],
                        <br />
                        {' '}
                        <br />
                        I just wanted to follow up on my message from last week. Might this week be a more convenient time for you to chat about your experience at [company]? Please let me know if that works.
                        <br />
                        {' '}
                        <br />
                        Best regards,
                        {' '}
                        <br />
                        [your first name]
                      </p>
                    </div>
                  </Accordion>
                </div>
              </div>
              <div data-tab="After Coffee Chat">
                <div className="tip-modal-accordions">
                  <Accordion title="Option 1">
                    <div>
                      <p>
                        <strong>Example:</strong>
                      </p>
                      <p>
                        Hi Jeff,
                        <br />
                        {' '}
                        <br />
                        Thanks for your time last week. It got me really excited and upon further reflection, this is definitely something I would like to pursue further. How would you go about doing that if you were me? For example, can you recommend someone I should speak to next?
                        <br />
                        {' '}
                        <br />
                        Thanks and best,
                        {' '}
                        <br />
                        Max
                      </p>
                      <p>
                        <strong>Template:</strong>
                      </p>
                      <p>
                        Template 1:
                        Hi [first name],
                        <br />
                        {' '}
                        <br />
                        Thanks for your time last week. It got me really excited and upon further reflection, this is definitely something I would like to pursue further. How would you go about doing that if you were me? For example, can you recommend someone I should speak to next?
                        <br />
                        {' '}
                        <br />
                        Thanks and best,
                        {' '}
                        <br />
                        [your first name]
                      </p>
                    </div>
                  </Accordion>
                  <Accordion title="Option 2">
                    <div>
                      <p>
                        <strong>Example:</strong>
                      </p>
                      <p>
                        Hi Jeff,
                        <br />
                        {' '}
                        <br />
                        I wanted to send you a quick update. I found the insights you shared last week super helpful, especially those on the best websites for Product Management. Per your advice, I also started reading “The Lean Startup” and I already feel more confident in my ability to discuss trends impacting early-stage startups today. Might you have any additional suggestions or recommend someone I should speak to next? Your insights are greatly appreciated and I will certainly keep you posted on my progress.
                        <br />
                        {' '}
                        <br />
                        Best regards,
                        {' '}
                        <br />
                        Max
                      </p>
                      <p>
                        <strong>Template:</strong>
                      </p>
                      <p>
                        Hi [first name],
                        <br />
                        {' '}
                        <br />
                        I wanted to send you a quick update. I found the insights you shared last week super helpful, especially those on [role or topic]. Per your advice, I also started reading [book, website or blog] and I already feel more confident in my ability to discuss trends impacting [industry] today. Might you have any additional suggestions or recommend someone I should speak to next? Your insights are greatly appreciated and I will certainly keep you posted on my progress.
                        <br />
                        {' '}
                        <br />
                        Best regards,
                        {' '}
                        <br />
                        [your first name]
                      </p>
                    </div>
                  </Accordion>
                </div>
              </div>
            </TabsContent>
          </>
        )}
      </div>
      <div className="message-templates__footer" />

    </div>
  );
};
export default withTabs(MessageTemplates);
