import { Link, useLoaderData } from 'react-router-dom';
import { Button } from '@careeros/coco';

import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { Input } from '@/components/input/input';
import './styles/people.scss';
import {
  KPIProgress,
} from '@/domains/core/admin';
import { sendGamificationEmail } from '@/services/api/admin';
import { getUserRoles } from '@/services/helpers/user';
import { router } from '@/main';
import { AdminResponse, Feature, University } from '@/domains/core/admin/types';

export default function AdminViewPage() {
  const {
    universities, features, acquisition, activation,
  } = useLoaderData() as AdminResponse;
  const { user } = useAuth0();
  const [searchValue, setSearchValue] = useState('');
  const [filteredUniversities, setFilteredUniversities] = useState(universities);

  const formatDate = (date: string) => new Date(date).toLocaleDateString('en-US', { month: 'short', year: 'numeric', day: 'numeric' });

  const searchUniversitiesByName = (value: string) => {
    setSearchValue(value);
    const filteredStudentsResult = universities.filter((university: University) => university.name.toLowerCase().includes(value.toLowerCase().trim()));
    setFilteredUniversities(filteredStudentsResult);
  };

  const handleSendEmail = async (universityID: string) => {
    await sendGamificationEmail(universityID);
    alert('Emails sent successfully');
  };

  const calculateAbsoluteProgress = (currentProgress: number, total: number) => {
    const absoluteProgress = (currentProgress / total) * 100;
    return Math.round(absoluteProgress * 10) / 10;
  };

  useEffect(() => {
    if (user && user.email) {
      const roles = getUserRoles(user);
      if (!roles.includes('Admin')) {
        router.navigate('/app/dashboard');
      }
    }
  }, [user]);

  return (
    <div className="people-list">
      <div className="white-wrapper">
        <h2 className="people-list__title">Weekly KPIs</h2>
        <div className="kpis">
          <div className="kpis__row">
            <div className="kpis__goal">
              <h3 className="kpis__goal__title">Acquisition, activation</h3>
              <p className="kpis__goal__description">User base growth week over week</p>
              <KPIProgress
                currentProgress={acquisition?.user_growth || 0}
                absoluteProgress={calculateAbsoluteProgress(acquisition?.user_growth, activation?.total_users)}
                goal={25}
                text="Users"
              />
            </div>
            <div className="kpis__goal">
              <h3 className="kpis__goal__title">Acquisition</h3>
              <p className="kpis__goal__description">Clients that have signed up</p>
              <KPIProgress
                currentProgress={acquisition.universities}
                absoluteProgress={100}
                goal={60}
                text="Universities"
              />
            </div>
            <div className="kpis__goal">
              <h3 className="kpis__goal__title">Activation</h3>
              <p className="kpis__goal__description">Users that came back after 7d</p>
              <KPIProgress
                currentProgress={acquisition.activated_users}
                absoluteProgress={calculateAbsoluteProgress(acquisition.activated_users, activation.weekly_users)}
                goal={30}
                text="Users"
              />
            </div>

          </div>
        </div>
        <div className="kpis__row">
          <div className="kpis__goal">
            <h3 className="kpis__goal__title">Active users</h3>
            <p className="kpis__goal__description">Users who get the unique product value</p>
            <KPIProgress
              currentProgress={activation.weekly_users}
              absoluteProgress={calculateAbsoluteProgress(activation.weekly_users, activation.total_users)}
              goal={120}
              text="Weekly"
            />
          </div>
          <div className="kpis__goal">
            <h3 className="kpis__goal__title">Active users</h3>
            <p className="kpis__goal__description">Users who get the unique product value</p>
            <KPIProgress
              currentProgress={activation.monthly_users}
              absoluteProgress={calculateAbsoluteProgress(activation.monthly_users, activation.total_users)}
              goal={480}
              text="Monthly"
            />
          </div>
          <div className="kpis__goal">
            <h3 className="kpis__goal__title">Active users</h3>
            <p className="kpis__goal__description">Users who get the unique product value</p>
            <KPIProgress
              currentProgress={activation.total_users}
              absoluteProgress={100}
              goal={1000}
              text="Total"
            />
          </div>
        </div>
        <h2 className="people-list__title">Overall Feature Usage</h2>
        <div className="kpis">
          <table className="people-list__table table">
            <thead>
              <th>Feature name</th>
              <th>Release Date</th>
              <th>Usage (gross)</th>
              <th>Users nr</th>
              <th>User Adoption</th>
            </thead>
            <tbody>
              {features.map((feature: Feature) => (
                <tr key={feature.name}>
                  <td>{feature.name}</td>
                  <td>{formatDate(feature.release_date)}</td>
                  <td>
                    {feature.total_usage}
                    {' '}
                    {feature.name}
                  </td>
                  <td>{feature.users_count}</td>
                  <td>
                    {feature.user_adoption}
                    {' '}
                    %
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <br />
        <br />
        <h2 className="people-list__title">Universities Table</h2>
        <p className="people-list__subtitle">
          Here you have full visibility of all the universities.
        </p>
        <Input
          icon="bi bi-search"
          value={searchValue}
          id="search-people"
          label=""
          placeholder="Search"
          handleValueChange={searchUniversitiesByName}
        />
        <div className="people-list__role-count">
          <span>Total Universities in System</span>
          <div className="people-list__role-count__number">
            {universities.length}
          </div>
        </div>
        <table className="people-list__table table">
          <thead>
            <th>Name</th>
            <th>Email</th>
            <th>Actions</th>
          </thead>
          <tbody>
            {filteredUniversities.map((university: University) => (
              <tr key={university.id}>
                <td>{university.name}</td>
                <td>{university.domain}</td>
                <td>
                  <Button className="gamification-email" onClick={() => handleSendEmail(university.id)} mode="primary" size="small" label="Send Gamification Email" />
                  <br />
                  <Link to={`/app/dashboard-advisor/${university.id}`}>
                    <i className="people-list__table__icon--eye bi bi-eye" />
                    <span>See University Dashboard</span>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>

        </table>
      </div>
    </div>
  );
}
