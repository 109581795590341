import { FirebaseEmailMessage, GroupedMessages } from '@/domains/core/netwoking/types';
import { AddToastFunction } from '@/domains/generic/toasts/types';

export const encodeName = (name: string) => name.split('').map(char => {
  if (char.charCodeAt(0) > 127) {
    return char;
  }
  return encodeURIComponent(char);
}).join('');

export function getGroupedMessages(groupBy: 'byDay' | 'byMonth', messages: any[]): { [index: string]: any } {
  return messages.reduce((messagesByDate: { [index: string]: any }, message) => {
    const month = message.createdAt.toDate().getMonth();
    const day = message.createdAt.toDate().getDate();
    const monthName = new Date(0, month).toLocaleString('en-US', { month: 'long' });
    const year = message.createdAt.toDate().getFullYear();
    const key = groupBy === 'byDay' ? `${year}-${monthName}-${day}` : `${year}-${monthName}`;

    if (!messagesByDate[key]) {
      messagesByDate[key] = [];
    }

    messagesByDate[key].push(message);

    return messagesByDate;
  }, {});
}

export const prettifyMessageTemplate = (message: string) => {
  const messageWithLineBreak = message.replace(/\n/g, '<br />');
  const messageWithHighlightedText = messageWithLineBreak.replace(/\[[^[\]]*\]/g, '<mark>$&</mark>');

  return messageWithHighlightedText;
};

export function findTextInBrackets(inputText: string): { index: number; length: number }[] {
  const bracketedTextParts: { index: number; length: number }[] = [];
  const regex = /\[([^\\[\]]*)]/g;

  let match = regex.exec(inputText);
  while (match !== null) {
    const bracketedText = match[1];
    const { index } = match;
    const length = bracketedText.length + 2;

    bracketedTextParts.push({ index, length });

    match = regex.exec(inputText);
  }

  return bracketedTextParts;
}

export const convertNewLinesToHtml = (text: string) => {
  // Replace double newlines with paragraph tags
  let htmlText = text.replace(/\n\n/g, '<br><br>');
  // Replace single newlines with line break tags
  htmlText = htmlText.replace(/\n/g, '<br>');
  // Wrap the whole text in paragraph tags
  htmlText = `<p>${htmlText}</p>`;
  return htmlText;
};

export function transformEmailHTML(html: string): string {
  // Step 1: Replace empty paragraphs (with both <br/> and <br> variations) with a single <br/>
  // Note the use of \/? to make the slash optional
  let processedHtml = html.replace(/<p><br\s*\/?><\/p>/g, '<br/>');

  // Step 2: Remove all opening <p> tags
  processedHtml = processedHtml.replace(/<p>/g, '');

  // Step 3: Replace closing </p> tags with <br/>, then remove the last <br/> if it's at the end
  // This step already correctly replaces </p> with <br/>, which is our desired outcome.
  processedHtml = processedHtml.replace(/<\/p>/g, '<br/>');

  // Removing the last <br/> if it's the very last thing in the string to avoid an extra break
  // This also covers cases where the last tag might be <br> without the slash, as we're checking for the presence of <br/>
  if (processedHtml.endsWith('<br/>')) {
    processedHtml = processedHtml.substring(0, processedHtml.lastIndexOf('<br/>'));
  }

  return processedHtml;
}

export const sendingMessageToasts = {
  success: {
    linkedin: {
      message: 'Success',
      additionalMessage: 'Your message has been sent.',
    },
    connection: {
      message: 'Your LinkedIn invitation has been sent',
      additionalMessage: 'Once your contact has accepted your invitation, you will see your messaging history with them on this page and on your Home Page.',
    },
  },
  error: {
    linkedin: {
      message: 'Failed to send LinkedIn message',
      additionalMessage: 'An error occurred while sending your message. Please try again later or contact us if the issue persists.',
    },
    connection: {
      message: 'Failed to send LinkedIn invitation',
      additionalMessage: 'Something went wrong. Please try again later or contact us if the issue persists.',
    },
  },
};

export const successConnectionSendToast = (addToast: AddToastFunction) => {
  addToast(
    {
      type: 'success',
      message: sendingMessageToasts.success.connection.message,
      additionalMessage: sendingMessageToasts.success.connection.additionalMessage,
    },
  );
};

export const errorMessageSendToast = (addToast: AddToastFunction, trackEvent: (eventName: string, user?: any, properties?: any) => void, errorMessage?: string) => {
  if (errorMessage === 'You have reached the maximum number of pending invitations.') {
    addToast({
      type: 'error',
      message: 'You have reached your weekly LinkedIn invitation limit.',
      additionalMessage: 'This limit is set by Linkedin and will reset once per week. Upgrade to Linkedin premium or use our email networking features to keep networking in the meantime.',
    });
    trackEvent('Toast Error Shown', {}, {
      message: 'You have reached your weekly LinkedIn invitation limit',
      error: errorMessage,
    });
    return;
  }

  addToast({
    type: 'error',
    message: errorMessage || sendingMessageToasts.error.connection.message,
    additionalMessage: sendingMessageToasts.error.connection.additionalMessage,
  });
  trackEvent('Toast Error Shown', {}, {
    message: errorMessage || sendingMessageToasts.error.connection.message,
    error: sendingMessageToasts.error.connection.additionalMessage,
  });
};

export const stripParagraphs = (htmlString: string) => {
  // Replace closing </p> tags with <br/><br/> to maintain visual separation as paragraphs
  let modifiedString = htmlString.replace(/<p>\s*<br\s*\/?>\s*<\/p>/gi, '<br>');

  modifiedString = modifiedString.replace(/<\/p>/gi, '<br/>');

  modifiedString = modifiedString.replace(/<p>/gi, '');

  modifiedString = modifiedString.replace(/<br\s*\/?>/gi, '\n');

  const dc = new DOMParser().parseFromString(modifiedString, 'text/html');
  return dc.body.textContent || '';
};

const retrieveNameFromEmail = (email: string, selfEmail: string) => {
  if (email === selfEmail) {
    return 'me';
  }
  if (!email.includes('@')) {
    return email.split(' ')[0];
  }

  const emailParts = email.split('@');
  return emailParts[0];
};

export const groupEmailMessagedBySubject = (messages: FirebaseEmailMessage[], selfEmail: string) => messages.reduce((groupedMessages, message) => {
  const messageSubject = message.subject.includes('Re:') ? message.subject.split('Re: ')[1] : message.subject;
  const senderName = retrieveNameFromEmail(message.from, selfEmail);
  const recipientName = retrieveNameFromEmail(message.to, selfEmail);
  if (!groupedMessages[messageSubject]) {
    groupedMessages[messageSubject] = {
      messages: [],
      data: {
        senderName,
        recipientName,
        subject: messageSubject,
        sentDateTime: message.sentDateTime,
        type: message.type,
        text: message.text,
        recipients: new Set([senderName, recipientName]),
      },
    };
  }

  groupedMessages[messageSubject].messages.push(message);
  groupedMessages[messageSubject].data.recipients.add(senderName);
  groupedMessages[messageSubject].data.recipients.add(recipientName);

  return groupedMessages;
}, {} as GroupedMessages);

export const extractFirstThreadMessage = (htmlString: string) => {
  const doc = new DOMParser().parseFromString(htmlString, 'text/html');
  const divElement = doc.body.querySelector('div:not(:first-child) div[dir="ltr"]');
  const divElementWithBlockquote = doc.body.querySelector('div blockquote');

  if (doc.body.childElementCount > 1 && (divElement || divElementWithBlockquote)) {
    divElement?.closest('div')?.remove();
    divElementWithBlockquote?.closest('div')?.remove();
  }

  return doc.body?.textContent || '';
};
