import { useContext } from 'react';
import './tile-actions.scss';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ModalContext } from '@/components/modal/modal-provider';
import { SidebarContext } from '@/components/sidebar/sidebar-provider';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { ContactsSidebar } from '../../sidebars/contacts-sidebar/contacts-sidebar';
import CommentsSidebar from '../../sidebars/comments-sidebar/comments-sidebar';
import { TileCta } from '../tile-cta/tile-cta';
import { AddComment, InfoModal } from '../../modals';
import { Comment } from '@/domains/core/company/types';
import { changeApplicationStatus } from '@/services/api/application';
import { statuses } from '@/domains/core/tracker-data';
import { TrackerContext, TrackerContextType } from '@/domains/core/company';

type Props = {
  readOnly?: boolean;
  tileID: string;
  tileOrdering: number;
  companyName: string;
  companyID: string;
  studentID: string;
  comments: Comment[];
  countOfContacts: number;
  withAddComment?: boolean;
};

export const TileActions = ({
  readOnly = false,
  companyName,
  companyID,
  studentID,
  comments,
  tileID,
  tileOrdering,
  countOfContacts,
  withAddComment = false,
}: Props) => {
  const { trackEvent } = useAnalytics();
  const { openSidebar, closeSidebar } = useContext(SidebarContext) as any;
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;
  const navigate = useNavigate();
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const queryClient = useQueryClient();

  const handleContactsIconClicked = () => {
    trackEvent('Overview Board: Contact Icon clicked', {});
    if (readOnly) {
      openSidebar(
        <ContactsSidebar
          companyName={companyName}
          companyID={companyID}
          closeSidebar={closeSidebar}
          userID={studentID}
        />,
      );
    } else {
      openTracker(companyID, 'Contacts');
    }
  };

  const handleCommentsIconClick = (hasResolveButton = false) => {
    trackEvent('Overview Board: Comments Icon clicked', {});
    openSidebar(
      <CommentsSidebar
        companyName={companyName}
        comments={comments}
        hasResolveButton={hasResolveButton}
        closeSidebar={closeSidebar}
      />,
    );
  };

  const openAddCommentModal = () => {
    openModal(
      <AddComment id={tileID} payload={{}} type="company" />,
    );
  };

  const goToArchive = () => {
    closeModal();
    navigate('/app/archive');
  };

  const archiveCompany = async (rememberAction: boolean) => {
    if (rememberAction) {
      localStorage.setItem('rememberArchiveCompany', 'true');
    }
    closeModal();
    await changeApplicationStatus(tileID, statuses.Archived, tileOrdering);
    await queryClient.invalidateQueries(['archive']);
    await queryClient.invalidateQueries(['applications']);
    await queryClient.invalidateQueries(['applications-no-cache']);
    trackEvent('Overview Board: Company Archive', {});
    openModal(
      <InfoModal
        handleButtonClick={goToArchive}
        title="Company Archived!"
        description="The Company is now archived. You can find it in your Archive List."
        buttonLabel="Go to Archive List"
      />,
    );
  };

  const openAddJobModal = () => {
    trackEvent('Overview Board: Jobs Icon clicked', {});
    openTracker(companyID, 'Jobs');
  };

  const goToNotesTab = () => {
    trackEvent('Overview Board: Notes Icon clicked', {});
    openTracker(companyID, 'Notes');
  };

  const openAreYouSure = () => {
    const rememberAction = localStorage.getItem('rememberArchiveCompany');
    if (!rememberAction) {
      openModal(
        <InfoModal
          icon="bi bi-exclamation-triangle-fill"
          handleButtonClick={archiveCompany}
          handleSecondaryButtonClick={closeModal}
          title="Are you sure?"
          description="Archiving will stop your progress with this company and remove it from your Overview Board. It will be moved to your Archive List."
          buttonLabel="Archive"
          secondaryButtonLabel="Cancel"
          rememberText="Remember my choice"
          isButtonDanger
        />,
      );
    } else {
      archiveCompany(false);
    }
  };
  return (
    <div className="tile-actions">
      {!readOnly && (
      <TileCta
        label="View Notes"
        icon="bi bi-sticky"
        handleClick={goToNotesTab}
      />
      )}
      <TileCta
        label={readOnly ? 'View Contacts' : `You have ${countOfContacts} contact(s) at this company.`}
        icon="bi bi-people"
        handleClick={handleContactsIconClicked}
        counter={countOfContacts}
      />
      {!readOnly ? (
        <>
          {comments?.length > 0 && (
          <TileCta
            label="See Comments"
            icon="bi bi-chat-square-text"
            handleClick={() => handleCommentsIconClick(true)}
            counter={comments.length}
          />
          )}
          <TileCta
            label="Save Job Posting"
            icon="bi bi-suitcase-lg"
            handleClick={openAddJobModal}
          />

          <TileCta
            label="Archive Company"
            icon="bi bi-archive"
            handleClick={openAreYouSure}
          />
        </>
      ) : (
        <TileCta
          label="View Comments"
          icon="bi bi-chat-square-text"
          handleClick={() => handleCommentsIconClick(false)}
          counter={comments?.length || 0}
        />
      )}

      {withAddComment && (
        <TileCta
          label="Write a comment"
          icon="bi bi-plus-lg"
          handleClick={openAddCommentModal}
        />
      )}
    </div>
  );
};
