/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
import React, { useState, useEffect } from 'react';
import { pdf } from '@react-pdf/renderer';
import { pdfjs } from 'react-pdf';
import { Button } from '@careeros/coco';

type PDFDownloadWrapperProps = {
  document: React.ReactElement;
  fileName: string;
  saveAndTrack: () => void;
};

const hasPageContent = async (pdfDoc: any, pageNumber: number) => {
  const page = await pdfDoc.getPage(pageNumber);
  const textContent = await page.getTextContent();
  return textContent.items.length > 0;
};

const PDFDownloadWrapper = ({ document, fileName, saveAndTrack }: PDFDownloadWrapperProps) => {
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);

  useEffect(() => {
    const generatePDF = async () => {
      const pdfDoc = await pdf(document).toBlob();
      const pdfJS = await pdfjs.getDocument(URL.createObjectURL(pdfDoc)).promise;

      const totalPages = pdfJS.numPages;
      const pagesWithContent = [];

      for (let i = 1; i <= totalPages; i++) {
        if (await hasPageContent(pdfJS, i)) {
          pagesWithContent.push(i);
        }
      }

      if (pagesWithContent.length < totalPages) {
        // If there are empty pages, create a new PDF with only non-empty pages
        const pdfDocResponse = await pdf(React.cloneElement(document, { pages: pagesWithContent })).toBlob();
        setPdfBlob(pdfDocResponse);
      } else {
        setPdfBlob(pdfDoc);
      }
    };

    generatePDF();
  }, [document]);

  const handleDownload = async () => {
    if (pdfBlob) {
      const url = URL.createObjectURL(pdfBlob);
      const link = window.document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(url);
      await saveAndTrack();
    }
  };

  return (
    <Button
      icon="bi bi-download"
      handleClick={handleDownload}
      label="Download PDF"
      mode="primary"
      size="medium"
      outlined
      disabled={!pdfBlob}
    />
  );
};

export default PDFDownloadWrapper;
