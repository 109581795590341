import { http } from '@/services/api/http';

export const getJobs = () => http.get('/jobs?offset=0&limit=20');

export const addJob = (job: any) => http.post('/jobs', { ...job, source: 'StudentApp' });

export const updateJobDeadline = (jobID: string, deadline: string | null, isRolling: boolean) => http.patch(`/jobs/${jobID}`, { deadline, rolling_date: isRolling });

export const getSelfJobs = () => http.get('/users/self/jobs');

export const searchJobs = (query: string, page: number, favorites?: boolean) => {
  const url = `/jobs/search?page=${page}${favorites ? '&favorites=true' : ''}${query ? `&${decodeURIComponent(query)}` : ''}`;
  return http.get(url);
};

export const getJobPostingsByCompany = (companyID: string, page: number) => http.get(`/jobs/search?page=${page}&companyID=${companyID}&favorites=true`);

export const getJobsLocations = () => http.get('/jobs/locations');

export const getJobsLocationsByQuery = (query:string) => http.get(`/jobs/locations?name=${query}`);

export const getJobsLanguages = () => http.get('/jobs/languages');

export const saveJob = (jobID: string) => http.post(`/jobs/${jobID}`, {});

export const unsaveJob = (jobID: string) => http.delete(`/jobs/${jobID}`);

export const applyForJob = (jobID: string, apply: boolean) => http.put(`/jobs/${jobID}/apply`, { apply });

export const getJobsByCompany = async (companyID: string) => http.get(`/jobs/bycompany/${companyID}`);
