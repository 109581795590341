import { EducationType, ExperienceType, ConnectionStatus } from '@/domains/core/contact/types';
import {
  CommunicationChannel, FirebaseEmailMessage, FirebaseLinkedinMessage, Message,
} from '@/domains/core/netwoking/types';
import { QuestType } from '@/domains/core/student/types';

export const orderByDate = (firstPeriod: any, lastPeriod: any) => {
  if (firstPeriod.endDate === '' && lastPeriod.endDate === '') {
    return lastPeriod.startDate?.localeCompare(firstPeriod.startDate);
  } if (firstPeriod.endDate === '') {
    return -1;
  } if ((firstPeriod.endDate === '' && firstPeriod.startDate === '')
    || (lastPeriod.endDate === '' && lastPeriod.startDate === '')
  ) {
    return (firstPeriod.endDate === '' && firstPeriod.startDate === '') ? 1 : -1;
  } if (lastPeriod.endDate === '') {
    return 1;
  }
  return lastPeriod.endDate?.localeCompare(firstPeriod.endDate);
};

export const formatDates = (work: ExperienceType | EducationType) => {
  let { endDate, startDate } = work;
  if (endDate.length) {
    endDate = new Date(endDate).toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
  }
  if (startDate.length) {
    startDate = new Date(startDate).toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
  }
  if (!endDate.length && startDate.length) {
    endDate = 'Present';
  }

  return {
    ...work,
    endDate,
    startDate,
  };
};

export function extractNameSubstringFromContactLink(link: string): string | null {
  const decodedLink = decodeURIComponent(link);
  // Define a regular expression pattern to extract the substring
  const pattern = /\/in\/([^/?#]+)(?:[/?#]|$)/;

  // Use RegExp.exec to find the pattern in the link
  const match = pattern.exec(decodedLink);

  // Check if a match is found
  if (match) {
    // Index 1 of the match contains the desired substring
    const substring = match[1];
    return substring;
  }
  // Return null if no match is found
  return null;
}

export function getNetworkingStatusByMessages(messages: any[], emails: any[]): ConnectionStatus {
  const hasSentMessage = messages.some((message) => !!message.fromUserID) || emails.some((email) => !!email.fromUserID);
  const hasReceivedMessage = messages.some((message) => !!message.fromContactID) || emails.some((email) => !!email.fromContactID);

  if (hasSentMessage && hasReceivedMessage) {
    return 'connected';
  }

  if (hasSentMessage) {
    return 'pending';
  }

  return 'notConnected';
}

export function getNetworkingStatusByQuest(activeQuests: QuestType[] | null, completedQuests: QuestType[] | null): ConnectionStatus | undefined {
  if (!activeQuests && !completedQuests) {
    return undefined;
  }

  const hasSentMessage = completedQuests
    && (completedQuests.includes(QuestType.SendConnection)
    || completedQuests.includes(QuestType.SendFollowUpEmail)
    || completedQuests.includes(QuestType.SendMessage)
    || completedQuests.includes(QuestType.SendFirstEmail));

  if (hasSentMessage && ((activeQuests && activeQuests.includes(QuestType.ScheduleCoffeeChat)) || completedQuests.includes(QuestType.ScheduleCoffeeChat))) {
    return 'connected';
  }

  if (hasSentMessage) {
    return 'pending';
  }

  if (activeQuests && (activeQuests.includes(QuestType.SendConnection) || activeQuests.includes(QuestType.SendFirstEmail))) {
    return 'notConnected';
  }

  return 'notConnected';
}

export function validateEmail(email: string) {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email.match(emailRegex);
}

export function extractEmailAddress(text: string | undefined) {
  if (!text) {
    return null;
  }

  const emailResult = text.match(/<(.*)>/)?.[1] || text;

  if (validateEmail(emailResult)) {
    return emailResult;
  }

  return null;
}

export const getMessageTemplate = {
  firstEmailTemplate: (contFirstName: string, studentFirstName: string, studentUniversity: string, contactIndustry: string, currentCompany: string, jobTitle: string): string => `Hi ${contFirstName}, <br/><br/>I'm ${studentFirstName}, a student at ${studentUniversity} passionate about ${contactIndustry}. I was wondering if I may I chat with you for a few minutes about your ${jobTitle} experience at ${currentCompany}?<br/><br/>I am trying to learn more about [job title] in the ${contactIndustry} space, so your insights would be greatly appreciated.<br/><br/>Thank you and best regards,<br/>${studentFirstName}`,
  firstMessageTemplate: (contFirstName: string, studentFirstName: string, currentCompany: string, contactIndustry: string): string => `Hi ${contFirstName}, I hope you’re doing well! I have recently developed a real passion for ${contactIndustry} and I’d would love to pick your brain about your time at ${currentCompany}. Do you have a few minutes next week to chat? Your insights would be highly appreciated! Best,${studentFirstName}`,
  linkedinConnectionTemplate: (contFirstName: string, studentUniversity: string, currentCompany: string, companyIndustry: string): string => `Hi ${contFirstName}, I'm studying at ${studentUniversity} and excited about ${companyIndustry}. Could I ask you about your journey at ${currentCompany}? I value connecting with professionals like you! Thanks!`,
  linkedinConnectionPremiumTemplate: (contFirstName: string, studentFirstName: string, studentUniversity: string, currentCompany: string, companyIndustry: string): string => `Hi ${contFirstName}, I'm studying at ${studentUniversity} and exploring ${companyIndustry}. Would love to pick your brain about your time at ${currentCompany}. Always happy to connect with professionals like you! Thanks, ${studentFirstName}`,
  followUpEmailTemplate: (contFirstName: string, studentFirstName: string, studentUniversity: string, currentCompany: string, contactIndustry: string, jobTitle: string): string => `Hi ${contFirstName}, <br/><br/>I'm ${studentFirstName}, a student at ${studentUniversity} passionate about ${contactIndustry}. I was wondering if I may I chat with you for a few minutes about your ${jobTitle} experience at ${currentCompany}?<br/> <br/>I am trying to learn more about [job title] in the ${contactIndustry} space, so your insights would be greatly appreciated.<br/> <br/>Thank you and best regards,<br/> ${studentFirstName}`,
  coffeeChatEmailTemplate: (contFirstName: string, studentFirstName: string, currentCompany: string, contactIndustry: string): string => `Hi ${contFirstName}, <br/><br/>Thanks so much for your response! As a student truly passionate about ${contactIndustry}, I can’t wait to learn more about your experience at ${currentCompany}. <br/><br/>Is there a 15-minute time slot that works for you this week or next week? Your insights would be greatly appreciated.<br/><br/>Thanks and best,<br/> ${studentFirstName};`,
  coffeeChatLinkedinTemplate: (contFirstName: string, studentFirstName: string, currentCompany: string): string => `Hi ${contFirstName}, <br/>Thanks for your reply, really appreciate it! As mentioned before, I would love to spend a few minutes to learn more about your experience ${currentCompany}. <br/>Is there a 15-minute time slot that works well for you this week or next week? Really looking forward to chatting!<br/>Thanks and best, <br/>${studentFirstName}`,
};

export function defineLastMessage(
  linkedInMessages: FirebaseLinkedinMessage[],
  emails: FirebaseEmailMessage[],
): (FirebaseLinkedinMessage | FirebaseEmailMessage) & { messageType: CommunicationChannel } | null {
  try {
    if (!linkedInMessages.length && !emails.length) {
      return null;
    }
    const orderedLinkedInMessages = linkedInMessages.sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis());
    const orderedEmails = emails.sort((a, b) => (b.createdAt || b.sentDateTime).toMillis() - (a.createdAt || a.sentDateTime).toMillis());
    const lastLinkedInMessage = orderedLinkedInMessages[0];
    const lastEmail = orderedEmails[0];

    if (!lastLinkedInMessage && lastEmail) {
      return {
        ...lastEmail,
        messageType: 'email',
      };
    }

    if (!lastEmail && lastLinkedInMessage) {
      return {
        ...lastLinkedInMessage,
        messageType: 'linkedin',
      };
    }

    const lastLinkedInMessageDate = lastLinkedInMessage?.createdAt.toMillis() || 0;
    const lastEmailDate = lastEmail?.createdAt?.toMillis() || 0;

    return lastLinkedInMessageDate < lastEmailDate ? {
      ...lastLinkedInMessage,
      messageType: 'linkedin',
    } : {
      ...lastEmail,
      messageType: 'email',
    };
  } catch (error) {
    return null;
  }
}

export function computeLastMessagePreview(
  lastMessage: (FirebaseLinkedinMessage | FirebaseEmailMessage) & { messageType: CommunicationChannel } | null,
): Message | null {
  try {
    if (!lastMessage) {
      return null;
    }

    const { text, createdAt, messageType } = lastMessage;
    const subject = 'subject' in lastMessage ? lastMessage.subject : '';
    const id = 'ID' in lastMessage ? lastMessage.ID : '';

    return {
      text,
      createdAt: createdAt || null,
      subject,
      type: messageType,
      ID: id,
    };
  } catch (error) {
    return null;
  }
}
