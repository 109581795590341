import { useContext } from 'react';
import { createPortal } from 'react-dom';
// eslint-disable-next-line import/no-cycle
import { ToastContext } from './toast-provider';
import ToastsList from '@/domains/generic/toasts/toasts-list/toasts-list';
import { Toast } from '@/domains/generic/toasts/types';
import './toast-container.scss';

interface ToastContainerProps {
  toasts: Toast[];
  removeToast: (id: number) => void;
}

// TODO: find a better way to solve problem with root
const toastRoot = document.querySelector('#toast-root') as HTMLElement || document.querySelector('#storybook-root') as HTMLElement;

const ToastContainer = () => {
  const { toasts, removeToast }: ToastContainerProps = useContext(ToastContext);

  return toastRoot ? createPortal(
    <div className="toasts">
      <ToastsList toasts={toasts} removeToast={removeToast} />
    </div>,
    toastRoot,
  ) : null;
};

export default ToastContainer;
