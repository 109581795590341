import { useQuery } from 'react-query';
import { getJobsByCompany } from '../api/job';
import { JobFeedJob } from '@/domains/core/job/types/job';

export const useJobsByCompany = (companyID: string, options = {}) => useQuery(['jobs-by-company', companyID], async () => {
  const result = await getJobsByCompany(companyID);

  return result && Array.isArray(result) ? result as JobFeedJob[] : [];
}, {
  retry: 0,
  // should be refetched in the background every 24 hours
  staleTime: 1000 * 60 * 60 * 24,
  ...options,
});
