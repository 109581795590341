/* eslint-disable react/no-array-index-key */
import {
  Page, Text, View, Document, Link, StyleSheet,
  Font,
} from '@react-pdf/renderer';
import { HighlightWords, ResumeSchema } from '../../types';

import Arial from '@/assets/fonts/resume-builder/Arial/Arial.ttf';
import ArialBold from '@/assets/fonts/resume-builder/Arial/Arial_Bold.ttf';
import ArialItalic from '@/assets/fonts/resume-builder/Arial/Arial_Italic.ttf';

import {
  computeSkillsStyle,
  renderAdditionalHighlightedText,
  renderWorkHighlightedText,
  trimUrlPrefix,
} from './utils';

Font.register({ family: 'Arial', src: Arial, fonts: [{ src: Arial }, { src: ArialItalic, fontStyle: 'italic' }, { src: ArialBold, fontWeight: 'bold' }] });

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: '#fff',
    fontFamily: 'Arial',
    width: '100%',
    height: '100%',
    padding: 30,
    margin: 0,
  },
  document: {
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    border: 0,
    background: 'white',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  headerLeftSide: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    textAlign: 'left',
    width: '40%',
  },
  headerLeft: {
    fontSize: 9,
    fontFamily: 'Arial',
    textAlign: 'left',
    color: '#333333',
  },
  headerLeftBlack: {
    fontSize: 9,
    fontFamily: 'Arial',
    textAlign: 'left',
    color: '#000',
  },
  headerRightBlack: {
    fontSize: 9,
    fontFamily: 'Arial',
    textAlign: 'left',
    color: '#000',
    width: '100%',
  },
  headerRight: {
    fontSize: 9,
    fontFamily: 'Arial',
    textAlign: 'left',
    width: '100%',
  },
  headerRightSide: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    textAlign: 'left',
    width: '40%',
  },
  topRow: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
  },
  bottomRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 5,
    width: '100%',
  },
  block: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    marginTop: 10,
  },
  EducationblockTitle: {
    fontSize: 12,
    fontFamily: 'Arial',
    textAlign: 'left',
    width: '100%',
    color: '#000',
    borderBottom: '2px solid #000',
  },
  WorkblockTitle: {
    fontSize: 12,
    fontFamily: 'Arial',
    textAlign: 'left',
    width: '100%',
    color: '#000',
    borderBottom: '2px solid #000',
  },
  LanguagesBlockTitle: {
    fontSize: 12,
    fontFamily: 'Arial',
    textAlign: 'left',
    width: '100%',
    color: '#000',
    borderBottom: '2px solid #000',
  },
  AdditionalBlockTitle: {
    fontSize: 12,
    fontFamily: 'Arial',
    textAlign: 'left',
    width: '100%',
    color: '#000',
    borderBottom: '2px solid #000',
  },
  educationBlock: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 10,
  },
  WorkBlock: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 10,
  },
  row: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    rowGap: 5,
  },
  EdRow: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  rowDescription: {
    flexDirection: 'row',
    width: '100%',
    height: 'auto',
  },
  Edinstitution: {
    fontSize: 9,
    fontFamily: 'Arial',
    textAlign: 'left',
    color: '#333333',
  },
  EdLocation: {
    fontSize: 9,
    fontFamily: 'Arial',
    textAlign: 'right',
    color: '#000',
  },
  EdstudyType: {
    fontSize: 9,
    fontFamily: 'Arial',
    textAlign: 'left',
    color: '#000',
  },
  EdDates: {
    fontSize: 9,
    fontFamily: 'Arial',
    textAlign: 'right',
    color: '#333333',
  },
  EdDescription: {
    fontSize: 9,
    width: '100%',
    height: 'auto',
    color: '#333333',
  },
  workInstitution: {
    fontSize: 9,
    fontFamily: 'Arial',
    color: '#000',
  },
  workLocation: {
    fontSize: 9,
    textAlign: 'right',
    fontFamily: 'Arial',
    color: '#000',
  },
  workPosition: {
    fontSize: 9,
    fontFamily: 'Arial',
    color: '#333333',
  },
  workDescription: {
    fontSize: 9,
    fontFamily: 'Arial',
    fontStyle: 'italic',
    color: '#333333',
    marginRight: 80,
  },
  workDates: {
    fontSize: 9,
    color: '#333333',
  },
  highlights: {
    marginTop: 5,
    paddingLeft: 15,
    width: '100%',
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  bulletPoint: {
    width: 10,
    fontSize: 9,
  },
  highlightText: {
    fontSize: 9,
    color: '#333333',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 3,
  },
  nameRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 15,
    marginTop: 15,
  },
  headerTopSideTwo: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    columnGap: 10,
    marginBottom: 5,
    marginTop: 10,
  },
  headerTopSide: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    columnGap: 10,
    marginBottom: 5,
    marginTop: 0,
    flexWrap: 'wrap',
  },
  headerTopSideText: {
    fontSize: 11,
    fontFamily: 'Arial',
    color: '#585958',
    textAlign: 'center',
  },
  headerTopSideTextTwo: {
    fontSize: 10,
    fontFamily: 'Arial',
    fontWeight: 'bold',
    color: '#000',
    textAlign: 'center',
  },
  nameText: {
    fontSize: 24,
    fontFamily: 'Arial',
    fontWeight: 'bold',
    color: '#000',
    textAlign: 'center',
  },

  link: {
    color: '#585958',
  },
  blockWithBorder: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 10,
  },
  blockBorder: {
    borderBottomWidth: 1.5,
    borderBottomColor: '#002060',
    borderBottomStyle: 'solid',
    width: '100%',
  },
  blockTitleText: {
    fontSize: 12,
    fontFamily: 'Arial',
    textAlign: 'left',
  },
  languages: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  languagesText: {
    fontSize: 9,
    fontFamily: 'Arial',
    color: '#333333',
  },
  normalWord: {
    color: 'black',
  },
  GreenHighlight: {
    color: '#07795C',
    textDecoration: 'underline',
    backgroundColor: '#bbece880',
  },
  RedHighlight: {
    color: '#BA1A1A',
    textDecoration: 'underline',
    backgroundColor: '#ffdad680',
  },
  SkillsGreenHighlight: {
    color: '#07795C',
    textDecoration: 'underline',
    backgroundColor: '#bbece880',
    fontSize: 9,
    fontFamily: 'Arial',
  },
  SkillsRedHighlight: {
    color: '#BA1A1A',
    textDecoration: 'underline',
    backgroundColor: '#ffdad680',
    fontSize: 9,
    fontFamily: 'Arial',
  },
  SkillsSubtleGreenHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#bbece880',
    fontSize: 9,
    fontFamily: 'Arial',
  },
  SubtleGreenHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#bbece880',
    fontSize: 9,
    fontFamily: 'Arial',
  },
  SubtleRedHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#ffdad680',
    fontSize: 9,
    fontFamily: 'Arial',
  },
  SkillsSubtleRedHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#ffdad680',
    fontSize: 9,
    fontFamily: 'Arial',
  },
});

type ResumeSchemaProps = {
  resume: ResumeSchema,
  title: string,
  highlightedWords: HighlightWords | null,
  activeField: { type: 'work' | 'additional_information'; index: number; bulletIndex: number } | null
};

let previousWorkName: string = '';

// Create Document Component
const HultTemplate = ({
  resume, title, highlightedWords, activeField,
}: ResumeSchemaProps) => (
  <Document title={title} style={styles.document}>
    <Page size="A4" style={styles.page}>
      <View style={styles.nameRow}>
        <Text style={styles.nameText}>
          {resume.basics.name}
        </Text>
      </View>
      <View style={styles.header}>
        <View style={styles.headerTopSide}>
          {resume.basics.phone_numbers?.map((phone) => (
            <Text key={phone} style={styles.headerTopSideText}>
              {phone}
            </Text>
          ))}
          {resume.basics?.emails?.map((email) => (
            <Text key={email} style={styles.headerTopSideText}>
              <Link style={styles.link} key={email} href={`mailto:${email}`}>
                {email}
              </Link>
            </Text>
          ))}
          <Text style={styles.headerTopSideText}>
            <Link style={styles.link} key={resume.basics.url} href={`https://www.${resume.basics.url && (trimUrlPrefix(resume.basics.url))}`}>
              {resume.basics.url && (trimUrlPrefix(resume.basics.url))}
            </Link>
          </Text>
        </View>

      </View>
      <View style={styles.headerTopSideTwo}>
        <Text style={styles.headerTopSideTextTwo}>
          {resume.basics.summary}
        </Text>
      </View>
      <View style={styles.block}>
        <View style={styles.blockWithBorder}>
          <View style={styles.EducationblockTitle}>
            <Text style={styles.blockTitleText}>
              Education
            </Text>
          </View>
        </View>
        {resume.education?.map((item) => (
          <View key={item.institution} style={styles.educationBlock}>
            <View style={styles.row}>
              <Text style={styles.Edinstitution}>
                {item.institution}
              </Text>
              <Text style={styles.EdLocation}>
                {item.location}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.EdstudyType}>
                {item.area}
              </Text>
              <Text style={styles.EdDates}>
                {item.startDate}
                {' '}
                -
                {' '}
                {item.endDate}
              </Text>
            </View>
            {item.description && item.description.map((desc) => (
              <View key={desc} style={styles.EdRow}>
                <Text style={styles.EdDescription}>
                  {desc}
                </Text>
              </View>
            ))}
          </View>
        ))}
      </View>
      <View style={styles.block}>
        <View style={styles.blockWithBorder}>
          <View style={styles.WorkblockTitle}>
            <Text style={styles.blockTitleText}>
              Relevant Experience
            </Text>
          </View>
        </View>
        {resume.work?.map((item, index) => {
          const showWorkName = item?.name !== previousWorkName;
          previousWorkName = item?.name as string;

          return (
            <View key={`${item.name}-${index}`} style={styles.WorkBlock}>
              {showWorkName && (
                <View style={styles.row}>
                  <Text style={styles.workInstitution}>
                    {item.name}
                  </Text>
                  <Text style={styles.workLocation}>
                    {item.location}
                  </Text>
                </View>
              )}
              <View style={styles.row}>
                <Text style={item.summary ? styles.workDescription : styles.workPosition}>
                  {showWorkName && (item.summary ? item.summary : item.position)}
                </Text>
                <Text style={styles.workDates}>
                  {item.startDate}
                  {' '}
                  -
                  {' '}
                  {item.endDate}
                </Text>
              </View>
              {item.summary && (
              <View style={styles.row}>
                <Text style={styles.workPosition}>
                  {item.position}
                </Text>
              </View>
              )}
              {item.highlights && item.highlights.length > 0 && (
              <View style={styles.highlights}>
                {item.highlights.map((highlight, highlightIndex) => {
                  const cleanedHighlight = highlight.replace(/^•\s/, '');

                  return (
                    <View key={`${highlight}-${highlightIndex}`} style={styles.listItem} wrap={false}>
                      <Text style={styles.bulletPoint}>•</Text>
                      <View style={styles.highlightText}>
                        {renderWorkHighlightedText(cleanedHighlight, highlightIndex, index, highlightedWords, activeField, styles)}
                      </View>
                    </View>
                  );
                })}
              </View>
              )}
            </View>
          );
        })}
      </View>

      {resume.additional_information?.bullets?.filter(bullet => bullet.trim().length > 0).length > 0
      && (
      <View style={styles.block}>
        <View style={styles.blockWithBorder}>
          <View style={styles.AdditionalBlockTitle}>
            <Text style={styles.blockTitleText}>
              Additional Experience
            </Text>
          </View>
        </View>
        <View style={styles.WorkBlock}>
          <View style={styles.highlights}>
            {resume.additional_information.bullets?.map((bullet, bulletIndex) => bullet.replace(/^•\s/, '') && (
              <View key={`${bullet}-${bulletIndex}`} style={styles.listItem} wrap={false}>
                <Text style={styles.bulletPoint}>•</Text>
                <View style={styles.highlightText}>
                  {renderAdditionalHighlightedText(bullet.replace(/^•\s/, ''), bulletIndex, highlightedWords, activeField, styles)}
                </View>
              </View>
            ))}
          </View>

        </View>
      </View>
      )}
      <View style={styles.block}>
        <View style={styles.blockWithBorder}>
          <View style={styles.LanguagesBlockTitle}>
            <Text style={styles.blockTitleText}>
              Additional Information
            </Text>
          </View>
        </View>
        <View style={styles.WorkBlock}>
          <View style={styles.languages}>
            <Text style={styles.languagesText}>
              Languages:
              {' '}
              {resume.languages?.map((language) => `${language.language} (${language.fluency})`).join(', ')}
            </Text>
            <Text style={computeSkillsStyle(highlightedWords, activeField, styles)}>
              Technologies:
              {resume.skills?.map((skill) => `${skill.name} (${skill.level})`).join(', ')}
            </Text>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

export default HultTemplate;
