import { Cohort } from '../../advisor/types';
import { FilterOption } from '../../company/types';
import { ResumeTemplates } from '../types';

export const mapCohortsToFilterOptions = (
  studentsCohorts: Cohort[],
): FilterOption[] => studentsCohorts?.map((cohort) => ({
  name: cohort.name,
  value: cohort.id,
}));

export const getDefaultTemplateByUniversity = (
  urlTemplate: string | null,
  resumeTemplates: ResumeTemplates[] | undefined,
  university: any,
) => {
  if (urlTemplate) return urlTemplate;

  const universityTemplates = resumeTemplates?.filter(
    (template: ResumeTemplates) => template.university_id === university?.id,
  );

  if (universityTemplates && universityTemplates.length > 0) {
    return universityTemplates[0].name;
  }

  return resumeTemplates?.[0]?.name || '';
};
