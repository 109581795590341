import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import DOMPurify from 'dompurify';
import * as Sentry from '@sentry/react';
import { formatDateDeltaFromNow } from '@/services/helpers/date';
import { TabsContent, TabsHeader, withTabs } from '@/components/tabs';
import { JOB_SOURCE, JobFeedJob } from '../../types/job';
import { ModalContext } from '@/components/modal/modal-provider';
import { InfoModal } from '@/domains/generic/modals';
import {
  addCompanyToFavorites,
  changeApplicationStatus,
  TrackerContext,
  TrackerContextType,
  TrackerInfoTab,
} from '@/domains/core/company';
import { getSingleCompany } from '@/services/api/company';
import { applyForJob } from '@/services/api/job';
import { EmptySavedDescription } from '../empty-saved-description/empty-saved-description';
import { useTabs } from '@/components/tabs/TabsContext';
import { useAnalytics } from '@/services/hooks/use-analytics';
import './job-description.scss';
import { Company } from '@/domains/core/company/types';
import { JobHeader } from '../job-header/job-header';
import { JobApplicationInfo } from '../job-application-info/job-application-info';

type Props = {
  job: JobFeedJob;
  isSaved: boolean;
  setAllJobsTab: () => void;
  handleSaveAction: (jobId: string) => void;
  handleUnsaveAction: (jobId: string) => void;
  optimisticJobUpdate: (jobId: string, job: JobFeedJob) => void;
  defaultShowDidYouApply: boolean;
};

const JobDescription = ({
  job,
  isSaved,
  setAllJobsTab,
  handleSaveAction,
  handleUnsaveAction,
  optimisticJobUpdate,
  defaultShowDidYouApply,
}: Props) => {
  const tabs = ['Job Description', 'About Company'];
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const [company, setCompany] = useState<Company | null>(null);
  const [showDidYouApply, setShowDidYouApply] = useState(false);
  const { setCurrentTab } = useTabs();
  const { trackEvent } = useAnalytics();
  const descriptionScrollRef = useRef<HTMLDivElement>(null);

  const fetchCompany = async () => {
    if (job && job.company_id) {
      const companyData = await getSingleCompany(job.company_id);
      setCompany(companyData);
    }
  };

  useEffect(() => {
    fetchCompany();
  }, [job?.company_id]);

  useEffect(() => {
    setCurrentTab(tabs[0]);
  }, [job]);

  useEffect(() => {
    if (defaultShowDidYouApply) {
      setShowDidYouApply(true);
    }
  }, [defaultShowDidYouApply]);

  useEffect(() => {
    if (descriptionScrollRef.current) {
      descriptionScrollRef.current.scrollTop = 0;
    }
    trackEvent('Job Viewed', {
      job_id: job?.id,
      company_name: job?.company_name,
      job_title: job?.title,
      industry: job?.tags[4]?.tag_content,
      location: job?.tags[1]?.tag_content,
      full_time: job?.tags[0]?.tag_content,
      on_site: job?.tags[2]?.tag_content,
    });
  }, [job]);

  if (!job) {
    return <EmptySavedDescription setAllJobsTab={setAllJobsTab} />;
  }

  if (!company) {
    return null;
  }

  const handleYesClick = async () => {
    try {
      await applyForJob(job.id, true);
      optimisticJobUpdate(job.id, { ...job, applied: true });
      trackEvent('Applied to job', {
        job_id: job.id,
        company_name: job.company_name,
        job_title: job.title,
        location: job.tags[1].tag_content,
        full_time: job.tags[0].tag_content,
        on_site: job.tags[2].tag_content,
        industry: job.tags[4].tag_content,
        size: job.tags[3].tag_content,
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleNoClick = async () => {
    try {
      await applyForJob(job.id, false);
      optimisticJobUpdate(job.id, { ...job, applied: false });
      trackEvent('Did not apply to job', {
        job_id: job?.id,
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleAppliedResponse = (didApply: boolean) => {
    if (!isSaved) {
      handleSaveAction(job.id);
    }

    if (didApply) {
      handleYesClick();
    } else {
      handleNoClick();
    }

    setShowDidYouApply(false);
    closeModal();
  };

  const rememberSkipModal = (rememberAction: boolean) => {
    if (rememberAction) {
      localStorage.setItem('rememberApplyUser', 'true');
    }
  };

  const handleOpenJobLink = () => {
    const utmUrl = `${job.linkedin_url}${job.linkedin_url.includes('?') ? '&' : '?'}utm_source=CareerOS`;
    window.open(utmUrl, '_blank');

    openModal(
      <InfoModal
        icon="bi bi-send"
        title="Did you apply?"
        description="Just let us know, and we'll help you keep tabs on your application!"
        buttonLabel="Yes, I did! 🤩"
        handleButtonClick={() => handleAppliedResponse(true)}
        secondaryButtonLabel="No, not yet 🙈"
        handleSecondaryButtonClick={() => handleAppliedResponse(false)}
      />,
    );
  };

  const updateJobDeadline = (deadline: string | null, isRolling: boolean) => {
    optimisticJobUpdate(job.id, { ...job, deadline: deadline || '', rolling_date: isRolling });
  };

  const handleHelpNetwork = async () => {
    await addCompanyToFavorites(company.id);
    const updated = await getSingleCompany(company.id);
    setCompany(updated);
    if (updated.application_id) {
      await changeApplicationStatus(updated.application_id, 'networking', 0);
      openTracker(updated.id);
    }
    if (!isSaved) {
      handleSaveAction(job.id);
    }
    closeModal();
    trackEvent('Help me network clicked');
  };

  const confirmApply = (rememberAction: boolean) => {
    rememberSkipModal(rememberAction);
    handleOpenJobLink();
  };

  const handleApply = () => {
    const rememberAction = localStorage.getItem('rememberApplyUser');
    if (rememberAction || (company.application_status && company.application_status !== 'saved')) {
      handleOpenJobLink();
    } else {
      openModal(
        <InfoModal
          icon="bi bi-sign-stop"
          title="Are you sure?"
          description="You have not networked with this company, yet. Are you sure you want to apply?"
          buttonLabel="Yes, I’m sure"
          handleButtonClick={confirmApply}
          secondaryButtonLabel="Help me network"
          handleSecondaryButtonClick={handleHelpNetwork}
          rememberText="Don't show this again"
        />,
      );
    }
    trackEvent('Apply button clicked', {
      job_id: job.id,
      job_title: job.title,
      company: job.company_name,
    });
  };

  return (
    <div className="job-description">
      <JobHeader
        isSaved={isSaved}
        handleSaveButtonClick={isSaved ? () => handleUnsaveAction(job.id) : () => handleSaveAction(job.id)}
        handleApplyButtonClick={handleApply}
        tags={job?.tags && Array.isArray(job.tags) ? job.tags : []}
        isEmployerVerified={job.source === JOB_SOURCE.EMPLOYER_APP}
        logoUrl={job.linkedin_logo_url}
        companyName={job.company_name}
        postedDate={formatDateDeltaFromNow(job.posted_on || job.created_at || '')}
        title={job.title}
        isApplied={job.applied}
        isHiddenApplyButton={job.linkedin_url === '' || !job.linkedin_url}
      />
      {(isSaved || showDidYouApply) && (
        <div className="company-jobs-layout__deadline">
          <JobApplicationInfo
            applicationDeadline={job.deadline || ''}
            isDateRolling={!!job.rolling_date}
            handleAppliedResponse={handleAppliedResponse}
            jobID={job.id}
            showDidYouApply={showDidYouApply}
            isApplied={job.applied}
            revalidateApplicationDeadline={updateJobDeadline}
          />
        </div>
      )}
      <div className="job-description__bottom">
        <TabsHeader withBorder tabs={tabs} containerName="description" defaultTab={tabs[0]} />
        <div className="job-description__tabs-content" ref={descriptionScrollRef}>
          <TabsContent>
            <div
              className="job-description__description"
              data-tab="Job Description"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(job.description),
              }}
            />
            <div
              className="job-description__description"
              data-tab="About Company"
            >
              <TrackerInfoTab
                aboutCompany={company.overview}
                name={company.name}
                logoUrl={company.logo_url}
                website={company.company_url}
                industry={company.industry || company.careeros_industry}
                location={company.hq_location}
              />
            </div>
          </TabsContent>
        </div>
      </div>
    </div>
  );
};

export default withTabs(JobDescription);
