import './table.scss';
import TableBody from './table-body';
import TableHead from './table-head';
import { useSortableTable } from './use-sort-table';
import { Column, TableRow } from './table-types';

type TableProps = {
  data: TableRow[];
  columns: Column[];
  withRowNumber?: boolean;
  isRowClickable?: boolean;
  isFixedLayout?: boolean;
  hasTooltip?: boolean;
  align?: 'left' | 'center' | 'right';
};

const Table = ({
  data,
  columns,
  withRowNumber = false,
  isRowClickable = false,
  isFixedLayout = false,
  hasTooltip = true,
  align = 'left',
}: TableProps) => {
  const [tableData, handleSorting] = useSortableTable(data, columns);

  return (
    <table className={`table ${isFixedLayout ? 'table--fixed' : ''}`}>
      <TableHead align={align} columns={columns} handleSorting={handleSorting} withRowNumber={withRowNumber} />
      <TableBody
        isRowClickable={isRowClickable}
        columns={columns}
        align={align}
        tableData={tableData}
        withRowNumber={withRowNumber}
        hasTooltip={hasTooltip}
      />
    </table>
  );
};

export default Table;
