import { Tooltip } from '../tooltip/tooltip';
import './toggle-views.scss';

export type ToggleViewsOptions = {
  size?: 'small' | 'large';
  selectedValue: number;
  emitSelectedValue: (value: number) => void;
  icons: string[];
  labels: string[];
  iconsSelected: string[];
};

export const ToggleViews = ({
  size = 'small',
  selectedValue = 0,
  emitSelectedValue,
  icons,
  iconsSelected,
  labels,
}: ToggleViewsOptions) => (
  <div
    className={`toggle-views toggle-views--${size}`}
    style={{ '--selected-value': selectedValue } as React.HTMLAttributes<HTMLDivElement>}
  >
    {labels.map((label, index) => (
      <div
        className={`toggle-views__option ${selectedValue === index ? 'toggle-views__option--active' : ''} toggle-views__option--${size}`}
        onClick={() => emitSelectedValue(index)}
        key={label}
      >
        {size === 'small' ? (
          <Tooltip label={label} position="top">
            {selectedValue === index
              ? <i className={`bi ${iconsSelected[index]}`} />
              : <i className={`bi ${icons[index]}`} />}
          </Tooltip>
        ) : (
          <>
            {selectedValue === index
              ? <i className={`bi ${iconsSelected[index]}`} />
              : <i className={`bi ${icons[index]}`} />}
            <span>{label}</span>
          </>
        )}
      </div>
    ))}
  </div>
);
