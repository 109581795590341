import { useEffect, useRef } from 'react';
import Confetti from '../../confetti/confetti';

type Props = {
  fireCount?: number;
};

export const ColumnConfetti = ({
  fireCount,
}: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const confettiRef = useRef<HTMLDivElement>(null);

  const updateCanvasPosition = () => {
    if (!wrapperRef.current || !confettiRef.current) {
      return;
    }

    const topDistance = wrapperRef.current.getBoundingClientRect().top < 0
      ? 0
      : wrapperRef.current.getBoundingClientRect().top;
    confettiRef.current.style.maxHeight = `${window.innerHeight - topDistance}px`;
    const innerTopPosition = wrapperRef.current.getBoundingClientRect().top > 0
      ? 0
      : -wrapperRef.current.getBoundingClientRect().top;
    confettiRef.current.style.top = `${innerTopPosition}px`;
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      updateCanvasPosition();
    });
    return () => {
      window.removeEventListener('scroll', updateCanvasPosition);
    };
  }, []);

  return (
    <div
      className="confetti-wrapper"
      ref={wrapperRef}
      style={{
        position: 'absolute', top: 0, left: 0, zIndex: -1, width: '100%', height: '100%',
      }}
    >
      <div
        className="confetti-wrapper__confetti"
        ref={confettiRef}
        style={{
          width: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
        }}
      >
        <Confetti fireCount={fireCount} />
      </div>
    </div>
  );
};
