import './sequence-step.scss';

type Props = {
  name: string;
  icon: string;
  currentStateIcon: string;
  isCurrent: boolean;
  isCompleted: boolean;
};

export const SequenceStep = ({
  name,
  icon,
  currentStateIcon,
  isCurrent,
  isCompleted,
}: Props) => {
  const calculateIcon = () => {
    switch (true) {
      case isCurrent:
        return currentStateIcon;
      case isCompleted:
        return 'bi bi-check-circle-fill';
      default:
        return icon;
    }
  };

  return (
    <div className="sequence-step">
      <i
        data-testid="sequence-step-icon"
        className={`sequence-step__icon ${
          isCompleted ? 'sequence-step__icon--completed' : ''
        } ${
          isCurrent ? 'sequence-step__icon--active' : ''
        } ${
          calculateIcon()
        }`}
      />
      <span
        className={`sequence-step__name ${
          isCompleted ? 'sequence-step__name--completed' : ''
        } ${
          isCurrent ? 'sequence-step__name--active' : ''
        }`}
      >
        {name}
      </span>
    </div>
  );
};
