import { JobTagType } from '@/domains/core/job/types/job';

const formatRangeToNumber = (value: string) => {
  const parts = value.split('-');

  if (parts.length === 2) {
    const start = Number(parts[0]);
    const end = Number(parts[1]);
    if (!Number.isNaN(start) && !Number.isNaN(end)) {
      return `${start.toLocaleString()}-${end.toLocaleString()}`;
    }
  }

  if (value.endsWith('+')) {
    const number = Number(value.slice(0, -1));
    if (!Number.isNaN(number)) {
      return `${number.toLocaleString()}+`;
    }
  }
  return value;
};

export const sortTags = (tags: JobTagType[]) => {
  const order = [
    'location',
    'contract',
    'workMode',
    'industry',
    'salary',
    'employees',
  ];
  return tags
    .map((tag) => ({
      tag_type: tag.tag_type,
      tag_content: formatRangeToNumber(tag.tag_content),
    }))
    .filter((tag) => tag.tag_content)
    .sort((a, b) => order.indexOf(a.tag_type) - order.indexOf(b.tag_type));
};
