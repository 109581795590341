export const progressStagesUploadResume = [
  {
    text: 'Snatching up your contact deets',
    start: 0,
    end: 9,
    duration: 1,
  },
  {
    text: 'Rounding up your career chronicles',
    start: 9,
    end: 15,
    duration: 1.5,
  },
  {
    text: 'Assembling your education highlights',
    start: 15,
    end: 37,
    duration: 2.5,
  },
  {
    text: 'Fetching your superpowers',
    start: 37,
    end: 53,
    duration: 4,
  },
  {
    text: 'Rounding up your language lingo',
    start: 53,
    end: 76,
    duration: 6.5,
  },
  {
    text: 'Great resumes might take a tiny bit longer',
    start: 76,
    end: 98,
    duration: 10.5,
  },
  {
    text: 'Almost there',
    start: 98,
    end: 100,
    duration: Infinity,
  },
];

export const progressStagesResumeBook = [
  {
    text: 'Running filter query',
    start: 0,
    end: 11,
    duration: 0.1,
  },
  {
    text: 'Filtering resumes',
    start: 11,
    end: 18,
    duration: 0.3,
  },
  {
    text: 'Generating pages',
    start: 18,
    end: 39,
    duration: 0.4,
  },
  {
    text: 'Assembling one long pdf',
    start: 39,
    end: 53,
    duration: 0.6,
  },
  {
    text: 'Preparing for download',
    start: 53,
    end: 98,
    duration: 0.9,
  },
  {
    text: 'Almost there',
    start: 98,
    end: 100,
    duration: 1,
  },
];
