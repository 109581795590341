import { findTrialUniversity, mapUniversitiesToGeneric } from '@/services/helpers/university';
import { LoginLoaderResponse } from '../types';
import { getUniversities } from '@/services/public-api/universities';

export async function loginLoader({ request }: any): Promise<LoginLoaderResponse> {
  const url = new URL(request.url);
  const selectedUniversity = url.searchParams.get('school') || url.searchParams.get('university');
  const decodedUniversityName = selectedUniversity ? decodeURIComponent(selectedUniversity) : selectedUniversity;

  const signedUniversitiesResponse = await getUniversities();
  const signedUniversities = signedUniversitiesResponse || [];

  const mappedSignedUniversities = mapUniversitiesToGeneric(signedUniversities);

  let universityInList = mappedSignedUniversities.find((s) => s.name === decodedUniversityName);

  if (decodedUniversityName && !universityInList) {
    universityInList = await findTrialUniversity(decodedUniversityName) || {
      name: decodedUniversityName || '', logo: '', isTrial: false, id: '',
    };
  }

  return {
    universities: mappedSignedUniversities ?? [],
    selectedUniversity: decodedUniversityName ? universityInList : undefined,
  };
}
