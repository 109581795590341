import { useEffect, useState } from 'react';
import '../sidebar.scss';
import LinkedinLogo from '@/assets/images/linkedin.svg';
import { getContactsByCompanyAndUser } from '@/services/api/contact';
import Comments from '@/assets/images/comments.svg';

type Props = {
  companyName: string;
  companyID: string;
  closeSidebar: () => any;
  otherContacts?: any;
  userID: string;
};

export const ContactsSidebar = ({
  companyName,
  companyID,
  closeSidebar,
  otherContacts,
  userID,
}: Props) => {
  const [contacts, setContacts] = useState([]);
  const fetchContacts = async () => {
    const response = await getContactsByCompanyAndUser(companyID, userID);
    setContacts(response ?? []);
  };
  useEffect(() => {
    if (companyID) {
      fetchContacts();
    }
  }, [companyID]);

  return (
    <div className="industry-sidebar">
      <h2 className="industry-sidebar__title" onClick={closeSidebar}>
        {companyName}
      </h2>
      <p className="industry-sidebar__subtitle">Contacts student is networking with</p>
      {contacts?.length > 0 ? (
        <div className="industry-sidebar__companies">
          <div className="industry-sidebar__companies__headers">
            <div className="industry-sidebar__companies__headers__head">Employee Name</div>
            <div className="industry-sidebar__companies__headers__head">Job Title</div>
          </div>
          <div className="industry-sidebar__companies__list">
            {contacts.map((contact: any) => (
              <div key={contact.id} className="industry-sidebar__companies__list__item">
                <a href={contact.linkedin_url} target="_blank" className="industry-sidebar__companies__list__item__company" rel="noreferrer">
                  <img src={LinkedinLogo} className="linkedin" alt="company logo" />
                  <span className="industry-sidebar__companies__list__item__company__title">{`${contact.first_name} ${contact.last_name}`}</span>
                </a>
                <div className="industry-sidebar__companies__list__item__students">
                  {contact.current_position.title}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="industry-sidebar__comments">
          <img src={Comments} alt="" />
          <p>There are no contacts for this student.</p>
        </div>
      )}
      {otherContacts && otherContacts.length > 0 && (
        <>
          <h2 className="industry-sidebar__title" onClick={closeSidebar}>
            Student Overlap
          </h2>
          <p className="industry-sidebar__subtitle">
            Other contacts saved by students
          </p>
          <div className="industry-sidebar__companies">
            <div className="industry-sidebar__companies__headers">
              <div className="industry-sidebar__companies__headers__head">Employee Name</div>
              <div className="industry-sidebar__companies__headers__head">Job Title</div>
            </div>
            <div className="industry-sidebar__companies__list">
              {otherContacts.map((contact: any) => (
                <div key={contact.id} className="industry-sidebar__companies__list__item">
                  <a href={contact.linkedin_url} target="_blank" className="industry-sidebar__companies__list__item__company" rel="noreferrer">
                    <img src={LinkedinLogo} className="linkedin" alt="company logo" />
                    <span className="industry-sidebar__companies__list__item__company__title">{`${contact.first_name} ${contact.last_name}`}</span>
                  </a>
                  <div className="industry-sidebar__companies__list__item__students">
                    {contact.current_position.title}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) }
    </div>
  );
};
