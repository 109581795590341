import { useContext } from 'react';
import DOMPurify from 'dompurify';
import { Button } from '@careeros/coco';
import { TabsHeader, TabsContent, withTabs } from '@/components/tabs/';
import ResumeComments from '../resume-comments/resume-comments';
import { AddComment } from '@/domains/generic/modals';
import { ModalContext } from '@/components/modal/modal-provider';
import { Job } from '@/domains/core/company/types';

type CommentsSidebarProps = {
  resumeID: string;
  job: Job
  email: string;
  url: string;
};

const CommentsSidebar = ({
  resumeID, job, email, url,
}: CommentsSidebarProps) => {
  const { openModal } = useContext(ModalContext) as any;

  const openAddCommentModal = (ev: any) => {
    ev.stopPropagation();
    openModal(
      <AddComment
        payload={
        {
          email,
          url,
        }
      }
        id={resumeID}
        type="resume"
      />,
    );
  };
  return (
    <div className="resume-builder__comments-sidebar">
      <div className="resume-builder__sidebar-header">
        <div className="resume-builder__sidebar-header-tabs">
          <TabsHeader tabs={job ? ['Comments', 'Job Description'] : ['Comments']} defaultTab="Comments" icons={job ? ['bi bi-chat-left-dots', 'bi bi-briefcase'] : ['bi bi-chat-left-dots']} />
        </div>
      </div>
      <div className="resume-builder__sidebar-content">
        <TabsContent>
          <div data-tab="Comments">
            <div style={{
              padding: '0 20px', marginBottom: 20, marginTop: 20,
            }}
            >
              <Button onClick={openAddCommentModal} mode="primary" icon="bi bi-plus-circle" label="Write a Comment" size="fill" />
            </div>
            {resumeID && <ResumeComments hasUnreadComments={() => {}} id={resumeID} />}
          </div>
          {job && (
          <div data-tab="Job Description">
            <div className="resume-builder__job-info">
              <div className="resume-builder__job-title">{job.title}</div>
              <div className="resume-builder__job-description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(job.description) }} />
            </div>
          </div>
          )}
        </TabsContent>
      </div>
    </div>
  );
};

export default withTabs(CommentsSidebar);
