import { User } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { Contact } from '@/domains/core/contact/types';

import { FirebaseLinkedinMessage } from '../../types/messages';
import { CalculateMessage } from '../linkedin-message/calculate-message';
import { useAnalytics } from '@/services/hooks/use-analytics';
import './linkedin-message-history.scss';

type LinkedinChatProps = {
  messages: FirebaseLinkedinMessage[];
  user: User;
  contact: Contact;
  selfID: string;
};

export const LinkedInMessageHistory = ({
  messages,
  user,
  contact,
  selfID,
}: LinkedinChatProps) => {
  const { trackEvent } = useAnalytics();
  const checkValidityOfMessages = (allMessages: FirebaseLinkedinMessage[]) => {
    const invalidMessages = allMessages.filter((message) => {
      if (message.fromContactID && message.fromContactID !== contact.id) {
        return true;
      }
      if (message.fromUserID && message.fromUserID !== selfID) {
        return true;
      }
      return false;
    });
    if (invalidMessages.length > 0) {
      trackEvent('Invalid Linkedin Messages', user, {
        userID: selfID,
        userName: user.name,
        contactID: contact.id,
      });
    }
  };
  useEffect(() => {
    checkValidityOfMessages(messages);
  }, [messages]);

  return (
    <div className="linkedin-message-history">
      {messages
        && messages.length > 0
        && messages.map((message: FirebaseLinkedinMessage, index) => (
          <div key={message.createdAt.toString() || index}>
            <CalculateMessage message={message} user={user} contact={contact} selfID={selfID} />
          </div>
        ))}
    </div>
  );
};
