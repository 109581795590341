import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Button } from '@careeros/coco';
import { Company } from '../../types';
import { addCompanyToFavorites } from '@/services/api/favorite';
import './company-overview.scss';
import { useScroll } from '@/services/hooks/use-scroll';
import { BrowseCompanies } from '../browse-company/browse-company';
import { Contact } from '@/domains/core/contact/types';
import { LinkButton } from '@/components/link/link';
import { CompanyProgressTracker } from '@/domains/core/company';
import { prettifyCompanyOverview } from '@/services/helpers/company';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { IconButton } from '@/components/icon-button/icon-button';

interface CompanyOverviewProps {
  company: Company;
  isPremium?: boolean;
  relatedCompanies?: Company[];
  contacts: Contact[];
}

const JOBS_IN_VIEW = 3;
const JOB_TILE_SIZE = 329;
const JOBS_COLUMN_GAP = 24;
const FULLY_VISIBLE_JOBS = 2;

const mockedJobs = [
  {
    position: 'Associate Consultant',
    location: 'Brussels, Belgium',
    type: 'Full-time',
  },
  {
    position: 'Marketing Manager',
    location: 'Barcelona, Spain',
    type: 'Full-time',
  },
  {
    position: 'Sales Operations Specialist',
    location: 'Brussels, Belgium',
    type: 'Full-time',
  },
  {
    position: 'Inbound Marketing Manager',
    location: 'Brussels, Belgium',
    type: 'Full-time',
  },
  {
    position: 'Associate Consultant',
    location: 'Brussels, Belgium',
    type: 'Full-time',
  },
  {
    position: 'Sales Operations Specialist',
    location: 'Munich',
    type: 'Full-time',
  },
];

const mockedContacts = [
  {
    name: 'Jenny Wilson',
    position: 'Sales Operations Specialist',
    location: 'Brussels, Belgium',
    image: 'https://media.licdn.com/dms/image/D4D03AQEysz6vb2L8fQ/profile-displayphoto-shrink_400_400/0/1675291504916?e=1687996800&v=beta&t=OGV6IMknVAgjJREV2ByWkxusiq3p9_KEyRQ7e_AlGzw',
  },
  {
    name: 'Amanda Clinton',
    position: 'Head of Marketing',
    location: 'Brussels, Belgium',
    image: 'https://media.licdn.com/dms/image/D4D03AQEysz6vb2L8fQ/profile-displayphoto-shrink_400_400/0/1675291504916?e=1687996800&v=beta&t=OGV6IMknVAgjJREV2ByWkxusiq3p9_KEyRQ7e_AlGzw',
  },
  {
    name: 'Ralph Edwards',
    position: 'Marketing Assistant',
    location: 'Brussels, Belgium',
    image: 'https://media.licdn.com/dms/image/C5603AQGXLZjP_Mmg3w/profile-displayphoto-shrink_400_400/0/1625849186624?e=1687996800&v=beta&t=eav2X5sdjdK3D0yCmhcq7Mt-H_cdsVTMdh_jZKA4Xho',
  },
  {
    name: 'Helena Reynolds',
    position: 'Consultant',
    location: 'Brussels, Belgium',
    image: 'https://media.licdn.com/dms/image/D4E03AQEQy_2bfpg6vA/profile-displayphoto-shrink_400_400/0/1679076350424?e=1687996800&v=beta&t=eREg-0mj4lnkXd6id-w8ASQCnxRImca0NKyPatCz1w8',
  },
];

/**
 * Primary UI component for the Company Overview Tab
 */
export const CompanyOverviewContainer = ({
  company,
  isPremium = false,
  relatedCompanies,
  contacts,
}: CompanyOverviewProps) => {
  const [aboutCompany, setAboutCompany] = useState('');
  const [jobsContainer, handleJobsScroll] = useScroll();
  const [showRightJobsScroll, setShowRightJobsScroll] = useState(true);
  const [showLeftJobsScroll, setShowLeftJobsScroll] = useState(false);
  const [activeDot, setActiveDot] = useState(1);
  const navigate = useNavigate();
  const maxScrollDots = mockedJobs.length / FULLY_VISIBLE_JOBS;
  const linkToAddContacts = `https://www.linkedin.com/search/results/people/?keywords=${encodeURIComponent(company.name)}&spellCorrectionEnabled=false&origin=GLOBAL_SEARCH_HEADER&sid=n)F`;
  const queryClient = useQueryClient();

  const revalidate = () => {
    queryClient.invalidateQueries(['applications-no-cache']);
    queryClient.invalidateQueries(['company', company.id]);
  };

  const goToContact = (contactID: string) => {
    navigate(`/app/inbox/${contactID}`);
  };

  const saveCompany = () => {
    addCompanyToFavorites(company.id).finally(revalidate);
  };

  const calculateScrollLimit = (childCount: number) => {
    const scrollLimit = (childCount - JOBS_IN_VIEW) * (JOB_TILE_SIZE + JOBS_COLUMN_GAP);
    return scrollLimit;
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const el = e.currentTarget;

    const scrollLimit = calculateScrollLimit(mockedJobs.length);
    const percentOfJobsScrolled = el.scrollLeft / scrollLimit;
    setShowRightJobsScroll(!(el.scrollLeft > scrollLimit));
    setShowLeftJobsScroll(el.scrollLeft !== 0);
    setActiveDot(Math.round(percentOfJobsScrolled * maxScrollDots) || 1);
  };

  useEffect(() => {
    setAboutCompany(prettifyCompanyOverview(company.overview));
  }, [company.overview]);

  return (
    <div className="company-overview-container">
      <div className={`progress-tracker ${company.application_status ? '' : 'progress-tracker--full'}`}>
        <h3 className="progress-tracker__title">Your application progress</h3>
        {company.application_status ? (
          <CompanyProgressTracker status={company.application_status} name={company.name} id={company.id} />
        ) : (
          <>
            <p className="progress-tracker__text">
              Saving
              {' '}
              {company.name}
              {' '}
              allows you to start your complete journey with them, including tips for success and a game plan checklist for each step along the way that will prepare and propel you towards your dream job.
            </p>
            <Button mode="primary" handleClick={saveCompany} label="Save Company" size="fill" />
          </>
        )}

      </div>
      {company.application_status && (
        <div className="networking-widget">
          <h3 className="networking-widget__title">Company Contacts</h3>
          <div className="networking-widget__content">
            {(company.application_status && contacts.length > 0) && (
              <div className="networking-widget__contacts">
                {contacts.map((contact) => (
                  <div className="networking-widget__contact">
                    <DefaultLogo
                      type="contact"
                      source={contact.career_summary.basics.image}
                      handleClick={() => goToContact(contact.id)}
                      className="networking-widget__contact__image"
                      name={`${contact.first_name} ${contact.last_name}`}
                      size={38}
                    />
                    <div className="networking-widget__contact__info">
                      <h4 className="networking-widget__contact__name">{`${contact.first_name} ${contact.last_name}`}</h4>
                      <span className="networking-widget__contact__label">{contact.current_position.title}</span>
                    </div>
                    <Button handleClick={() => goToContact(contact.id)} label="View contact profile" outlined mode="primary" size="small" />
                  </div>
                ))}
                <LinkButton
                  link={linkToAddContacts}
                  label="Add Contact"
                  icon="bi bi-plus-circle"
                />
              </div>
            )}

            {(company.application_status && !contacts.length) && (
              <LinkButton
                link={linkToAddContacts}
                label="Add Contact"
                icon="bi bi-plus-circle"
                target="_blank"
              />
            )}
          </div>
        </div>
      )}
      <div className="company-about">
        <h3 className="company-about__title">About</h3>
        <div>{aboutCompany.split(/\n/).map(line => <p className="company-about__text" key={line}>{line}</p>)}</div>
      </div>
      <div className="company-info">
        <div className="company-info__row">
          <i className="company-info__row__icon bi bi-geo-alt-fill" />
          <div className="company-info__row__value">{company.hq_location}</div>
        </div>
        <div className="company-info__row">
          <i className="company-info__row__icon bi bi-buildings" />
          <div className="company-info__row__value">
            {company.careeros_industry}
          </div>
        </div>
        <div className="company-info__row">
          <i className="company-info__row__icon bi bi-people" />
          <div className="company-info__row__value">
            {`${company.company_size} ${company.company_size?.includes('employees') ? '' : 'employees'}`}
          </div>
        </div>
        <div className="company-info__row">
          <LinkButton label="Website" link={company.company_url} icon="bi bi-link" target="_blank" />
        </div>
        <div>
          <h4>Social links</h4>
          <div className="company-info__social-links">
            <a href={company.linkedin_url} target="_blank" className="company-info__social-link" rel="noreferrer">
              <i className="bi bi-linkedin" />
            </a>
            {company.twitter_url
              && (
                <a href={company.twitter_url} target="_blank" className="company-info__social-link" rel="noreferrer">
                  <i className="bi bi-twitter" />
                </a>
              )}
          </div>
        </div>
      </div>
      {isPremium && (
        <>
          <div className="company-values">
            <h3 className="company-values__title">Values</h3>
            <p className="company-values__text">
              {company.values}
            </p>
          </div>
          <div className="job-listing">
            <div className="job-listing__top">
              <h3 className="job-listing__title">Recent Job Listings</h3>
              <div className="job-listing__controls">
                <Button mode="rounded" outlined size="small" label="View all" />
                <IconButton
                  icon="bi bi-chevron-left"
                  mode="rounded"
                  size="medium"
                  outlined
                  handleClick={() => handleJobsScroll('left')}
                  disabled={!showLeftJobsScroll}
                />
                <IconButton
                  icon="bi bi-chevron-right"
                  mode="rounded"
                  size="medium"
                  outlined
                  handleClick={() => handleJobsScroll('right')}
                  disabled={!showRightJobsScroll}
                />
              </div>
            </div>
            <div
              className="job-listing__container"
            >
              <div
                className="job-listing__jobs"
                ref={jobsContainer}
                onScroll={handleScroll}
              >
                {mockedJobs.map((job) => (
                  <div className="job-card">
                    <h4 className="job-card__title">{job.position}</h4>
                    <div className="job-card__details">
                      <div className="job-card__detail">
                        <i className="bi bi-geo-alt-fill" />
                        <span className="job-card__detail__text">{job.location}</span>
                      </div>
                      <div className="job-card__detail">
                        <i className="bi bi-sticky-fill" />
                        <span className="job-card__detail__text">{job.type}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="job-listing__scroll-dots">
                {Array.from({ length: maxScrollDots }, (_, i) => i + 1).map((dotNumber) => (
                  <div className={`job-listing__scroll-dot ${activeDot === dotNumber && 'job-listing__scroll-dot--active'}`} />
                ))}
              </div>
            </div>
          </div>
          <div className="company-contacts">
            <h3 className="company-contacts__title">Suggested Contacts</h3>
            <div className="company-contacts__container">
              <div className="company-contacts__contacts">
                {mockedContacts.map((contact) => (
                  <div className="company-contacts__tile">
                    <img
                      src={contact.image}
                      className="company-contacts__tile__image"
                      alt=""
                    />
                    <div className="company-contacts__tile__info">
                      <h4 className="company-contacts__tile__name">{contact.name}</h4>
                      <span className="company-contacts__tile__label">
                        {contact.position}
                        {' at '}
                        {company.name}
                      </span>
                      <span className="company-contacts__tile__location">
                        {contact.location}
                      </span>
                      <div className="company-contacts__tile__underline" />
                    </div>
                    <div className="company-contacts__tile__icons">
                      <i className="company-contacts__icon bi bi-envelope" />
                      <i className="company-contacts__icon bi bi-eye" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {(relatedCompanies && relatedCompanies.length > 0) && (
            <BrowseCompanies tag={company.tags[0]} relatedCompanies={relatedCompanies} />
          )}
        </>
      )}
    </div>
  );
};
