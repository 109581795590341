import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useCookies } from 'react-cookie';
import { LinkType } from './types/types';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { SidebarView } from './sidebar-view/sidebar-view';
import { useSelfUniversity } from '@/services/queries/user';
import { getLinksForRole } from './utils/links';
import { StudentLinks } from './sidebar-links';

type SidebarProps = {
  collapsed: boolean;
  handleToggle: () => void;
};

export default function Sidebar({ collapsed, handleToggle }: SidebarProps) {
  const { user } = useAuth0();
  const { trackEvent } = useAnalytics();
  const [links, setLinks] = useState<LinkType[]>([]);
  const { data: university, isLoading: selfLoading } = useSelfUniversity();
  const [cookies] = useCookies(['cookiesAccepted']);
  const [logoLink, setLogoLink] = useState('/app/dashboard');
  const handleLinkClick = (linkName: string) => {
    trackEvent('Page Visit', user, {
      pageURL: linkName,
    });
    const newLinks = links?.map((linksLink: LinkType) => {
      linksLink.isActive = false;
      return linksLink;
    });
    setLinks(newLinks);
  };

  const setUpLinks = () => {
    if (user && university) {
      const roles = user['https://careeros.com/roles'];
      const visibleLinks = getLinksForRole(roles, university);

      if (roles.includes('CareerAdvisor')) {
        setLogoLink(`/app/dashboard-advisor/${university.id}`);
      } else if (roles.includes('Admin')) {
        setLogoLink('/app/admin-view');
      }

      setLinks(visibleLinks);
    }
  };

  useEffect(() => {
    if (!selfLoading) {
      setUpLinks();
    }
  }, [user, university, selfLoading]);

  return (
    <SidebarView
      collapsed={collapsed}
      isCookieBannerVisible={!cookies.cookiesAccepted}
      showContent
      logoLink={logoLink}
      links={links}
      handleToggle={handleToggle}
      handleLinkClick={handleLinkClick}
      splitBefore={links[0] !== StudentLinks[0] ? StudentLinks[0].label : undefined}
    />
  );
}
