import DownloadExtensionIcon from '@/assets/images/icons/quests/download_chrome_extension.svg';
import ResolveCommentIcon from '@/assets/images/icons/quests/resolve-comment.svg';
import SaveCompanyIcon from '@/assets/images/icons/quests/save-company.svg';
import SendLinkedinIcon from '@/assets/images/icons/quests/send-linkedin.svg';
import CoffeeChatIcon from '@/assets/images/icons/quests/coffee-chat.svg';
import ConnectEmailIcon from '@/assets/images/icons/quests/connect-email.svg';
import CompleteFormIcon from '@/assets/images/icons/quests/complete-form.svg';
import { QuestType } from '../../types/quests';

export const QUEST_SHORT_TITLE: any = {
  [QuestType.DownloadExtension]: 'Download Chrome Extension',
  [QuestType.ApplicationDeadline]: 'Submit job application',
  [QuestType.ResolveComment]: 'Resolve a comment',
  [QuestType.SaveCompany]: 'Save a company',
  [QuestType.SaveFirstCompany]: 'Save your first company',
  [QuestType.CompleteDemographicsForm]: 'Customize your experience',
  [QuestType.SaveContact]: 'Add a contact ',
  [QuestType.SendMessage]: 'Send a Linkedin message ',
  [QuestType.SendConnection]: 'Send a Linkedin invitation ',
  [QuestType.SendFirstEmail]: 'Send first email',
  [QuestType.SendFirstOutreach]: 'Send first outreach',
  [QuestType.SendSecondOutreach]: 'Send second outreach',
  [QuestType.ScheduleCoffeeChat]: 'Schedule a coffee chat',
  [QuestType.AddNewContact]: 'Add a new contact ',
  [QuestType.SendFollowUpEmail]: 'Send follow-up email ',
  [QuestType.RespondToEmail]: 'Respond to email',
  [QuestType.ConnectEmail]: 'Connect email',
  [QuestType.ReplyToContact]: 'Reply to contact',
};

export const ICON_TYPE: any = {
  [QuestType.DownloadExtension]: DownloadExtensionIcon,
  [QuestType.ApplicationDeadline]: 'ShowCompanyLogo',
  [QuestType.ResolveComment]: ResolveCommentIcon,
  [QuestType.SaveCompany]: SaveCompanyIcon,
  [QuestType.SaveFirstCompany]: SaveCompanyIcon,
  [QuestType.CompleteDemographicsForm]: CompleteFormIcon,
  [QuestType.SaveContact]: 'ShowCompanyLogo',
  [QuestType.SendMessage]: SendLinkedinIcon,
  [QuestType.SendConnection]: SendLinkedinIcon,
  [QuestType.ScheduleCoffeeChat]: CoffeeChatIcon,
  [QuestType.ConnectEmail]: ConnectEmailIcon,
};
export const QUEST_ORDER: any = {
  [QuestType.DownloadExtension]: 1,
  [QuestType.SaveFirstCompany]: 2,
  [QuestType.ConnectEmail]: 2,
  [QuestType.CompleteDemographicsForm]: 2,
  [QuestType.ScheduleCoffeeChat]: 3,
  [QuestType.ApplicationDeadline]: 4,
  [QuestType.SendMessage]: 10, // deprecated
  [QuestType.SendConnection]: 10, // deprecated
  [QuestType.SendFirstEmail]: 10, // deprecated
  [QuestType.SendFirstOutreach]: 7,
  [QuestType.SendSecondOutreach]: 7,
  [QuestType.ResolveComment]: 9,
  [QuestType.SaveCompany]: 8,
  [QuestType.SaveContact]: 8,
  [QuestType.AddNewContact]: 8,
  [QuestType.SendFollowUpEmail]: 6, // deprecated
  [QuestType.RespondToEmail]: 4,
  [QuestType.ReplyToContact]: 4,
};

export const CTA_LINK: any = {
  [QuestType.DownloadExtension]: import.meta.env.VITE_EXTENSION_LINK,
  [QuestType.ConnectEmail]: '/app/profile',
  [QuestType.CompleteDemographicsForm]: '/app/onboarding?show_navigation=true',
  [QuestType.ApplicationDeadline]: (companyID: string) => `/app/overview/${companyID}/#startApplying`,
  [QuestType.ResolveComment]: '/quests',
  [QuestType.SaveCompany]: '/app/companies/explore',
  [QuestType.SaveFirstCompany]: '/app/companies/explore',
  [QuestType.SaveContact]: (companyID: string) => `/app/overview/${companyID}?company-tracker-tab=Contacts#addContact`,
  [QuestType.SendMessage]: (contactID: string) => `/app/inbox/${contactID}?view=linkedin`,
  [QuestType.SendConnection]: (contactID: string) => `/app/inbox/${contactID}?view=linkedin`,
  [QuestType.SendFirstEmail]: (contactID: string) => `/app/inbox/${contactID}?view=email`,
  [QuestType.SendFirstOutreach]: (contactID: string) => `/app/inbox/${contactID}`,
  [QuestType.SendSecondOutreach]: (contactID: string) => `/app/inbox/${contactID}`,
  [QuestType.ScheduleCoffeeChat]: (contactID: string) => `/app/inbox/${contactID}`,
  [QuestType.AddNewContact]: (companyID: string) => `/app/overview/${companyID}?company-tracker-tab=Contacts`,
  [QuestType.SendFollowUpEmail]: (contactID: string) => `/app/inbox/${contactID}?view=email`,
  [QuestType.RespondToEmail]: (contactID: string) => `/app/inbox/${contactID}?view=email`,
  [QuestType.ReplyToContact]: (contactID: string) => `/app/inbox/${contactID}`,
};

export const CTA_LABEL: any = {
  [QuestType.DownloadExtension]: 'Install',
  [QuestType.ApplicationDeadline]: 'Application',
  [QuestType.ResolveComment]: 'See Comment',
  [QuestType.CompleteDemographicsForm]: 'Complete',
  [QuestType.ConnectEmail]: 'Connect',
  [QuestType.SaveCompany]: 'Explore',
  [QuestType.SaveFirstCompany]: 'Browse',
  [QuestType.SaveContact]: 'Add Contact',
  [QuestType.SendMessage]: 'Reach out',
  [QuestType.SendConnection]: 'Connect',
  [QuestType.SendFirstEmail]: 'Send email',
  [QuestType.SendFirstOutreach]: 'Reach out',
  [QuestType.SendSecondOutreach]: 'Follow up',
  [QuestType.ScheduleCoffeeChat]: 'Message',
  [QuestType.AddNewContact]: 'Save Contact',
  [QuestType.SendFollowUpEmail]: 'Follow up',
  [QuestType.RespondToEmail]: 'Respond',
  [QuestType.ReplyToContact]: 'Reply',
};

export const QUEST_COMPLETED_DESCRIPTION: any = {
  [QuestType.DownloadExtension]: 'Woohoo! Done. Thanks for installing our Chrome Extension.',
};

export const CTA_COMPLETED_LABEL: any = {
  [QuestType.DownloadExtension]: 'Install again',
};

export const QUEST_TITLE: any = {
  [QuestType.DownloadExtension]: 'Install the CareerOS Chrome Extension',
  [QuestType.ApplicationDeadline]: (companyName: string, role: string) => `Submit your application for ${role} at ${companyName}`,
  [QuestType.ResolveComment]: 'Resolve a comment',
  [QuestType.SaveCompany]: 'Save a company',
  [QuestType.CompleteDemographicsForm]: 'Customize your experience',
  [QuestType.ConnectEmail]: 'Connect your email inbox',
  [QuestType.SaveFirstCompany]: 'Save your first company',
  [QuestType.SaveContact]: (companyName: string) => `Add a contact to ${companyName}`,
  [QuestType.SendMessage]: (contactName: string, companyName: string) => `Send a Linkedin message to ${contactName} at ${companyName}`,
  [QuestType.SendConnection]: (contactName: string, companyName: string) => `Send a Linkedin invitation to ${contactName} at ${companyName}`,
  [QuestType.SendFirstEmail]: (contactName: string, companyName: string) => `Send first email to ${contactName} at ${companyName}`,
  [QuestType.SendFirstOutreach]: (contactName: string, companyName: string) => `Send First Outreach to ${contactName} at ${companyName}`,
  [QuestType.SendSecondOutreach]: (contactName: string, companyName: string) => `Send Second Outreach to ${contactName} at ${companyName}`,
  [QuestType.ScheduleCoffeeChat]: (contactName: string, companyName: string) => `Schedule a coffee chat with ${contactName} at ${companyName}`,
  [QuestType.AddNewContact]: (companyName: string) => `Add a new contact to ${companyName}`,
  [QuestType.SendFollowUpEmail]: (contactName: string, companyName: string) => `Send follow-up email to ${contactName} at ${companyName}`,
  [QuestType.RespondToEmail]: (contactName: string, companyName: string) => `Respond to email from ${contactName} at ${companyName}`,
  [QuestType.ReplyToContact]: (contactName: string, companyName: string) => `Reply to ${contactName} from ${companyName}`,
};

export const QUEST_DESCRIPTION: any = {
  [QuestType.DownloadExtension]: () => 'Unlock the superpowers of CareerOS. The plugin lets you save contacts, and jobs from Linkedin and other job boards. It also lets you access our messaging template and send Linkedin messages directly from CareerOS.',
  [QuestType.ApplicationDeadline]: ({ deadline }: { [key: string]: string }) => `Submit your application by ${deadline}.`,
  [QuestType.ResolveComment]: () => 'Resolve a comment on your dashboard.',
  [QuestType.ConnectEmail]: () => 'Connect your email account to send and receive emails within CareerOS.',
  [QuestType.CompleteDemographicsForm]: () => 'We collect your answers from this form to customize your experience and show you relevant career opportunities.',
  [QuestType.SaveCompany]: () => 'Save a company to your dashboard by clicking on the Career OS Chrome Extension icon in your browser.',
  [QuestType.SaveFirstCompany]: () => 'Browse our company database for your favorite companies or new inspiration and save your first company to get started.',
  [QuestType.SaveContact]: () => 'Add your first contact to this company to start networking.',
  [QuestType.SendMessage]: () => 'Send a customized Linkedin message to reach out to your new contact.',
  [QuestType.SendConnection]: () => 'Send an invitation with a tailored message to your new contact.',
  [QuestType.SendFirstEmail]: ({ contactName, companyName }: { [key: string]: string }) => `Send a first email to ${contactName} at ${companyName} to get a conversation started.`,
  [QuestType.SendFirstOutreach]: ({ contactName, companyName }: { [key: string]: string }) => `Send a first outreach  to ${contactName} at ${companyName} to get a conversation started.`,
  [QuestType.SendSecondOutreach]: ({ contactName, companyName }: { [key: string]: string }) => `Send a second outreach  to ${contactName} at ${companyName} to get a conversation started.`,
  [QuestType.RespondToEmail]: ({ contactName, companyName }: { [key: string]: string }) => `Respond to an email from ${contactName} at ${companyName}.`,
  [QuestType.ScheduleCoffeeChat]: () => (
    <>
      Get unique insights from your new contact by scheduling an informational interview with them. Make sure to use our
      {' '}
      <a href="#coffeeChatTips">coffee chat tips</a>
      {' '}
      to prepare for your meeting.
    </>
  ),
  [QuestType.AddNewContact]: () => 'Add another contact to this company to start networking.',
  [QuestType.SendFollowUpEmail]: ({ contactName, companyName }: { [key: string]: string }) => `Send a follow-up email to ${contactName} at ${companyName} to get a conversation started.`,
  [QuestType.ReplyToContact]: ({ contactName, companyName }: { [key: string]: string }) => `${contactName} from ${companyName} sent you a message. Get back to them now.`,
};
export const QUEST_CATEGORY: any = {
  [QuestType.DownloadExtension]: 'Extension',
  [QuestType.ApplicationDeadline]: 'Application',
  [QuestType.ResolveComment]: 'Comments',
  [QuestType.ConnectEmail]: 'Messaging',
  [QuestType.CompleteDemographicsForm]: 'Company',
  [QuestType.SaveCompany]: 'Company',
  [QuestType.SaveFirstCompany]: 'Company',
  [QuestType.SaveContact]: 'Contact',
  [QuestType.SendMessage]: 'Messaging',
  [QuestType.SendConnection]: 'Messaging',
  [QuestType.SendFirstEmail]: 'Messaging',
  [QuestType.SendFirstOutreach]: 'Messaging',
  [QuestType.SendSecondOutreach]: 'Messaging',
  [QuestType.ScheduleCoffeeChat]: 'Coffee Chat',
  [QuestType.AddNewContact]: 'Contact',
  [QuestType.SendFollowUpEmail]: 'Messaging',
  [QuestType.RespondToEmail]: 'Messaging',
  [QuestType.ReplyToContact]: 'Messaging',
};

export const QUEST_DISMISSIBLE: any = {
  [QuestType.DownloadExtension]: false,
  [QuestType.ApplicationDeadline]: true,
  [QuestType.ResolveComment]: false,
  [QuestType.CompleteDemographicsForm]: false,
  [QuestType.ConnectEmail]: false,
  [QuestType.SaveCompany]: false,
  [QuestType.SaveFirstCompany]: false,
  [QuestType.SaveContact]: true,
  [QuestType.SendMessage]: false,
  [QuestType.SendFirstEmail]: true,
  [QuestType.SendFirstOutreach]: true,
  [QuestType.SendSecondOutreach]: true,
  [QuestType.ScheduleCoffeeChat]: false,
  [QuestType.AddNewContact]: false,
  [QuestType.SendFollowUpEmail]: false,
  [QuestType.RespondToEmail]: false,
  [QuestType.ReplyToContact]: false,
};

export const QUEST_COMPLETABLE: any = {
  [QuestType.DownloadExtension]: false,
  [QuestType.ApplicationDeadline]: true,
  [QuestType.ConnectEmail]: false,
  [QuestType.ResolveComment]: false,
  [QuestType.CompleteDemographicsForm]: false,
  [QuestType.SaveCompany]: false,
  [QuestType.SaveFirstCompany]: false,
  [QuestType.SaveContact]: false,
  [QuestType.SendMessage]: false,
  [QuestType.SendFirstOutreach]: false,
  [QuestType.SendSecondOutreach]: false,
  [QuestType.SendFirstEmail]: false,
  [QuestType.ScheduleCoffeeChat]: true,
  [QuestType.AddNewContact]: false,
  [QuestType.SendFollowUpEmail]: false,
  [QuestType.RespondToEmail]: false,
  [QuestType.ReplyToContact]: false,
};
