import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { Button } from '@careeros/coco';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { formatDateDeltaFromNow } from '@/services/helpers/date';
import { prettifyHTML } from '@/services/helpers/processHtml';
import { MessageType } from '@/domains/core/netwoking/types';

interface MessageProps {
  contactImage: string;
  contactName: string;
  messageTimestamp: any;
  company: string;
  text: string;
  contactId: string;
  type: MessageType;
}
export const RecentMessage = ({
  contactName,
  contactImage,
  company,
  text,
  messageTimestamp,
  contactId,
  type,
}: MessageProps) => {
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();
  const goToContact = () => {
    trackEvent('Dashboard: Reply to Contact clicked');
    navigate(`/app/inbox/${contactId}?view=${type}`);
  };

  return (
    <div
      className="recent-message"
      onClick={goToContact}
    >
      <DefaultLogo
        type="contact"
        source={contactImage}
        handleClick={goToContact}
        className="recent-message__image"
        name={contactName}
        size={80}
      />
      <div className="recent-message__type">
        {type === 'email' ? <i className="bi bi-envelope" /> : <i className="bi bi-linkedin" /> }
      </div>
      <div className="recent-message__info">
        <div className="recent-message__top-info">
          <div className="recent-message__name">
            {contactName}
          </div>
          <div className="recent-message__date">
            {formatDateDeltaFromNow(messageTimestamp.toDate())}
          </div>
          <Button mode="primary" size="small" outlined icon="bi bi-reply" label="Reply" handleClick={goToContact} />
        </div>
        <div className="recent-message__company">
          {company}
        </div>
        <p className="recent-message__text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prettifyHTML(text)) }} />
      </div>
    </div>
  );
};
