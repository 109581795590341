import { Link } from 'react-router-dom';

import './styles/success.scss';
import { Button } from '@careeros/coco';

const portalUrl = 'https://portal.azure.com/#view/Microsoft_AAD_IAM/StartboardApplicationsMenuBlade/~/AppAppsPreview/menuId~/null';

export default function AdminConsentGrantedPage() {
  const goToWebsite = () => {
    window.location.href = 'https://thecareeros.com';
  };

  const goToPortal = () => {
    window.open(portalUrl, '_blank');
  };

  return (
    <div id="auth-page">
      <Link className="CareerOS-logo" to="/">
        <img src="https://thecareeros.com/logo-default.svg" width={400} alt="" />
      </Link>
      <div className="auth-box">
        <h3 className="auth-box__title">Thank you for granting an admin consent for CareerOS</h3>
        <p>Now students of your university can use our networking features to help them take their career to the next level!</p>
        <div className="auth-box__buttons-container">
          <Button handleClick={goToWebsite} mode="primary" size="fill" label="Go to our website" />
          <Button handleClick={goToPortal} mode="primary" size="fill" label="Browse registered apps in your AzureAD" />
        </div>
      </div>
    </div>
  );
}
