import { SidebarLink } from '../sidebar-link/sidebar-link';
import { LinkType } from '../types/types';
import './sidebar-links.scss';

interface SidebarLinksProps {
  links: LinkType[] | undefined;
  collapsed: boolean;
  splitBefore?: string;
  handleLinkClick: (link: string) => void;
}
export const SidebarLinks = ({
  links,
  collapsed,
  splitBefore,
  handleLinkClick,
}: SidebarLinksProps) => (
  <ul className="sidebar-links">
    {!!links && links.map((link) => (
      <>
        {splitBefore === link.label && <div className={`sidebar-links__separator ${collapsed ? 'sidebar-links__separator--collapsed' : ''}`} />}
        <li key={link.url}>
          <SidebarLink
            link={link}
            collapsed={collapsed}
            handleLinkClick={() => handleLinkClick(link.label)}
          />
        </li>
      </>
    ))}
  </ul>
);
