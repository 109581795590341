import {
  useContext,
  useEffect, useMemo, useRef,
} from 'react';
import { useQueryClient } from 'react-query';
import { User } from '@auth0/auth0-react';
import { Timestamp } from 'firebase/firestore';
import { InboxContextType, useInboxContext } from '@/pages/context/inbox';
import { LinkedinActions } from '../linkedin-actions/linkedin-actions';
import { LinkedInMessageHistory } from '../linkedin-message-history/linkedin-message-history';
import './linkedin-chat.scss';
import { FirebaseLinkedinMessage, TemplateRequestPayload } from '../../types';
import { errorMessageSendToast, stripParagraphs, successConnectionSendToast } from '@/services/helpers/messages';
import { useExtensionMessaging } from '@/services/hooks/use-extension-messaging';
import { sendMessage, sentConnection, updateContactConnectionStatus } from '@/services/api/contact';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { ConnectionStatus } from '@/domains/core/contact/types';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import { ConnectionStatusPending } from '../connection-status-pending/connection-status-pending';

type Props = {
  messages: FirebaseLinkedinMessage[];
  user: User;
  selfID: string;
  updateMessages: (messages: FirebaseLinkedinMessage) => void;
  companyName?: string;
  companyLogo?: string;
  regenerateData: Omit<TemplateRequestPayload, 'channel'>;
  isFirstOutreach: boolean;
  connectionStatus: ConnectionStatus;
};

export const LinkedInChat = ({
  messages,
  user,
  selfID,
  companyName,
  companyLogo,
  updateMessages,
  regenerateData,
  isFirstOutreach,
  connectionStatus,
}: Props) => {
  const { addToast }: AddToastType = useContext(ToastContext);
  const { currentContact: contact, isPremiumLinkedIn } = useInboxContext() as InboxContextType;
  const messageLimit = useMemo(() => {
    if (connectionStatus !== 'notConnected') {
      return undefined;
    }
    return isPremiumLinkedIn ? 300 : 200;
  }, [isPremiumLinkedIn, connectionStatus]);
  const {
    sendLinkedInMessage,
    sendLinkedInConnection,
  } = useExtensionMessaging();
  const queryClient = useQueryClient();
  const { trackEvent } = useAnalytics();
  const chatRef = useRef<HTMLDivElement>(null);

  const handleSendConnectionMessage = async (messageText: string) => {
    try {
      await sendLinkedInConnection(contact.linkedin_urn, messageText);
      await sendMessage(contact.id);
      await sentConnection(contact.id);
      await updateContactConnectionStatus(contact, 'pending');
      successConnectionSendToast(addToast);

      trackEvent('Linkedin Connection Sent', user, {
        source: 'Inbox',
      });
    } catch (error) {
      const errorMessage = typeof error === 'string' ? error : undefined;
      errorMessageSendToast(addToast, trackEvent, errorMessage);
      trackEvent('LinkedIn connection send failed', {}, { errorMessage });
    }
  };

  const handleSendLinkedInMessage = async (message: string) => {
    const now = new Date();
    const seconds = Math.floor(now.getTime() / 1000);
    const nanoseconds = (now.getTime() % 1000) * 1000000;
    const createdAt = new Timestamp(seconds, nanoseconds);

    updateMessages({
      text: message, fromUserID: selfID, fromContactID: null, createdAt, type: 'pending',
    });

    const strippedMessage = stripParagraphs(message);

    try {
      const messageResult = await sendLinkedInMessage(contact.linkedin_urn, strippedMessage);
      sendMessage(contact.id);

      trackEvent('Message Sent', user, {
        type: 'linkedin',
        source: 'Inbox',
        contactName: contact.career_summary.basics.name,
        companyName,
        companyLogo,
        contactLogo: contact.career_summary.basics.image,
      });
      return messageResult;
    } catch (e) {
      trackEvent('Linkedin Message Sent Failure', user, {
        type: 'linkedin',
        source: 'Inbox',
      });

      return undefined;
    }
  };

  const handleSendClick = async (messageText: string) => {
    if (connectionStatus === 'notConnected') {
      await handleSendConnectionMessage(messageText);
    } else {
      await handleSendLinkedInMessage(messageText);
    }

    queryClient.invalidateQueries('quests');
    queryClient.invalidateQueries(['contact', contact.id]);
  };

  useEffect(() => {
    if (chatRef.current && messages) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages]);

  if (connectionStatus === 'pending') {
    return (
      <div className="linkedin-chat__pending">
        <ConnectionStatusPending />
      </div>
    );
  }

  return (
    <div className="linkedin-chat">
      <div className="linkedin-chat__content" ref={chatRef}>
        <LinkedInMessageHistory
          selfID={selfID}
          messages={messages}
          user={user}
          contact={contact}
        />
      </div>
      <div className="linkedin-chat__message-input">
        <LinkedinActions
          isConnectionRequest={connectionStatus === 'notConnected'}
          contactID={contact.id}
          sendMessage={handleSendClick}
          contactName={contact.career_summary.basics.name}
          isFirstOutreach={isFirstOutreach}
          messageLengthLimit={messageLimit}
          regenerateData={{
            ...regenerateData,
            maxCharacters: messageLimit,
            channel: connectionStatus === 'notConnected' ? 'connection' : 'linkedin',
          }}
        />
      </div>
    </div>
  );
};
