import { MessageType, OutreachScenarioType } from '../../types';

type OutreachType = {
  name: string;
  icon?: string;
  value: OutreachScenarioType;
};

export const outreachScenarios: OutreachType[] = [
  {
    name: '🥶 Cold Outreach',
    value: 'cold_outreach',
  },
  {
    name: '🤝 Met at in-person Career Fair',
    value: 'meet_at_in_person_career_fair',
  },
  {
    name: '🧑‍💻 Met at virtual Career Fair',
    value: 'meet_at_virtual_fair',
  },
  {
    name: '🎓 Contact is an alum',
    value: 'contact_is_an_alum',
  },
  // {
  //   name: 'Referral from Career Advisor',
  //   icon: 'bi bi-5-circle',
  //   value: 'referral_from_career_advisor',
  // },
];

export const CatolicaTemplate = (firstName: string) => `Hi [First Name],

I really enjoyed our chat at Career Networking Monday in Consulting! I’m ${firstName}, a [year] student from [program]. I really found our talk insightful, especially learning about [points about the conversation].

I'd love to connect and learn more about the industry and your company.

Thanks so much!
`;

type OutreachScenariosInfo = {
  [key in MessageType]: {
    title: string;
    description: string | JSX.Element;
    image?: string;
    icon?: string;
  };
};

export const outreachScenariosInfo = (contactFirstName: string): OutreachScenariosInfo => ({
  email: {
    title: 'You don’t have an email history with this contact yet',
    description: (
      <>
        Overall,
        <mark> 1,375 </mark>
        emails have been sent via CareerOS.
      </>
    ),
    image: '@/assets/images/incoming-message.png',
  },
  linkedin: {
    title: `You and ${contactFirstName} are connected on LinkedIn!!`,
    description: (
      <>
        Generate outreach message using AI, based on your relationship with this contact.
        <br />
        Overall,
        <mark> 1,900 </mark>
        LinkedIn messages were sent via CareerOS.
      </>
    ),
    icon: 'bi bi-linkedin',
  },
  connection: {
    title: 'You are not connected with this contact yet',
    description: (
      <>
        Generate a outreach message using AI, based on your relationship with this contact.
        <br />
        Overall,
        <mark> 1,200 </mark>
        LinkedIn connection requests were sent via CareerOS.
      </>
    ),
    icon: 'bi bi-linkedin',
  },
});
