import ContractImage from '@/assets/images/icons/tags/tag-contract.png';
import EmployeesImage from '@/assets/images/icons/tags/tag-employees.png';
import IndustryImage from '@/assets/images/icons/tags/tag-industry.png';
import LocationImage from '@/assets/images/icons/tags/tag-location.png';
import RemoteImage from '@/assets/images/icons/tags/tag-remote.png';
import SalaryImage from '@/assets/images/icons/tags/tag-salary.png';

import { JobTagType } from '../types/job';

const tagsIcons = {
  industry: IndustryImage,
  contract: ContractImage,
  salary: SalaryImage,
  employees: EmployeesImage,
  location: LocationImage,
  workMode: RemoteImage,
};

export const getTagIcons = (tag_type: JobTagType['tag_type']) => tagsIcons[tag_type];
