import React from 'react';

import './loading-modal.scss';
import { ResumeFakeLoader } from '@/domains/core/resume';
import { progressStagesResumeBook } from '@/domains/core/resume/utils/fake-loader';

type Props = {
  orientation: 'horizontal' | 'vertical';
  image?: string;
  title: string;
  hasProgressBar?: boolean;
};

export const LoadingModal = ({
  orientation,
  image,
  title,
  hasProgressBar,
}: Props) => (
  <div className={`loading-modal info-modal--${orientation}`}>
    {image && (
      <div className="loading-modal__image">
        <i className={image} />
      </div>
    )}
    <div className="loading-modal__text">
      <h3 className="loading-modal__title">{title}</h3>
    </div>
    {hasProgressBar ? (
      <div className="loading-modal__progress-bar">
        <ResumeFakeLoader progressStages={progressStagesResumeBook} />
      </div>
    )
      : (<div className="loading-modal__loader" />)}
  </div>
);
