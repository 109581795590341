import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import DOMPurify from 'dompurify';
import './company-jobs.scss';
import { JobHeader } from '../job-header/job-header';
import { JOB_SOURCE, JobFeedJob } from '../../types/job';
import { formatDateDeltaFromNow } from '@/services/helpers/date';
import { JobTile } from '../job-tile/job-tile';
import { JobApplicationInfo } from '../job-application-info/job-application-info';
import { AddJobButton } from '@/domains/core/company/components/add-job-button/add-job-button';

type Props = {
  companyName: string;
  jobs: JobFeedJob[];
  companyLogo: string;
  companyLinkedIn: string;
  handleSaveAction: (jobId: string, save: boolean) => void;
  handleAppliedAction: (jobId: string, applied: boolean) => void;
  handleApplyClick: (url: string, jobId: string) => void;
  revalidateJobs: () => void;
  openAddManually?: () => void;
  isApplicationActive?: boolean;
};

export const CompanyJobs = ({
  companyName,
  jobs,
  companyLogo,
  handleAppliedAction,
  handleSaveAction,
  handleApplyClick,
  revalidateJobs,
  openAddManually,
  isApplicationActive,
  companyLinkedIn,
}: Props) => {
  const [currentJobID, setCurrentJobID] = useState(jobs[0].id);
  const currentJob = useMemo(() => jobs.find((job) => job.id === currentJobID), [currentJobID, jobs]);
  const [showDidYouApply, setShowDidYouApply] = useState(false);
  const descriptionRef = useRef<HTMLDivElement>(null);

  const handleAppliedResponse = (id: string, didApply: boolean) => {
    handleAppliedAction(id, didApply);

    setShowDidYouApply(false);
  };

  const handleTileApplyClick = (id: string, url: string, apply: boolean) => {
    setCurrentJobID(id);

    if (apply) {
      handleApplyClick(url, id);
      return;
    }

    handleAppliedResponse(id, false);
  };

  useEffect(() => {
    if (!jobs.find((job) => job.id === currentJobID)) {
      setCurrentJobID(jobs[0].id);
    }
  }, [jobs]);

  useEffect(() => {
    if (descriptionRef.current) {
      descriptionRef.current.scrollTop = 0;
    }
  }, [currentJobID]);

  useEffect(() => {
    const newSearchParams = new URLSearchParams(window.location.search);

    if (newSearchParams.has('jobID')) {
      const jobIDFromSearchParams = newSearchParams.get('jobID');

      const jobFromParams = jobs.find((job) => job.id === jobIDFromSearchParams);

      if (jobFromParams) {
        setCurrentJobID(jobFromParams.id);
      }
    }
  }, []);

  return (
    <div className="company-jobs-layout">
      <div className="company-jobs-layout__left">
        <div className="company-jobs-layout__side-header">
          <div className="company-jobs-layout__header-text">
            <h3 className="company-jobs-layout__title">Job Postings</h3>
            <p className="company-jobs-layout__subtitle">{`for ${companyName}`}</p>
          </div>
          <div className="company-jobs-layout__header-button">
            <AddJobButton
              icon="bi bi-plus-circle"
              isOutlined
              buttonLabel="Add Job"
              size="small"
              listPosition="right"
              withSaveManually={isApplicationActive}
              onAddManuallyClick={openAddManually}
              tooltipLabel="Add Jobs from Other Sources"
              companyLinkedIn={companyLinkedIn}
            />
          </div>
        </div>
        <ul className="company-jobs-layout__job-list">
          {jobs.map((job) => (
            <li className="company-jobs-layout__job-item" key={job.id}>
              <JobTile
                job={job}
                isActive={currentJobID === job.id}
                isLoading={false}
                isSaved={job.saved}
                onJobSaveToggle={() => handleSaveAction(job.id, !job.saved)}
                onSelect={() => setCurrentJobID(job.id)}
                onApplyClick={handleTileApplyClick}
              />
            </li>
          ))}
        </ul>
      </div>
      <div className="company-jobs-layout__right">
        <div ref={descriptionRef} className="company-jobs-layout__description-container">
          {currentJob && (
            <JobHeader
              handleApplyButtonClick={() => handleApplyClick(currentJob.linkedin_url, currentJob.id)}
              tags={currentJob.tags}
              handleSaveButtonClick={() => handleSaveAction(currentJobID, !currentJob.saved)}
              isEmployerVerified={currentJob.source === JOB_SOURCE.EMPLOYER_APP}
              logoUrl={companyLogo}
              companyName={companyName}
              postedDate={formatDateDeltaFromNow(currentJob.posted_on || currentJob.created_at || '')}
              title={currentJob.title}
              isApplied={currentJob.applied}
              isSaved={currentJob.saved}
              isIconCTA
              isHiddenApplyButton={currentJob.linkedin_url === '' || !currentJob.linkedin_url}
            />
          )}
          {currentJob && (currentJob.saved || showDidYouApply) && (
            <div className="company-jobs-layout__deadline">
              <JobApplicationInfo
                applicationDeadline={currentJob.deadline || ''}
                isDateRolling={!!currentJob.rolling_date}
                handleAppliedResponse={(didApply) => handleAppliedResponse(currentJob.id, didApply)}
                jobID={currentJob.id}
                showDidYouApply={showDidYouApply}
                isApplied={currentJob.applied}
                revalidateApplicationDeadline={revalidateJobs}
              />
            </div>
          )}
          <div className="company-jobs-layout__separator" />
          <div
            className="company-jobs-layout__description"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(currentJob?.description || ''),
            }}
          />
        </div>
      </div>
    </div>
  );
};
