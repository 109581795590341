import {
  getBaseResume, getBaseResumes, getTailoredResume, getTailoredResumes,
} from '@/services/api/resume';
import {
  Profile, ResumeBuilderResponse, ResumesIDResponse, ResumesResponse,
} from '../types';
import { getSelfJobs } from '@/services/api/job';

export async function resumeBuilderLoader(): Promise<ResumeBuilderResponse> {
  const jobs = await getSelfJobs();
  return {
    jobs,
  };
}

export async function resumeBuilderListLoader(): Promise<ResumesResponse> {
  let [baseResumes, tailoredResumes] = await Promise.all([getBaseResumes(), getTailoredResumes()]);
  baseResumes = Array.isArray(baseResumes) ? baseResumes?.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()) : [];
  tailoredResumes = Array.isArray(tailoredResumes) ? tailoredResumes?.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()) : [];
  return {
    baseResumes,
    tailoredResumes,
  };
}

export async function resumeBuilderBaseLoader(request: any): Promise<ResumesIDResponse> {
  const { resumeID } = request.params;
  const resumeResponse = await getBaseResume(resumeID);

  const defaultResume = {
    basics: {
      phone_numbers: [''],
      emails: [''],
      nationality: [''],
      url: '',
      profiles: [],
    },
    education: [{
      institution: '',
      area: '',
      study_type: '',
      start_date: '',
      end_date: '',
      gpa: '',
      courses: [''],
    }],
    work_section_subheading: 'Professional Experience',
    work: [{
      company: '',
      position: '',
      website: '',
      start_date: '',
      end_date: '',
      summary: '',
      highlights: [''],
    }],
    skills: [{ name: '', level: '' }],
    languages: [{ language: '', fluency: '' }],
    additional_information: {
      bullets: ['', ''],
    },
  };

  const resume = resumeResponse?.resume || defaultResume;
  const nationality = resume?.basics?.nationality?.filter((nat: string) => !!nat) ?? [''];

  return {
    ...resumeResponse,
    resume: {
      basics: {
        ...resume.basics,
        phone_numbers: [...(resume?.basics?.phone_numbers ?? [''])],
        emails: [...(resume?.basics?.emails ?? [''])],
        nationality: nationality?.length > 0 ? nationality : [''],
        url: resume?.basics?.url || resume?.basics?.profiles?.find((profile: Profile) => profile.network === 'LinkedIn')?.url || '',
      },
      education: resume?.education?.length > 0 ? resume.education : defaultResume.education,
      work_section_subheading: resume?.work_section_subheading || defaultResume.work_section_subheading,
      work: resume?.work?.length > 0 ? resume.work : defaultResume.work,
      skills: resume?.skills?.length > 0 ? resume.skills : defaultResume.skills,
      languages: resume?.languages?.length > 0 ? resume.languages : defaultResume.languages,
      additional_information: {
        ...resume?.additional_information,
        bullets: resume?.additional_information?.bullets?.length > 0
          ? resume.additional_information.bullets
          : defaultResume.additional_information.bullets,
      },
    },
  };
}

export async function resumeBuilderTailoredLoader(request: any): Promise<ResumesIDResponse> {
  const { resumeID } = request.params;
  const resumeResponse = await getTailoredResume(resumeID);
  return {
    ...resumeResponse,
  };
}
