import { Link, NavLink } from 'react-router-dom';
import { LinkType } from '../types/types';
import './sidebar-link.scss';

interface SidebarLinkProps {
  link: LinkType;
  collapsed: boolean;
  handleLinkClick: () => void;
}
export const SidebarLink = ({ link, collapsed, handleLinkClick }: SidebarLinkProps) => (
  link.isExternal ? (
    <Link
      to={link.url}
      target="_blank"
      aria-label={link.label}
      onClick={handleLinkClick}
      className={collapsed ? 'sidebar-link sidebar-link--collapsed' : 'sidebar-link'}
    >
      <i className={`sidebar-link__icon ${link.icon}`} />
      <i className={`sidebar-link__icon sidebar-link__icon--selected ${link.iconSelected}`} />
      <span className="sidebar-link__label">
        {link.label}
      </span>
    </Link>
  ) : (
    <NavLink
      to={link.url}
      onClick={handleLinkClick}
      aria-label={link.label}
      id={`sidebar-${link.label}`}
      className={({ isActive }) => ((isActive || (link.url === 'companies/explore' && window.location.pathname.includes('/app/companies')))
        ? 'sidebar-link sidebar-link--active'
        : 'sidebar-link') + (collapsed ? ' sidebar-link--collapsed' : '')}
    >
      <i className={`sidebar-link__icon ${link.icon}`} />
      <i className={`sidebar-link__icon sidebar-link__icon--selected ${link.iconSelected}`} />
      <span className="sidebar-link__label">
        {link.label}
      </span>
    </NavLink>
  )
);
