import { useMemo } from 'react';
import './job-tile-deadline.scss';
import { Tooltip } from '@/components/tooltip/tooltip';
import { getDateDeltaFromNowInDays } from '@/services/helpers/date';

type Props = {
  deadline: string;
  handleClick: () => void;
};

export const JobTileDeadline = ({
  deadline,
  handleClick,
}: Props) => {
  const isOverdue = useMemo(() => {
    const today = new Date();
    const deadlineDate = new Date(deadline);
    return today.getTime() > deadlineDate.getTime();
  }, [deadline]);

  const isApproaching = useMemo(() => {
    const diffTime = getDateDeltaFromNowInDays(deadline);

    return diffTime <= 7 && diffTime > 0;
  }, [deadline]);

  const isDeadlineToday = useMemo(() => {
    const diffTime = getDateDeltaFromNowInDays(deadline);
    return diffTime === 0;
  }, [deadline]);

  const formatDate = (date: string) => new Date(date).toLocaleDateString('en-GB', { month: 'numeric', year: 'numeric', day: 'numeric' });

  const onClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    handleClick();
  };

  const deadlineText = useMemo(() => {
    if (isDeadlineToday) {
      return 'Deadline today!';
    }

    if (isApproaching) {
      const today = new Date();
      const deadlineDate = new Date(deadline);
      const delta = deadlineDate.getTime() - today.getTime();

      return `${Math.abs(Math.ceil(delta / (1000 * 60 * 60 * 24)))} days to apply`;
    }

    return formatDate(deadline);
  }, [deadline, isApproaching, isDeadlineToday]);

  const tooltipLabel = useMemo(() => {
    if (isDeadlineToday) {
      return 'Click to apply';
    }
    if (isApproaching) {
      return 'Deadline approaching! Click to apply';
    }

    if (isOverdue) {
      return 'Overdue deadline! Click to apply';
    }

    return 'Application deadline. Click to apply';
  }, [isApproaching, isOverdue, isDeadlineToday]);

  return (
    <div className="job-tile-deadline" onClick={onClick}>
      <Tooltip label={tooltipLabel} position="top" withArrow>
        <div className={`job-tile-deadline__container ${isOverdue ? 'job-tile-deadline__container--danger' : ''} ${isApproaching ? 'job-tile-deadline__container--approaching' : ''}`}>
          <div className="job-tile-deadline__icon-container">
            <i className="bi bi-send job-tile-deadline__icon" />
            <i className={`bi ${!isOverdue && !isApproaching ? 'bi-send' : 'bi-send-fill'} job-tile-deadline__icon job-tile-deadline__icon--hovered`} />
            {isApproaching && (
              <div className="job-tile-deadline__indicator" />
            )}
          </div>
          <div className="job-tile-deadline__date">
            {deadlineText}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};
