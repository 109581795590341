import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useAnalytics } from '@/services/hooks/use-analytics';
import './leaderboard.scss';
import PlaceholderLogo from '@/assets/images/university/university-placeholder-logo.png';
import { LeaderboardItemType } from '../../types/dashboard-response';
import { LeaderboardItem } from './leaderboard-item';
import { useSelf, useSelfUniversity } from '@/services/queries/user';
import { identifyUserInAppcues, identifyUserInCustomerIO, setUserInMixpanel } from '@/services/helpers/user';

type Props = {
  universityName: string;
  universityLogo: string;
  cohort: string;
  students: LeaderboardItemType[];
  highlightQuests: () => void;
};

type Student = {
  first_name: string;
  last_name: string;
  completed_quests: number;
  rating: number;
};

const NUMBER_OF_STUDENTS_ABOVE = 3;
const NUMBER_OF_STUDENTS_BELOW = 3;

export const LeaderBoard = ({
  universityName, cohort, students, highlightQuests, universityLogo,
}: Props) => {
  const { trackEvent } = useAnalytics();
  const { data: university } = useSelfUniversity();
  const { user } = useAuth0();
  const { data: self } = useSelf();
  const [topThreeStudents, setTopThreeStudents] = useState<Student[]>([]);
  const [studentClosestCompetitors, setStudentClosestCompetitors] = useState<Student[]>([]);
  const [studentTopCompetitors, setStudentTopCompetitors] = useState<number>(0);
  const [studentBottomCompetitors, setStudentBottomCompetitors] = useState<number>(0);

  const computeLeaderboard = () => {
    const mappedStudents = students
      .map((student, index) => ({ ...student, rating: index + 1 }));
    const currentStudentIndex = mappedStudents
      .findIndex((student) => student.first_name.length > 0 && student.last_name.length > 0);

    if (currentStudentIndex <= 3) {
      // if the current student is in the top 4 show simply 10 top students
      setStudentClosestCompetitors(mappedStudents.slice(3, mappedStudents.length > 10 ? 10 : mappedStudents.length));
      setTopThreeStudents(mappedStudents.slice(0, 3));
      setStudentTopCompetitors(0);
      setStudentBottomCompetitors(mappedStudents.length - 10);

      return;
    }

    const higherRatedUserIndex = currentStudentIndex - 3 > 2 ? currentStudentIndex - 3 : 3;
    const studentCompetitors = mappedStudents
      .slice(higherRatedUserIndex, higherRatedUserIndex + NUMBER_OF_STUDENTS_ABOVE + NUMBER_OF_STUDENTS_BELOW + 1);
    const studentTopCompetitorsNumber = higherRatedUserIndex - NUMBER_OF_STUDENTS_ABOVE;
    const studentBottomCompetitorsNumber = mappedStudents.length - (currentStudentIndex + NUMBER_OF_STUDENTS_BELOW) - 1;

    setStudentClosestCompetitors(studentCompetitors);
    setTopThreeStudents(mappedStudents.slice(0, 3));
    setStudentTopCompetitors(studentTopCompetitorsNumber);
    setStudentBottomCompetitors(studentBottomCompetitorsNumber);
  };

  const handleCompleteQuestsClick = () => {
    highlightQuests();

    trackEvent('Leaderboard: Complete Quest Button Clicked', user, {
      university: university?.name,
      cohort,
    });
  };

  useEffect(() => {
    computeLeaderboard();
  }, []);

  useEffect(() => {
    if (user && user.name && universityName && self) {
      identifyUserInCustomerIO({ ...user, given_name: user.given_name || self?.first_name, completedDemographicsForm: self?.has_completed_demographics_form }, universityName, cohort);
      identifyUserInAppcues({ ...user, completedDemographicsForm: self?.has_completed_demographics_form }, universityName, cohort);
      setUserInMixpanel({ ...user, completedDemographicsForm: self?.has_completed_demographics_form }, universityName, cohort);
    }
  }, [user, self]);

  return (
    <div className="leaderboard">
      <div className="leaderboard__header">
        <div className="leaderboard__title">
          <span className="leaderboard__university-name">{`${universityName} ${cohort}`}</span>
          <span>Leaderboard</span>
        </div>
        <p className="leaderboard__description">
          <span
            className="leaderboard__quests-button"
            onClick={handleCompleteQuestsClick}
          >
            Complete quests
          </span>
          {' '}
          and climb the ladder ✨
        </p>
      </div>
      <ul className="leaderboard__students-list">
        {topThreeStudents.map((student) => (
          <LeaderboardItem
            key={student.rating}
            university={universityName}
            studentName={student.first_name.length ? `${student.first_name} ${student.last_name}` : undefined}
            rating={student.rating}
            completedQuests={student.completed_quests}
            universityLogo={universityLogo || PlaceholderLogo}
          />
        ))}
        {studentTopCompetitors > 0 && (
          <div className="leaderboard__split">
            <span className="leaderboard__split-number">
              {studentTopCompetitors}
            </span>
          </div>
        )}
        {studentClosestCompetitors.map((student) => (
          <LeaderboardItem
            key={student.rating}
            university={universityName}
            studentName={student.first_name.length ? `${student.first_name} ${student.last_name}` : undefined}
            rating={student.rating}
            completedQuests={student.completed_quests}
            universityLogo={universityLogo || PlaceholderLogo}
          />
        ))}
        {studentBottomCompetitors > 0 && (
          <div className="leaderboard__split">
            <span className="leaderboard__split-number">
              {studentBottomCompetitors}
            </span>
          </div>
        )}
      </ul>
    </div>
  );
};
