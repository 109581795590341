import { useQuery } from 'react-query';
import { getContact, getContacts, getNotesByContact } from '../api/contact';
import { Contact } from '@/domains/core/contact/types';
import { Note } from '@/domains/core/company/types';

export const useGetContacts = (options = {}) => useQuery(
  'contacts',
  async () => {
    const response = await getContacts();
    return response ?? [];
  },
  {
    retry: 0,
    staleTime: 1000 * 60 * 60 * 1, // refetch in the background every 8 hours
    ...options, // Spread additional options
  },
);

export const useGetContactsNoCache = (options = {}) => useQuery(
  'contacts-no-cache',
  async () => {
    const response = await getContacts();

    return response ?? [];
  },
  {
    cacheTime: Infinity, // refetch on tab change
    retry: 0,
    ...options, // Spread additional options
  },
);

export const useGetContact = (contactID: string | undefined, options = {}) => useQuery(
  ['contact', contactID],
  async () => {
    if (contactID) {
      const response: Contact | { error: any } = await getContact(contactID);

      return 'error' in response ? undefined : response;
    }
    return undefined;
  },
  {
    retry: 0,
    staleTime: 1000 * 60 * 60 * 8, // refetch in the background every 8 hours
    ...options, // Spread additional options
  },
);

export const useGetContactNotes = (contactID: string | undefined, options = {}) => useQuery(
  ['contact-notes', contactID],
  async () => {
    if (contactID) {
      const response: Note[] | { error: any } = await getNotesByContact(contactID);

      return 'error' in response ? [] : response;
    }
    return [];
  },
  {
    retry: 0,
    staleTime: 1000 * 60 * 60 * 8, // refetch in the background every 8 hours
    ...options, // Spread additional options
  },
);
