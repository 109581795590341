import { Link } from 'react-router-dom';
import { Button } from '@careeros/coco';

import { router } from '@/main';
import GetStartedImage from '@/assets/images/getting-started.svg';
import { TabsContent, TabsHeader, withTabs } from '@/components/tabs';
import { Accordion } from '@/components/accordion/accordion';

import './styles/support-page.scss';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';

const GetStartedPage = () => {
  const backButtonClickHandler = () => {
    router.navigate('/app/support/');
  };

  return (
    <div className="support-page">
      <div className="white-wrapper">
        <div
          className="white-wrapper__back-button"
        >
          <Button
            mode="invisible"
            size="medium"
            icon="bi bi-arrow-left"
            iconPosition="left"
            label="Back"
            handleClick={backButtonClickHandler}
          />
        </div>
        <div className="support-page__content">
          <img className="support-page__image" src={GetStartedImage} alt="" />
          <h2 className="support-page__title">
            Getting Started
          </h2>
          <p className="support-page__subtitle">
            Learn about the basics on how to quickly get you using CareerOS.
          </p>
          <TabsHeader
            tabs={['First Steps', 'The Basics', 'LinkedIn Settings']}
            // tabs={['The Basics', 'LinkedIn Settings', 'Email Integration']}
            defaultTab="First Steps"
          />
          <TabsContent>
            <div className="support-page__tab" data-tab="First Steps">
              <h3 className="support-page__tab__title">
                Here is your quick guide to getting started!
              </h3>
              <article className="support-page__tab__description">
                Let&apos;s get rolling 🚀, here are the 5 steps to unlock the CareerOS superpowers:

                <h4>Companies</h4>
                <p>
                  Step 1: Download the super cool CareerOS Chrome Extension. Why? This allows you to save companies, contacts, and jobs on LinkedIn with one click. It’s your Career Co-Pilot! Let&apos;s check it out, download it here -
                  <Link to={import.meta.env.VITE_EXTENSION_LINK} target="_blank" rel="noreferrer">CareerOS Extension</Link>
                  .
                  <b> (important note: log in with the same email address you used to log into CareerOS)</b>
                  <br />
                  <br />
                  Step 2: Follow the instructions in the extension to save your first company.
                  <br />
                  <br />
                  Step 3: Just like that, you saved your first company 🥳 . Now that you got the hang of it, go ahead and save some of your favorite companies. Pro-tip, check out the&nbsp;
                  <Link to="/app/companies/explore">Explore Companies</Link>
                  &nbsp;page here to find over 1,000 top MBA employers.
                </p>
                <h4>Contacts</h4>
                <p>
                  Step 4: Let’s save your first contact. Go to one of the companies on your Overview Board and click the “Add Contact” button to easily import new contacts.
                </p>
                <h4>Messaging</h4>
                <p>
                  Step 5:  Now it’s time to unlock the CareerOS superpower - Messaging. For any of your new contacts, simply click “Message contact” and we will guide you through the outreach process. You can even send Linkedin invitations directly from CareerOS.
                  <br />
                  <br />
                  Congrats! You are now a pro! You have officially saved your first company, a contact and sent your first message! 😎
                  <br />
                  <br />
                  Messaging Pro-Tip: Your conversations with contacts will appear on the home page as well as the contact’s profile page once you send your first message to the contact within the app. Note that we are only syncing conversations for contacts saved in CareerOS.
                </p>
              </article>
            </div>
            <div className="support-page__tab" data-tab="The Basics">
              <h3 className="support-page__tab__title">Step 1</h3>
              <p className="support-page__tab__description">
                Install the&nbsp;
                <Link target="_blank" to="https://www.google.com/chrome/">Google Chrome</Link>
&nbsp;browser and ensure you are using the latest version.
              </p>
              <h3 className="support-page__tab__title">Step 2</h3>
              <p className="support-page__tab__description">
                Download the&nbsp;
                <Link target="_blank" to={import.meta.env.VITE_EXTENSION_LINK}>CareerOS Chrome extension</Link>
                .
              </p>
            </div>
            <div className="support-page__tab" data-tab="LinkedIn Settings">
              <h3 className="support-page__tab__title">Pro Tips</h3>
              <p className="support-page__tab__description">
                Make sure you are logged into LinkedIn when using CareerOS
              </p>
              <Accordion title="For easier use, you can also pin a LinkedIn tab permanently">
                <img src="https://storage.googleapis.com/student-app-assets/pin-linkedin.gif" alt="" />
              </Accordion>
              <Accordion title="Please ensure that your LinkedIn language is set to English for optimal usability">
                <img src="https://storage.googleapis.com/student-app-assets/language-settings.gif" alt="" />
              </Accordion>
            </div>
            {/* <div className="support-page__tab" data-tab="Email Integration">
              <h3 className="support-page__tab__title">Add to CareerOS Account</h3>
              <p className="support-page__tab__description">
                Connect your Gmail or Outlook email account to be able to send and receive emails from your contacts in CareerOS (coming soon).
              </p>
            </div> */}
          </TabsContent>
        </div>
      </div>
    </div>
  );
};

export default CustomWithAuthenticationRequired(withTabs(GetStartedPage));
