import {
  useCallback, useEffect, useState,
} from 'react';
import * as Sentry from '@sentry/react';
import { DropdownSearch } from '../dropdown-search/dropdown-search';
import useDebounceEffect from '@/services/hooks/use-debounce';

type Props = React.ComponentProps<typeof DropdownSearch> & {
  loaderFunction: (query: string) => Promise<React.ComponentProps<typeof DropdownSearch>['options']>;
};

export const DropdownSearchDynamic = ({
  options,
  loaderFunction,
  ...props
}: Props) => {
  const [currentQuery, setCurrentQuery] = useState('');
  const [filteredList, setFilteredList] = useState<typeof options>([]);
  const [isLoading, setIsLoading] = useState(false);

  const onQueryChange = (query: string) => {
    setCurrentQuery(query);
    if (!query) {
      setFilteredList(options);
    }
  };

  const computeFilteredList = useCallback((baseOptions: typeof options, apiResponse: typeof options, query: string) => {
    const computedOptions = [...baseOptions, ...apiResponse];

    const uniqueOptions = [...new Map(computedOptions.map(item => [item.name, item])).values()];

    return uniqueOptions
      .filter((option) => option.name.toLowerCase().includes(query.toLowerCase()))
      .sort((firstOption, secondOption) => {
        const firstOptionStartsWith = firstOption.name.toLowerCase().startsWith(query.toLowerCase());
        const secondOptionStartsWith = secondOption.name.toLowerCase().startsWith(query.toLowerCase());
        if (firstOptionStartsWith && !secondOptionStartsWith) return -1;
        if (!firstOptionStartsWith && secondOptionStartsWith) return 1;
        return 0;
      });
  }, []);

  const handleQueryChange = async (query: string) => {
    setIsLoading(true);
    try {
      const apiResponse = await loaderFunction(query);
      const filtered = computeFilteredList(options, apiResponse, query);

      setFilteredList(filtered);
    } catch (error) {
      Sentry.captureException(error);
      const filteredStaticOptions = computeFilteredList(options, [], query);

      setFilteredList(filteredStaticOptions);
    } finally {
      setIsLoading(false);
    }
  };

  useDebounceEffect(() => {
    if (currentQuery) {
      handleQueryChange(currentQuery);
    }
  }, [currentQuery], 300);

  useEffect(() => {
    setFilteredList(options);
  }, [options]);

  return (
    <DropdownSearch
      {...props}
      options={filteredList}
      onValueChange={onQueryChange}
      isLoading={isLoading}
    />
  );
};
