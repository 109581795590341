import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import PlaceholderLogo from '@/assets/images/university/university-placeholder-logo.png';
import './dropdown-search.scss';
import { Input } from '../input/input';

type Props = {
  name: string;
  selectedOption: string | string[];
  warningText?: string | JSX.Element;
  onValueChange?: (value: string) => void;
  id: string;
  placeholder: string;
  label?: string;
  options: {
    name: string;
    logo?: string;
    value?: any;
  }[],
  handleItemSelect: (item: string, value?: any) => void;
  invalid?: boolean;
  canAddItems?: boolean;
  showIcon?: boolean;
  handleEntireItemSelect?: (item: any) => void;
  size?: 'medium' | 'large';
  withArrow?: boolean;
  inputIcon?: string;
  openOnClick?: boolean;
  withMultipleSelection?: boolean;
  listWidth?: 'full' | 'fit-box';
  isDisabled?: boolean;
  isLoading?: boolean;
};

export const DropdownSearch = ({
  selectedOption,
  options,
  handleItemSelect,
  invalid = false,
  warningText,
  placeholder,
  label,
  name,
  id,
  canAddItems = true,
  showIcon = true,
  handleEntireItemSelect,
  size = 'large',
  withArrow = false,
  inputIcon,
  openOnClick = false,
  withMultipleSelection = false,
  listWidth = 'fit-box',
  isDisabled = false,
  onValueChange,
  isLoading = false,
}: Props) => {
  const [query, setQuery] = useState('');
  const [isListOpened, setIsListOpened] = useState(false);
  const [inputImage, setInputImage] = useState<string>();
  const [activeIndex, setActiveIndex] = useState(0);
  const listRef = useRef<HTMLUListElement>(null);
  const container = useRef<HTMLDivElement>(null);

  const handleQueryChange = (value: string) => {
    setQuery(value);
    setInputImage(undefined);
    setIsListOpened(true);
    setActiveIndex(0);

    if (onValueChange) {
      onValueChange(value);
    }
  };

  const onItemSelect = (option: string, value?: any) => {
    setIsListOpened(false);

    handleItemSelect(option, value);
    const itm = options.find((item) => item.name === option);
    if (itm && handleEntireItemSelect) {
      handleEntireItemSelect(itm);
    }

    setInputImage(options.find((item) => item.name === option)?.logo);
    if (!withMultipleSelection) {
      setQuery(option);
    } else {
      setQuery('');
    }
  };

  const handleBlur = (e: any) => {
    if (!container.current?.contains(e.target)) {
      setIsListOpened(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const fullListLength = canAddItems ? options.length + 1 : options.length;
    if (event.key === 'ArrowDown' && isListOpened) {
      event.preventDefault();
      setActiveIndex((prevActiveIndex) => (prevActiveIndex + 1) % fullListLength);
    } else if (event.key === 'ArrowUp' && isListOpened) {
      event.preventDefault();
      setActiveIndex((prevActiveIndex) => (prevActiveIndex - 1 + fullListLength) % fullListLength);
    } else if (event.key === 'Enter') {
      event.preventDefault();
      onItemSelect(options[activeIndex] ? options[activeIndex].name : query, options[activeIndex]?.value);
    } else if (event.key === 'Tab') {
      setIsListOpened(false);
    }
  };

  const matchOption = useCallback(() => options.some((option) => option.name.toLocaleLowerCase() === query.toLocaleLowerCase()), []);

  useEffect(() => {
    document.addEventListener('mousedown', handleBlur);

    return () => document.removeEventListener('mousedown', handleBlur);
  }, []);

  useEffect(() => {
    if (typeof selectedOption === 'string') {
      setQuery(selectedOption);
    }
  }, [selectedOption]);

  useEffect(() => {
    // TODO: retink typeof listRef.current.children[activeIndex].scrollIntoView === 'function' check (it was added to fix the issue with the test run)
    if (isListOpened && listRef.current && typeof listRef.current.children[activeIndex]?.scrollIntoView === 'function') {
      listRef.current.children[activeIndex]?.scrollIntoView({ block: 'nearest' });
    }
  }, [activeIndex, isListOpened]);

  useEffect(() => {
    if (isListOpened) {
      setActiveIndex(0);
    }
  }, [isListOpened]);

  return (
    <div className="dropdown-search" ref={container}>
      <div
        className="dropdow-search__input"
      >
        <Input
          type="text"
          name={name}
          value={query}
          id={id}
          label={label || ''}
          placeholder={placeholder}
          handleValueChange={handleQueryChange}
          onFocus={() => setIsListOpened(true)}
          invalid={invalid}
          warningText={warningText}
          image={inputImage}
          inputSize={size}
          icon={inputIcon}
          onKeyDown={handleKeyDown}
          disabled={isDisabled}
        />
        {withArrow && (
          <i
            onClick={() => setIsListOpened(!isListOpened)}
            className={`bi bi-caret-down-fill dropdown-search__arrow ${isListOpened ? 'dropdown-search__arrow--opened' : ''}`}
          />
        )}
      </div>
      {(isLoading && isListOpened) && (
        <div className="dropdown-search__list">
          <div className="dropdown-search__item">
            <div className="dropdown-search__image-skeleton" />
            <div className="dropdown-search__item-skeleton" />
          </div>
          <div className="dropdown-search__item">
            <div className="dropdown-search__image-skeleton" />
            <div className="dropdown-search__item-skeleton" />
          </div>
        </div>
      )}
      {(isListOpened && !isLoading && (withArrow || openOnClick || query.length > 0)) && (
        <ul data-testid="dropdown-search-list" className={`dropdown-search__list dropdown-search__list--${listWidth}`} onKeyDown={handleKeyDown} ref={listRef}>
          {options.map((option, index) => (
            <li
              key={`${option.name}-${Math.random()}`}
              className={`dropdown-search__item ${index === activeIndex ? 'dropdown-search__item--active' : ''}`}
              onClick={() => onItemSelect(option.name, option.value)}
              data-testid="dropdown-search-list-option"
            >
              {showIcon && (
                <img
                  src={option.logo || PlaceholderLogo}
                  alt=""
                  className="dropdown-search__item-image"
                />
              )}
              <span>{option.name}</span>
            </li>
          ))}
          {canAddItems && !matchOption() && (
            <li
              className={`dropdown-search__item dropdown-search__item--add ${options.length === activeIndex ? 'dropdown-search__item--active' : ''}`}
              onClick={() => onItemSelect(query)}
            >
              <i className="dropdown-search__item-image dropdown-search__item-image--add bi bi-plus" />
              <span>
                Add “
                {query}
                ” as a new institution
                {' '}
              </span>
            </li>
          )}
          {options.length === 0 && !canAddItems && query && (
            <li className="dropdown-search__item dropdown-search__item--empty">
              We couldn’t find anything matching your search. Please try again with something different.
            </li>
          )}
        </ul>
      )}
    </div>
  );
};
