import { useQuery } from 'react-query';
import { getAlumniByCohort } from '../api/alumni';

export const useGetAlumniByCohort = (cohortID: string, options = {}) => useQuery(
  ['alumni', cohortID], // Use an array with 'cohort' and the actual cohortID as the query key
  () => getAlumniByCohort(cohortID), // Use an arrow function to call your actual function with the cohortID
  {
    retry: 0,
    // should be refetched in the background every 8 hours
    staleTime: 1000 * 60 * 60 * 8,
    ...options, // Spread additional options
  },
);
