import { useLoaderData, useParams } from 'react-router-dom';
import { useState } from 'react';

import { CompanyResponse } from '@/domains/core/company/types';
import { TabsContent, withTabs } from '@/components/tabs';
import { Loader } from '@/components/loader/loader';
import './styles/company.scss';
import { BasicCompanyJobsTab } from '@/domains/core/company/components/basic-company-jobs-tab/basic-company-jobs-tab';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';
import { useGetSingleCompany } from '@/services/queries/company';
import { BasicCompanyHeader, CompanyOverviewContainer } from '@/domains/core/company';

function CompanyPage() {
  const { contacts } = useLoaderData() as CompanyResponse;
  const params = useParams();
  const { companyID } = params;
  const { data: company, isLoading: isCompanyLoading, isFetching: isCompanyFetching } = useGetSingleCompany(companyID, { enabled: !!companyID });
  const [jobsTabCount, setJobsTabCount] = useState(0);

  const updateJobsCount = (count: number) => {
    setJobsTabCount(count);
  };

  if (!company && (isCompanyFetching || isCompanyLoading)) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }

  if (!company) {
    return (
      <>
        Company not found
      </>
    );
  }

  return (
    <div className="company-page">
      <div className="company-page__header">
        <BasicCompanyHeader
          name={company.name}
          logo={company.logo_url}
          tabs={['About Company', 'Jobs']}
          location={company.hq_location}
          isVerified={false}
          tabIcons={['bi bi-building', 'bi bi-briefcase']}
          jobsTabCounter={jobsTabCount}
        />
      </div>
      <div className="company-page__content">
        <TabsContent>
          <div className="company-page__overview" data-tab="About Company">
            <CompanyOverviewContainer contacts={contacts} company={company} />
          </div>
          <div className="company-page__jobs" data-tab="Jobs">
            <BasicCompanyJobsTab
              companyID={company.id}
              isCompanyApplicationActive={company.application_status && company.application_status !== 'archive'}
              companyName={company.name}
              companyLogo={company.logo_url}
              companyLinkedIn={company.linkedin_url}
              updateTabCount={updateJobsCount}
            />
          </div>
        </TabsContent>
      </div>
    </div>
  );
}

export default CustomWithAuthenticationRequired(withTabs(CompanyPage), {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
