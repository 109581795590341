import { Link } from 'react-router-dom';
import './checkbox.scss';

type Props = {
  id?: string;
  label: string;
  checked: boolean;
  labelLink?: string;
  target?: React.HTMLAttributeAnchorTarget;
  onChange: (checked: boolean) => void;
  onClick?: () => void;
};

// WIP: Implement the Checkbox component

export const Checkbox = ({
  id,
  label,
  checked,
  labelLink,
  target,
  onChange,
  onClick,
}: Props) => (
  <div className="careerOS-checkbox">
    <input
      type="checkbox"
      checked={checked}
      onChange={(e) => onChange(e.target.checked)}
      className="careerOS-checkbox__input"
      id={id || label}
      name={id}
    />
    <div className="careerOS-checkbox__icon" onClick={() => onChange(!checked)}>
      <i className="bi bi-check-square-fill" />
    </div>
    <label className="careerOS-checkbox__label" htmlFor={id || label} onClick={onClick}>
      {labelLink && !checked ? (
        <Link to={labelLink} target={target} rel="noopener noreferrer">
          {label}
        </Link>
      ) : (
        <span className={onClick ? 'careerOS-checkbox__label--clickable' : ''}>{label}</span>
      )}
    </label>
  </div>
);
