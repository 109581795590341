import { useParams } from 'react-router-dom';
import { Loader } from '@/components/loader/loader';
import '@/pages/styles/resume-feed.scss';
import {
  CommentsSidebar,
  PreviewResume,
} from '@/domains/core/resume';

import CustomWithAuthenticationRequired from '../auth/custom-protected-route';
import { useStudentResumeQuery } from '@/services/queries/resume';
import { useStudentByID } from '@/services/queries/student';

const baseURL = window.location.origin;

const ViewResumePage = ({ type }: { type: 'base' | 'tailored' }) => {
  const { studentID, resumeID } = useParams();
  const { data: resumeResponse, isLoading: isResumeLoading } = useStudentResumeQuery(type, studentID || '', resumeID || '', {
    enabled: !!studentID && !!resumeID,
  });

  const { data: student, isLoading: isStudentLoading } = useStudentByID(studentID || '', {
    enabled: !!studentID,
  });

  const URL = `${baseURL}/app/resume-os/${type}/${resumeID}`;

  if (isResumeLoading || isStudentLoading) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }

  return (
    <div className="resume-builder resume-builder--advisor">
      {resumeResponse && (
      <div className="resume-builder__content resume-builder__content--advisor">
        <PreviewResume setNumberOfPages={() => {}} selectedTemplate={resumeResponse?.resume_template?.name} baseResume={resumeResponse.resume} />
        <CommentsSidebar email={student.email} url={URL} hasUnreadComments={false} job={resumeResponse?.job} resumeID={resumeResponse.id} />
      </div>
      )}
    </div>
  );
};

export default CustomWithAuthenticationRequired(ViewResumePage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
