import './contact-card.scss';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Button } from '@careeros/coco';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { StatusTag } from '../status-tags/status-tag';
import { ConnectionStatus } from '../../types/contact';
import { Tooltip } from '@/components/tooltip/tooltip';
import { IconButton } from '@/components/icon-button/icon-button';

interface ContactCardProps {
  id: string;
  imageURL: string;
  contactName: string;
  contactPosition: string;
  linkedInUrl?: string;
  isPreview?: boolean;
  handleSave?: () => Promise<void>;
  networkingStatus?: ConnectionStatus;
  linkedInStatus?: ConnectionStatus;
  handleArchive?: (id: string) => void;
}

/**
 * Primary UI component for company card
 */
export const ContactCard = ({
  imageURL,
  id,
  contactName,
  contactPosition,
  isPreview = false,
  linkedInUrl,
  networkingStatus,
  linkedInStatus,
  handleSave,
  handleArchive,
}: ContactCardProps) => {
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const goToContact = () => {
    if (isPreview && linkedInUrl) {
      trackEvent('Linkedin Suggested Contact Clicked');
      window.open(linkedInUrl, '_blank');
    } else {
      navigate(`/app/inbox/${id}`);
    }
  };

  const handleArchiveClick = () => {
    if (handleArchive) {
      handleArchive(id);
    }
  };

  const handleSaveClick = async () => {
    if (handleSave) {
      setIsLoading(true);
      await handleSave();
      setIsLoading(false);
    }
  };

  return (
    <div className="contact-card">
      <DefaultLogo
        type="contact"
        source={imageURL}
        handleClick={goToContact}
        className="contact-card__image"
        name={contactName}
        size={48}
      />
      <div className="contact-card__main">
        <div className="contact-card__info">
          <h4 onClick={goToContact} className="contact-card__name">{contactName}</h4>
          <span className="contact-card__label">{contactPosition}</span>
        </div>
        {networkingStatus && linkedInStatus && (
          <div className="contact-card__statuses">
            <StatusTag status={networkingStatus} type="networking" />
            <StatusTag status={linkedInStatus} type="linkedin" withTooltip />
          </div>
        )}
      </div>
      {isLoading && (
        <div className="contact-card__loading" />
      )}
      {isPreview && (
        <Button
          handleClick={handleSaveClick}
          label="Save Contact"
          icon="bi bi-bookmark"
          outlined
          mode="primary"
          size="medium"
          iconPosition="left"
        />
      )}

      {!isPreview && (
        <div className="contact-card__actions">
          <Tooltip label="Archive" withArrow position="top">
            <IconButton
              icon="bi bi-archive"
              mode="rounded"
              size="medium"
              handleClick={handleArchiveClick}
              outlined
              isDangerous
            />
          </Tooltip>
          <Tooltip label="Message" withArrow position="top">
            <IconButton
              icon="bi bi-chat-text"
              mode="rounded"
              size="medium"
              handleClick={goToContact}
              outlined
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};
