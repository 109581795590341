import { useQueryClient } from 'react-query';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { changeQuestStatus } from '@/services/api/student';
import { useHash } from '@/services/hooks/use-hash';
import { fetchAllReminders } from '@/services/api/quest';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { Tooltip } from '@/components/tooltip/tooltip';

import './tracker-quests-container.scss';
import { QUESTSTATUS } from '@/domains/core/student/components/quests/quest-service/quest.service';
import { QuestTile } from '@/domains/core/student';
import { CTA_LINK } from '@/domains/core/student/components/quests/quests-data';
import { QuestTileType, QuestType } from '@/domains/core/student/types';
import { useIdentities } from '@/services/queries/user';

type Props = {
  quests: QuestTileType[];
  handleQuestDelete: (questID: string) => void;
  changeTab: (tab: string, state?: string, setTabAsCurrent?: boolean) => void;
};

export const TrackerQuests = ({
  quests, handleQuestDelete, changeTab,
}: Props) => {
  const { handleHashChange } = useHash({});
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();
  const { hash } = window.location;
  const { data: identities } = useIdentities();
  const queryClient = useQueryClient();

  const handleDissmissClick = async (questID: string, questType: string) => {
    const response = await changeQuestStatus(questID, QUESTSTATUS.WONTDO, questType);
    if (response) {
      handleQuestDelete(questID);
      trackEvent('Quest dismissed', { questType });
      queryClient.invalidateQueries('quests');
    }
  };

  const handleCompleteClick = async (questID: string, questType: string) => {
    const response = await changeQuestStatus(questID, QUESTSTATUS.DONE, questType);
    if (response) {
      handleQuestDelete(questID);
      queryClient.invalidateQueries('quests');
    }
  };

  const checkForResponses = async () => {
    if (!identities || identities.length === 0) {
      return;
    }

    await fetchAllReminders();
  };

  const handleQuestClick = (type: QuestType, companyID: string, contactID: string) => {
    const isSendMessageQuestType = type === QuestType.SendConnection
    || type === QuestType.SendMessage
    || type === QuestType.SendFirstEmail
    || type === QuestType.SendFirstOutreach
    || type === QuestType.SendSecondOutreach
    || type === QuestType.SendFollowUpEmail
    || type === QuestType.RespondToEmail
    || type === QuestType.ScheduleCoffeeChat
    || type === QuestType.ReplyToContact;

    if (type === QuestType.SaveContact || type === QuestType.AddNewContact) {
      changeTab('Contacts', 'addContact', true);
      return;
    }

    if (type === QuestType.ApplicationDeadline) {
      changeTab('Jobs', undefined, true);
      return;
    }

    if (type === QuestType.DownloadExtension) {
      window.open(CTA_LINK[type], '_blank');
    } else if (isSendMessageQuestType) {
      navigate(CTA_LINK[type](contactID));
    } else {
      navigate(CTA_LINK[type]);
    }
  };

  useEffect(() => {
    checkForResponses();
  }, []);

  useEffect(() => {
    handleHashChange();
  }, [hash]);

  if (!quests?.length) {
    return null;
  }

  return (
    <div className="tracker-quests">
      <h2 className="tracker-quests__title">
        <span />
        Quests
        <div className="tracker-quests__icon-container">
          <Tooltip
            withArrow
            position="right"
            size="large"
            label="Finish Quests to climb the leaderboard!"
          >
            <i className="bi bi-info-circle" />
          </Tooltip>
        </div>
      </h2>
      <div className="tracker-quests__scroll-container">
        <div className={`tracker-quests__wrapper ${quests?.length > 2 ? 'tracker-quests__wrapper--scrollable' : ''}`}>
          {quests.map((quest: QuestTileType, index) => (
            <QuestTile
              key={quest.id}
              id={quest.id}
              type={quest.type}
              dueDate={quest.due_date}
              company={quest.company}
              contact={quest.contact}
              job={quest.job}
              dismissAction={handleDissmissClick}
              completeAction={handleCompleteClick}
              primary={index === 0}
              customCTAClickHandler={handleQuestClick}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
