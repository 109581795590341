import { useState, useEffect } from 'react';
import { Button } from '@careeros/coco';

import { useQueryClient } from 'react-query';
import { TabsContent, TabsHeader, withTabs } from '@/components/tabs';
import { formatDateDeltaFromNow } from '@/services/helpers/date';
import '../sidebar.scss';
import './comments-sidebar.scss';
import { Comment } from '@/domains/core/company/types';
import { resolveComment } from '@/services/api/student';
import CheckIcon from '@/assets/images/icons/checkmark.svg';
import Comments from '@/assets/images/comments.svg';

interface CommentSidebarProps {
  companyName: string;
  closeSidebar: any;
  hasResolveButton: boolean;
  comments: Comment[];
}

const CommentsSidebar = ({
  companyName,
  closeSidebar,
  hasResolveButton = false,
  comments,
}: CommentSidebarProps) => {
  const queryClient = useQueryClient();
  const [isEmpty, setIsEmpty] = useState(false);

  const unresolved = `Unresolved (${comments?.filter((comment:Comment) => !comment.resolved).length})`;
  const tabs = comments?.filter((comment:Comment) => !comment.resolved).length > 0 ? [unresolved, 'View all'] : ['View all', unresolved];
  const defaultTab = comments?.filter((comment:Comment) => !comment.resolved).length > 0 ? unresolved : 'View all';

  const handleResolveComment = async (commentID: string) => {
    const response = await resolveComment(commentID);
    if (response) {
      queryClient.invalidateQueries(['studentComments']);
      closeSidebar();
    }
  };

  useEffect(() => {
    setIsEmpty(!comments.length);
  }, [comments]);

  return (
    <div className="industry-sidebar">
      <h2 className="industry-sidebar__title">
        Comment History
      </h2>
      <p className="industry-sidebar__subtitle">
        All Comments made for
        {' '}
        {companyName}
        {' '}
      </p>
      {isEmpty
        ? (
          <div className="industry-sidebar__comments">
            <img src={Comments} alt="" />
            <p>There are no comments for this company.</p>
          </div>
        )
        : (
          <>
            <div className="industry-sidebar__tabs-header">
              <TabsHeader tabs={tabs} defaultTab={defaultTab} />
            </div>
            <div className="industry-sidebar__tabs-content">
              <TabsContent>
                <div data-tab="View all">
                  <div className="comments">
                    {comments?.sort((a: any, b: any) => {
                      const dateA: any = new Date(a.created_at);
                      const dateB: any = new Date(b.created_at);
                      return dateB - dateA;
                    }).map((comment: Comment) => (
                      <div key={comment.id} className="comments__item">
                        <div className="comments__item-header">
                          <div className="comments__item-header-sender">
                            {comment.advisor_name}
                          </div>
                          <div className="comments__item-header-date">
                            {formatDateDeltaFromNow(comment.created_at)}
                          </div>
                          {comment.resolved && (
                          <div className="comments__item-header-resolved">
                            <img className="comments__item-header-resolved-img" src={CheckIcon} alt="" />
                            Resolved
                          </div>
                          )}
                        </div>
                        <div className="comments__item-content">
                          {comment.comment}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div data-tab={unresolved}>
                  <div className="comments">
                    {comments?.filter((comment: Comment) => !comment.resolved).sort((a: any, b: any) => {
                      const dateA: any = new Date(a.created_at);
                      const dateB: any = new Date(b.created_at);
                      return dateB - dateA;
                    }).map((comment: Comment) => (
                      <div key={comment.id} className="comments__item">
                        <div className="comments__item-header">
                          <div className="comments__item-header-sender">
                            {comment.advisor_name}
                          </div>
                          <div className="comments__item-header-date">
                            {formatDateDeltaFromNow(comment.created_at)}
                          </div>
                        </div>
                        <div className="comments__item-content">
                          {comment.comment}
                        </div>
                        {hasResolveButton && (
                        <div className="comments__item-action">
                          <Button handleClick={() => handleResolveComment(comment.id)} label="Mark as resolved" outlined mode="primary" size="small" />
                        </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </TabsContent>
            </div>
          </>
        )}
    </div>
  );
};

export default withTabs(CommentsSidebar);
