import { useState } from 'react';
import { Button } from '@careeros/coco';
import { ProviderLoginButton } from '@/domains/generic/auth';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { IconButton } from '@/components/icon-button/icon-button';
import '../styles/login-box-form.scss';

type Props = {
  universityName: string;
  logo: string;
  handleClose: () => void;
};

export const CodeCorrect = ({ universityName, logo, handleClose }: Props) => {
  const { trackEvent } = useAnalytics();
  const [requirementsUnderstood, setRequirementsUnderstood] = useState(false);

  const handleBackButtonClick = () => {
    trackEvent('Login: Back button clicked', {}, { flowStep: 'Trial Code Correct' });
    if (!requirementsUnderstood) {
      handleClose();
    }
    setRequirementsUnderstood(false);
  };

  return (
    <>
      <div
        className="university-login__back-button"
      >
        <IconButton
          mode="unset"
          size="medium"
          icon="bi bi-arrow-left-circle"
          hoverIcon="bi bi-arrow-left-circle-fill"
          handleClick={handleBackButtonClick}
        />
      </div>
      <div className="login-form">
        <div className="login-form__header">
          <img
            src={logo}
            alt=""
            className="login-form__image"
          />
          <h2 className="login-form__title">
            {universityName}
          </h2>
          <p className="login-form__subtitle">
            Code correct!
          </p>
        </div>
        {requirementsUnderstood ? (
          <>
            <div className="login-form__prompt">
              Please use a
              {' '}
              <b>PERSONAL</b>
              {' '}
              account to sign in:
            </div>
            <div className="login-form__buttons-container">
              <ProviderLoginButton universityName="Trial" provider="google-oauth2" accountType="consumers" />
              <ProviderLoginButton universityName="Trial" provider="windowslive" accountType="consumers" />
            </div>
          </>
        ) : (
          <>
            <div className="login-form__prompt">
              To create a trial account, please
              {' '}
              <strong>
                do not use your
                {' '}
                {universityName}
                {' '}
                email
              </strong>
              , use any personal email instead. (e.g. example@gmail.com)
            </div>
            <div className="login-form__buttons-container">
              <Button
                mode="primary"
                size="fill"
                label="Understood. Create account with private email."
                handleClick={() => setRequirementsUnderstood(true)}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
