import { useEffect, useState } from 'react';
import { DropdownSearch } from '../dropdown-search/dropdown-search';

type Props = React.ComponentProps<typeof DropdownSearch>;

export const DropdownSearchStatic = ({
  options,
  ...props
}: Props) => {
  const [currentQuery, setCurrentQuery] = useState('');
  const [filteredList, setFilteredList] = useState<typeof options>(options || []);

  const onQueryChange = (query: string) => {
    const filtered = (options || [])
      .filter((option) => option.name.toLowerCase().includes(query.toLowerCase()))
      .sort((firstOption, secondOption) => {
        const firstOptionStartsWith = firstOption.name.toLowerCase().startsWith(query.toLowerCase());
        const secondOptionStartsWith = secondOption.name.toLowerCase().startsWith(query.toLowerCase());
        if (firstOptionStartsWith && !secondOptionStartsWith) return -1;
        if (!firstOptionStartsWith && secondOptionStartsWith) return 1;
        return 0;
      });
    setFilteredList(filtered);
    setCurrentQuery(query);
  };

  useEffect(() => {
    onQueryChange(currentQuery);
  }, [options]);

  return (
    <DropdownSearch {...props} options={filteredList} onValueChange={onQueryChange} />
  );
};
