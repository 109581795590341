import {
  useContext,
  useEffect,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from '@/components/loader/loader';
// import { KanbanBoard } from '@/domains/generic/kanban/kanban-board';
import {
  changeApplicationStatus,
} from '@/domains/core/company';
import { statuses } from '@/domains/core/tracker-data';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { AllCommentsSidebar, CommentsSidebar, OverviewContent } from '@/domains/generic';
import { SidebarContext } from '@/components/sidebar/sidebar-provider';
import {
  filterTiles, filterTilesByStatus, getAllSavedIndustries, sortByPosition,
} from '@/services/helpers/company';
import { FilterNameType, FilterOption, Tile } from '@/domains/core/company/types';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';
import { useApplicationsNoCache } from '@/services/queries/application';
import './styles/overview.scss';
import { useStudentComments } from '@/services/queries/student';

const companyStatuses: string[] = [
  'Company Saved',
  'Networking',
  'Applying',
  'Applied',
  'Interviewing',
  'Offer',
  'Rejected',
];

function OverviewPage() {
  const { data: tiles = [], isLoading: isTilesLoading, isFetching: isFetchingTiles } = useApplicationsNoCache();
  const { data: commentsResponse } = useStudentComments(undefined);
  const { trackEvent } = useAnalytics();
  const { hash } = window.location;
  const { user } = useAuth0();
  const { addToast }: AddToastType = useContext(ToastContext);
  const [kanbanLayout, setKanbanLayout] = useState({});
  const [currentFilters, setCurrentFilters] = useState<{ [name in FilterNameType]: FilterOption[] }>();
  const { openSidebar, closeSidebar } = useContext(SidebarContext) as any;
  const [industries, setIndustries] = useState(getAllSavedIndustries(tiles));
  const [searchValue, setSearchValue] = useState('');
  const [filteredTilesByStatus, setFilteredTilesByStatus] = useState<Tile[]>([]);
  const queryClient = useQueryClient();
  const areFiltersEmpty = (!currentFilters || Object.values(currentFilters).filter((v) => v && v.length > 0).length === 0) && !searchValue.length;

  const computeKanbanLayout = (allCompanies: Tile[]) => {
    if (!allCompanies || !Array.isArray(allCompanies)) {
      setKanbanLayout({});
      return;
    }
    const kanban = {
      'Company Saved': sortByPosition(allCompanies.filter((company: Tile) => company.status === 'saved')),
      Networking: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'networking')),
      Applying: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'applying')),
      Applied: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'applied')),
      Interviewing: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'interviewing')),
      Offer: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'offer')),
      Rejected: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'reject')),
    };
    setKanbanLayout(kanban);
  };

  const handleFiltering = (filters: { [name in FilterNameType]: FilterOption[] }, searchQuery: string) => {
    setCurrentFilters(filters);
    setSearchValue(searchQuery);
    const filteredResult = filterTiles(tiles, filters, searchQuery);
    const tilesFilteredByStatusRes = filterTilesByStatus(filteredResult, Object.values(filters.status));

    setFilteredTilesByStatus(tilesFilteredByStatusRes);
    computeKanbanLayout(filteredResult);
  };

  const openCommentsSideBar = (tile: Tile) => {
    if (tile.comments?.length > 0) {
      openSidebar(<CommentsSidebar company={tile} hasResolveButton closeSidebar={closeSidebar} />);
    }
  };

  const sortByCreatedAt = (arr: any[]) => arr.sort((a, b) => {
    const dateA: any = new Date(a.created_at);
    const dateB: any = new Date(b.created_at);
    return dateB - dateA;
  });

  const openFullCommentHistory = () => {
    const sortedComments = sortByCreatedAt(commentsResponse || []);
    openSidebar(<AllCommentsSidebar comments={sortedComments} closeSidebar={closeSidebar} />);
  };

  const trackStatusChange = (status: string, newStatus: string, companyName: string) => {
    if (status === newStatus) {
      return;
    }

    if (newStatus !== 'Company Saved') {
      trackEvent(`Company moved to ${newStatus}`, user, { company: companyName });
    }

    trackEvent(`Overview Board: Company Dropped from ${status} to ${newStatus}`);
    trackEvent('Overview Board: Company Status change');
    trackEvent('Kanban Board Update', user);
  };

  // Handle Data
  const cardChangeHandler = async (cardInfo: any, newStatus: string) => {
    const {
      id, status, dropPosition,
    } = cardInfo;
    const newStatusForAPI = statuses[newStatus];
    const newKanban: any = { ...kanbanLayout };
    const oldKanban: any = { ...kanbanLayout };
    const tile = newKanban[status].find((company: Tile) => company.id === id);
    if (!tile) {
      return;
    }
    newKanban[status] = newKanban[status].filter((company: Tile) => company.id !== id);

    // Insert the tile at the specific index provided in dropPosition
    newKanban[newStatus].splice(dropPosition, 0, tile);

    setKanbanLayout(newKanban);
    trackStatusChange(status, newStatus, tile.company_name);

    const response = await changeApplicationStatus(id, newStatusForAPI, dropPosition);
    // Handle the API response and possible error states
    if (response.status === newStatusForAPI) {
      addToast({
        type: 'success',
        message: 'Company status updated',
        additionalMessage: 'The company status has been successfully updated.',
      });
    } else {
      addToast({
        type: 'error',
        message: 'Failed to update company status',
        additionalMessage: "We're looking into the issue. Please try again later.",
      });
      trackEvent('Toast Error Shown', user, {
        message: 'Failed to update company status',
        error: response.error,
      });
      oldKanban[newStatus] = oldKanban[newStatus].filter((company: Tile) => company.id !== id);
      setKanbanLayout(oldKanban);
    }
    await queryClient.invalidateQueries(['applications']);
    await queryClient.invalidateQueries(['applications-no-cache']);
  };

  useEffect(() => {
    if (isTilesLoading || isFetchingTiles || !Array.isArray(tiles)) {
      return;
    }

    if (!areFiltersEmpty && currentFilters) {
      handleFiltering(currentFilters, searchValue);
    } else {
      computeKanbanLayout(tiles);
      setFilteredTilesByStatus(tiles.filter((tile: Tile) => tile.status !== statuses.Archived));
    }

    setIndustries(getAllSavedIndustries(tiles));
  }, [tiles, isTilesLoading, isFetchingTiles]);

  useEffect(() => {
    if (!hash) {
      return;
    }
    const tileId = hash.split('_')[1];
    if (!tileId) {
      return;
    }
    const tile = tiles.find((company: Tile) => company.id === tileId) as Tile;
    openCommentsSideBar(tile);
  }, [hash]);

  useEffect(() => {
    trackEvent('Page Visit', {}, {
      pageURL: 'Dashboard',
    });
  }, []);

  return (
    <div className="overview-board" id="main">
      <OverviewContent
        columns={companyStatuses}
        layout={kanbanLayout}
        cardChangeHandler={cardChangeHandler}
        handleFiltersChange={handleFiltering}
        savedIndustries={industries}
        openFullCommentHistory={openFullCommentHistory}
        tiles={filteredTilesByStatus}
      />
    </div>
  );
}

export default CustomWithAuthenticationRequired(OverviewPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
