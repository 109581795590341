const MOBILE_BREAKPOINT = 1480;

export const isMobileDevice = (window.innerWidth <= MOBILE_BREAKPOINT)
            && window.matchMedia
            && window.matchMedia('(pointer: coarse)').matches;

export const setMobileVhUnit = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
