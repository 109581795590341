import trackerData from './tracker-data';

const applicationStatuses: { [key: string]: string } = {
  'Company Saved': 'saved',
  Networking: 'networking',
  Applying: 'applying',
  Applied: 'applied',
  Interviewing: 'interviewing',
  Offer: 'offer',
  Archived: 'archive',
  Rejected: 'reject',
};

const flip = (data: typeof applicationStatuses) => Object.fromEntries(
  Object
    .entries(data)
    .map(([key, value]) => [value, key]),
);

const applicationAPIstatuses = flip(applicationStatuses);

export { trackerData, applicationStatuses as statuses, applicationAPIstatuses as APIstatuses };
export * from './types';
export * from './content';
