import { Link, useLoaderData } from 'react-router-dom';
import { useState } from 'react';
import { Button } from '@careeros/coco';
import { LoginLoaderResponse } from '@/domains/generic/auth/types';
import { getCode } from '@/services/strapi/universities';

import '../styles/login-box-form.scss';
import { waitlistHubspotFormId } from './waitlist-form';
import { router } from '@/main';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { CodeCorrect } from './code-correct';
import { IconButton } from '@/components/icon-button/icon-button';
import { Input } from '@/components/input/input';

export default function TrialCodeCheck() {
  const { selectedUniversity } = useLoaderData() as LoginLoaderResponse;
  const { trackEvent } = useAnalytics();
  const [inputValue, setInputValue] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  if (!selectedUniversity) {
    window.location.href = '/with-university';
    return null;
  }

  const handleCodeChange = (code: string) => {
    setInputValue(code);
    setIsSubmitted(false);
  };

  const handleSubmitButtonClick = async () => {
    const response = await getCode(inputValue);

    const isCodeInvalid = response.data.length === 0
    || !response.data.some((code: any) => code?.attributes.university.data?.attributes.name === selectedUniversity.name);

    trackEvent('Login: Trial code submitted', {}, { codeValid: !isCodeInvalid, code: inputValue });

    if (isCodeInvalid) {
      setIsSubmitted(true);
      return;
    }

    setShowSuccessMessage(true);
  };

  const handleDontHaveCodeClick = () => {
    trackEvent('Login: Don’t have a code clicked');
  };

  const handleBackButtonClick = (step: string) => {
    trackEvent('Login: Back button clicked', {}, { flowStep: step });
    if (showSuccessMessage) {
      setShowSuccessMessage(false);
      setIsSubmitted(false);
      setInputValue('');
    } else {
      router.navigate('/with-university');
    }
  };

  const resetCodeCorrect = () => {
    setShowSuccessMessage(false);
    setInputValue('');
    setIsSubmitted(false);
  };

  if (showSuccessMessage) {
    return (
      <CodeCorrect handleClose={resetCodeCorrect} universityName={selectedUniversity.name} logo={selectedUniversity.logo} />
    );
  }

  return (
    <>
      <div
        className="university-login__back-button"
      >
        <IconButton
          mode="unset"
          size="medium"
          icon="bi bi-arrow-left-circle"
          hoverIcon="bi bi-arrow-left-circle-fill"
          handleClick={() => handleBackButtonClick('Trial Code Check')}
        />
      </div>
      <div className="login-form">
        <div className="login-form__header">
          <img
            src={selectedUniversity.logo}
            alt=""
            className="login-form__image"
          />
          <h2 className="login-form__title">
            {selectedUniversity.name}
            : Sign in
          </h2>
          <p className="login-form__subtitle">
            Please provide your sign up code
          </p>
        </div>
        <div>
          <Input
            name="code"
            id="sign-up-code"
            type="text"
            value={inputValue}
            placeholder="Enter sign up code..."
            invalid={isSubmitted}
            label=""
            warningText={(
              <>
                Invalid code, try again.
                {' '}
                <Link to="/with-university">
                  {selectedUniversity.name}
                  {' '}
                  not your institution?
                </Link>
              </>
            )}
            handleValueChange={handleCodeChange}
          />
          <div className="login-form__link">
            <Link className="login-form__linked-text" onClick={handleDontHaveCodeClick} to={`/with-university/hubspot?school=${encodeURIComponent(selectedUniversity.name)}&hs_form_id=${waitlistHubspotFormId}`}>
              I don’t have a sign up code
            </Link>
          </div>
        </div>

        <div className={`login-form__button ${isSubmitted ? 'login-form__button--disabled' : ''}`}>
          <Button
            mode="primary"
            size="fill"
            label="Continue"
            disabled={isSubmitted || inputValue.length === 0}
            handleClick={handleSubmitButtonClick}
          />
        </div>
      </div>
    </>
  );
}
