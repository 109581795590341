/* eslint-disable react/no-array-index-key */
import {
  Page,
  Text,
  View,
  Document,
  Link,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';
import { HighlightWords, ResumeSchema } from '../../types';

import Arial from '@/assets/fonts/resume-builder/Arial/Arial.ttf';
import ArialBold from '@/assets/fonts/resume-builder/Arial/Arial_Bold.ttf';
import ArialItalic from '@/assets/fonts/resume-builder/Arial/Arial_Italic.ttf';

import {
  computeSkillsStyle,
  formatDateToYear,
  renderWorkHighlightedText,
  trimUrlPrefix,
} from './utils';
import { formatDate } from '../../helpers/formate-date-templates';

Font.register({
  family: 'Arial',
  src: Arial,
  fonts: [
    { src: Arial },
    { src: ArialItalic, fontStyle: 'italic' },
    { src: ArialBold, fontWeight: 'bold' },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: '#fff',
    fontFamily: 'Arial',
    width: '100%',
    height: '100%',
    padding: '35px 40px',
    margin: 0,
  },
  document: {
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    border: 0,
    background: 'white',
  },
  header: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 15,
  },
  bold: {
    fontWeight: 'bold',
  },
  block: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  EducationblockTitle: {
    fontSize: 10,
    fontFamily: 'Arial',
    textAlign: 'left',
    width: '100%',
    color: '#000',
    borderTop: '1.8px solid grey',
    paddingTop: 3,
  },
  WorkblockTitle: {
    fontSize: 10,
    fontFamily: 'Arial',
    textAlign: 'left',
    width: '100%',
    color: '#000',
    borderTop: '1.8px solid grey',
    paddingTop: 3,
  },
  LanguagesBlockTitle: {
    fontSize: 10,
    fontFamily: 'Arial',
    textAlign: 'left',
    width: '100%',
    color: '#000',
    borderTop: '1.8px solid grey',
    paddingTop: 3,
  },
  AdditionalInfo: {
    fontSize: 10,
    fontFamily: 'Arial',
  },
  AdditionalBlock: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 15,
  },
  educationBlock: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 10,
  },
  WorkBlock: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 10,
  },
  row: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  EdRow: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    marginLeft: 15,
  },
  Edinstitution: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 10,
    fontFamily: 'Arial',
    textAlign: 'left',
    fontWeight: 'bold',
    width: '100%',
  },
  EdDates: {
    fontSize: 10,
    fontFamily: 'Arial',
    textAlign: 'right',
    alignSelf: 'flex-end',
    marginLeft: 'auto',
  },
  EdDescription: {
    fontSize: 10,
    width: '100%',
    height: 'auto',
    marginLeft: 5,
  },
  workRow: {
    flexDirection: 'row',
    fontWeight: 'bold',
    width: '100%',
  },
  workInstitution: {
    fontSize: 10,
    fontFamily: 'Arial',
    color: '#000',
    textTransform: 'uppercase',
  },
  workLocation: {
    fontSize: 10,
    textAlign: 'right',
    fontFamily: 'Arial',
    color: '#000',
  },
  workPosition: {
    fontSize: 10,
    fontFamily: 'Arial',
    fontWeight: 'bold',
  },
  workDates: {
    fontSize: 10,
    marginLeft: 'auto',
    fontWeight: 'bold',
  },
  summary: {
    fontSize: 10,
    fontFamily: 'Arial',
    fontStyle: 'italic',
  },
  highlights: {
    marginTop: 5,
    paddingLeft: 15,
    width: '100%',
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  bulletPoint: {
    width: 10,
    fontSize: 10,
  },
  highlightText: {
    fontSize: 10,
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 3,
    marginLeft: 5,
  },
  nameRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 2,
  },
  headerTopSide: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    columnGap: 5,
    flexWrap: 'wrap',
  },
  headerTopSideText: {
    fontSize: 10,
    fontFamily: 'Arial',
    textAlign: 'center',
  },
  nameText: {
    fontSize: 10,
    fontFamily: 'Arial',
    fontWeight: 'bold',
    color: '#000',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  link: {
    color: '#000',
    textDecoration: 'none',
  },
  blockWithBorder: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 10,
  },
  blockTitleText: {
    fontSize: 10,
    fontFamily: 'Arial',
    textAlign: 'left',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  languages: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  languagesText: {
    display: 'flex',
    flexDirection: 'row',
    gap: 3,
    fontSize: 10,
    fontFamily: 'Arial',
  },
  GreenHighlight: {
    color: '#07795C',
    textDecoration: 'underline',
    backgroundColor: '#bbece880',
  },
  RedHighlight: {
    color: '#BA1A1A',
    textDecoration: 'underline',
    backgroundColor: '#ffdad680',
  },
  SkillsGreenHighlight: {
    color: '#07795C',
    textDecoration: 'underline',
    backgroundColor: '#bbece880',
    fontSize: 10,
    fontFamily: 'Arial',
  },
  SkillsRedHighlight: {
    color: '#BA1A1A',
    textDecoration: 'underline',
    backgroundColor: '#ffdad680',
    fontSize: 10,
    fontFamily: 'Arial',
  },
  SkillsSubtleGreenHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#bbece880',
    fontSize: 10,
    fontFamily: 'Arial',
  },
  SubtleGreenHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#bbece880',
    fontSize: 10,
    fontFamily: 'Arial',
  },
  SubtleRedHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#ffdad680',
    fontSize: 10,
    fontFamily: 'Arial',
  },
  SkillsSubtleRedHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#ffdad680',
    fontSize: 10,
    fontFamily: 'Arial',
  },
  Area: {
    marginLeft: 30,
  },
  sectionHeaderWithMargin: {
    fontSize: 10,
    marginTop: 3,
    fontWeight: 'bold',
  },
  normalTextSmall: {
    fontSize: 10,
    marginTop: 3,
    marginLeft: 15,
    display: 'flex',
    flexDirection: 'row',
    gap: 3,
    alignItems: 'flex-start',
  },
});

type ResumeSchemaProps = {
  resume: ResumeSchema;
  title: string;
  highlightedWords: HighlightWords | null;
  activeField: {
    type: 'work' | 'additional_information';
    index: number;
    bulletIndex: number;
  } | null;
};

let previousWorkName: string = '';

// Create Document Component
const BayesTemplate = ({
  resume,
  title,
  highlightedWords,
  activeField,
}: ResumeSchemaProps) => (
  <Document title={title} style={styles.document}>
    <Page size="A4" style={styles.page}>
      <View style={styles.nameRow}>
        <Text style={styles.nameText}>{resume.basics.name}</Text>
      </View>
      <View style={styles.header}>
        {resume.basics.phone_numbers?.map((phone) => (
          <Text key={phone} style={styles.headerTopSideText}>
            {`Tel: ${phone}`}
          </Text>
        ))}
        {resume.basics?.emails?.map((email) => (
          <Text key={email} style={styles.headerTopSideText}>
            <Link style={styles.link} key={email} href={`mailto:${email}`}>
              {`Email: ${email}`}
            </Link>
          </Text>
        ))}
        <Text style={styles.headerTopSideText}>
          <Link
            style={styles.link}
            key={resume.basics.url}
            href={`https://www.${resume.basics.url && (trimUrlPrefix(resume.basics.url))}`}
          >
            {`LinkedIn: ${resume.basics.url && (trimUrlPrefix(resume.basics.url))}`}
          </Link>
        </Text>
      </View>
      <View style={styles.block}>
        <View style={styles.blockWithBorder}>
          <View style={styles.EducationblockTitle}>
            <Text style={styles.blockTitleText}>Education</Text>
          </View>
        </View>
        {resume.education?.map((item) => (
          <View key={item.institution} style={styles.educationBlock}>
            <View style={styles.row}>
              <View style={styles.Edinstitution}>
                <Text>{`${item.institution}, `}</Text>
                <Text>{item.location}</Text>
                <Text style={styles.Area}>{item.area}</Text>
                <Text style={styles.EdDates}>
                  {formatDateToYear(item.startDate)}
                  {' '}
                  -
                  {' '}
                  {item.endDate === 'Present'
                    ? new Date().getFullYear().toString()
                    : formatDateToYear(item.endDate)}
                </Text>
              </View>
            </View>
            {item.description
            && item.description.map((desc) => (
              <View key={desc} style={styles.EdRow}>
                <Text style={styles.bulletPoint}>•</Text>
                <Text style={styles.EdDescription}>{desc}</Text>
              </View>
            ))}
          </View>
        ))}
      </View>
      <View style={styles.block}>
        <View style={styles.blockWithBorder}>
          <View style={styles.WorkblockTitle}>
            <Text style={styles.blockTitleText}>Professional Experience</Text>
          </View>
        </View>
        {resume.work?.map((item, index) => {
          const showWorkName = item?.name !== previousWorkName;
          previousWorkName = item?.name as string;

          return (
            <View key={`${item.name}-${index}`} style={styles.WorkBlock}>
              {showWorkName && (
                <View style={styles.workRow}>
                  <Text style={styles.workInstitution}>{`${item.name}, `}</Text>
                  <Text style={styles.workLocation}>{item.location}</Text>
                  <Text style={styles.workDates}>
                    {formatDate(item.startDate)}
                    {' '}
                    -
                    {' '}
                    {item.endDate === 'Present'
                      ? item.endDate
                      : formatDate(item.endDate)}
                  </Text>
                </View>
              )}
              <View style={styles.row}>
                <Text
                  style={item.summary ? styles.summary : styles.workPosition}
                >
                  {showWorkName && item.summary && item.summary}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.workPosition}>{item.position}</Text>
                {!showWorkName && (
                  <Text style={styles.workDates}>
                    {formatDate(item.startDate)}
                    {' '}
                    -
                    {' '}
                    {item.endDate === 'Present'
                      ? item.endDate
                      : formatDate(item.endDate)}
                  </Text>
                )}
              </View>
              {item.highlights && item.highlights.length > 0 && (
                <View style={styles.highlights}>
                  {item.highlights.map((highlight, highlightIndex) => {
                    const cleanedHighlight = highlight.replace(/^•\s/, '');

                    return (
                      <View
                        key={`${highlight}-${highlightIndex}`}
                        style={styles.listItem}
                        wrap={false}
                      >
                        <Text style={styles.bulletPoint}>•</Text>
                        <View style={styles.highlightText}>
                          {renderWorkHighlightedText(
                            cleanedHighlight,
                            highlightIndex,
                            index,
                            highlightedWords,
                            activeField,
                            styles,
                          )}
                        </View>
                      </View>
                    );
                  })}
                </View>
              )}
            </View>
          );
        })}
      </View>
      <View style={styles.block}>
        <View style={styles.blockWithBorder}>
          <View style={styles.LanguagesBlockTitle}>
            <Text style={styles.blockTitleText}>Additional Information</Text>
          </View>
        </View>
        <View style={styles.WorkBlock}>
          <View style={styles.languages}>
            <View
              style={computeSkillsStyle(highlightedWords, activeField, styles)}
            >
              <View style={styles.languagesText}>
                <Text style={styles.bold}>IT Skills:</Text>
                <Text>
                  {resume.skills
                    ?.map((skill) => `${skill.name} (${skill.level})`)
                    .join(', ')}
                </Text>
              </View>
            </View>
            <View style={styles.languagesText}>
              <Text style={styles.bold}>Languages:</Text>
              <Text>
                {resume.languages
                  ?.map(
                    (language) => `${language.language} (${language.fluency})`,
                  )
                  .join(', ')}
              </Text>
            </View>
            {/* {resume.additional_information.bullets?.length > 0 && (
              <View style={styles.AdditionalInfo}>
                <Text style={styles.bold}>Achievements and Interests:</Text>
                {resume.additional_information.bullets?.map(
                  (bullet, bulletIndex) => bullet.replace(/^•\s/, '') && (
                    <View
                      key={`${bullet}-${bulletIndex}`}
                      style={styles.AdditionalBlock}
                      wrap={false}
                    >
                      <Text style={styles.bulletPoint}>•</Text>
                      <View style={styles.highlightText}>
                        {renderAdditionalHighlightedText(
                          bullet.replace(/^•\s/, ''),
                          bulletIndex,
                          highlightedWords,
                          activeField,
                          styles,
                        )}
                      </View>
                    </View>
                  ),
                )}
              </View>
            )} */}

            {resume.additional_information.order && resume.additional_information.order.map((section) => (section.type === 'heading' ? (
              <Text style={styles.sectionHeaderWithMargin}>
                {`${section.value}: `}
              </Text>
            ) : (
              <View style={styles.normalTextSmall}>
                <Text style={styles.bulletPoint}>•</Text>
                <Text>{section.value}</Text>
              </View>
            )))}
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

export default BayesTemplate;
