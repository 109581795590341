import { FilterOption } from '../../company/types/company';
import {
  contractStaticOptions,
  postedDateStaticOptions,
  workTypeStaticOptions,
} from '../components/job-filters/constants';
import { JobsFilterState } from '../types/job';

export const convertURLParamsToFilters = (
  filters: JobsFilterState,
  searchParams: URLSearchParams,
  staticOptions: { industriesOptions: FilterOption[], sizesOptions: FilterOption[], jobsLanguagesOptions: FilterOption[] },
) => {
  const newFilters = { ...filters };
  const { industriesOptions, sizesOptions, jobsLanguagesOptions } = staticOptions;

  const mapOptions = (values: string[], options: FilterOption[]) => values
    .flatMap((value) => value.split(','))
    .map((value) => options.find((option) => option.value === decodeURIComponent(value)))
    .filter(Boolean) as FilterOption[];

  const mapLocationOptions = (locationStrings: string[]) => locationStrings
    .flatMap((locationString) => {
      try {
        const parsed = JSON.parse(locationString);
        const locations = Array.isArray(parsed) ? parsed : [parsed];
        return locations.map((loc) => {
          const parsedLoc = JSON.parse(loc);
          const name = [parsedLoc.city, parsedLoc.state, parsedLoc.country]
            .filter(Boolean)
            .join(', ');
          return { name, value: JSON.stringify(parsedLoc) };
        });
      } catch (error) {
        return [];
      }
    })
    .filter(
      (loc) => loc !== null && typeof loc === 'object',
    ) as FilterOption[];

  const dateRange = searchParams.getAll('date_range');
  const workTypes = searchParams.getAll('work_modes');
  const contractTypes = searchParams.getAll('job_types');
  const selectedIndustries = searchParams.getAll('industries');
  const selectedSizes = searchParams.getAll('sizes');
  const selectedLocations = searchParams.getAll('locations');
  const selectedLanguages = searchParams.getAll('languages');

  if (dateRange.length > 0) {
    newFilters.date_range = mapOptions(dateRange, postedDateStaticOptions);
  }

  if (workTypes.length > 0) {
    newFilters.work_modes = mapOptions(workTypes, workTypeStaticOptions);
  }

  if (contractTypes.length > 0) {
    newFilters.job_types = mapOptions(contractTypes, contractStaticOptions);
  }

  if (selectedIndustries.length > 0) {
    newFilters.industries = mapOptions(selectedIndustries, industriesOptions);
  }

  if (selectedSizes.length > 0) {
    newFilters.sizes = mapOptions(selectedSizes, sizesOptions);
  }

  if (selectedLocations.length > 0) {
    newFilters.locations = mapLocationOptions(selectedLocations);
  }

  if (selectedLanguages.length > 0) {
    newFilters.languages = mapOptions(selectedLanguages, jobsLanguagesOptions);
  }

  return newFilters;
};

export const updateSearchParamsWithNewFilters = (
  name: string,
  value: FilterOption[] | boolean | string,
) => {
  const newSearchParams = new URLSearchParams(window.location.search);
  newSearchParams.delete(name);

  if (typeof value === 'boolean' || typeof value === 'string') {
    newSearchParams.append(name, value.toString());
  } else if (name === 'locations') {
    newSearchParams.append(name, JSON.stringify(value.flatMap((v) => v.value)));
  } else if (value.length > 0) {
    newSearchParams.append(
      name,
      value.map((option) => option.value).join(','),
    );
  }

  window.history.replaceState(null, '', `?${newSearchParams.toString()}`);
};
