import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Student } from '@/domains/core/advisor/types';
import { useSelfUniversity } from '../queries/user';
import {
  useGetAllStudents,
  useGetAllStudentsResumes,
  useGetCohortsByUniversityID,
} from '../queries/student';

type FetchType = 'students' | 'resumes';

export const useStudentsByUniversity = (fetchType: FetchType, universityID?: string) => {
  const { data: university } = useSelfUniversity();
  const currentUniversityID = universityID || university?.id || '';
  const { cohortID } = useParams();

  const { data: cohorts, isLoading: isCohortsLoading } = useGetCohortsByUniversityID(currentUniversityID, {
    enabled: !!currentUniversityID,
  });

  const queryFn = fetchType === 'resumes' ? useGetAllStudentsResumes : useGetAllStudents;

  const { data: allStudentsResponse, isLoading: isStudentsLoading } = queryFn(
    currentUniversityID,
    { enabled: !!currentUniversityID },
  );

  const filteredStudentsByCohort = useMemo(
    () => (Array.isArray(allStudentsResponse)
      ? allStudentsResponse.filter((student: Student) => student?.cohort_id === cohortID)
      : []),
    [allStudentsResponse, cohortID],
  );

  const data = useMemo(() => {
    if (!Array.isArray(allStudentsResponse)) return [];
    return cohortID ? filteredStudentsByCohort : allStudentsResponse;
  }, [allStudentsResponse, cohortID, filteredStudentsByCohort]);

  return {
    data,
    cohorts,
    isCohortsLoading,
    isStudentsLoading,
  };
};
