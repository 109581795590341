/* eslint-disable @typescript-eslint/no-unused-vars */
export function formatDate(dateString: string | undefined): string {
  if (!dateString) {
    return 'N/A';
  }

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const [month, year] = dateString.split('/');
  const monthIndex = parseInt(month, 10) - 1;

  return `${months[monthIndex]} ${year}`;
}

export function formatDateYearOnly(dateString: string | undefined): string {
  if (!dateString) {
    return 'N/A';
  }
  const [month, year] = dateString.split('/');
  const fullYear = 2000 + parseInt(year, 10);
  return fullYear.toString();
}
