import { User } from '@auth0/auth0-react';
import { useContext, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import * as Sentry from '@sentry/react';
import './tracker-job-tab.scss';
import { Button } from '@careeros/coco';
import { ModalContext } from '@/components/modal/modal-provider';
import { InfoModal } from '@/domains/generic/modals';
import { Job } from '../../types';
import { useAnalytics } from '@/services/hooks/use-analytics';
import {
  addJob, applyForJob, saveJob, unsaveJob,
} from '@/services/api/job';
import { ManuallyAddJob } from '../manual-job-add/manual-add-job';
import { JobTabStates } from '@/domains/core/tracker-data';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import { CompanyJobs, CompanyJobsEmptyState } from '@/domains/core/job';
import { useJobsByCompany } from '@/services/queries/jobs';
import { getDateDeltaFromNowInDays } from '@/services/helpers/date';

type Props = {
  user?: User;
  companyName: string;
  companyId: string;
  companyLinkedIn: string;
  companyLogo: string;
  changeTab: (tab: string, state?: string) => void;
  tabState?: JobTabStates;
  revalidate: () => void;
  setTotalJobsCount: (count: number) => void;
  updateUnreadIndicator: (unread: boolean) => void;
};

export const TrackerJobTab = ({
  user,
  companyName,
  companyId,
  companyLinkedIn,
  companyLogo,
  changeTab,
  revalidate,
  tabState,
  setTotalJobsCount,
  updateUnreadIndicator,
}: Props) => {
  const { data: jobs, isLoading, isFetching } = useJobsByCompany(companyId);
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const { trackEvent } = useAnalytics();
  const { addToast }: AddToastType = useContext(ToastContext);
  const queryClient = useQueryClient();

  const handleAddJobManuallyClick = () => {
    changeTab('Jobs', 'addJobManually');
  };

  const revalidateJobs = () => {
    queryClient.invalidateQueries(['jobs-by-company', companyId]);
    queryClient.invalidateQueries(['applications-no-cache']);
    queryClient.invalidateQueries(['company', companyId]);
  };

  const checkTabUnread = () => {
    if (!jobs) {
      return false;
    }

    if (!jobs.length) {
      return true;
    }

    const hasJobWithApproachingDeadline = jobs.some((job) => {
      if (!job.saved || !job.deadline || job.rolling_date) {
        return false;
      }

      const dateDelta = getDateDeltaFromNowInDays(job.deadline);

      return dateDelta <= 7 && dateDelta >= 0;
    });

    if (hasJobWithApproachingDeadline) {
      return true;
    }

    if (!jobs.some((job) => job.saved)) {
      return true;
    }

    return false;
  };

  const handleAddJob = async (job: Omit<Job, 'id'>) => {
    try {
      const response = await addJob(job);

      if (response.error) {
        throw new Error(response.error);
      }

      revalidate();

      revalidateJobs();
      changeTab('Jobs');
      trackEvent('Tracker page: Saved job manually');
      addToast(
        {
          type: 'success',
          message: 'Job added successfully',
          additionalMessage: 'This job is now saved to your Overview Board.',
        },
      );
    } catch (error) {
      addToast(
        {
          type: 'error',
          message: 'Failed to add a job',
          additionalMessage: "We're looking into the issue. Please try again later.",
        },
      );
      trackEvent('Toast Error Shown', user, {
        message: 'Failed to add a job',
        error,
      });
    }
  };

  const closeAddJob = () => {
    changeTab('Jobs');
  };

  const confirmUnsaveJob = async (rememberAction: boolean, jobID: string) => {
    if (rememberAction) {
      localStorage.setItem('rememberUnsaveUser', 'true');
    }
    try {
      await unsaveJob(jobID);

      trackEvent('Job unsaved on Tracker Page', {
        job_id: jobID,
      });

      revalidateJobs();
    } catch (err) {
      Sentry.captureException(err);
    }
    closeModal();
  };

  const handleUnsave = async (jobID: string) => {
    const rememberAction = localStorage.getItem('rememberUnsaveUser');
    if (rememberAction) {
      confirmUnsaveJob(true, jobID);
    } else {
      openModal(
        <InfoModal
          icon="bi bi-sign-stop"
          title="Unsave Job"
          description="Once you unsave this job, it will be gone for good. Please be aware that you may not find this job posting again afterwards."
          buttonLabel="Yes, I want to unsave"
          handleButtonClick={(rememberAnswer: boolean) => confirmUnsaveJob(rememberAnswer, jobID)}
          secondaryButtonLabel="Keep this job posting"
          handleSecondaryButtonClick={closeModal}
          rememberText="Don't ask this again"
          isImageDanger
        />,
      );
    }
  };

  const handleSave = async (jobID: string) => {
    try {
      await saveJob(jobID);

      trackEvent('Job saved on Tracker Page', {
        job_id: jobID,
      });

      revalidateJobs();
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const handleApplyToJob = async (jobID: string, applied: boolean) => {
    try {
      await saveJob(jobID);
      await applyForJob(jobID, applied);

      closeModal();
      await revalidateJobs();
      trackEvent('Applied to job from Tracker Page', {
        job_id: jobID,
      });
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const openJobLink = (url: string, jobID: string) => {
    if (url) {
      const applicationURL = new URL(url);
      applicationURL.searchParams.append('utm_source', 'CareerOS');

      window.open(applicationURL, '_blank');
    }

    openModal(
      <InfoModal
        icon="bi bi-send"
        title="Did you apply?"
        description="Just let us know, and we'll help you keep tabs on your application!"
        buttonLabel="Yes, I did! 🤩"
        handleButtonClick={() => handleApplyToJob(jobID, true)}
        secondaryButtonLabel="No, not yet 🙈"
        handleSecondaryButtonClick={() => handleApplyToJob(jobID, false)}
      />,
    );
  };

  const handleJobSaveToggle = async (jobId: string, save: boolean) => {
    if (save) {
      handleSave(jobId);
    } else {
      trackEvent('Job unsaved on Tracker Page', {
        job_id: jobId,
      });
      handleUnsave(jobId);
    }
  };

  useEffect(() => {
    revalidate();
  }, []);

  useEffect(() => {
    if (!isFetching && !isLoading) {
      setTotalJobsCount(jobs?.length || 0);
      updateUnreadIndicator(checkTabUnread());
    }
  }, [jobs, isFetching, isLoading]);

  useEffect(() => {
    const newSearchParams = new URLSearchParams(window.location.search);

    if (newSearchParams.has('addJobManually') && newSearchParams.get('addJobManually') === 'true') {
      changeTab('Jobs', 'addJobManually');
    }
  }, []);

  if (tabState === 'addJobManually') {
    return (
      <div className="jobs-tab">
        <div className="jobs-tab__back-button">
          <Button
            mode="invisible"
            size="medium"
            label="Back"
            icon="bi bi-arrow-left"
            handleClick={closeAddJob}
          />
        </div>

        <div className="white-bordered-box">
          <ManuallyAddJob
            handleJobAdd={handleAddJob}
            companyName={companyName}
            companyID={companyId}
          />
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="jobs-tab__loader-container">
        <div className="job-tabs__loader" />
        <p className="jobs-tab__loader-text">
          Loading jobs...
        </p>
      </div>
    );
  }

  if (!Array.isArray(jobs) || !jobs || jobs.length === 0) {
    return (
      <CompanyJobsEmptyState
        companyLinkedIn={companyLinkedIn}
        handleAddJobClick={handleAddJobManuallyClick}
        companyName={companyName}
      />
    );
  }

  return (
    <div className="jobs-tab">
      <CompanyJobs
        companyName={companyName}
        jobs={jobs}
        companyLogo={companyLogo}
        handleSaveAction={handleJobSaveToggle}
        handleAppliedAction={handleApplyToJob}
        revalidateJobs={revalidateJobs}
        handleApplyClick={openJobLink}
        isApplicationActive
        openAddManually={handleAddJobManuallyClick}
        companyLinkedIn={companyLinkedIn}
      />
    </div>
  );
};
