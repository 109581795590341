import { DefaultLogo } from '@/components/default-logo/default-logo';
import { Column, TableRow } from '@/components/table/table-types';
import { CompanyRating } from '@/domains/core/company';
import { Comment, Tile } from '@/domains/core/company/types';
import { TileActions } from '../../kanban/tile-actions/tile-actions';
import { APIstatuses } from '@/domains/core/tracker-data';

export const ApplicationsTableColumns: Column[] = [
  {
    label: 'Company', accessor: 'name', sortable: true, type: 'link', sortbyOrder: 'asc', isCentered: false,
  },
  {
    label: 'Status', accessor: 'status', sortable: true, type: 'text', isCentered: false,
  },
  {
    label: 'Industry', accessor: 'industry', type: 'text', sortable: true, isCentered: false,
  },
  {
    label: 'Hype Level', accessor: 'hype_level', type: 'text', sortable: true, isCentered: false,
  },
  {
    label: 'Actions', accessor: 'actions', type: 'actions', sortable: false, isCentered: false,
  },
];

type ApplicationMapHandler = (
  tiles: Tile[],
  additionalData: {
    openTracker: (id: string, tab?: string | undefined, additionalParams?: { [key: string]: string }) => void,
    handleCompanyRate: (id: string, rate: number) => Promise<void>,
    userID: string,
    comments: Comment[],
    readOnly?: boolean,
  }
) => TableRow[];

export const mapApplications: ApplicationMapHandler = (tiles, {
  openTracker, handleCompanyRate, userID, comments, readOnly = false,
}) => (Array.isArray(tiles) ? tiles.map((tile) => ({
  id: tile.id,
  handleRowClick: () => openTracker(tile.company_id),
  items: {
    name: {
      value: tile.company_name,
      label: tile.company_name,
      image: <DefaultLogo
        type="contact"
        source={tile.logo_url}
        className="applications-table__image"
        name={tile.company_name}
        size={24}
      />,
      type: 'link',
      handleClick: () => openTracker(encodeURIComponent(tile.company_id)),
    },
    status: {
      value: tile.status,
      label: APIstatuses[tile.status],
      type: 'text',
    },
    industry: {
      type: 'text',
      label: tile.industry,
      value: tile.industry,
    },
    hype_level: {
      type: 'icon',
      label: (
        <div className="applications-table__rating">
          <CompanyRating readOnly={readOnly} handleCompanyRate={(rate) => handleCompanyRate(tile.id, rate)} currentRate={tile.rating} />
        </div>
      ),
      value: tile.rating,
    },
    actions: {
      label: (
        <TileActions
          readOnly={readOnly}
          companyID={tile.company_id}
          companyName={tile.company_name}
          comments={comments.filter((comment: Comment) => comment.application.id === tile.id)}
          studentID={userID}
          tileID={tile.id}
          countOfContacts={tile.count_of_contacts}
          tileOrdering={tile.ordering}
          withAddComment={readOnly}
        />
      ),
      value: tile.id,
    },
  },
})) : []);
