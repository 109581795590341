import './chat-box-loader.scss';

export const ChatBoxLoader = () => (
  <div className="chat-box">
    <div className="chat-box__header chat-box-header">
      <div className="chat-box-header__info">
        <h2 className="chat-box-header__name">
          <div className="chat-box-skeleton__text chat-box-skeleton__text--title" />
        </h2>
        <div className="chat-box-skeleton__split" />
        <h3 className="chat-box__position">
          <div className="chat-box-skeleton__text chat-box-skeleton__text--subtitle" />
        </h3>
      </div>
      <div className="chat-box__actions" />
    </div>
    <div className="chat-box__content" />
    <div className="chat-box__footer" />
  </div>
);
