import { Contact, ContactApiStatus } from '../../contact/types';

export const contactKanbanStatuses: { [key: string]: ContactApiStatus } = {
  'Contact Saved': 'saved',
  '1st Outreach': '1st-outreach',
  '2nd Outreach': '2nd-outreach',
  'Response Received': 'response-received',
  'Coffee Chat': 'coffee-chat',
  Nurturing: 'nurturing',
  'Golden Referral': 'golden-referral',
  Archived: 'archive',
};

export const contactKanbanColumns = Object.keys(contactKanbanStatuses);

export type ContactStatus = typeof contactKanbanColumns[number];

const flip = (data: typeof contactKanbanStatuses) => Object.fromEntries(
  Object
    .entries(data)
    .map(([key, value]) => [value, key]),
);

export const contactAPIstatuses = flip(contactKanbanStatuses);

const sortByPosition = (unsortedTiles: Contact[]): Contact[] => unsortedTiles.sort((a, b) => {
  if (a.kanban_position.row > b.kanban_position.row) {
    return 1;
  } if (a.kanban_position.row < b.kanban_position.row) {
    return -1;
  }
  return 1;
});

export const getContactsKanbanLayout = (contacts: Contact[]) => {
  const layoutResult = contactKanbanColumns.reduce((acc, column) => {
    acc[column] = sortByPosition(
      contacts.filter((contact) => contact.kanban_position.status === contactKanbanStatuses[column]),
    );

    return acc;
  }, {} as { [key: typeof contactKanbanColumns[number]]: Contact[] });

  return layoutResult;
};

export const presavedContactNames = [
  'Marcus Castro',
  'Maximilian Stengel',
  'Franz Schürmann',
];

export const filterPresavedContacts = (contactsToFilter?: Contact[]) => {
  if (!contactsToFilter || !Array.isArray(contactsToFilter)) {
    return [];
  }

  return contactsToFilter.filter((contact) => !presavedContactNames.includes(`${contact.first_name} ${contact.last_name}`));
};
