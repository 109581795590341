import { useMemo } from 'react';
import { JobDeadline } from '../job-deadline/job-deadline';
import './job-applivation-info.scss';
import { DidYouApply } from '../did-you-apply/did-you-apply';
import { AppliedBadge } from '../applied-badge/applied-badge';

type Props = {
  applicationDeadline: string;
  isDateRolling: boolean;
  handleAppliedResponse: (didApply: boolean) => void;
  jobID: string;
  showDidYouApply: boolean;
  isApplied: boolean;
  revalidateApplicationDeadline: (deadline: string | null, isRolling: boolean) => void;
};

export const JobApplicationInfo = ({
  applicationDeadline,
  isDateRolling,
  handleAppliedResponse,
  jobID,
  showDidYouApply,
  isApplied,
  revalidateApplicationDeadline,
}: Props) => {
  const formatDate = (date: string) => new Date(date).toLocaleDateString('en-US', { month: 'short', year: 'numeric', day: 'numeric' });
  const applicationDeadlineText = useMemo(() => (applicationDeadline && !isDateRolling
    ? formatDate(applicationDeadline)
    : 'Rolling'), [applicationDeadline, isDateRolling]);

  return (
    <div className="job-application-info">
      {!isApplied && (
        <JobDeadline
          applicationDeadlineText={applicationDeadlineText}
          applicationDeadline={applicationDeadline}
          isDateRolling={isDateRolling}
          revalidate={revalidateApplicationDeadline}
          jobID={jobID}
        />
      )}

      {(showDidYouApply && !isApplied) && (
        <DidYouApply
          handleYesClick={() => handleAppliedResponse(true)}
          handleNoClick={() => handleAppliedResponse(false)}
        />
      )}

      {isApplied && (
        <AppliedBadge handleClick={() => handleAppliedResponse(false)} />
      )}
    </div>
  );
};
