import { useState } from 'react';
import { Tile } from '@/domains/core/company/types';
import { CompanyTile } from '../company-tile/company-tile';
import { useScroll } from '@/services/hooks/use-scroll';
import './company-kanban-board.scss';
import { IconButton } from '@/components/icon-button/icon-button';

interface CompanyKanbanBoardProps {
  columns: string[];
  layout: any;
}

/**
 * Primary UI component for the kanban board
 */

// const COLUMN_SIZE = 385;
// const COLUMN_COLUMN_GAP = 20;
// const COLUMN_IN_VIEW = 5;

export const CompanyKanbanBoard = ({
  columns,
  layout,
}: CompanyKanbanBoardProps) => {
  const [kanbanContainer, handleVerticalScroll] = useScroll();
  const [showRightScrollArrow, setShowRightScrollArrow] = useState(true);
  const [showLeftScrollArrow, setShowLeftScrollArrow] = useState(false);

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const el = e.currentTarget;
    const allColumnsWidth = kanbanContainer.current.scrollWidth;
    const columnsInViewWidth = kanbanContainer.current.clientWidth;

    if (Math.round(el.scrollLeft + columnsInViewWidth) >= Math.round(allColumnsWidth)) {
      setShowRightScrollArrow(false);
    } else {
      setShowRightScrollArrow(true);
    }
    if (el.scrollLeft === 0) {
      setShowLeftScrollArrow(false);
    } else {
      setShowLeftScrollArrow(true);
    }
  };

  return (
    <div className="company-kanban-board">
      <div className="company-kanban-board__controls-wrapper">
        <div className="company-kanban-board__controls">
          <IconButton
            icon="bi bi-chevron-left"
            mode="rounded"
            size="medium"
            outlined
            handleClick={() => handleVerticalScroll('left')}
            disabled={!showLeftScrollArrow}
          />
          <IconButton
            icon="bi bi-chevron-right"
            mode="rounded"
            size="medium"
            outlined
            handleClick={() => handleVerticalScroll('right')}
            disabled={!showRightScrollArrow}
          />
        </div>
      </div>
      <div className="company-kanban-board__content" ref={kanbanContainer} onScroll={handleScroll}>
        {columns.map((column, index) => (
          <div
            key={column}
            className={`company-kanban-board__column company-kanban-board__column--nr-${index}`}
          >
            <div className="company-kanban-board__column-title">
              <span className="company-kanban-board__column-title-square" />
              <span className="company-kanban-board__column-title-text">
                {' '}
                {column}
              </span>
              <span className="company-kanban-board__column-title-number">
                {layout[column] && layout[column].length}
              </span>
            </div>

            <div
              className="company-kanban-board__column-drop-zone"
            >
              {layout[column]
                && layout[column].map((tile: Tile) => (
                  <CompanyTile key={tile.id} tile={tile} isReadOnly />
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
