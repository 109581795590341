import { AddJobButton } from '../../../company/components/add-job-button/add-job-button';
import BreifcaseImage from '@/assets/images/breifcase.png';
import './company-jobs-empty-state.scss';

type Props = {
  companyLinkedIn: string;
  handleAddJobClick: () => void;
  companyName: string;
  withoutSaveManually?: boolean;
};

export const CompanyJobsEmptyState = ({
  companyLinkedIn,
  handleAddJobClick,
  companyName,
  withoutSaveManually = false,
}: Props) => (
  <div className="tracker-jobs-empty-state">
    <img className="tracker-jobs-empty-state__image" src={BreifcaseImage} alt="" />
    <div className="tracker-jobs-empty-state__text">
      <p className="tracker-jobs-empty-state__text tracker-jobs-empty-state__text--title">
        {`No job postings for ${companyName} were found on CareerOS.`}
        <br />
        Don’t worry.
      </p>
      <br />
      <p className="tracker-jobs-empty-state__text tracker-jobs-empty-state__text--description">
        {`Our Chrome Extension allows you to save ${companyName} job postings from other job boards.`}
      </p>
    </div>
    <div className="tracker-jobs-empty-state__button">
      <AddJobButton
        buttonLabel="Save Job"
        onAddManuallyClick={handleAddJobClick}
        listPosition="left"
        companyLinkedIn={companyLinkedIn}
        icon="bi bi-plus-circle"
        mode="primary"
        withSaveManually={!withoutSaveManually}
        isOutlined
        size="medium"
        isFullWidth
        isRelative
      />
    </div>
  </div>
);
