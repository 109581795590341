import { useContext, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useQueryClient } from 'react-query';
import {
  addCompanyToFavorites,
  TrackerContext,
  TrackerContextType,
} from '@/domains/core/company';
import { CompanyJobs, CompanyJobsEmptyState } from '@/domains/core/job';
import './basic-company-jobs-tab.scss';
import { useJobsByCompany } from '@/services/queries/jobs';
import { ModalContext } from '@/components/modal/modal-provider';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { applyForJob, saveJob, unsaveJob } from '@/services/api/job';
import { InfoModal } from '@/domains/generic/modals';
import { Loader } from '@/components/loader/loader';

type Props = {
  companyID: string;
  isCompanyApplicationActive: boolean;
  companyName: string;
  companyLogo: string;
  companyLinkedIn: string;
  updateTabCount: (count: number) => void;
};

export const BasicCompanyJobsTab = ({
  companyID,
  isCompanyApplicationActive,
  companyName,
  companyLogo,
  companyLinkedIn,
  updateTabCount,
}: Props) => {
  const { data: jobs, isLoading, isFetching } = useJobsByCompany(companyID, { enabled: !!companyID });
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const { trackEvent } = useAnalytics();
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;
  const queryClient = useQueryClient();

  const handleManuallyAddJob = () => {
    openTracker(companyID, 'Jobs', { addJobManually: 'true' });
  };

  const revalidateJobs = () => {
    queryClient.invalidateQueries(['jobs-by-company', companyID]);
    queryClient.invalidateQueries(['applications-no-cache']);
    queryClient.invalidateQueries(['company', companyID]);
  };

  const confirmUnsaveJob = async (rememberAction: boolean, jobID: string) => {
    if (rememberAction) {
      localStorage.setItem('rememberUnsaveUser', 'true');
    }
    try {
      await unsaveJob(jobID);

      trackEvent('Job unsaved on Tracker Page', {
        job_id: jobID,
      });

      revalidateJobs();
    } catch (err) {
      Sentry.captureException(err);
    }
    closeModal();
  };

  const handleUnsave = async (jobID: string) => {
    const rememberAction = localStorage.getItem('rememberUnsaveUser');
    if (rememberAction) {
      confirmUnsaveJob(true, jobID);
    } else {
      openModal(
        <InfoModal
          icon="bi bi-sign-stop"
          title="Unsave Job"
          description="Once you unsave this job, it will be gone for good. Please be aware that you may not find this job posting again afterwards."
          buttonLabel="Yes, I want to unsave"
          handleButtonClick={(rememberAnswer: boolean) => confirmUnsaveJob(rememberAnswer, jobID)}
          secondaryButtonLabel="Keep this job posting"
          handleSecondaryButtonClick={closeModal}
          rememberText="Don't ask this again"
          isImageDanger
        />,
      );
    }
  };

  const handleSave = async (jobID: string) => {
    try {
      await saveJob(jobID);

      trackEvent('Job saved on Tracker Page', {
        job_id: jobID,
      });

      revalidateJobs();
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const rememberSkipModal = (rememberAction: boolean) => {
    if (rememberAction) {
      localStorage.setItem('rememberApplyUser', 'true');
    }
  };

  const handleApplyToJob = async (jobID: string, applied: boolean) => {
    try {
      await saveJob(jobID);
      await applyForJob(jobID, applied);

      closeModal();
      revalidateJobs();
      trackEvent('Applied to job from Company Profile', {
        job_id: jobID,
      });
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const handleOpenJobLink = (url: string, jobID: string) => {
    const applicationURL = new URL(url);
    applicationURL.searchParams.append('utm_source', 'CareerOS');

    window.open(applicationURL, '_blank');

    openModal(
      <InfoModal
        icon="bi bi-send"
        title="Did you apply?"
        description="Just let us know, and we'll help you keep tabs on your application!"
        buttonLabel="Yes, I did! 🤩"
        handleButtonClick={() => handleApplyToJob(jobID, true)}
        secondaryButtonLabel="No, not yet 🙈"
        handleSecondaryButtonClick={() => handleApplyToJob(jobID, false)}
      />,
    );
  };

  const confirmApply = (rememberAction: boolean, url: string, jobID: string) => {
    closeModal();
    rememberSkipModal(rememberAction);
    handleOpenJobLink(url, jobID);
  };

  const handleHelpNetwork = async () => {
    await addCompanyToFavorites(companyID);
    await queryClient.invalidateQueries(['company', companyID]);
    openTracker(companyID);

    closeModal();
    trackEvent('Help me network clicked');
  };

  const beforeOpenJobLink = async (url: string, jobID: string) => {
    try {
      if (isCompanyApplicationActive) {
        openModal(
          <InfoModal
            icon="bi bi-sign-stop"
            title="Are you sure?"
            description="You have not networked with this company, yet. Are you sure you want to apply?"
            buttonLabel="Yes, I’m sure"
            handleButtonClick={(remember) => confirmApply(remember, url, jobID)}
            secondaryButtonLabel="Help me network"
            handleSecondaryButtonClick={handleHelpNetwork}
            rememberText="Don't show this again"
          />,
        );

        return;
      }

      handleOpenJobLink(url, jobID);
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const handleJobSaveToggle = async (jobId: string, save: boolean) => {
    if (save) {
      handleSave(jobId);
    } else {
      trackEvent('Job unsaved on Tracker Page', {
        job_id: jobId,
      });
      handleUnsave(jobId);
    }
  };

  useEffect(() => {
    if (!isLoading && !isFetching && jobs) {
      updateTabCount(jobs.length || 0);
    }
  }, [jobs, isLoading, isFetching]);

  if (isLoading) {
    return (
      <Loader />
    );
  }

  return (
    <div className="basic-company-jobs">
      {(!jobs || jobs.length === 0) ? (
        <CompanyJobsEmptyState
          companyLinkedIn={companyLinkedIn}
          handleAddJobClick={handleManuallyAddJob}
          companyName={companyName}
          withoutSaveManually={!isCompanyApplicationActive}
        />
      ) : (
        <CompanyJobs
          companyName={companyName}
          jobs={jobs}
          companyLogo={companyLogo}
          handleSaveAction={handleJobSaveToggle}
          handleAppliedAction={handleApplyToJob}
          handleApplyClick={beforeOpenJobLink}
          revalidateJobs={revalidateJobs}
          openAddManually={handleManuallyAddJob}
          isApplicationActive={isCompanyApplicationActive}
          companyLinkedIn={companyLinkedIn}
        />
      )}
    </div>
  );
};
