import { changeQuestStatus, getQuests } from '@/services/api/student';

export enum QUESTSTATUS {
  TODO = 'todo',
  WONTDO = 'wontdo',
  DONE = 'done',
}

export const checkForQuestCompletion = async (contactID: string) => {
  const response = await getQuests();
  const responseQuests = response.quests ?? [];
  const messagesQuestForContact = responseQuests.find((quest: any) => quest.contact && quest.contact?.id === contactID && (quest.type.includes('message') || quest.type.includes('outreach') || quest.type.includes('connection')));
  if (messagesQuestForContact) {
    await changeQuestStatus(messagesQuestForContact.id, QUESTSTATUS.DONE, messagesQuestForContact.type);
  }
};
