import { useEffect, useState } from 'react';
import './tracker-tips-tab.scss';
import { Button } from '@careeros/coco';
import { TrackerContent } from '../tracker-content/tracker-content';
import { useTabs } from '@/components/tabs/TabsContext';
import { TrackerTip } from '@/domains/generic/modals';
import { TaskAction } from '@/domains/core/tracker-data';
import { useAnalytics } from '@/services/hooks/use-analytics';

type Props = {
  applicationId: string;
  activeState: any;
  status: any;
  tasks: any[];
  university?: string;
  changeTab: (tab: string, state?: string, setTabAsCurrent?: boolean) => void;
  tabState?: 'tips';
  handleRejection: () => void;
};

export const TrackerTipsTab = ({
  activeState,
  applicationId,
  status,
  tasks,
  university,
  changeTab,
  tabState,
  handleRejection,
}: Props) => {
  const { setCurrentTab } = useTabs();
  const { trackEvent } = useAnalytics();
  const [targetTip, setTargetTip] = useState<string>();

  const handleActionClick = (action: TaskAction) => {
    if (action.type === 'openTab') {
      setCurrentTab(action.target);
    }

    if (action.type === 'openTip') {
      setTargetTip(action.target);
      // setIsTipsOpen(true);
      changeTab('Tips & Tricks', 'tips');
    }

    if (action.type === 'useAITool') {
      changeTab('AI Tools', action.target, true);
    }

    trackEvent('Tips & Tricks: Tip clicked');
  };

  const closeTips = () => {
    // setIsTipsOpen(false);
    changeTab('Tips & Tricks', undefined);
    setTargetTip(undefined);
  };

  useEffect(() => {
    // setIsTipsOpen(false);
    changeTab('Tips & Tricks', undefined);
  }, [status]);

  if (tabState === 'tips') {
    return (
      <div className="jobs-tab">
        <div className="jobs-tab__back-button">
          <Button
            mode="invisible"
            size="medium"
            label="Back"
            icon="bi bi-arrow-left"
            handleClick={closeTips}
          />
        </div>

        <TrackerTip title={activeState.tipTitle} university={university} activeState={status} tipLocation={targetTip || '#openTipModal'} />
      </div>
    );
  }

  return (
    <div className="tracker-tips-tab">
      <TrackerContent
        applicationId={applicationId}
        // companyName={companyName}
        activeState={activeState}
        // user={user}
        status={status}
        tasks={tasks}
        handleAction={handleActionClick}
        university={university}
        handleRejection={handleRejection}
      />
    </div>
  );
};
