import { useMemo } from 'react';
import { SequenceStepName, SequenceStepType } from '../../types';
import { SequenceStep } from '../sequence-step/sequence-step';
import './sequence-progress.scss';

type Props = {
  steps: SequenceStepType[];
  currentStepName: SequenceStepName | null;
  isTimeVisible: boolean;
  lastStepCompletedTime: string;
  timeBetweenStepsInDays: number[];
  lastCompletedStepName: SequenceStepName | undefined;
  completedSteps: SequenceStepName[];
};

export const SequenceProgress = ({
  steps,
  currentStepName,
  isTimeVisible,
  lastStepCompletedTime,
  timeBetweenStepsInDays,
  lastCompletedStepName,
  completedSteps,
}: Props) => {
  const currentStepIndex = useMemo(() => {
    if (!currentStepName) {
      return 0;
    }

    return steps.findIndex((s) => s.name === currentStepName);
  }, [steps, currentStepName]);
  const pendingStep = useMemo(() => {
    if (currentStepName) {
      return undefined;
    }
    const lastCompletedStepIndex = steps.findIndex((s) => s.name === lastCompletedStepName);
    return steps[lastCompletedStepIndex + 1];
  }, [steps, currentStepName, lastCompletedStepName]);

  const currentTimeProgress = useMemo(() => {
    if (lastStepCompletedTime === '') {
      return 0;
    }

    const timeBetweenSteps = 7;
    const lastStepTime = new Date(lastStepCompletedTime).getTime();
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - lastStepTime;
    const timeDifferenceInDays = Math.ceil(timeDifference / (24 * 60 * 60 * 1000));
    const timeProgress = (timeDifferenceInDays / timeBetweenSteps) * 100;
    return timeProgress > 100 ? 100 : timeProgress;
  }, [lastStepCompletedTime, currentStepIndex, timeBetweenStepsInDays]);

  return (
    <div data-testid="sequence-progress" className="sequence-progress">
      {steps.map((step, index) => (
        <div data-testid="sequence-progress-step" className="sequence-progress__step">
          <SequenceStep
            key={step.name}
            name={step.label}
            icon={step.icon}
            currentStateIcon={step.currentStateIcon}
            isCurrent={step.name === currentStepName && !completedSteps.includes(step.name)}
            isCompleted={step.isAlwaysDone || index < currentStepIndex || completedSteps.includes(step.name)}
          />
          {index !== 0 && (
            <div data-testid="sequence-progress-timeline" className="sequence-progress__timeline">
              {(index > currentStepIndex && !step.isAlwaysDone) && (
                <div className="sequence-progress__time">
                  <i className="sequence-progress__time-icon bi bi-clock" />

                  <span
                    className={`sequence-progress__time-text ${
                      !isTimeVisible ? 'sequence-progress__time-text--hidden' : ''
                    }`}
                  >
                    {`${timeBetweenStepsInDays[index - 1]} days`}
                  </span>
                </div>
              )}
              {(index <= currentStepIndex || (completedSteps.includes(step.name) || step.name === currentStepName) || step.isAlwaysDone) && (
                <div
                  className="sequence-progress__timeline-progress"
                  style={{ width: '100%' }}
                />
              )}

              {step === pendingStep && !currentStepName && (
                <div
                  className="sequence-progress__timeline-progress sequence-progress__timeline-progress--active"
                  style={{ width: `${currentTimeProgress}%` }}
                />
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
