import { User } from '@auth0/auth0-react';

const AUDIENCE = import.meta.env.VITE_AUDIENCE;
const DOMAIN = import.meta.env.VITE_DOMAIN;
const WS_URL = import.meta.env.VITE_WS_URL;
const CLIENT_ID = import.meta.env.VITE_CLIENT_ID;
const API_URL = import.meta.env.VITE_BASE_URL;
const AUTH_AZURE_SOCIAL_CONNECTION = import.meta.env.VITE_AUTH_AZURE_SOCIAL_CONNECTION;
const AUTH_GMAIL_SOCIAL_CONNECTION = import.meta.env.VITE_AUTH_GMAIL_SOCIAL_CONNECTION;
const AUTH_AZURE_ENTERPRISE_CONNECTION = import.meta.env.VITE_AUTH_AZURE_ENTERPRISE_CONNECTION;
const AUTH_GMAIL_ENTERPRISE_CONNECTION = import.meta.env.VITE_AUTH_GMAIL_ENTERPRISE_CONNECTION;
const gmailProvider = 'gmail';

const idpToEmailProviderMap: Record<string, string> = {
  waad: 'outlook',
  windowslive: 'outlook',
  'google-oauth2': 'gmail',
  'google-apps': 'gmail',
};

const idpToEmailConnectionMap: Record<string, string> = {
  waad: AUTH_AZURE_ENTERPRISE_CONNECTION,
  windowslive: AUTH_AZURE_SOCIAL_CONNECTION,
  'google-oauth2': AUTH_GMAIL_SOCIAL_CONNECTION,
  'google-apps': AUTH_GMAIL_ENTERPRISE_CONNECTION,
};

const outlookScope = 'openid profile email offline_access https://graph.microsoft.com/User.Read https://graph.microsoft.com/Mail.ReadWrite https://graph.microsoft.com/Mail.Send';
const gmailScope = 'openid profile email https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.modify';

function getEmailProviderFromIDP(idpType: string): string | undefined {
  return idpToEmailProviderMap[idpType];
}

function getEmailConnectionForIDP(idpType: string): string | undefined {
  return idpToEmailConnectionMap[idpType];
}

function getAuthorizationParamsForIDP(idpType: string, user: User | undefined) {
  const provider = getEmailProviderFromIDP(idpType);
  if (!provider) {
    return {};
  }

  return {
    audience: AUDIENCE,
    connection: getEmailConnectionForIDP(idpType),
    connection_scope: provider === gmailProvider ? gmailScope : outlookScope,
    login_hint: user?.email,
    ...(provider === gmailProvider && { access_type: 'offline', prompt: 'consent' }),
  };
}

const REDIRECT_URL = `${window.location.origin}/logged-in`;
const BASE_URL = window.location.origin;

export function getConfig() {
  return {
    domain: DOMAIN,
    clientId: CLIENT_ID,
    audience: AUDIENCE,
    baseUrl: BASE_URL,
    redirect: REDIRECT_URL,
    redirectIncognito: `${BASE_URL}/incognito-login`,
    websocketUrl: WS_URL,
    apiUrl: API_URL,
    getEmailProviderFromIDP,
    getAuthorizationParamsForIDP,
    gmailProvider,
  };
}
