import { JobFeedJob } from '../../types/job';
import { JobTile } from '../job-tile/job-tile';
import { TooltipIcon } from '@/components/tooltip-icon/tooltip-icon';
import './job-tabs.scss';

type Props = {
  savedJobsList: JobFeedJob[];
  currentActiveSavedId: string;
  observerTargetSaved: React.RefObject<HTMLDivElement>;
  isNewPageLoadingSaved: boolean;
  totalJobsCountSaved: number;
  currentLoadingId: string;
  handleUnsaveAction: (jobId: string) => void;
  handleClick: (jobId: string) => void;
  onApplyClick: (id: string, url: string, apply: boolean) => void;
};

export const SavedJobsTab = ({
  savedJobsList,
  totalJobsCountSaved,
  observerTargetSaved,
  isNewPageLoadingSaved,
  currentActiveSavedId,
  currentLoadingId,
  handleUnsaveAction,
  handleClick,
  onApplyClick,
}: Props) => (
  <>
    <div className="job-tabs__count">
      <p className="job-tabs__count-number">{`${totalJobsCountSaved?.toLocaleString()} jobs found`}</p>
      {totalJobsCountSaved > 0 && (
        <TooltipIcon tooltipLabel="Jobs are ranked by date saved" />
      )}
    </div>
    <div className="job-tabs__items">
      {savedJobsList.map((job: JobFeedJob) => (
        <JobTile
          key={job.id}
          job={job}
          isActive={currentActiveSavedId === job.id}
          isLoading={currentLoadingId === job.id}
          isSaved
          onJobSaveToggle={handleUnsaveAction}
          onSelect={handleClick}
          withCompanyName
          onApplyClick={onApplyClick}
        />
      ))}
      <div ref={observerTargetSaved} />
      {isNewPageLoadingSaved && <div className="job-tabs__loader" />}
    </div>
  </>
);
