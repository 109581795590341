/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-undef */
import { Text } from '@react-pdf/renderer';
import { HighlightWords } from '../../types';

export const renderAdditionalHighlightedText = (
  text: string,
  listIndex: number,
  highlightedWords: HighlightWords | null,
  activeField: { type: 'work' | 'additional_information'; index: number; bulletIndex: number } | null,
  styles: any,
): React.ReactNode[] => {
  const words = text.split(' ');
  return words.map((word, wordIndex) => {
    const highlightWord = highlightedWords?.additional_information?.find(hw => hw.listIndex === listIndex
      && hw.started_at !== undefined
      && hw.ended_at !== undefined
      && word === hw.word.trim());

    let wordStyle: any = styles.normalWord;
    if (highlightWord) {
      if (highlightWord.type === 'positive') {
        if (!activeField || (activeField.type === 'additional_information' && activeField.bulletIndex === listIndex)) {
          wordStyle = styles.GreenHighlight;
        } else {
          wordStyle = styles.SubtleGreenHighlight;
        }
      } else if (!activeField || (activeField.type === 'additional_information' && activeField.bulletIndex === listIndex)) {
        wordStyle = styles.RedHighlight;
      } else {
        wordStyle = styles.SubtleRedHighlight;
      }
    }

    return (
      <>
        <Text key={`adI-${listIndex}-${wordIndex}`} style={wordStyle}>
          {word}
        </Text>
        <Text>
          {wordIndex < words.length - 1 ? ' ' : ''}
        </Text>
      </>
    );
  });
};

export const computeSkillsStyle = (
  highlightedWords: HighlightWords | null,
  activeField: { type: 'work' | 'additional_information'; index: number; bulletIndex: number } | null,
  styles: any,
) => {
  if (highlightedWords) {
    if (highlightedWords?.skills?.is_done) {
      if (activeField) {
        return styles.SkillsSubtleGreenHighlight;
      }
      return styles.SkillsGreenHighlight;
    }
    if (activeField) {
      return styles.SkillsSubtleRedHighlight;
    }
    return styles.SkillsRedHighlight;
  }
  return styles.languagesText;
};

export const renderWorkHighlightedText = (
  text: string,
  listIndex: number,
  workIndex: number,
  highlightedWords: HighlightWords | null,
  activeField: { type: 'work' | 'additional_information'; index: number; bulletIndex: number } | null,
  styles: any,
): React.ReactNode[] => {
  const words = text.split(' ');
  return words.map((word, wordIndex) => {
    const highlightWord = highlightedWords?.work?.[workIndex]?.find(hw => hw.listIndex === listIndex
      && hw.started_at !== undefined
      && hw.ended_at !== undefined
      && word === hw.word.trim());

    let wordStyle: any = styles.normalWord;
    if (highlightWord) {
      if (highlightWord.type === 'positive') {
        if (!activeField || (activeField.type === 'work' && activeField.index === workIndex && activeField.bulletIndex === listIndex)) {
          wordStyle = styles.GreenHighlight;
        } else {
          wordStyle = styles.SubtleGreenHighlight;
        }
      } else if (!activeField || (activeField.type === 'work' && activeField.index === workIndex && activeField.bulletIndex === listIndex)) {
        wordStyle = styles.RedHighlight;
      } else {
        wordStyle = styles.SubtleRedHighlight;
      }
    }

    return (
      <>
        <Text key={`${word}-${wordIndex}`} style={wordStyle}>
          {word}
        </Text>
        <Text>
          {wordIndex < words.length - 1 ? ' ' : ''}
        </Text>
      </>
    );
  });
};

export function formatDateToYear(dateString: string | undefined): string {
  if (!dateString) {
    return 'N/A';
  }
  const [month, year] = dateString.split('/');
  const date = new Date(2000 + parseInt(year, 10), parseInt(month, 10) - 1, 1);
  return date.getFullYear().toString();
}

export function trimUrlPrefix(url: string): string {
  return url.replace(/^https?:\/\/(?:www\.)?/, '');
}
