import './resource-tile.scss';
import { Button } from '@careeros/coco';

interface ResourceProps {
  image: string;
  name: string;
  description: string;
  link: string;
  linkTarget?: string;
  linkLabel: string;
}

export const ResourceTile = ({
  image,
  name, description, link, linkTarget = '_blank', linkLabel,
}: ResourceProps) => {
  const handleButtonClick = () => {
    window.open(link, linkTarget);
  };
  return (
    <div className="resource">
      <img
        className="resource__image"
        src={image}
        alt=""
      />
      <div className="resource__info">
        <h4 className="resource__name">
          {name}
        </h4>
        <p className="resource__description">
          {description}
        </p>
      </div>
      <Button mode="primary" outlined onClick={handleButtonClick} icon="bi bi-box-arrow-up-right" iconPosition="right" size="small" label={linkLabel} />
    </div>
  );
};
