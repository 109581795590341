export const formatDateDeltaFromNow = (date: string | Date) => {
  const givenDate = new Date(date);
  const currentDate = new Date();
  const timeDifference = Math.abs(currentDate.getTime() - givenDate.getTime());

  const minutes = Math.floor(timeDifference / (1000 * 60));
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} day(s) ago`;
  } if (hours > 0) {
    return `${hours} hour(s) ago`;
  } if (minutes > 0) {
    return `${minutes} min(s) ago`;
  }
  return 'Just now';
};

export function formatDatesDifferenceText(startDate: string, endDate: string) {
  const date1 = new Date(startDate);
  const date2 = (endDate !== 'Present' && endDate) ? new Date(endDate) : new Date();

  // Check if dates are valid
  if (Number.isNaN(date1.getTime()) || Number.isNaN(date2.getTime())) {
    return 'Invalid Dates';
  }

  // Check if end date is earlier than start date
  if (date2.getTime() < date1.getTime()) {
    return 'Invalid Dates';
  }

  const diffMonths = (date2.getFullYear() - date1.getFullYear()) * 12 + (date2.getMonth() - date1.getMonth());
  const diffYears = Math.floor(diffMonths / 12);
  const diffRemainingMonths = diffMonths % 12;

  let result = '';
  if (diffYears > 0) {
    result += `${diffYears} ${diffYears === 1 ? 'year' : 'years'}`;
  }
  if (diffYears > 0 && diffRemainingMonths > 0) {
    result += ' ';
  }
  if (diffRemainingMonths > 0) {
    result += `${diffRemainingMonths} ${diffRemainingMonths === 1 ? 'month' : 'months'}`;
  }

  return result ? result.trim() : 'Just now';
}

function isSameDay(date1: Date, date2: Date) {
  return (
    date1.getFullYear() === date2.getFullYear()
    && date1.getMonth() === date2.getMonth()
    && date1.getDate() === date2.getDate()
  );
}

export function formatDate(date: Date) {
  const currentDate = new Date();
  if (isSameDay(date, currentDate)) {
    const formattedTime = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    }).format(date);
    return formattedTime;
  }

  const formattedDate = new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: 'short',
  }).format(date);

  return formattedDate;
}

export const sortByCreatedAt = <T extends { created_at: string | Date }>(arr: T[]): T[] => arr.sort((a, b) => {
  const dateA = new Date(a.created_at);
  const dateB = new Date(b.created_at);
  return dateB.getTime() - dateA.getTime();
});

export const sortByUpdatedAt = <T extends { updated_at: string | Date }>(arr: T[]): T[] => arr.sort((a, b) => {
  const dateA = new Date(a.updated_at);
  const dateB = new Date(b.updated_at);
  return dateB.getTime() - dateA.getTime();
});

export const getDateDeltaFromNowInDays = (date: string | Date) => {
  const today = new Date();
  const deadlineDate = new Date(date);
  const diffTime = Math.floor(deadlineDate.getTime() / (1000 * 60 * 60 * 24))
    - Math.floor(today.getTime() / (1000 * 60 * 60 * 24));

  return diffTime;
};
