import { useMemo, useState } from 'react';
import { SequenceStepName } from '../../types';
import { SequenceProgress } from '../sequence-progress/sequence-progress';
import './sequence-banner.scss';
import { defaultSteps, responseReceivedAfterFirstOutreachSteps, responseReceivedAfterSecondOutreachSteps } from '../../constants';
import { QuestType } from '@/domains/core/student/types';

type Props = {
  currentStep: SequenceStepName | null;
  completedSteps: SequenceStepName[];
  lastCompletedStepName?: SequenceStepName;
  lastCompletedStepTime: string;
  isResponseReceived?: boolean;
  handleClose: () => void;
  handleAddNewContactClick: () => void;
  handleCoffechatClick: () => void;
};

export const SequenceBanner = ({
  currentStep,
  lastCompletedStepName,
  completedSteps,
  lastCompletedStepTime,
  isResponseReceived,
  handleClose,
  handleAddNewContactClick,
  handleCoffechatClick,
}: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const steps = useMemo(() => {
    if (completedSteps.includes(QuestType.SendSecondOutreach) && isResponseReceived) {
      return responseReceivedAfterSecondOutreachSteps;
    }

    if (completedSteps.includes(QuestType.SendFirstOutreach) && isResponseReceived) {
      return responseReceivedAfterFirstOutreachSteps;
    }

    if (isResponseReceived) {
      return responseReceivedAfterFirstOutreachSteps;
    }

    return defaultSteps;
  }, [currentStep, completedSteps, isResponseReceived]);

  const description = useMemo(() => {
    if (isResponseReceived || currentStep === QuestType.ScheduleCoffeeChat) {
      return (
        <>
          Nice! You received a response. Get unique insights from your contact by asking them
          <span data-testid="sequence-banner-linked-text-tips" className="sequence-banner__linked-text" onClick={handleCoffechatClick}> the right questions in a coffee chat.</span>
        </>
      );
    }

    if (currentStep === QuestType.AddNewContact) {
      return (
        <>
          Looks like you have not heard back from this contact in a while. Let’s
          <span data-testid="sequence-banner-linked-text-add-contact" className="sequence-banner__linked-text" onClick={handleAddNewContactClick}> add a new contact.</span>
        </>
      );
    }

    if (!currentStep && (lastCompletedStepName === QuestType.SendFirstOutreach || lastCompletedStepName === QuestType.SendSecondOutreach) && !isResponseReceived) {
      return (
        <>
          We will notify you once the contact has responded or a follow-up is needed. In the meantime, prepare for your coffee chat with our
          <span data-testid="sequence-banner-linked-text-tips" className="sequence-banner__linked-text" onClick={handleCoffechatClick}> tips and tricks here.</span>
        </>
      );
    }

    if (currentStep === QuestType.SendFirstOutreach) {
      return 'It\'s time to reach out to your contact. Did you know, emails have the highest success when networking?';
    }

    if (currentStep === QuestType.SendSecondOutreach) {
      return 'It\'s time to follow up with your contact. Indicate your sincere interest in connecting with them.';
    }

    return '';
  }, [currentStep, lastCompletedStepName, isResponseReceived]);

  return (
    <div
      className="sequence-banner"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      data-testid="sequence-banner"
    >
      <div data-testid="close-button" className="sequence-banner__close" onClick={handleClose}>
        <i className="bi bi-x-lg" />
      </div>
      <div data-testid="sequence-banner-steps" className="sequence-banner__steps">
        <SequenceProgress
          steps={steps}
          currentStepName={currentStep}
          completedSteps={completedSteps}
          lastStepCompletedTime={lastCompletedStepTime}
          timeBetweenStepsInDays={[7, 7]}
          isTimeVisible={isHovered}
          lastCompletedStepName={lastCompletedStepName}
        />
      </div>
      <div className="sequence-banner__content">
        <p className="sequence-banner__description" data-testid="sequence-banner-text">{description}</p>
      </div>
    </div>
  );
};
