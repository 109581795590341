import { ButtonHTMLAttributes } from 'react';
import './icon-button.scss';

export interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Type of the button
   */
  mode: 'primary' | 'rounded' | 'special' | 'unset';
  /**
   * Is the button outlined?
   */
  outlined?: boolean;
  /**
   * How large should the button be?
   */
  size: 'small' | 'medium' | 'large' | 'x-small';

  isDangerous?: boolean;
  disabled?: boolean;
  icon: string;
  hoverIcon?: string;
  label?: string;
  isLoading?: boolean;
  handleClick?: React.MouseEventHandler<HTMLButtonElement>;
  iconRef?: React.RefObject<HTMLButtonElement>;
}

/**
 * Primary UI component for user interaction
 */
export const IconButton = ({
  mode = 'primary',
  handleClick,
  size = 'large',
  outlined = false,
  icon,
  hoverIcon,
  children,
  disabled = false,
  isDangerous = false,
  isLoading = false,
  label,
  ...props
}: IconButtonProps) => {
  const isButtonOulined = outlined
    ? 'careerOS-icon-button--outlined'
    : 'careerOS-icon-button--normal';

  const isButtonDisabled = disabled || isLoading
    ? 'careerOS-icon-button--disabled'
    : 'careerOS-icon-button--not-disabled';

  const isButtonDangerous = isDangerous
    ? 'careerOS-icon-button--dangerous'
    : '';

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!handleClick || disabled || isLoading) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
    handleClick(e);
  };

  return (
    <button
      type="button"
      onClick={onClick}
      aria-label={label}
      ref={props.iconRef}
      tabIndex={0}
      className={[
        'careerOS-icon-button',
        `careerOS-icon-button--mode-${mode}`,
        `careerOS-icon-button--size-${size}`,
        isButtonOulined,
        isButtonDisabled,
        isButtonDangerous,
      ].join(' ')}
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading && (
        <span className="careerOS-icon-button__loader" />
      )}
      <i className={`${icon} careerOS-icon-button__icon`} />
      <i className={`${hoverIcon || icon} careerOS-icon-button__icon careerOS-icon-button__icon--hover`} />

    </button>
  );
};
