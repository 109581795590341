import { useNavigate } from 'react-router-dom';
import { Button } from '@careeros/coco';

import React, { ReactNode, useState } from 'react';
import { useScroll } from '@/services/hooks/use-scroll';
import './category-widget.scss';
import { IconButton } from '@/components/icon-button/icon-button';
import { Tooltip } from '@/components/tooltip/tooltip';

interface CategoryWidgetProps {
  children: ReactNode;
  title: string;
  subtitle: string;
  exploreMoreLink: string;
}

// const EXPLORE_COMPANY_LINK_LENGTH = 1500;

/**
 * CategoryWidget is used for displaying category in the explore companies page.
 * Each category contains list of companies belonging to it
 */

export const CategoryWidget = ({
  children,
  title,
  subtitle,
  exploreMoreLink,
}: CategoryWidgetProps) => {
  const [widgetContainer, handleVerticalScroll] = useScroll();
  const [showRightScrollArrow, setShowRightScrollArrow] = useState(true);
  const [showLeftScrollArrow, setShowLeftScrollArrow] = useState(false);
  const navigate = useNavigate();

  const goToCategory = () => {
    navigate(exploreMoreLink);
  };
  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const el = e.currentTarget;
    const allColumnsWidth = widgetContainer.current.scrollWidth;
    const columnsInViewWidth = widgetContainer.current.clientWidth;

    if (Math.round(el.scrollLeft + columnsInViewWidth) >= Math.round(allColumnsWidth)) {
      setShowRightScrollArrow(false);
    } else {
      setShowRightScrollArrow(true);
    }
    if (el.scrollLeft === 0) {
      setShowLeftScrollArrow(false);
    } else {
      setShowLeftScrollArrow(true);
    }
  };

  return (
    <div className="category-widget">
      <div className="category-widget__top">
        <div className="category-widget__header">
          <h2 className="category-widget__title">
            {title}
          </h2>
          <h3 className="category-widget__subtitle">{subtitle}</h3>
        </div>

        <div className="category-widget__buttons">
          <Tooltip
            label="View more companies from this category"
            withArrow
            position="top"
          >
            <Button
              handleClick={goToCategory}
              mode="blue"
              size="medium"
              label="Browse Category"
              icon="bi bi-box-arrow-up-right"
              iconPosition="right"
              outlined
            />
          </Tooltip>
          <div className="category-widget__controls">
            <IconButton
              icon="bi bi-chevron-left"
              mode="rounded"
              size="medium"
              outlined
              handleClick={() => handleVerticalScroll('left')}
              disabled={!showLeftScrollArrow}
            />
            <IconButton
              icon="bi bi-chevron-right"
              mode="rounded"
              size="medium"
              outlined
              handleClick={() => handleVerticalScroll('right')}
              disabled={!showRightScrollArrow}
            />
          </div>
        </div>
      </div>
      <div className={`category-widget__wrapper ${!showRightScrollArrow ? 'category-widget__wrapper--no-mask' : ''}`} ref={widgetContainer} onScroll={handleScroll}>
        <div className="category-widget__companies">{children}</div>
      </div>
    </div>
  );
};
