export enum QuestType {
  DownloadExtension = 'install_extension',
  ApplicationDeadline = 'submit_application',
  ResolveComment = 'resolve-comment',
  SaveCompany = 'save-company',
  SaveFirstCompany = 'save_first_company',
  CompleteDemographicsForm = 'complete_demographics_form',
  SaveContact = 'add_contact',
  SendMessage = 'send_linkedin_message',
  SendConnection = 'send_linkedin_connection',
  SendFirstEmail = 'send_first_email',
  SendFirstOutreach = 'send_first_outreach',
  SendSecondOutreach = 'send_second_outreach',
  AddNewContact = 'add_new_contact',
  ScheduleCoffeeChat = 'schedule_coffee_chat',
  SendFollowUpEmail = 'send_follow_up_email',
  RespondToEmail = 'respond_to_email',
  ConnectEmail = 'connect_email',
  ReplyToContact = 'reply_to_contact',
}
export const QuestCompleted: any = {
  [QuestType.DownloadExtension]: 'ExtensionComplete',
  // more to add in the future
};
export type QuestCompanyType = {
  id: string,
  logo_url: string,
  name: string,
  linkedin_url: string,
};
export type QuestJobType = {
  id: string,
  title: string,
  linkedin_url?: string,
};
export type QuestContactType = {
  id: string,
  first_name: string,
  last_name: string,
  profile_image: string
  linkedin_url: string,
};

export type QuestTileType = { // this needs to be a partial of the props
  id: string;
  type: QuestType;
  company?: QuestCompanyType
  job?: QuestJobType
  contact?: QuestContactType
  due_date: string | null;
  created_at: string;
  completable: boolean;
  status: string;
};
