import { CompanyWithCount, Tile } from '@/domains/core/company/types';
import { mapCompaniesSavedByStudentsToTableData } from '@/services/helpers/advisor';

export const processCompanyData = (
  tileData: Tile[],
  universityId: string,
  cohortId: string,
  computeFilteredCompaniesData: (status: string, companies: string[], tiles: Tile[]) => number[],
) => {
  const tilesArray = tileData.flatMap((array: any) => array).filter(Boolean);
  const uniqueCompanies: CompanyWithCount[] = [];

  const processedCompanies = tilesArray
    .map((obj: Tile) => {
      const company = uniqueCompanies.find(
        (c: CompanyWithCount) => c.company_name === obj.company_name && c.industry === obj.industry,
      );

      if (company) {
        company.count += 1;
        return null;
      }

      const newCompany = { ...obj, count: 1 };
      uniqueCompanies.push(newCompany);
      return newCompany;
    })
    .filter((company): company is CompanyWithCount => Boolean(company))
    .sort((a: CompanyWithCount, b: CompanyWithCount) => b.count - a.count)
    .slice(0, 10);

  const topTenCompanies = processedCompanies.map((company: CompanyWithCount) => company.company_name);

  return {
    allCompanies: uniqueCompanies.sort((a: any, b: any) => b.count - a.count),
    filteredCompanies: mapCompaniesSavedByStudentsToTableData(
      uniqueCompanies.sort((a: CompanyWithCount, b: CompanyWithCount) => b.count - a.count),
      universityId,
      cohortId || '',
    ),
    companiesData: {
      labels: topTenCompanies,
      datasets: [
        {
          label: 'Company Saved',
          data: computeFilteredCompaniesData('Company Saved', topTenCompanies, tileData),
          backgroundColor: '#06A47E',
          color: '#ffffff',
        },
        {
          label: 'Networking',
          data: computeFilteredCompaniesData('Networking', topTenCompanies, tileData),
          backgroundColor: '#98A6FD',
          color: '#ffffff',
        },
        {
          label: 'Applying',
          data: computeFilteredCompaniesData('Applying', topTenCompanies, tileData),
          backgroundColor: '#2DCDC7',
          color: '#ffffff',
        },
        {
          label: 'Applied',
          data: computeFilteredCompaniesData('Applied', topTenCompanies, tileData),
          backgroundColor: '#2C9ACF',
          color: '#ffffff',
        },
        {
          label: 'Interviewing',
          data: computeFilteredCompaniesData('Interviewing', topTenCompanies, tileData),
          backgroundColor: '#EB993E',
          color: '#ffffff',
        },
        {
          label: 'Offer Received',
          data: computeFilteredCompaniesData('Offer', topTenCompanies, tileData),
          backgroundColor: '#FC47BE',
          color: '#ffffff',
        },
        {
          label: 'Archived',
          data: computeFilteredCompaniesData('Archived', topTenCompanies, tileData),
          backgroundColor: '#BEC9C8',
          color: '#ffffff',
        },
      ],
    },
  };
};
