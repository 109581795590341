/* eslint-disable arrow-body-style */
import {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';
import * as Sentry from '@sentry/react';
import { Banner } from '@careeros/coco';
import { useNavigate } from 'react-router-dom';
import {
  JobFilters, JobNoExtension, JobsNotFound, AllJobsTab, SavedJobsTab,
} from '@/domains/core/job';
import JobDescription from '@/domains/core/job/components/job-description/job-description';
import { Loader } from '@/components/loader/loader';
import { useExtensionMessaging } from '@/services/hooks/use-extension-messaging';
import { applyForJob, saveJob, unsaveJob } from '@/services/api/job';
import { useJobsFiltering } from '@/services/hooks/use-jobs';
import { useSavedJobs } from '@/services/hooks/use-saved-jobs';
import { TabsHeader, TabsContent, withTabs } from '@/components/tabs';
import { IconButton } from '@/components/icon-button/icon-button';
import { ModalContext } from '@/components/modal/modal-provider';
import { InfoModal } from '@/domains/generic/modals';
import { JobFeedJob } from '@/domains/core/job/types/job';
import { useTabs } from '@/components/tabs/TabsContext';
import { useAnalytics } from '@/services/hooks/use-analytics';
import './styles/job-feed.scss';
import { useSelf } from '@/services/queries/user';

import BannerSpecialIcon from '@/assets/images/banners/banner-special-icon.png';
import { updateSearchParamsWithNewFilters } from '@/domains/core/job/helpers/convertURLParamsToFilters';

// TODO: this component is too complex, consider refactoring https://thecareeros.atlassian.net/browse/COM-3695
const JobFeedPage = () => {
  const tabs = ['All Jobs', 'Saved Jobs'];
  const [isBannerSelected, setIsBannerSelected] = useState(false);
  const [hasExtensionInstalled, setHasExtensionInstalled] = useState(false);
  const [showTallyBanner, setShowTallyBanner] = useState(false);
  const [showDidYouApply, setShowDidYouApply] = useState(false);
  const { data: self, isLoading: isSelfLoading } = useSelf();
  const pollIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const { currentTab, setCurrentTab } = useTabs();
  const queryClient = useQueryClient();
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();

  const {
    jobs,
    filters,
    currentActiveId,
    observerTarget,
    isNewPageLoading,
    isLoading,
    jobsLocations,
    jobsLanguages,
    totalJobsCount,
    currentLoadingId,
    isPageEnd,
    setFilters,
    setCurrentActiveId,
    setCurrentLoadingId,
    setJobs,
  } = useJobsFiltering({ currentTab });

  const {
    savedJobs,
    observerTargetSaved,
    isNewPageLoading: isNewPageLoadingSaved,
    currentActiveId: currentActiveSavedId,
    totalJobsCountSaved,
    setCurrentActiveId: setCurrentActiveSavedId,
    setTotalJobsCountSaved,
    setSavedJobs,
  } = useSavedJobs({ currentTab, isLoading });
  const { checkLinkedInLogin } = useExtensionMessaging();
  const isSmallScreen = useMemo(() => () => window.innerWidth <= 720, []);

  const handleClick = (jobId: string, withAppliedOptions = false) => {
    setIsBannerSelected(false);
    setCurrentActiveId(jobId);
    setCurrentActiveSavedId(jobId);
    setShowDidYouApply(withAppliedOptions);
  };

  const handleBannerSelected = () => {
    setIsBannerSelected(true);
    setCurrentActiveId('');
    setCurrentActiveSavedId('');
  };

  const checkExtensionStatus = async () => {
    const hasExtension = await checkLinkedInLogin();
    setHasExtensionInstalled(hasExtension);
    if (hasExtension) {
      const firstJob = jobs[0];
      if (!isSmallScreen() && !currentActiveId && firstJob) {
        setCurrentActiveId(firstJob.id);
      }
      setIsBannerSelected(false);
      if (pollIntervalRef.current) {
        clearInterval(pollIntervalRef.current);
        pollIntervalRef.current = null;
      }
    } else {
      trackEvent('Job Feed Chrome Extension banner shown');
    }
  };

  const setupInterval = () => {
    pollIntervalRef.current = setInterval(checkExtensionStatus, 5000);
  };

  const isSavedJobsTabSelected = useMemo(() => currentTab === 'Saved Jobs', [currentTab]);
  const displayedJob = useMemo(() => {
    const jobsSource = isSavedJobsTabSelected ? savedJobs : jobs;
    const selectedInTab = isSavedJobsTabSelected ? currentActiveSavedId : currentActiveId;
    const jobSelected = jobsSource.find((job) => job.id === selectedInTab);

    return jobSelected;
  }, [jobs, savedJobs, currentActiveId, currentActiveSavedId, currentTab, isSavedJobsTabSelected]);

  const updateJobSavedStatus = (prevSavedJobs: JobFeedJob[], jobID: string, isSaved: boolean) => prevSavedJobs.map((job) => (job.id === jobID ? { ...job, saved: isSaved } : job));

  const handleSaveAction = async (jobID: string) => {
    setCurrentLoadingId(jobID);
    try {
      await saveJob(jobID);

      setTotalJobsCountSaved(prevTotalJobsCount => prevTotalJobsCount + 1);

      const jobToSave = jobs.find(job => job.id === jobID);

      if (!jobToSave) {
        return;
      }

      jobToSave.saved = true;

      setJobs(prevJobs => updateJobSavedStatus(prevJobs, jobID, true));
      setSavedJobs(prevSavedJobs => [jobToSave, ...prevSavedJobs]);
      trackEvent('Job Saved', {
        job_id: jobID,
        company_name: jobToSave.company_name,
        job_title: jobToSave.title,
        location: jobToSave.tags[1]?.tag_content,
        full_time: jobToSave.tags[0]?.tag_content,
        on_site: jobToSave.tags[2]?.tag_content,
        industry: jobToSave.tags[4]?.tag_content,
        size: jobToSave.tags[3]?.tag_content,
      });

      await queryClient.invalidateQueries(['applications-no-cache']);
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      setCurrentLoadingId('');
    }
  };

  const updateJobInList = (jobID: string, updatedJob: JobFeedJob) => {
    const updatedJobsList = jobs.map(j => (j.id === jobID ? updatedJob : j));
    const updatedSavedJobsList = savedJobs.map(j => (j.id === jobID ? updatedJob : j));

    setJobs(updatedJobsList);
    setSavedJobs(updatedSavedJobsList);
    setShowDidYouApply(false);
  };

  const handleUnsaveAction = async (rememberAction: boolean, jobID: string) => {
    if (rememberAction) {
      localStorage.setItem('rememberUnsaveUser', 'true');
    }
    setCurrentLoadingId(jobID);
    try {
      await unsaveJob(jobID);

      setTotalJobsCountSaved(prevTotalJobsCount => prevTotalJobsCount - 1);

      const jobToUnsave = savedJobs.find(job => job.id === jobID);

      if (!jobToUnsave) {
        return;
      }

      jobToUnsave.saved = false;

      setSavedJobs(prevSavedJobs => prevSavedJobs.filter(job => job !== jobToUnsave));
      setJobs(prevJobs => updateJobSavedStatus(prevJobs, jobID, false));
      trackEvent('Job unsaved', {
        job_id: jobID,
        company_name: jobToUnsave.company_name,
        job_title: jobToUnsave.title,
        location: jobToUnsave.tags[1]?.tag_content,
        full_time: jobToUnsave.tags[0]?.tag_content,
        on_site: jobToUnsave.tags[2]?.tag_content,
        industry: jobToUnsave.tags[4]?.tag_content,
        size: jobToUnsave.tags[3]?.tag_content,
      });

      if (currentActiveSavedId === jobID) {
        const newActiveJob = savedJobs.find(job => job.id !== jobID);
        setCurrentActiveSavedId(newActiveJob ? newActiveJob.id : '');
      }
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      setCurrentLoadingId('');
    }
    closeModal();
  };

  const handleUnsave = (jobID: string) => {
    const rememberAction = localStorage.getItem('rememberUnsaveUser');
    if (rememberAction) {
      handleUnsaveAction(true, jobID);
    } else {
      openModal(
        <InfoModal
          icon="bi bi-sign-stop"
          title="Unsave Job"
          description="Once you unsave this job, it will be gone for good. Please be aware that you may not find this job posting again afterwards."
          buttonLabel="Yes, I want to unsave"
          handleButtonClick={(rememberAnswer: boolean) => handleUnsaveAction(rememberAnswer, jobID)}
          secondaryButtonLabel="Keep this job posting"
          handleSecondaryButtonClick={closeModal}
          rememberText="Don't ask this again"
          isImageDanger
        />,
      );
    }
  };

  const isSavedJob = useMemo(() => {
    return (jobId: string) => savedJobs.find((job) => job.id === jobId);
  }, [savedJobs]);

  const setAllJobsTab = () => {
    setCurrentTab(tabs[0]);
  };

  const handleAppliedResponse = async (id: string, didApply: boolean) => {
    await applyForJob(id, false);
    closeModal();

    const jobToUpdate = jobs.find(job => job.id === id);

    if (!jobToUpdate) {
      return;
    }

    updateJobInList(id, { ...jobToUpdate, applied: didApply });
  };

  const handleTileApplyClick = async (id: string, url: string, apply: boolean) => {
    if (apply) {
      const utmUrl = `${url}${url.includes('?') ? '&' : '?'}utm_source=CareerOS`;
      window.open(utmUrl, '_blank');

      handleClick(id, true);
      openModal(
        <InfoModal
          icon="bi bi-send"
          title="Did you apply?"
          description="Just let us know, and we'll help you keep tabs on your application!"
          buttonLabel="Yes, I did! 🤩"
          handleButtonClick={() => handleAppliedResponse(id, true)}
          secondaryButtonLabel="No, not yet 🙈"
          handleSecondaryButtonClick={() => handleAppliedResponse(id, false)}
        />,
      );

      return;
    }

    handleAppliedResponse(id, false);
  };

  const handleSavedCompaniesToggle = (checked: boolean) => {
    setFilters({ ...filters, favoriteCompanies: checked });
    updateSearchParamsWithNewFilters('favoriteCompanies', checked);
  };

  useEffect(() => {
    checkExtensionStatus();
    return () => {
      if (pollIntervalRef.current) {
        clearInterval(pollIntervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isSmallScreen()) {
      return;
    }

    const jobsSource = isSavedJobsTabSelected ? savedJobs : jobs;
    const setter = isSavedJobsTabSelected ? setCurrentActiveSavedId : setCurrentActiveId;
    const jobId = jobsSource[0]?.id;
    if (jobId) {
      setter(jobId);
    }
    setIsBannerSelected(false);
  }, [currentTab]);

  useEffect(() => {
    const handleResize = () => {
      if (isSmallScreen()) {
        setCurrentActiveId('');
      } else if (!currentActiveId && jobs.length > 0) {
        setCurrentActiveId(jobs[0].id);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [jobs, currentActiveId]);

  useEffect(() => {
    if (currentTab !== 'All Jobs') {
      setCurrentTab('All Jobs');
    }
  }, []);

  useEffect(() => {
    if (self && !isSelfLoading && !self.has_completed_demographics_form) {
      setShowTallyBanner(true);
    }
  }, [self, isSelfLoading]);

  if (Array.isArray(jobs) && jobs.length === 0 && !isLoading) {
    return (
      <div className="job-feed-page" id="job-feed-page">
        <div className="job-feed-page__top">
          <JobFilters
            filters={filters}
            jobsLocations={jobsLocations}
            jobsLanguages={jobsLanguages}
            setFilters={setFilters}
          />
        </div>
        <JobsNotFound />
      </div>
    );
  }

  return (
    <div className="job-feed-page" id="job-feed-page">
      <div className="job-feed-page__top">
        <JobFilters
          filters={filters}
          jobsLocations={jobsLocations}
          jobsLanguages={jobsLanguages}
          setFilters={setFilters}
        />
      </div>
      {showTallyBanner && (
        <div className="job-feed-page__banner">
          <Banner
            buttonIcon="bi bi-stars"
            buttonLabel="Customize My Experience"
            buttonPlacement="vertical"
            handleButtonClick={() => navigate('/app/onboarding?show_navigation=true')}
            image={BannerSpecialIcon}
            isClosable
            mode="special"
            text="We get it. Finding the right job is challenging. Customize your experience now. (takes 2 min)"
            title="Get personalized recommendations on jobs, companies and contacts!"
            handleClose={() => setShowTallyBanner(false)}
          />
        </div>
      )}
      <div className="job-feed-page__bottom">
        {isLoading ? <Loader /> : (
          <>
            <div className="job-tabs">
              <TabsHeader
                withBorder
                tabs={tabs}
                defaultTab={tabs[0]}
                tabCounters={{ [tabs[1]]: totalJobsCountSaved }}
              />

              <TabsContent>
                <div data-tab="All Jobs" className="job-feed-page__tab">
                  <AllJobsTab
                    savedJobs={savedJobs}
                    jobsList={jobs}
                    totalJobsCount={totalJobsCount}
                    observerTarget={observerTarget}
                    isNewPageLoading={isNewPageLoading}
                    isBannerSelected={isBannerSelected}
                    currentActiveId={currentActiveId}
                    currentLoadingId={currentLoadingId}
                    hasExtensionInstalled={hasExtensionInstalled}
                    isPageEnd={isPageEnd}
                    onSelectBanner={handleBannerSelected}
                    handleSaveAction={handleSaveAction}
                    handleClick={handleClick}
                    handleUnsaveAction={handleUnsave}
                    onApplyClick={handleTileApplyClick}
                    toggleSavedCompaniesFilter={handleSavedCompaniesToggle}
                    isSavedCompaniesFilterOn={filters.favoriteCompanies}
                  />
                </div>
                <div data-tab="Saved Jobs" className="job-feed-page__tab">
                  <SavedJobsTab
                    savedJobsList={savedJobs}
                    totalJobsCountSaved={totalJobsCountSaved}
                    observerTargetSaved={observerTargetSaved}
                    isNewPageLoadingSaved={isNewPageLoadingSaved}
                    currentLoadingId={currentLoadingId}
                    currentActiveSavedId={currentActiveSavedId}
                    handleUnsaveAction={handleUnsave}
                    handleClick={handleClick}
                    onApplyClick={handleTileApplyClick}
                  />
                </div>
              </TabsContent>
            </div>
            <div className={`job-feed-page__content-wrapper ${currentActiveId ? 'job-feed-page__content-wrapper--active' : ''}`}>
              <div className="job-feed-page__block">
                {isBannerSelected && (!currentActiveId || !currentActiveSavedId) && (
                  <JobNoExtension setupInterval={setupInterval} />
                )}
                {!isBannerSelected && (currentActiveId || currentActiveSavedId) && (
                  <JobDescription
                    job={displayedJob}
                    isSaved={isSavedJob(displayedJob?.id ?? '')}
                    handleSaveAction={handleSaveAction}
                    handleUnsaveAction={handleUnsave}
                    setTotalJobsCountSaved={setTotalJobsCountSaved}
                    setAllJobsTab={setAllJobsTab}
                    optimisticJobUpdate={updateJobInList}
                    defaultShowDidYouApply={showDidYouApply}
                  />
                )}
                <div className="job-feed-page__block-mobile-button">
                  <IconButton mode="unset" size="medium" icon="bi bi-x" handleClick={() => setCurrentActiveId('')} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default withTabs(JobFeedPage);
