import { LinkType } from '@/pages/layout/sidebar/types/types';

const homeIcon = 'bi bi-house-door';
const homeFilledIcon = 'bi bi-house-door-fill';
const exploreIcon = 'bi bi-building';
const exploreFilledIcon = 'bi bi-building-fill';
const overviewIcon = 'bi bi-grid-1x2';
const overviewFilledIcon = 'bi bi-grid-1x2-fill';
const dashboardIcon = 'bi bi-bar-chart';
const dashboardFilledIcon = 'bi bi-bar-chart-fill';
const peopleIcon = 'bi bi-people';
const peopleFilledIcon = 'bi bi-people-fill';
const jobFeedIcon = 'bi bi-briefcase';
const jobFeedFilledIcon = 'bi bi-briefcase-fill';
const resumeBuilderFilledIcon = 'bi bi-file-earmark-fill';
const resumeBuilderIcon = 'bi bi-file-earmark';
const contactsIcon = 'bi bi-person';
const contactsFilledIcon = 'bi bi-person-fill';
const extensionIcon = 'bi bi-puzzle';
const inboxIcon = 'bi bi-chat';
const inboxSelectedIcon = 'bi bi-chat-fill';
const eyeIcon = 'bi bi-eye';
const eyeFillIcon = 'bi bi-eye-fill';

export const CareerAdvisorLinks: LinkType[] = [
  {
    label: 'Dashboard',
    url: 'dashboard-advisor',
    icon: dashboardIcon,
    iconSelected: dashboardFilledIcon,
    order: 0,
  },
  {
    label: 'Students',
    url: 'students',
    icon: peopleIcon,
    iconSelected: peopleFilledIcon,
    order: 1,
  },
  {
    label: 'Insights',
    url: 'insights',
    icon: eyeIcon,
    iconSelected: eyeFillIcon,
    includedForUniversities: ['ESMT', 'HHL Leipzig Graduate School of Management'],
    order: 1,
  },
];

export const AdminLinks: LinkType[] = [
  {
    label: 'Admin',
    url: 'admin-view',
    icon: homeIcon,
    iconSelected: homeFilledIcon,
    order: 0,
  },
];

export const StudentLinks: LinkType[] = [
  {
    label: 'Home',
    url: 'dashboard',
    icon: homeIcon,
    iconSelected: homeFilledIcon,
    order: 2,
  },
  {
    label: 'Overview Board',
    url: 'overview',
    icon: overviewIcon,
    iconSelected: overviewFilledIcon,
    order: 3,
  },
  {
    label: 'Companies',
    url: 'companies/explore',
    icon: exploreIcon,
    iconSelected: exploreFilledIcon,
    order: 7,
  },
  {
    label: 'Contacts',
    url: 'contacts',
    icon: contactsIcon,
    iconSelected: contactsFilledIcon,
    order: 5,
  },
  {
    label: 'Inbox',
    url: 'inbox',
    icon: inboxIcon,
    iconSelected: inboxSelectedIcon,
    order: 4,
  },
  {
    label: 'Chrome Extension',
    url: 'https://chromewebstore.google.com/detail/careeros-save-jobs-contac/ahkmpgpdcneppdhhdgmmmcgicgfcfmka?hl=en-GB',
    icon: extensionIcon,
    iconSelected: extensionIcon,
    isExternal: true,
    order: 100,
  },
  {
    label: 'Jobs',
    url: 'jobs',
    icon: jobFeedIcon,
    iconSelected: jobFeedFilledIcon,
    order: 6,
    excludedForUniversities: ['Copenhagen Business School'],
  },
  {
    label: 'ResumeOS',
    url: 'resume-os',
    icon: resumeBuilderIcon,
    iconSelected: resumeBuilderFilledIcon,
    order: 8,
  },
];

export const BetaUsersLinks: LinkType[] = [];
