import { Link } from 'react-router-dom';
import { Button } from '@careeros/coco';

import { useEffect } from 'react';
import { Logo } from '@/components/logo/logo';
import ErrorImage from '@/assets/images/error-image.svg';

import './styles/error.scss';
import { router } from '@/main';
import { useAnalytics } from '@/services/hooks/use-analytics';

export default function ErrorIncognitoPage() {
  const { trackEvent } = useAnalytics();
  const goToOverview = () => {
    router.navigate('/');
  };
  useEffect(() => {
    trackEvent('Error Incognito Page');
  }, []);
  return (
    <div id="error-page">
      <div className="white-wrapper">
        <Link className="CareerOS-logo" to="/">
          <Logo size="large" />
        </Link>

        <div className="error-box">
          <div className="error-box__column">
            <img src={ErrorImage} className="error-box__image" alt="" />
          </div>
          <div className="error-box__column">
            <h3 className="error-box__title">Unfortunately CareerOS login does not work in Incognito </h3>
            <p>Our app uses cookies to log you in, and they are disabled by default in incognito</p>
            <Button handleClick={goToOverview} mode="primary" size="fill" label="Go to Login" />
          </div>
        </div>
      </div>
    </div>
  );
}
