import {
  deleteContact, patchContact,
} from '@/services/api/contact';

export const deleteCompanyContact = async (contactID: string): Promise<any> => {
  await deleteContact(contactID);
  return { contactID };
};

export async function updateContactEmails(
  id: string,
  email: string | null,
  secondary_email: string | null,
) {
  return patchContact({ id, email: email || null, secondary_email: secondary_email || null });
}
