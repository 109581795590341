import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useRef, useState } from 'react';
import { FilterOption } from '@/domains/core/company/types';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { MultiSelectOption } from './multi-select-option/multi-select-option';
import './multi-select.scss';

type Props = {
  options: FilterOption[];
  placeholder: string;
  handleFiltering: (options: FilterOption[]) => void;
  selected: FilterOption[];
  withSelectAllOption?: boolean;
  size?: 'small' | 'medium' | 'large' | 'full';
  labelType: 'list' | 'number' | 'filterAndOptions';
  width?: 'full' | 'fit-box';
  label?: string;
  hint?: string;
  isInvalid?: boolean;
  warningMessage?: string;
  icon?: string;
  showIcon?: boolean;
  textSize?: 'large';
};

export const MultiSelect = ({
  placeholder,
  options,
  handleFiltering,
  labelType,
  selected,
  size = 'medium',
  withSelectAllOption = false,
  width = 'full',
  label,
  hint,
  isInvalid,
  warningMessage,
  icon,
  showIcon,
  textSize,
}: Props) => {
  const { trackEvent } = useAnalytics();
  const { user } = useAuth0();
  const [selectedOptions, setSelectedOptions] = useState<FilterOption[]>([]);
  const [isListOpened, setIsListOpened] = useState(false);
  const container = useRef<HTMLDivElement>(null);

  const handleFilterClick = () => {
    setIsListOpened(!isListOpened);

    trackEvent(`${placeholder} Filter clicked`, user);
  };

  const handleSelectAll = () => {
    if (selectedOptions.length === options.length) {
      handleFiltering([]);
      setSelectedOptions([]);
    } else {
      handleFiltering(options);
      setSelectedOptions(options);
    }
  };

  const handleItemSelect = (option: FilterOption) => {
    let newSelectedOptions: FilterOption[] = [];
    if (selectedOptions.map(o => o.value).includes(option.value)) {
      newSelectedOptions = selectedOptions.filter((item) => item.value !== option.value);
    } else {
      newSelectedOptions = [...selectedOptions, option];
    }
    handleFiltering(newSelectedOptions);
    setSelectedOptions(newSelectedOptions);
  };

  const handleBlur = (e: any) => {
    if (!container.current?.contains(e.target)) {
      setIsListOpened(false);
    }
  };

  const renderSwitchLabel = () => {
    switch (labelType) {
      case 'number':
        return (
          <>
            {placeholder}
            <span className="multi-select__number">{selectedOptions.length}</span>
          </>
        );
      case 'filterAndOptions':
        return `${placeholder} (${selectedOptions.map(o => o.name).join(', ')})`;
      case 'list':
      default:
        return selectedOptions.map(o => o.name).join(', ');
    }
  };

  useEffect(() => {
    setSelectedOptions(selected);
  }, [selected.length]);

  useEffect(() => {
    document.addEventListener('mousedown', handleBlur);

    return () => document.removeEventListener('mousedown', handleBlur);
  }, []);

  return (
    <div className={`multi-select multi-select--${textSize}`}>
      {label && (
        <div className="multi-select__label">
          <span className="multi-select__label-text">{label}</span>
        </div>
      )}
      <div className={`multi-select__input multi-select__input--${size}`} ref={container}>
        <div
          data-testid="multi-select-field"
          className={`multi-select__field ${!selectedOptions.length ? 'multi-select__field--placeholder' : ''} ${isListOpened ? 'multi-select__field--focused' : ''} ${isInvalid ? 'multi-select__field--invalid' : ''}`}
          onClick={handleFilterClick}
        >
          <span className="multi-select__name">{selectedOptions.length ? renderSwitchLabel() : placeholder}</span>
          <i
            className={`multi-select__icon ${isListOpened ? 'multi-select__icon--opened' : ''} bi bi-caret-down-fill`}
          />
        </div>
        {isListOpened && (
          <div className="multi-select__dropdown">
            <ul className="multi-select__list">
              {withSelectAllOption && (
                <li
                  className="multi-select__item multi-select__item--fixed"
                  onClick={handleSelectAll}
                >
                  <div className={`multi-select__checkbox ${selectedOptions.length === options.length ? 'multi-select__checkbox--minus' : ''}`} />
                  <span>
                    {selectedOptions.length === options.length ? 'Deselect all' : 'Select all'}
                  </span>
                </li>
              )}
              {options.map((option) => (
                <li
                  key={option.value.toString()}
                  className="multi-select__item"
                  onClick={() => handleItemSelect(option)}
                >
                  <MultiSelectOption showIcon={showIcon} icon={icon} width={width} isSelected={selectedOptions.map(o => o.value).includes(option.value)} name={option.name} />
                </li>
              ))}
            </ul>
          </div>
        )}
        {isInvalid && <span className="multi-select__warning">{warningMessage}</span>}
      </div>
      {hint && (
        <div className="multi-select__hint">
          <span className="multi-select__hint-text">{hint}</span>
        </div>
      )}
    </div>
  );
};
