import { useCallback, useMemo } from 'react';
import { Tag, TagColors } from '@/components/tag/tag';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { ActionButtonSave } from '@/components/action-buttons/action-button-save';
import { getTagIcons } from '../../helpers/getTagIcons';
import { JobFeedJob } from '../../types/job';
import { sortTags } from '@/services/helpers/job-feed';
import { formatDateDeltaFromNow } from '@/services/helpers/date';
import { Tooltip } from '@/components/tooltip/tooltip';
import './job-tile.scss';
import { JobTileDeadline } from '../job-tile-deadline/job-tile-deadline';
import { AppliedBadge } from '../applied-badge/applied-badge';

type Props = {
  job: JobFeedJob;
  isActive?: boolean;
  isLoading: boolean;
  isSaved: boolean;
  onJobSaveToggle: (jobId: string) => void;
  onSelect: (jobId: string) => void;
  withCompanyName?: boolean;
  onApplyClick: (id: string, url: string, apply: boolean) => void;
};

export const JobTile = ({
  job,
  isActive = false,
  isSaved,
  isLoading,
  onJobSaveToggle,
  onSelect,
  withCompanyName = false,
  onApplyClick,
}: Props) => {
  const handleSave = useCallback(() => onJobSaveToggle(job.id), [onJobSaveToggle, job.id]);
  const sortedTags = useMemo(() => (job && job.tags ? sortTags(job.tags) : []), [job?.tags]);
  const postedDate = useMemo(() => formatDateDeltaFromNow(job.posted_on || job.created_at || ''), [job.posted_on, job.created_at]);

  const handleAppliedClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    onApplyClick(job.id, job.linkedin_url, false);
  };

  return (
    <div
      className={['job-tile', isActive ? 'job-tile--active' : ''].join(' ')}
      onClick={() => onSelect(job.id)}
    >
      <div className="job-tile__action" data-testid="job-tile-loader">
        <ActionButtonSave
          onClick={handleSave}
          isLoading={isLoading}
          iconName="bi-bookmark-heart"
          iconNameSaved="bi-bookmark-heart-fill"
          label="Save"
          labelSaved="Unsave"
          ariaLabel="Save Job"
          ariaLabelSaved="Unsave Job"
          isSaved={isSaved}
        />
      </div>
      <div className="job-tile__main">
        <Tooltip label={job.title} position="top" disableHoverListener={!job.title}>
          <p className="job-tile__title">{job.title || 'No title'}</p>
        </Tooltip>
        {withCompanyName && (
          <div className="job-tile__company" data-testid="job-logo">
            <DefaultLogo
              source={job.linkedin_logo_url}
              type="job"
              name={job.company_name}
              className="job-tile__company-logo"
              size={24}
            />
            <p className="job-tile__company-name">{job.company_name}</p>
          </div>
        )}
        <div className="job-tile__dates">
          <p className="job-tile__posted-on">{postedDate}</p>

          {(job?.deadline && !job?.applied && job.saved && !job.rolling_date) && (
            <JobTileDeadline deadline={job?.deadline} handleClick={() => onApplyClick(job.id, job.linkedin_url, true)} />
          )}

          {job?.applied && (
            <AppliedBadge handleClick={handleAppliedClick} />
          )}
        </div>
      </div>
      <div className="job-tile__bottom">
        {job?.tags && sortedTags.map((tag) => (
          <Tag
            key={tag.tag_type}
            color={TagColors.Grey}
            label={tag.tag_content}
            image={getTagIcons(tag.tag_type)}
          />
        ))}
      </div>
    </div>
  );
};
