import './verified-badge.scss';

export function VerifiedBadge({ label }: { label: string }) {
  return (
    <div className="verified-badge">
      <i className="verified-badge__icon bi bi-check-circle" />
      <span>{label}</span>
    </div>
  );
}
