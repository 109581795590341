import { useEffect, useState } from 'react';
import { useRevalidator } from 'react-router-dom';
import { Button } from '@careeros/coco';
import './edit-goals.scss';
import { GoalInput } from './goal-input';
import { updateTargetGoals } from '@/services/api/user';
import { useAnalytics } from '@/services/hooks/use-analytics';

interface EditGoalsProps {
  companies: number;
  contacts: number;
  connections: number;
  handleClose: () => void;
}

export const EditGoals = ({
  companies, contacts, connections, handleClose,
}: EditGoalsProps) => {
  const [companiesGoal, setCompaniesGoal] = useState(companies);
  const [contactsGoal, setContactsGoal] = useState(contacts);
  const [connectionsGoal, setConnectionsGoal] = useState(connections);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const revalidator = useRevalidator();
  const { trackEvent } = useAnalytics();
  const isGoalValid = (goal: number) => goal > 0 && goal < 100;

  const handleCancel = () => {
    handleClose();
  };
  const handleSave = async () => {
    await updateTargetGoals(companiesGoal, contactsGoal, connectionsGoal);
    trackEvent('Own goals set');
    revalidator.revalidate();
    handleClose();
  };

  useEffect(() => {
    const goalsWasChanged = companies !== companiesGoal || contacts !== contactsGoal || connections !== connectionsGoal;
    if (goalsWasChanged) {
      const allGoalsValid = isGoalValid(companiesGoal) && isGoalValid(contactsGoal) && isGoalValid(connectionsGoal);
      setSaveButtonDisabled(!allGoalsValid);
    } else {
      setSaveButtonDisabled(true);
    }
  }, [companiesGoal, contactsGoal, connectionsGoal]);

  return (
    <div className="edit-goals">
      <div className="edit-goals__fields">
        <GoalInput
          value={companiesGoal}
          label="Companies"
          handleChange={(value) => setCompaniesGoal(value)}
        />
        <GoalInput
          value={contactsGoal}
          label="Contacts"
          handleChange={(value) => setContactsGoal(value)}
        />
        <GoalInput
          value={connectionsGoal}
          label="LinkedIn Invitations"
          handleChange={(value) => setConnectionsGoal(value)}
        />
      </div>
      <div className="edit-goals__actions">
        <Button
          label="Save"
          mode="primary"
          size="small"
          disabled={saveButtonDisabled}
          handleClick={handleSave}
        />
        <Button
          label="Cancel"
          mode="primary"
          outlined
          size="small"
          handleClick={handleCancel}
        />
      </div>
    </div>
  );
};
