import { University } from '@/domains/core/admin/types';
import { Cohort, Student } from '@/domains/core/advisor/types';
import { http } from '@/services/api/http';

export const getAllUniversities = async (): Promise<University[]> => http.get('/universities/all');

export const getCohortsByUniversityID = async (universityID: string): Promise<Cohort[]> => http.get(`/university/${universityID}/cohorts`);

export const getAllStudentsByCohortID = async (cohortID: string): Promise<Student[]> => http.get(`/cohort/${cohortID}/students`);

export const getAllStudents = async (universityID: string): Promise<Student[]> => http.get(`/university/${universityID}/students`);

export const getAllStudentsResumes = async (universityID: string): Promise<Student[]> => http.get(`/university/${universityID}/resumes`);

export const getAllStudentsResumesByCohortID = async (cohortID: string): Promise<Student[]> => http.get(`/cohort/${cohortID}/resumes`);
