import { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { Button } from '@careeros/coco';
import { Input } from '@/components/input/input';
import './custom-template-editor.scss';
import { CustomTemplatesInsertButton } from '../custom-templates-insert-button/custom-templates-insert-button';
import useDebounceEffect from '@/services/hooks/use-debounce';
import { findTextInBrackets } from '@/services/helpers/messages';
import { CustomTemplatePreview } from '../custom-template-preview/custom-template-preview';
import { ContactPlaceholders, TemplatePreviewType } from '../../types';
import { resolveTemplatePlaceholdersWithContactData } from '@/services/helpers/processHtml';
import { useSelf } from '@/services/queries/user';
import { EditorToolbar } from '@/domains/core/contact';

type Props = {
  defaultTitle?: string;
  defaultContent?: string;
  defaultSubject?: string;
  templateType: TemplatePreviewType;
  contactPlaceholders: ContactPlaceholders;
  handleSaveTemplate: (title: string, content: string, subject?: string,) => void;
  handleCancel: () => void;
  maxLength?: number;
  contactEmail?: string;
};

export const CustomTemplateEditor = ({
  contactPlaceholders,
  handleSaveTemplate,
  handleCancel,
  templateType,
  defaultContent,
  defaultSubject,
  defaultTitle,
  maxLength,
  contactEmail,
}: Props) => {
  const { data: self } = useSelf();
  const [title, setTitle] = useState(defaultTitle || '');
  const [content, setContent] = useState(defaultContent || '');
  const [subject, setSubject] = useState(defaultSubject);
  const [contentLength, setContentLength] = useState(0);
  const [lastSelection, setLastSelection] = useState<{ source: 'subject' | 'content', index: number }>({ source: 'content', index: 0 });
  const reactQuillRef = useRef<ReactQuill | null>(null);
  const reactQuillSubjectRef = useRef<ReactQuill | null>(null);
  const highlightPlaceholders = (targetRef: ReactQuill | null) => {
    if (!targetRef) {
      return;
    }
    const quillContentEditor = targetRef.getEditor();

    const bracketedParts = findTextInBrackets(quillContentEditor.getText());
    quillContentEditor.formatText(0, quillContentEditor.getText().length, {
      color: false,
    });
    bracketedParts.forEach(part => {
      quillContentEditor.formatText(part.index, part.length, {
        color: 'var(--purpose-information)',
      });
    });
  };

  const handleVariableInsert = (variable: string) => {
    const targetRef = lastSelection.source === 'subject' ? reactQuillSubjectRef : reactQuillRef;
    const quill = targetRef.current?.getEditor();

    if (lastSelection.index === 0) {
      quill?.insertText(quill?.getLength() ? quill.getLength() - 1 : 0, variable);
      return;
    }

    quill?.insertText(lastSelection.index, variable);
  };

  useEffect(() => {
    setLastSelection({ source: 'content', index: reactQuillRef.current?.getEditor().getSelection()?.index || 0 });
    setContentLength(reactQuillRef.current?.getEditor().getText().trim().length || 0);
  }, [content]);

  useEffect(() => {
    setLastSelection({ source: 'subject', index: reactQuillSubjectRef.current?.getEditor().getSelection()?.index || 0 });
  }, [subject]);

  useDebounceEffect(() => {
    if (content && reactQuillRef.current) {
      highlightPlaceholders(reactQuillRef.current);
    }
  }, [content]);

  useDebounceEffect(() => {
    if (!subject || !reactQuillSubjectRef.current) {
      return;
    }

    highlightPlaceholders(reactQuillSubjectRef.current);

    if (reactQuillSubjectRef.current.getEditor().getText().trim().length === 0) {
      setSubject(undefined);
    }
  }, [subject]);

  useEffect(() => {
    const contentQuill = reactQuillRef.current?.getEditor();
    const subjectQuill = reactQuillSubjectRef.current?.getEditor();

    const handleSelectionChange = (range: { index: number, length: number }, targetQuill: any, source: 'subject' | 'content') => {
      if (range) {
        setLastSelection({ source, index: range.index });
      }
    };

    const handleContentSelectionChange = (range: { index: number, length: number }) => (
      handleSelectionChange(range, contentQuill, 'content')
    );

    const handleSubjectSelectionChange = (range: { index: number, length: number }) => (
      handleSelectionChange(range, subjectQuill, 'subject')
    );

    contentQuill?.on('selection-change', handleContentSelectionChange);
    subjectQuill?.on('selection-change', handleSubjectSelectionChange);

    return () => {
      contentQuill?.off('selection-change', handleContentSelectionChange);
      subjectQuill?.off('selection-change', handleSubjectSelectionChange);
    };
  }, []);

  return (
    <div className="template-editor" data-testid="template-editor">
      {/* TODO: create a separate file for the form */}
      <div className="template-editor__form" data-testid="template-editor-form">
        <Input
          label="Title"
          value={title}
          handleValueChange={setTitle}
          placeholder="E.g. First outreach, follow up"
          id="template-title"
          data-testid="template-title-input"
        />
        <div className="template-editor__subject">
          <div
            onClick={() => {
              reactQuillSubjectRef.current?.focus();
            }}
            className="template-editor__subject-label"
            data-testid="template-subject-label"
          >
            <span className="template-editor__label-text">Subject</span>
            <span className="template-editor__label-text template-editor__label-text--secondary">For emails only</span>
          </div>
          <div
            className="template-editor__subject-input"
            onKeyDownCapture={(e) => {
              if (e.code === 'Enter') {
                e.preventDefault();
                reactQuillRef.current?.focus();
              }
            }}
          >
            <ReactQuill
              ref={reactQuillSubjectRef}
              theme="snow"
              modules={{
                toolbar: null,
              }}
              id="template-subject"
              value={subject}
              placeholder="E.g. Career Insights"
              onChange={setSubject}
              style={{
                border: 'none',
                padding: '0',
                height: 'fit-content',
              }}
              data-testid="template-subject-input"
            />
          </div>
        </div>
        <div data-testid="template-content-input" className="template-editor__content-input">
          <EditorToolbar id="template" />
          <ReactQuill
            id="template-content-input"
            ref={reactQuillRef}
            modules={{ toolbar: '#toolbar-template' }}
            theme="snow"
            value={content}
            placeholder="Start template here"
            onChange={setContent}
            style={{
              border: 'none',
              padding: '0',
              height: 'calc(100% - 24px)',
            }}
          />
        </div>
      </div>

      <div className="template-editor__preview" data-testid="template-editor-preview">
        <CustomTemplatePreview
          subject={subject ? resolveTemplatePlaceholdersWithContactData(subject, contactPlaceholders) : ''}
          template={resolveTemplatePlaceholdersWithContactData(content, contactPlaceholders)}
          messageType={templateType}
          user={{
            name: `${self?.first_name} ${self?.last_name}`,
            picture: self?.profile_picture_url || '',
          }}
          contactEmail={contactEmail}
          maxLength={maxLength}
        />
      </div>

      <div className="template-editor__footer" data-testid="template-editor-footer">
        <CustomTemplatesInsertButton handleVariableInsert={handleVariableInsert} />

        <div className="template-editor__cta">
          <Button
            label="Cancel"
            mode="primary"
            outlined
            size="medium"
            handleClick={handleCancel}
            data-testid="cancel-button"
          />
          <Button
            label="Save"
            mode="primary"
            size="medium"
            disabled={contentLength === 0 || !title}
            handleClick={() => handleSaveTemplate(title, content, subject)}
            data-testid="save-button"
          />
        </div>
      </div>
    </div>
  );
};
