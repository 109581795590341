import { useState } from 'react';
import { Button } from '@careeros/coco';
import { Input } from '@/components/input/input';
import { updateJobDeadline } from '@/services/api/job';
import './job-deadline-edit.scss';
import { Checkbox } from '@/components/checkbox/checkbox';

type JobDeadlineProps = {
  isDateRolling?: boolean;
  deadlineDate?: string;
  handleClose: (deadline: string | null, isRolling: boolean) => void;
  jobID: string;
  isEditing?: boolean;
};

export const JobDeadlineEdit = ({
  isDateRolling = false,
  deadlineDate = '',
  isEditing = false,
  jobID,
  handleClose,
}: JobDeadlineProps) => {
  const [deadline, setDeadline] = useState(deadlineDate);
  const [isRolling, setIsRolling] = useState(isDateRolling);

  const handleDeadlineChange = (date: string) => {
    setDeadline(date);
    setIsRolling(false);
  };
  const handleRollingDateChange = (checked: boolean | undefined) => {
    setIsRolling(!!checked);
    setDeadline(deadline);
  };

  const handleSaveClick: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const deadlineDateTime = deadline ? new Date(deadline).toISOString() : null;
    await updateJobDeadline(jobID, deadlineDateTime, isRolling);
    handleClose(deadlineDateTime, isDateRolling);
  };

  return (
    <form className="job-deadline-edit" onSubmit={handleSaveClick}>
      <Input
        label={`${isEditing ? 'Edit' : 'Add'} application deadline`}
        value={deadline}
        id="deadline"
        placeholder=""
        type="date"
        handleValueChange={handleDeadlineChange}
        min={new Date().toISOString().split('T')[0]}
        max="3000-12-31"
        readOnly={isRolling}
      />
      <Checkbox
        label="No deadline"
        checked={isRolling}
        onChange={handleRollingDateChange}
        id="rollingDate"
      />
      <div className="job-deadline-edit__buttons">
        <Button
          label="Save"
          icon="bi bi-floppy"
          type="submit"
          size="XS"
          mode="primary"
        />
        <Button
          label="Cancel"
          size="XS"
          mode="primary"
          outlined
          handleClick={() => handleClose(deadlineDate, isDateRolling)}
        />
      </div>
    </form>
  );
};
